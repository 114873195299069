//import { useParams } from "react-router-dom";
import QAScore from "pages/assessments/score/QAScore";

// using Proxy Page to remount when route to same route location but different param
function QAScoreProxy() {
  //const { mode, qasurveyId } = useParams();
  return <QAScore /*key={`${mode}/${qasurveyId}`}*/ />;
}

export default QAScoreProxy;
