import { useState } from "react";
//import { useAuthContext } from "context/AuthContext";
//import { useDocument } from "hooks/useDocument";
//import { useParams } from "react-router-dom";
//import { Formik, Form, Field, ErrorMessage } from "formik";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const timeUnitOptions = [];
timeUnitOptions.push({ label: "minute(s)" });
timeUnitOptions.push({ label: "hour(s)" });
function Questionnaire({ mainForm, mainFormField }) {
  const {
    Q_RR1,
    Q_RR2,
    Q_RR3,
    Q_RR4,
    Q_RF1a,
    Q_RF1b,
    Q_RF2,
    Q_WW1,
    Q_WBe1,
    Q_WBa1,
    Q_WBa2,
    Q_WBa3,
    Q_WBa4,
    Q_HF1,
    Q_HT1,
    Q_HT2,
    Q_HT3,
    Q_HT4,
    Q_HSl1,
    Q_HA1,
    Q_HA1_unit,
    Q_HSc1,
  } = mainFormField;

  const { initialValues, values, setFieldValue } = mainForm;

  // destructure from Attachments Form for current section
  const [answerQ_RR1, setAnswerQ_RR1] = useState(initialValues.Q_RR1);
  const [answerQ_RR2, setAnswerQ_RR2] = useState(initialValues.Q_RR2);
  const [answerQ_RR3, setAnswerQ_RR3] = useState(initialValues.Q_RR3);
  const [answerQ_RR4, setAnswerQ_RR4] = useState(initialValues.Q_RR4);
  const [answerQ_RF1a, setAnswerQ_RF1a] = useState(initialValues.Q_RF1a);
  const [answerQ_RF1b, setAnswerQ_RF1b] = useState(initialValues.Q_RF1b);
  const [answerQ_RF2, setAnswerQ_RF2] = useState(initialValues.Q_RF2);
  const [answerQ_WW1, setAnswerQ_WW1] = useState(initialValues.Q_WW1);
  const [answerQ_WBe1, setAnswerQ_WBe1] = useState(initialValues.Q_WBe1);
  const [answerQ_WBa1, setAnswerQ_WBa1] = useState(initialValues.Q_WBa1);
  const [answerQ_WBa2, setAnswerQ_WBa2] = useState(initialValues.Q_WBa2);
  const [answerQ_WBa3, setAnswerQ_WBa3] = useState(initialValues.Q_WBa3);
  const [answerQ_WBa4, setAnswerQ_WBa4] = useState(initialValues.Q_WBa4);
  const [answerQ_HF1, setAnswerQ_HF1] = useState(initialValues.Q_HF1);
  const [answerQ_HT1, setAnswerQ_HT1] = useState(initialValues.Q_HT1);
  const [answerQ_HT2, setAnswerQ_HT2] = useState(initialValues.Q_HT2);
  const [answerQ_HT3, setAnswerQ_HT3] = useState(initialValues.Q_HT3);
  const [answerQ_HT4, setAnswerQ_HT4] = useState(initialValues.Q_HT4);
  const [answerQ_HSl1, setAnswerQ_HSl1] = useState(initialValues.Q_HSl1);
  const [answerQ_HA1, setAnswerQ_HA1] = useState(initialValues.Q_HA1);
  const [answerQ_HA1_unit, setAnswerQ_HA1_unit] = useState({
    label: initialValues.Q_HA1_unit,
  });
  const [answerQ_HSc1, setAnswerQ_HSc1] = useState(initialValues.Q_HSc1);

  return (
    <>
      <Card id="qa-regulation" sx={{ overflow: "visible" }}>
        <MDBox m={2}>
          <MDBox
            borderRadius="lg"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            sx={{ backgroundColor: "#4CAF50" }}
            p={1}
            pl={2}
          >
            <MDTypography variant="body1" fontWeight="bold" color="white">
              Regulation
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox m={3} mt={0}>
          {/* Question Q_RR1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_RR1.minAge && values.age.months <= Q_RR1.maxAge ? (
            <>
              <MDBox>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_RR1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_RR1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_RR1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_RR1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_RR1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_RR1.label_12m_15m}
                      {values.age.months > 15 && Q_RR1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="success"
                        size="small"
                        variant="contained"
                        value={answerQ_RR1}
                        exclusive
                        fullWidth={true}
                        onChange={(event, value) => {
                          value !== null && setAnswerQ_RR1(value);
                          value !== null && setFieldValue(Q_RR1.name, value);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_RR1}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_RR2*/}
          {"age" in values === false ? null : values.age.months >=
              Q_RR2.minAge && values.age.months <= Q_RR2.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_RR2.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_RR2.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_RR2.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_RR2.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_RR2.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_RR2.label_12m_15m}
                      {values.age.months > 15 && Q_RR2.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <MDBox display="flex" justifyContent="right">
                      <ToggleButtonGroup
                        color="success"
                        size="small"
                        variant="contained"
                        value={answerQ_RR2}
                        fullWidth={true}
                        exclusive
                        onChange={(event, value) => {
                          value !== null && setAnswerQ_RR2(value);
                          value !== null && setFieldValue(Q_RR2.name, value);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_RR2}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_RR3*/}
          {"age" in values === false ? null : values.age.months >=
              Q_RR3.minAge && values.age.months <= Q_RR3.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_RR3.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_RR3.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_RR3.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_RR3.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_RR3.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_RR3.label_12m_15m}
                      {values.age.months > 15 && Q_RR3.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="success"
                        size="small"
                        variant="contained"
                        value={answerQ_RR3}
                        fullWidth={true}
                        exclusive
                        onChange={(event, value) => {
                          value !== null && setAnswerQ_RR3(value);
                          value !== null && setFieldValue(Q_RR3.name, value);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_RR3}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_RR4*/}
          {"age" in values === false ? null : values.age.months >=
              Q_RR4.minAge && values.age.months <= Q_RR4.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_RR4.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_RR4.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_RR4.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_RR4.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_RR4.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_RR4.label_12m_15m}
                      {values.age.months > 15 && Q_RR4.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="success"
                        size="small"
                        variant="contained"
                        value={answerQ_RR4}
                        fullWidth={true}
                        exclusive
                        onChange={(event, value) => {
                          value !== null && setAnswerQ_RR4(value);
                          value !== null && setFieldValue(Q_RR4.name, value);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_RR4}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_RF1a*/}
          {"age" in values === false ? null : values.age.months >=
              Q_RF1a.minAge && values.age.months <= Q_RF1a.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_RF1a.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_RF1a.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_RF1a.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_RF1a.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_RF1a.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_RF1a.label_12m_15m}
                      {values.age.months > 15 && Q_RF1a.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <MDBox display="flex" justifyContent="right">
                      <ToggleButtonGroup
                        color="success"
                        size="small"
                        variant="contained"
                        value={answerQ_RF1a}
                        fullWidth={true}
                        exclusive
                        onChange={(event, value) => {
                          if (value !== null) {
                            setAnswerQ_RF1a(value);
                            values.Q_RF1a = value;
                            value === "NO" && setFieldValue(Q_RF1b.name, "NA");
                            value === "NO" && setAnswerQ_RF1b("NA");
                            value === "YES" && (values.Q_RF1b = "");
                            value === "YES" && setFieldValue(Q_RF1b.name, "");
                          }
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_RF1a}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_RF1b*/}
          {"age" in values === false ? null : values.age.months >=
              Q_RF1b.minAge && values.age.months <= Q_RF1b.maxAge ? (
            <>
              <MDBox mt={1}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_RF1b.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_RF1b.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_RF1b.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_RF1b.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_RF1b.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_RF1b.label_12m_15m}
                      {values.age.months > 15 && Q_RF1b.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={9}>
                    <MDBox display="flex" justifyContent="left">
                      <FormTextFieldFast
                        form={mainForm}
                        field={Q_RF1b}
                        variant="standard"
                        value={answerQ_RF1b}
                        //disabled={true}
                        hideLabel={true}
                        //hideField={true}
                        onChange={(event) => {
                          setAnswerQ_RF1b(event.target.value);
                          setFieldValue(Q_RF1b.name, event.target.value);
                          //values.Q_RF1b = event.target.value;
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={3} />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_RF2*/}
          {"age" in values === false ? null : values.age.months >=
              Q_RF2.minAge && values.age.months <= Q_RF2.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_RF2.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_RF2.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_RF2.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_RF2.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_RF2.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_RF2.label_12m_15m}
                      {values.age.months > 15 && Q_RF2.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="success"
                        size="small"
                        variant="contained"
                        value={answerQ_RF2}
                        fullWidth={true}
                        exclusive
                        onChange={(event, value) => {
                          value !== null && setAnswerQ_RF2(value);
                          value !== null && setFieldValue(Q_RF2.name, value);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_RF2}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}
        </MDBox>
      </Card>
      <MDBox mb={3}></MDBox>

      <Card id="qa-weaning" sx={{ overflow: "visible" }}>
        <MDBox m={2} mb={0}>
          <MDBox
            borderRadius="lg"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            sx={{ backgroundColor: "#FFA726" }}
            p={1}
            pl={2}
          >
            <MDTypography variant="body1" fontWeight="bold" color="white">
              Weaning
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox m={3} mt={0}>
          {/* Question Q_WW1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WW1.minAge && values.age.months <= Q_WW1.maxAge ? (
            <>
              <MDBox>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WW1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WW1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WW1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WW1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WW1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WW1.label_12m_15m}
                      {values.age.months > 15 && Q_WW1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={answerQ_WW1}
                        fullWidth={true}
                        exclusive
                        onChange={(event, value) => {
                          value !== null && setAnswerQ_WW1(value);
                          value !== null && setFieldValue(Q_WW1.name, value);
                        }}
                        aria-label="Platform"
                      >
                        {values.age.months <= 9 && (
                          <ToggleButton value="HAVEN'T STARTED">
                            Haven't started
                          </ToggleButton>
                        )}
                        {values.age.months <= 4 && (
                          <ToggleButton value="0-4 MONTHS">
                            0-4 MONTHS
                          </ToggleButton>
                        )}
                        {values.age.months > 4 && values.age.months <= 9 && (
                          <ToggleButton value="4-9 MONTHS">
                            4-9 MONTHS
                          </ToggleButton>
                        )}
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_WW1}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_WBe1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WBe1.minAge && values.age.months <= Q_WBe1.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WBe1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WBe1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WBe1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WBe1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WBe1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WBe1.label_12m_15m}
                      {values.age.months > 15 && Q_WBe1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={answerQ_WBe1}
                        fullWidth={true}
                        exclusive
                        onChange={(event, value) => {
                          value !== null && setAnswerQ_WBe1(value);
                          value !== null && setFieldValue(Q_WBe1.name, value);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_WBe1}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_WBa1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WBa1.minAge && values.age.months <= Q_WBa1.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WBa1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WBa1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WBa1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WBa1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WBa1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WBa1.label_12m_15m}
                      {values.age.months > 15 && Q_WBa1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={answerQ_WBa1}
                        fullWidth={true}
                        exclusive
                        onChange={(event, value) => {
                          value !== null && setAnswerQ_WBa1(value);
                          value !== null && setFieldValue(Q_WBa1.name, value);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_WBa1}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_WBa2*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WBa2.minAge && values.age.months <= Q_WBa2.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WBa2.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WBa2.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WBa2.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WBa2.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WBa2.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WBa2.label_12m_15m}
                      {values.age.months > 15 && Q_WBa2.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={answerQ_WBa2}
                        fullWidth={true}
                        exclusive
                        onChange={(event, value) => {
                          value !== null && setAnswerQ_WBa2(value);
                          value !== null && setFieldValue(Q_WBa2.name, value);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_WBa2}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_WBa3*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WBa3.minAge && values.age.months <= Q_WBa3.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WBa3.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WBa3.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WBa3.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WBa3.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WBa3.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WBa3.label_12m_15m}
                      {values.age.months > 15 && Q_WBa3.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={answerQ_WBa3}
                        fullWidth={true}
                        exclusive
                        onChange={(event, value) => {
                          value !== null && setAnswerQ_WBa3(value);
                          value !== null && setFieldValue(Q_WBa3.name, value);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_WBa3}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_WBa4*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WBa4.minAge && values.age.months <= Q_WBa4.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WBa4.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WBa4.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WBa4.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WBa4.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WBa4.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WBa4.label_12m_15m}
                      {values.age.months > 15 && Q_WBa4.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={answerQ_WBa4}
                        fullWidth={true}
                        exclusive
                        onChange={(event, value) => {
                          value !== null && setAnswerQ_WBa4(value);
                          value !== null && setFieldValue(Q_WBa4.name, value);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_WBa4}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}
        </MDBox>
      </Card>
      <MDBox mb={3}></MDBox>

      <Card id="qa-habituation" sx={{ overflow: "visible" }}>
        <MDBox m={2} mb={0}>
          <MDBox
            borderRadius="lg"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            sx={{ backgroundColor: "#1A73E8" }}
            p={1}
            pl={2}
          >
            <MDTypography variant="body1" fontWeight="bold" color="white">
              Habituation
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox m={3} mt={0}>
          {/* Question Q_HF1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HF1.minAge && values.age.months <= Q_HF1.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_HF1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_HF1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_HF1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_HF1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_HF1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_HF1.label_12m_15m}
                      {values.age.months > 15 && Q_HF1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="info"
                        size="small"
                        variant="contained"
                        value={answerQ_HF1}
                        fullWidth={true}
                        exclusive
                        onChange={(event, value) => {
                          value !== null && setAnswerQ_HF1(value);
                          value !== null && setFieldValue(Q_HF1.name, value);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_HF1}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_HT1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HT1.minAge && values.age.months <= Q_HT1.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_HT1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_HT1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_HT1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_HT1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_HT1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_HT1.label_12m_15m}
                      {values.age.months > 15 && Q_HT1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={9}>
                    <MDBox display="flex" justifyContent="left">
                      <TextField
                        variant="standard"
                        type={"number"}
                        value={answerQ_HT1}
                        onChange={(event) => {
                          if (event.target.value.length > 0) {
                            const parseIntValue = parseInt(
                              event.target.value
                            ).toString();
                            setAnswerQ_HT1(parseIntValue);
                            setFieldValue(Q_HT1.name, parseIntValue);
                          } else {
                            setFieldValue(Q_HT1.name, "");
                            setAnswerQ_HT1("");
                          }
                        }}
                      />
                      <MDBox mr={1} />
                      <MDTypography
                        variant="body2"
                        fontWeight="light"
                        textAlign="justify"
                      >
                        minute(s)
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_HT1}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_HT2*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HT2.minAge && values.age.months <= Q_HT2.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_HT2.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_HT2.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_HT2.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_HT2.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_HT2.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_HT2.label_12m_15m}
                      {values.age.months > 15 && Q_HT2.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={9}>
                    <MDBox display="flex" justifyContent="left">
                      <TextField
                        variant="standard"
                        type={"number"}
                        value={answerQ_HT2}
                        onChange={(event) => {
                          if (event.target.value.length > 0) {
                            const parseIntValue = parseInt(
                              event.target.value
                            ).toString();
                            setAnswerQ_HT2(parseIntValue);
                            setFieldValue(Q_HT2.name, parseIntValue);
                          } else {
                            setFieldValue(Q_HT2.name, "");
                            setAnswerQ_HT2("");
                          }
                        }}
                      />
                      <MDBox mr={1} />
                      <MDTypography
                        variant="body2"
                        fontWeight="light"
                        textAlign="justify"
                      >
                        time(s)
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_HT2}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_HT3*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HT3.minAge && values.age.months <= Q_HT3.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_HT3.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_HT3.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_HT3.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_HT3.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_HT3.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_HT3.label_12m_15m}
                      {values.age.months > 15 && Q_HT3.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="info"
                        size="small"
                        variant="contained"
                        value={answerQ_HT3}
                        fullWidth={true}
                        exclusive
                        onChange={(event, value) => {
                          value !== null && setAnswerQ_HT3(value);
                          value !== null && setFieldValue(Q_HT3.name, value);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_HT3}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_HT4*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HT4.minAge && values.age.months <= Q_HT4.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_HT4.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_HT4.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_HT4.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_HT4.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_HT4.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_HT4.label_12m_15m}
                      {values.age.months > 15 && Q_HT4.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    <MDBox display="flex" justifyContent="right">
                      <ToggleButtonGroup
                        color="info"
                        size="small"
                        variant="contained"
                        value={answerQ_HT4}
                        fullWidth={true}
                        exclusive
                        onChange={(event, value) => {
                          value !== null && setAnswerQ_HT4(value);
                          value !== null && setFieldValue(Q_HT4.name, value);
                        }}
                        aria-label="Platform"
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_HT4}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_HSl1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HSl1.minAge && values.age.months <= Q_HSl1.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 3 &&
                        Q_HSl1.label_0m_3m}
                      {values.age.months > 3 &&
                        values.age.months <= 11 &&
                        Q_HSl1.label_3m_11m}
                      {values.age.months > 11 && Q_HSl1.label_11m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={9}>
                    <MDBox display="flex" justifyContent="left">
                      <TextField
                        variant="standard"
                        type={"number"}
                        value={answerQ_HSl1}
                        onChange={(event) => {
                          if (event.target.value.length > 0) {
                            setAnswerQ_HSl1(event.target.value);
                            setFieldValue(Q_HSl1.name, event.target.value);
                          } else {
                            setFieldValue(Q_HSl1.name, "");
                            setAnswerQ_HSl1("");
                          }
                        }}
                      />
                      <MDBox mr={1} />
                      <MDTypography
                        variant="body2"
                        fontWeight="light"
                        textAlign="justify"
                      >
                        hour(s)
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_HSl1}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_HA1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HA1.minAge && values.age.months <= Q_HA1.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 3 &&
                        Q_HA1.label_0m_3m}
                      {values.age.months > 3 &&
                        values.age.months <= 11 &&
                        Q_HA1.label_3m_11m}
                      {values.age.months > 11 && Q_HA1.label_11m_23m}
                    </MDTypography>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      type={"number"}
                      value={answerQ_HA1}
                      onChange={(event) => {
                        if (event.target.value.length > 0) {
                          setAnswerQ_HA1(event.target.value);
                          setFieldValue(Q_HA1.name, event.target.value);
                        } else {
                          setFieldValue(Q_HA1.name, "");
                          setAnswerQ_HA1("");
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      disablePortal
                      id="autocomplete-timePerFeedLeftValue"
                      value={answerQ_HA1_unit}
                      options={timeUnitOptions}
                      getOptionLabel={(option) =>
                        option?.label ? option.label : ""
                      }
                      size="small"
                      onChange={(event, newValue) => {
                        setAnswerQ_HA1_unit(newValue);
                        if (newValue !== null) {
                          setFieldValue(Q_HA1_unit.name, newValue.label);
                        } else {
                          setFieldValue(Q_HA1_unit.name, "");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Time unit"
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_HA1}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_HA1_unit}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}

          {/* Question Q_HSc1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HSc1.minAge && values.age.months <= Q_HSc1.maxAge ? (
            <>
              <MDBox mt={3}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      textAlign="justify"
                    >
                      {values.age.months >= 0 &&
                        values.age.months <= 3 &&
                        Q_HSc1.label_0m_3m}
                      {values.age.months > 3 &&
                        values.age.months <= 11 &&
                        Q_HSc1.label_3m_11m}
                      {values.age.months > 11 && Q_HSc1.label_11m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={10}>
                    {values.age.months <= 11 ? (
                      <MDBox display="flex" justifyContent="left">
                        <ToggleButtonGroup
                          color="info"
                          size="small"
                          variant="contained"
                          value={answerQ_HSc1}
                          fullWidth={true}
                          exclusive
                          onChange={(event, value) => {
                            value !== null && setAnswerQ_HSc1(value);
                            value !== null && setFieldValue(Q_HSc1.name, value);
                          }}
                          aria-label="Platform"
                        >
                          {values.age.months <= 11 && (
                            <ToggleButton value="YES">YES</ToggleButton>
                          )}
                          {values.age.months <= 11 && (
                            <ToggleButton value="NO">NO</ToggleButton>
                          )}
                          {values.age.months > 11 && (
                            <ToggleButton value="<1 HOUR">
                              {"<"} 1 HOUR
                            </ToggleButton>
                          )}
                          {values.age.months > 11 && (
                            <ToggleButton value=">=1 HOUR">
                              {">="} 1 HOUR
                            </ToggleButton>
                          )}
                        </ToggleButtonGroup>
                      </MDBox>
                    ) : (
                      <MDBox display="flex" justifyContent="left">
                        <TextField
                          variant="standard"
                          type={"number"}
                          value={answerQ_HSc1}
                          onChange={(event) => {
                            if (event.target.value.length > 0) {
                              setAnswerQ_HSc1(event.target.value);
                              setFieldValue(Q_HSc1.name, event.target.value);
                            } else {
                              setFieldValue(Q_HSc1.name, "");
                              setAnswerQ_HSc1("");
                            }
                          }}
                        />
                        <MDBox mr={1} />
                        <MDTypography
                          variant="body2"
                          fontWeight="light"
                          textAlign="justify"
                        >
                          hour(s)
                        </MDTypography>
                      </MDBox>
                    )}
                  </Grid>
                  <Grid item xs={2} />
                </Grid>
              </MDBox>
              <MDBox mt={-1}>
                <Grid item xs={12}>
                  <FormTextFieldFast
                    form={mainForm}
                    field={Q_HSc1}
                    variant="standard"
                    //disabled={true}
                    hideField={true}
                  />
                </Grid>
              </MDBox>
            </>
          ) : null}
        </MDBox>
      </Card>
    </>
  );
}

Questionnaire.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  //modeDisabled: PropTypes.bool.isRequired,
  //mode: PropTypes.string.isRequired,
};

export default Questionnaire;
