const formPhysical = {
  formId: "physical-form",
  formField: {
    environment: {
      name: "environment",
      type: "text",
      label: "Environment",
      errorMsg: "Environment is required.",
      invalidMsg: "Environment is invalid.",
    },
    tummyTime: {
      name: "tummyTime",
      type: "text",
      label: "Tummy Time",
      errorMsg: "Tummy Time is required.",
      invalidMsg: "Tummy Time is invalid.",
    },
    intensity: {
      name: "intensity",
      type: "text",
      label: "Intensity",
      errorMsg: "Intensity is required.",
      invalidMsg: "Intensity is invalid.",
    },
    duration: {
      name: "duration",
      type: "text",
      label: "Duration",
      errorMsg: "Duration is required.",
      invalidMsg: "Duration is invalid.",
    },
    starttime: {
      name: "starttime",
      type: "text",
      label: "Start Time",
      errorMsg: "Start Time is required.",
      invalidMsg: "Start Time is invalid.",
    },
    endtime: {
      name: "endtime",
      type: "text",
      label: "End Time",
      errorMsg: "End Time is required.",
      invalidMsg: "End Time is invalid.",
    },
  },
};

export { formPhysical as default };
