import { useEffect, useCallback, useState } from "react";
import { useDocument } from "hooks/useDocument";

import colors from "assets/theme/base/colors";
import MDBox from "components/atoms/MDBox";
import Highcharts from "highcharts/highcharts";
import HighchartsReact from "highcharts-react-official";
import xrange from "highcharts/modules/xrange";
xrange(Highcharts);

const { dark, grey } = colors;

const collectionPathTrackingDailyRecords = "trackingDailyRecords";
const configPlots = [
  {
    label: "Milk",
    shortLabel: "M",
    type: "milk",
    color: "rgb(243,138,180)",
    value: 0,
  },
  {
    label: "Food",
    shortLabel: "F",
    type: "food",
    color: "rgb(201,21, 94)",
    value: 1,
  },
  {
    label: "Physical Activity",
    shortLabel: "P",
    type: "physical",
    color: "rgb(79,145,205)",
    value: 2,
  },
  {
    label: "Sedentary Activity",
    shortLabel: "Se",
    type: "sedentary",
    color: "rgb(43,99, 149)",
    value: 3,
  },
  {
    label: "Sleep",
    shortLabel: "Sl",
    type: "sleep",
    color: "rgb(146, 208, 80)",
    value: 4,
  },
];

function DailyChart(props) {
  const { retrieveDoc } = useDocument();
  const [plotData, setPlotData] = useState(null);

  const getLineOptionsDark = (colorMode) => ({
    chart: {
      type: "xrange",
      height: 85,
      plotBorderWidth: 1,
      borderWidth: 0,
      plotBackgroundColor: "#F5F5F5",
      plotBorderColor: "#D3D3D3",
    },
    time: {
      useUTC: false,
      timezone: "Asia/Singapore",
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      min: plotData.xAxisStartTime,
      max: plotData.xAxisEndTime,
      lineColor: "#D3D3D3", //"rgb(11,118,160)",
      tickColor: "#D3D3D3", //"rgb(11,118,160)",
      tickPosition: "outside",
      labels: {
        style: {
          color: colorMode === "dark" ? dark.main : grey["400"],
        },
      },
    },
    yAxis: {
      title: {
        text: "", // plotData.yAxisTitleText,
        style: {
          color: colorMode === "dark" ? dark.main : grey["400"],
        },
      },
      min: 0,
      max: 4,
      categories: [
        "Milk",
        "Non milk / Food",
        "Physical Activity",
        "Sedentary Activity",
        "Sleep",
      ],
      reversed: true,
      gridLineWidth: 0,
      offset: -8,
      labels: {
        enabled: false,
        style: {
          color: colorMode === "dark" ? dark.main : grey["400"], //"rgb(11,118,160)",
        },
      },
    },
    series: [
      {
        name: "",
        // pointPadding: 0,
        // groupPadding: 0,
        pointWidth: 12,
        data: plotData.data,
        dataLabels: {
          enabled: true,
        },
        showInLegend: false,
      },
    ],
    credits: {
      enabled: false,
    },
  });

  const getLineOptionsLight = (colorMode) => ({
    chart: {
      type: "xrange",
      height: 85,
      plotBorderWidth: 1,
      borderWidth: 0,
      plotBackgroundColor: "#F5F5F5",
      plotBorderColor: "#D3D3D3",
    },
    time: {
      useUTC: false,
      timezone: "Asia/Singapore",
    },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      min: plotData.xAxisStartTime,
      max: plotData.xAxisEndTime,
      lineColor: "#D3D3D3", //"rgb(11,118,160)",
      tickColor: "#D3D3D3", //"rgb(11,118,160)",
      tickPosition: "outside",
      labels: {
        style: {
          color: colorMode === "dark" ? dark.main : grey["400"],
        },
      },
    },
    yAxis: {
      title: {
        text: "", // plotData.yAxisTitleText,
        style: {
          color: colorMode === "dark" ? dark.main : grey["400"],
        },
      },
      min: 0,
      max: 4,
      categories: [
        "Milk",
        "Non milk / Food",
        "Physical Activity",
        "Sedentary Activity",
        "Sleep",
      ],
      reversed: true,
      gridLineWidth: 0,
      offset: -8,
      labels: {
        enabled: false,
        style: {
          color: colorMode === "dark" ? dark.main : grey["400"], //"rgb(11,118,160)",
        },
      },
    },
    series: [
      {
        name: "",
        // pointPadding: 0,
        // groupPadding: 0,
        pointWidth: 12,
        data: [], //plotData.data,
        dataLabels: {
          enabled: true,
        },
        showInLegend: false,
      },
    ],
    credits: {
      enabled: false,
    },
  });

  const preparePlotData = useCallback(
    async (record) => {
      const calendarDateToday = record.calendarDateToday; // calendarDate: dd/mm/yyyy
      const year = parseInt(calendarDateToday.substring(6, 10));
      const month = parseInt(calendarDateToday.substring(3, 5)) - 1;
      const day = parseInt(calendarDateToday.substring(0, 3));
      const date = new Date(year, month, day, 0, 0, 0);
      const xAxisStartTime = date.getTime();
      const xAxisEndTime = xAxisStartTime + 24 * 3600 * 1000;
      const options = {
        day: "numeric",
        month: "short",
      };
      const dateString = date.toLocaleDateString("en-SG", options);

      let dailyRecordsDaybefore = [];
      if (record.documentIdDaybefore.length > 0) {
        const retrievedDoc = await retrieveDoc(
          collectionPathTrackingDailyRecords,
          record.documentIdDaybefore
        );
        dailyRecordsDaybefore = retrievedDoc.data.records;
      }

      let dailyRecordsToday = [];
      if (record.documentIdToday.length > 0) {
        const retrievedDoc = await retrieveDoc(
          collectionPathTrackingDailyRecords,
          record.documentIdToday
        );
        dailyRecordsToday = retrievedDoc.data.records;
      }
      const totalRecords = dailyRecordsDaybefore.concat(dailyRecordsToday);

      if (totalRecords.length > 0) {
        const data = totalRecords.map((record) => {
          const configPlot = configPlots.find(
            (elm) => elm.type === record.type
          );

          return {
            x: record.startTime.seconds * 1000,
            x2: record.endTime.seconds * 1000,
            y: configPlot.value,
            color: configPlot.color,
          };
        });

        setPlotData({
          data: data,
          xAxisStartTime: xAxisStartTime, //xAxisStartTime,
          xAxisEndTime: xAxisEndTime, //xAxisEndTime,
          yAxisTitleText: dateString,
        });
      } else {
        setPlotData({
          data: [],
          xAxisStartTime: xAxisStartTime, //xAxisStartTime,
          xAxisEndTime: xAxisEndTime, //xAxisEndTime,
          yAxisTitleText: dateString,
        });
      }
    },
    [retrieveDoc]
  );

  useEffect(() => {
    try {
      preparePlotData(props.data);
    } catch (err) {
      //dispatchError(err);
    }
  }, [props.data, preparePlotData]);

  return (
    <MDBox m={0} mr={0}>
      {!!plotData &&
        (props.data.documentIdToday.length > 0 ||
        props.data.documentIdDaybefore.length > 0 ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={getLineOptionsDark("dark")}
          />
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={getLineOptionsLight("light")}
          />
        ))}
    </MDBox>
  );
}

export default DailyChart;
