import Grid from "@mui/material/Grid";
import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";

function NudgeDetails({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  const { week, contents } = mainFormField;

  return (
    <MDBox m={3}>
      <MDBox mb={3}>
        <MDTypography variant="h5">Nudge Details</MDTypography>
      </MDBox>

      <MDBox>
        <Grid
          container
          rowSpacing={{ xs: 1, sm: 2 }}
          columnSpacing={{ xs: 1, sm: 3 }}
        >
          <Grid item xs={12} sm={2}>
            <MDTypography variant="body3" fontWeight="bold">
              {week.label}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormTextFieldFast
              form={mainForm}
              field={week}
              variant="standard"
              disabled={true}
              multiline
            />
          </Grid>
          <Grid item xs={12} sm={2}></Grid>

          <Grid item xs={12} sm={2}>
            <MDTypography variant="body3" fontWeight="bold">
              {contents.label}
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormTextFieldFast
              form={mainForm}
              field={contents}
              variant="standard"
              disabled={modeDisabled}
              multiline
            />
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default NudgeDetails;
