import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorMessage } from "formik";

//import { DateRangePicker, DatePicker } from 'rsuite';
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import isBefore from "date-fns/isBefore";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const locations = [
  { label: "Bedroom" },
  { label: "Stroller" },
  { label: "Car" },
  { label: "Others" },
];

const positions = [{ label: "Side" }, { label: "Prone" }, { label: "Back" }];

const sleepingTools = [
  { label: "Music" },
  { label: "Screen" },
  { label: "White Noise Maker" },
  { label: "Others" },
];

function SleepDetails({
  mainForm,
  mainFormField,
  closeInputPanel,
  calendarDate,
}) {
  const {
    location,
    otherLocation,
    selfCompanion,
    position,
    sleepingTool,
    otherSleepingTool,
    starttime,
    endtime,
  } = mainFormField;
  const { initialValues, setFieldValue } = mainForm;
  /* Init Location */
  const locationSplit =
    initialValues.location.length > 0 ? initialValues.location.split(",") : [];
  const initLocation =
    locationSplit.length > 0
      ? locationSplit.map((elm) => ({
          label: elm,
        }))
      : [];
  const [locationValue, setLocationValue] = useState(initLocation);
  const [otherLocationValue, setOtherLocationValue] = useState(
    initialValues.otherLocation
  );
  const [showOtherLocation, setShowOtherLocation] = useState(false);

  const [selfCompanionValue, setSelfCompanionValue] = useState(
    initialValues.selfCompanion
  );

  /* Position */
  const positionSplit =
    initialValues.position.length > 0 ? initialValues.position.split(",") : [];
  const initPosition =
    positionSplit.length > 0
      ? positionSplit.map((elm) => ({
          label: elm,
        }))
      : [];
  const [positionValue, setPositionValue] = useState(initPosition);

  /* Sleeping Tools */
  const sleepingToolSplit =
    initialValues.sleepingTool.length > 0
      ? initialValues.sleepingTool.split(",")
      : [];
  const initSleepingTool =
    sleepingToolSplit.length > 0
      ? sleepingToolSplit.map((elm) => ({
          label: elm,
        }))
      : [];
  const [toolValue, setToolValue] = useState(initSleepingTool);

  const [otherSleepingToolValue, setOtherSleepingToolValue] = useState(
    initialValues.otherSleepingTool
  );
  const [showOtherSleepingTool, setShowOtherSleepingTool] = useState(false);

  const [startTimeValue, setStartTimeValue] = useState("");
  const [endTimeValue, setEndTimeValue] = useState("");
  const [disableBeforeValue, setDisableBeforeValue] = useState("");
  //const [disableBeforeHour, setDisableBeforeHour] = useState(0);

  useEffect(() => {
    try {
      const dateDisableBefour = new Date(
        calendarDate.getFullYear(),
        calendarDate.getMonth(),
        calendarDate.getDate(),
        0,
        0,
        0
      );
      setDisableBeforeValue(dateDisableBefour);

      const Now = new Date();
      if (initialValues.starttime.length > 0) {
        setStartTimeValue(new Date(initialValues.starttime));
        setFieldValue(starttime.name, initialValues.starttime);
      } else {
        const newDate = new Date(
          calendarDate.getFullYear(),
          calendarDate.getMonth(),
          calendarDate.getDate(),
          Now.getHours(),
          Now.getMinutes(),
          Now.getSeconds()
        );
        setStartTimeValue(newDate);
        setFieldValue(starttime.name, newDate.toISOString());
        //setDisableBeforeHour(newDate.getHours());
      }

      if (initialValues.endtime.length > 0) {
        setEndTimeValue(new Date(initialValues.endtime));
        setFieldValue(endtime.name, initialValues.endtime);
      } else {
        const newDate = new Date(
          calendarDate.getFullYear(),
          calendarDate.getMonth(),
          calendarDate.getDate(),
          Now.getHours(),
          Now.getMinutes(),
          Now.getSeconds()
        );
        newDate.setMinutes(newDate.getMinutes() + 15);
        setEndTimeValue(newDate);
        setFieldValue(endtime.name, newDate.toISOString());
      }

      if (initialValues.location.length > 0) {
        initialValues.location.includes("Others") && setShowOtherLocation(true);
      } else {
        setShowOtherLocation(false);
      }

      if (initialValues.sleepingTool.length > 0) {
        initialValues.sleepingTool.includes("Others") &&
          setShowOtherSleepingTool(true);
      } else {
        setShowOtherSleepingTool(false);
      }
    } catch (err) {
      console.log(err);
    }
  }, [initialValues, starttime, endtime, setFieldValue, calendarDate]);

  return (
    <MDBox m={1} mt={2}>
      <MDBox
        display="flex"
        borderRadius="lg"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "rgb(146, 208, 80)" }}
        p={1}
      >
        <MDBox />
        <MDTypography variant="body2" fontWeight="bold" color="white">
          SLEEP / REST
        </MDTypography>
        <MDButton
          variant="text"
          size="medium"
          color="white"
          iconOnly
          onClick={() => closeInputPanel("sleep")}
        >
          <CloseIcon />
        </MDButton>
      </MDBox>
      <MDBox m={1} mt={3} mb={0}>
        <Grid
          container
          rowSpacing={{ xs: 0 }}
          columnSpacing={{ xs: 1 }}
          alignItems="center"
        >
          {/* Location */}
          <Grid item xs={5}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {location.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={7}>
            <Autocomplete
              multiple
              id="autocomplete-locations"
              value={locationValue}
              options={locations}
              getOptionLabel={(option) => (option?.label ? option.label : "")}
              size="small"
              onChange={(e, newValue) => {
                setLocationValue(newValue);
                if (newValue !== null) {
                  const labelArray = newValue.map((elm) => {
                    return elm.label;
                  });
                  setFieldValue(location.name, labelArray.toString());

                  const index = labelArray.findIndex((elm) => elm === "Others");
                  if (index >= 0) {
                    // "Others" option selected
                    setShowOtherLocation(true);
                    // Set otherLocation data field to empty for user input
                    setOtherLocationValue("");
                    setFieldValue(otherLocation.name, "");
                  } else {
                    // "Others" option not selected
                    setShowOtherLocation(false);
                    // Set otherLocation data field to "NIL"
                    setOtherLocationValue("NIL");
                    setFieldValue(otherLocation.name, "NIL");
                  }
                } else {
                  setFieldValue(location.name, "");
                }
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 2 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
            />
          </Grid>
          {/* Error message - Location */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={0}
              mr={0}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={location.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>

          {/* Other Location */}
          {showOtherLocation && (
            <>
              <Grid item xs={5}>
                <MDBox display="flex">
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                  >
                    {otherLocation.label}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  id="otherLocation"
                  label=""
                  variant="standard"
                  fullWidth
                  value={otherLocationValue}
                  onChange={(e) => {
                    if (!!e.target.value) {
                      setOtherLocationValue(e.target.value);
                      setFieldValue(otherLocation.name, e.target.value);
                    } else {
                      setOtherLocationValue("");
                      setFieldValue(otherLocation.name, "");
                    }
                  }}
                ></TextField>
              </Grid>
              {/* Error message - Other Location */}
              <Grid item xs={12}>
                <MDBox
                  mt={1}
                  ml={0}
                  mr={0}
                  mb={1}
                  display="flex"
                  justifyContent="right"
                >
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    <ErrorMessage name={otherLocation.name}></ErrorMessage>
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} mb={1}></Grid>
            </>
          )}

          <Grid item xs={5}>
            <MDTypography variant="button" fontWeight="regular" color="dark">
              {selfCompanion.label}
            </MDTypography>
          </Grid>
          <Grid item xs={7}>
            <ToggleButtonGroup
              color="info"
              size="small"
              variant="contained"
              value={selfCompanionValue}
              exclusive
              fullWidth={true}
              onChange={(event, value) => {
                value !== null && setSelfCompanionValue(value);
                //value !== null && (values.selfCompanion = value);
                value !== null && setFieldValue(selfCompanion.name, value);
              }}
              aria-label="Platform"
            >
              <ToggleButton value="With">With</ToggleButton>
              <ToggleButton value="Without">Without</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {/* Error message - Self Companion */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={0}
              mr={0}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={selfCompanion.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>

          {/* Position */}
          <Grid item xs={5}>
            <MDTypography variant="button" fontWeight="regular" color="dark">
              {position.label}
            </MDTypography>
          </Grid>
          <Grid item xs={7}>
            <Autocomplete
              multiple
              id="autocomplete-positions"
              value={positionValue}
              options={positions}
              getOptionLabel={(option) => (option?.label ? option.label : "")}
              size="small"
              onChange={(e, newValue) => {
                setPositionValue(newValue);
                if (newValue !== null) {
                  const labelArray = newValue.map((elm) => {
                    return elm.label;
                  });
                  setFieldValue(position.name, labelArray.toString());
                } else {
                  setFieldValue(position.name, "");
                }
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 2 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sleeping Position"
                  inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
            />
          </Grid>
          {/* Error message - Position */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={0}
              mr={0}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={position.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>

          {/* Sleeping Tool */}
          <Grid item xs={5}>
            <MDTypography variant="button" fontWeight="regular" color="dark">
              {sleepingTool.label}
            </MDTypography>
          </Grid>
          <Grid item xs={7}>
            <Autocomplete
              multiple
              id="autocomplete-sleeping-tool"
              value={toolValue}
              options={sleepingTools}
              getOptionLabel={(option) => (option?.label ? option.label : "")}
              size="small"
              onChange={(e, newValue) => {
                setToolValue(newValue);
                if (newValue !== null) {
                  const labelArray = newValue.map((elm) => {
                    return elm.label;
                  });
                  setFieldValue(sleepingTool.name, labelArray.toString());

                  const index = labelArray.findIndex((elm) => elm === "Others");
                  if (index >= 0) {
                    // "Others" option selected
                    setShowOtherSleepingTool(true);
                    // Set otherLocation data field to empty for user input
                    setOtherSleepingToolValue("");
                    setFieldValue(otherSleepingTool.name, "");
                  } else {
                    // "Others" option not selected
                    setShowOtherSleepingTool(false);
                    // Set otherLocation data field to "NIL"
                    setOtherSleepingToolValue("NIL");
                    setFieldValue(otherSleepingTool.name, "NIL");
                  }
                } else {
                  setFieldValue(sleepingTool.name, "");
                }
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 2 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sleeping Tool"
                  inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
            />
          </Grid>
          {/* Error message - Sleeping Tool */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={0}
              mr={0}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={sleepingTool.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>

          {/* Other Sleeping Tool */}
          {showOtherSleepingTool && (
            <>
              <Grid item xs={5}>
                <MDBox display="flex">
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                  >
                    {otherSleepingTool.label}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  id="otherLocation"
                  label=""
                  variant="standard"
                  fullWidth
                  value={otherSleepingToolValue}
                  onChange={(e) => {
                    if (!!e.target.value) {
                      setOtherSleepingToolValue(e.target.value);
                      setFieldValue(otherSleepingTool.name, e.target.value);
                    } else {
                      setOtherSleepingToolValue("");
                      setFieldValue(otherSleepingTool.name, "");
                    }
                  }}
                ></TextField>
              </Grid>
              {/* Error message - Other Location */}
              <Grid item xs={12}>
                <MDBox
                  mt={1}
                  ml={0}
                  mr={0}
                  mb={1}
                  display="flex"
                  justifyContent="right"
                >
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    <ErrorMessage name={otherSleepingTool.name}></ErrorMessage>
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} mb={1}></Grid>
            </>
          )}

          <Grid item xs={5}>
            <MDTypography variant="button" fontWeight="regular" color="dark">
              {starttime.label}
            </MDTypography>
          </Grid>
          <Grid item xs={7}>
            <DatePicker
              format="HH:mm"
              block
              editable={false}
              appearance="default"
              placement="topEnd"
              style={{ width: 300 }}
              size="sm"
              value={startTimeValue instanceof Date ? startTimeValue : null}
              onChange={(date) => {
                if (date !== null) {
                  const newDate = new Date(
                    calendarDate.getFullYear(),
                    calendarDate.getMonth(),
                    calendarDate.getDate(),
                    date.getHours(),
                    date.getMinutes(),
                    0
                  );
                  setFieldValue(starttime.name, newDate.toISOString());
                  setStartTimeValue(newDate);
                  //setDisableBeforeHour(newDate.getHours());
                }
              }}
            />
          </Grid>
          {/* Error message - Start Time */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={0}
              mr={0}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={starttime.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>

          <Grid item xs={5}>
            <MDTypography variant="button" fontWeight="regular" color="dark">
              {endtime.label}
            </MDTypography>
          </Grid>
          <Grid item xs={7}>
            <DatePicker
              format="dd/MM/yyyy HH:mm"
              block
              editable={false}
              appearance="default"
              placement="topEnd"
              style={{ width: 300 }}
              size="sm"
              value={endTimeValue instanceof Date ? endTimeValue : null}
              onChange={(date) => {
                if (date !== null) {
                  setFieldValue(endtime.name, date.toISOString());
                  setEndTimeValue(date);
                }
              }}
              shouldDisableDate={(date) =>
                isBefore(date, new Date(disableBeforeValue))
              }
              //hideHours={(hour) => hour < disableBeforeHour}
            />
          </Grid>
          {/* Error message - End Time */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={0}
              mr={0}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={endtime.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default SleepDetails;
