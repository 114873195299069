import formMilk from "pages/tracking/manage/schemas/formMilk";

const {
  formField: {
    leftBreast,
    timePerFeedLeft,
    rightBreast,
    timePerFeedRight,
    typeofMilk,
    milkVolume,
    typeofFeed,
    whoLed,
    starttime,
    endtime,
  },
} = formMilk;

const initialValuesMilk = {
  [leftBreast.name]: "",
  [timePerFeedLeft.name]: "",
  [rightBreast.name]: "",
  [timePerFeedRight.name]: "",
  [typeofMilk.name]: "",
  [milkVolume.name]: "0",
  [typeofFeed.name]: "",
  [whoLed.name]: "",
  [starttime.name]: "",
  [endtime.name]: "",
};

export { initialValuesMilk as default };
