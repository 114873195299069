//import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";

function ScoreHistory({ records }) {
  //records !== undefined && console.log(JSON.stringify(records));
  //records !== undefined && console.log(JSON.stringify(Array.isArray(records)));
  return (
    <MDBox mb={3}>
      <Card id="qa-pastscores" sx={{ overflow: "visible" }}>
        <MDBox ml={2.5} mt={-1.5}>
          <MDButton
            size="small"
            variant="contained"
            color="success"
            disabled={true}
            key={"pastRecordTitle"}
          >
            History
          </MDButton>
        </MDBox>
        {records !== undefined &&
          Array.isArray(records) &&
          records.toReversed().map((record, index) => {
            return index > 0 ? (
              <MDBox m={2} key={"pastRecordRow" + index}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                  alignItems="center"
                >
                  <Grid item xs={8}>
                    <MDTypography
                      variant="body2"
                      fontWeight="regular"
                      color="dark"
                      textAlign="center"
                    >
                      {record.localeDay}, {record.localeDate}{" "}
                      {record.localeTime}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={4}>
                    <MDButton
                      size="medium"
                      variant="contained"
                      color="success"
                      disabled={true}
                    >
                      {
                        Math.round(record.normalizedScore * 100) +
                          "%" /*parseFloat(record.normalizedScore * 100).toFixed(1)*/
                      }
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            ) : null;
          })}
      </Card>
    </MDBox>
  );
}

export default ScoreHistory;
