import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import { DatePicker, RadioGroup, Radio } from "rsuite";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";

function TimeDetails({ mainForm, mainFormField }) {
  const { initialValues, values, setFieldValue } = mainForm;
  const { timeISOstring, day } = mainFormField;
  const [dayValue, setDayValue] = useState(null);
  const [timeValue, setTimeValue] = useState(null);

  useEffect(() => {
    setTimeValue(initialValues.timeISOstring);
    setFieldValue(timeISOstring.name, initialValues.timeISOstring);
    setDayValue(initialValues.day);
    setFieldValue(day.name, initialValues.day);
  }, [initialValues, timeISOstring, day, setFieldValue]);

  return (
    <MDBox m={3}>
      <MDBox mb={3}>
        <MDTypography variant="h5">
          Setting for weekly nudge sending
        </MDTypography>
      </MDBox>

      <MDBox m={1} mt={3} mb={0}>
        <Grid
          container
          rowSpacing={{ xs: 1 }}
          columnSpacing={{ xs: 1 }}
          alignItems="center"
        >
          <Grid item xs={3}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                Time
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={9}>
            {timeValue !== null && (
              <DatePicker
                format="HH:mm"
                ranges={[]}
                style={{ width: 600 }}
                value={new Date(timeValue)}
                hideHours={(hour) => hour < 8 || hour > 18}
                hideMinutes={(minute) => minute % 15 !== 0}
                hideSeconds={(second) => second % 30 !== 0}
                placement="bottomEnd"
                onChange={(date) => {
                  if (date !== null) {
                    setTimeValue(date.toISOString());
                    setFieldValue(timeISOstring.name, date.toISOString());
                  } else {
                    setTimeValue("");
                    setFieldValue(timeISOstring.name, "");
                  }
                }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {values?.timeISOstring !== null && (
              <FormTextFieldFast
                form={mainForm}
                field={timeISOstring}
                variant="standard"
                disabled={true}
                hideLabel={true}
                hideField={true}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                Day
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            {dayValue !== null && (
              <RadioGroup
                inline
                appearance="picker"
                value={dayValue}
                onChange={(value) => {
                  setFieldValue(day.name, value);
                  setDayValue(value);
                }}
              >
                <Radio value={"SUN"}>Sun</Radio>
                <Radio value={"MON"}>Mon</Radio>
                <Radio value={"TUE"}>Tue</Radio>
                <Radio value={"WED"}>Wed</Radio>
                <Radio value={"THU"}>Thu</Radio>
                <Radio value={"FRI"}>Fri</Radio>
                <Radio value={"SAT"}>Sat</Radio>
              </RadioGroup>
            )}
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            {values?.day !== null && (
              <FormTextFieldFast
                form={mainForm}
                field={day}
                variant="standard"
                disabled={true}
                hideLabel={true}
                hideField={true}
              />
            )}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default TimeDetails;
