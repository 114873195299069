import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";

// Images
import iconAssessment from "assets/images/HELMS_Icons_Vr01_01 A.png"; // iconAssessment.png";
import iconTracking from "assets/images/HELMS_Icons_Vr01_02 A.png"; //iconTracking.png";
//import iconMonitoring from "assets/images/iconMonitoring.png";

const padTo2Digits = (num) => {
  return String(num).padStart(2, "0");
};

function Functions() {
  const now = new Date();
  const routeTo =
    "/tracking/dailylog/" +
    padTo2Digits(now.getDate()) +
    padTo2Digits(now.getMonth() + 1) +
    padTo2Digits(now.getFullYear());

  return (
    <Card id="profile-functions" /*borderRadius="xxl"*/>
      <MDBox p={2} pt={4}>
        <Grid container spacing={4}>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <MDBox display="flex" justifyContent="center">
              <MDButton
                component={Link}
                to="/assessments/qasurvey"
                variant="outlined"
                size="small"
                color="warning"
              >
                <MDBox
                  component="img"
                  src={iconAssessment}
                  alt="Assessment"
                  width={{ xs: "100%", xl: "100%" }}
                  opacity={1.0}
                  p={1}
                />
              </MDButton>
            </MDBox>
            <MDBox display="flex" justifyContent="center" mt={0.5}>
              <MDTypography
                variant="h6"
                color="dark"
                fontWeight="medium"
                opacity={0.8}
              >
                ASSESSMENT
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={4}>
            <MDBox display="flex" justifyContent="center">
              <MDButton
                component={Link}
                to={routeTo}
                variant="outlined"
                size="small"
                color="info"
              >
                <MDBox
                  component="img"
                  src={iconTracking}
                  alt="Tracking"
                  width={{ xs: "100%", xl: "100%" }}
                  opacity={1.0}
                  p={1}
                />
              </MDButton>
            </MDBox>
            <MDBox display="flex" justifyContent="center" mt={0.5}>
              <MDTypography
                variant="h6"
                color="dark"
                fontWeight="medium"
                opacity={0.8}
              >
                TRACKING
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Functions;
