import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DashboardLayout from "components/templates/DashboardLayout";
import TopnavV3 from "components/organisms/TopnavV3";

//import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";
//import InvalidOperation from "components/organisms/InvalidOperation";
//import IncompleteOperation from "components/organisms/IncompleteOperation";
//import PromptAddBabyProfile from "components/organisms/PromptAddBabyProfile";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import Icon from "@mui/material/Icon";
import SubmissionProgress from "components/organisms/SubmissionProgress";
//import Divider from "@mui/material/Divider";
//import Footer from "components/organisms/Footer";
import { useTrackingManager } from "pages/tracking/hooks/useTrackingManager";
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

//import dayjs from 'dayjs';
import lunchTime from "assets/images/HELMS_Icons_Vr01_03A_64.png"; //lunch-time.png";
import physicalActivity from "assets/images/HELMS_Icons_Vr01_06A_64.png";
import sleep from "assets/images/HELMS_Icons_Vr01_09A_64.png"; //sleep.png";
import spoon from "assets/images/HELMS_Icons_Vr01_05A_64.png"; //spoon.png";
import feedingBottle from "assets/images/HELMS_Icons_Vr01_04A_64.png"; //feeding-bottle.png";
import triangle from "assets/images/triangle.png";
import meditation from "assets/images/meditation.png";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import PhysicalDetails from "pages/tracking/manage/components/PhysicalDetails";
import formPhysical from "pages/tracking/manage/schemas/formPhysical";
import initialValuesPhysical from "pages/tracking/manage/schemas/initialValuesPhysical";
import SedentaryDetails from "pages/tracking/manage/components/SedentaryDetails";
import formSedentary from "pages/tracking/manage/schemas/formSedentary";
import initialValuesSedentary from "pages/tracking/manage/schemas/initialValuesSedentary";
import SleepDetails from "pages/tracking/manage/components/SleepDetails";
import formSleep from "pages/tracking/manage/schemas/formSleep";
import initialValuesSleep from "pages/tracking/manage/schemas/initialValuesSleep";
import MilkDetails from "pages/tracking/manage/components/MilkDetails";
import formMilk from "pages/tracking/manage/schemas/formMilk";
import initialValuesMilk from "pages/tracking/manage/schemas/initialValuesMilk";
import FoodDetails from "pages/tracking/manage/components/FoodDetails";
import formFood from "pages/tracking/manage/schemas/formFood";
import initialValuesFood from "pages/tracking/manage/schemas/initialValuesFood";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";

import {
  listLifestyleAdvisory,
  listFeedingAdvisory,
} from "pages/tracking/manage/schemas/setup";

const _ = require("lodash");

const styleButton = {
  p: 0.6,
  border: "1px solid grey",
  borderRadius: "50%",
  backgroundColor: "white",
};

const collectionPathBabies = "babies";

function Tracking() {
  const { date } = useParams();
  const {
    response,
    //modeValidation,
    //modeFieldDisabled,
    //modeSubmit,
    modeTitle,
    //formId,
    //formField,
    submitNew,
    calendarDate,
    setCalendarDate,
    dispatchError,
    setIsUnmounted,
    retrieveBabyUid,
    babyUid,
    babyProfile,
    retrieveDoc,
  } = useTrackingManager("dailylog", date);

  const { formId, formField } = formSleep;
  const { formId: formIdSendetary, formField: formFieldSedentary } =
    formSedentary;
  const { formId: formIdPhysical, formField: formFieldPhysical } = formPhysical;
  const { formId: formIdMilk, formField: formFieldMilk } = formMilk;
  const { formId: formIdFood, formField: formFieldFood } = formFood;

  const avatarURL =
    babyProfile?.avatarAttachments !== undefined
      ? babyProfile.avatarAttachments[0].attachmentURL
      : null;

  //const clonedValues = _.cloneDeep(initialValuesSleep);
  //const [sleepValues] = useState(clonedValues);
  const [showButtonPanel, setShowButtonPanel] = useState(true);
  const [showMealTimeButtons, setShowMealTimeButtons] = useState(false);
  const [showPhysicalButtons, setShowPhysicalButtons] = useState(false);
  const [showSleepInputPanel, setShowSleepInputPanel] = useState(false);
  const [showSedentaryInputPanel, setShowSedentaryInputPanel] = useState(false);
  const [showPhysicalInputPanel, setShowPhysicalInputPanel] = useState(false);
  const [showMilkInputPanel, setShowMilkInputPanel] = useState(false);
  const [showFoodInputPanel, setShowFoodInputPanel] = useState(false);
  const [initialValuesEditPhysical, setInitialValuesEditPhysical] =
    useState(null);
  const [initialValuesEditSedentary, setInitialValuesEditSedentary] =
    useState(null);
  const [initialValuesEditSleep, setInitialValuesEditSleep] = useState(null);
  const [initialValuesEditMilk, setInitialValuesEditMilk] = useState(null);
  const [initialValuesEditFood, setInitialValuesEditFood] = useState(null);
  const [lifestyleAdvisory, setLifestyleAdvisory] = useState([]);
  const [feedingAdvisory, setFeedingAdvisory] = useState([]);

  const [openCalendar, setOpenCalendar] = useState(false);
  const [activityValues, setActivityValues] = useState([]);
  const modeEdit = useRef(false);
  const rowIndex = useRef(-1);
  const modeSubmit = useRef("Submit new");

  const padTo2Digits = (num) => {
    return String(num).padStart(2, "0");
  };

  const resetFlags = () => {
    modeEdit.current = false;
    modeSubmit.current = "Submit new";
    rowIndex.current = -1;
  };

  const closeInputPanel = (type) => {
    switch (type) {
      case "physical":
        setShowPhysicalInputPanel(false);
        break;
      case "sedentary":
        setShowSedentaryInputPanel(false);
        break;
      case "sleep":
        setShowSleepInputPanel(false);
        break;
      case "milk":
        setShowMilkInputPanel(false);
        break;
      case "food":
        setShowFoodInputPanel(false);
        break;
      default:
        break;
    }
    resetFlags();
  };

  const handleSubmitPhysical = async (values, actions) => {
    const startTimeISOstring = values.starttime;
    const startTime = new Date(startTimeISOstring);
    const startHHMM =
      padTo2Digits(startTime.getHours()) +
      ":" +
      padTo2Digits(startTime.getMinutes());
    const endTimeISOstring = values.endtime;
    const endTime = new Date(endTimeISOstring);
    const endHHMM =
      padTo2Digits(endTime.getHours()) +
      ":" +
      padTo2Digits(endTime.getMinutes());
    const durationMinutes = Math.floor((endTime - startTime) / (60 * 1000));

    const details = [];
    details.push(values.environment);
    details.push(values.tummyTime);
    details.push(values.intensity);

    const desc = [];
    desc.push({ label: "Environment: ", content: values.environment });
    desc.push({ label: "Tummy Time: ", content: values.tummyTime });
    desc.push({ label: "Intensity: ", content: values.intensity });

    const newValues = _.cloneDeep(activityValues);
    if (rowIndex.current === -1) {
      newValues.push({
        type: "physical",
        durationMinutes: durationMinutes,
        startHHMM: startHHMM,
        startTime: startTime,
        startTimeISOstring: startTimeISOstring,
        endHHMM: endHHMM,
        endTime: endTime,
        endTimeISOstring: endTimeISOstring,
        desc: desc,
        details: details,
      });
    } else {
      newValues[rowIndex.current].durationMinutes = durationMinutes;
      newValues[rowIndex.current].startHHMM = startHHMM;
      newValues[rowIndex.current].startTime = startTime;
      newValues[rowIndex.current].startTimeISOstring = startTimeISOstring;
      newValues[rowIndex.current].endHHMM = endHHMM;
      newValues[rowIndex.current].endTime = endTime;
      newValues[rowIndex.current].endTimeISOstring = endTimeISOstring;
      newValues[rowIndex.current].desc = desc;
      newValues[rowIndex.current].details = details;
    }

    newValues.sort((a, b) => {
      return a.startTimeISOstring > b.startTimeISOstring ? 1 : -1;
    });
    setActivityValues(newValues);
    await submitNew(newValues, calendarDate, modeSubmit);
    closeInputPanel("physical");
  };

  const handleSubmitSedentary = async (values, actions) => {
    const startTimeISOstring = values.starttime;
    const startTime = new Date(startTimeISOstring);
    const startHHMM =
      padTo2Digits(startTime.getHours()) +
      ":" +
      padTo2Digits(startTime.getMinutes());
    const endTimeISOstring = values.endtime;
    const endTime = new Date(endTimeISOstring);
    const endHHMM =
      padTo2Digits(endTime.getHours()) +
      ":" +
      padTo2Digits(endTime.getMinutes());
    const durationMinutes = Math.floor((endTime - startTime) / (60 * 1000));

    const details = [];
    details.push(values.sedentaryActivity);
    details.push(values.distractionUsed);
    details.push(values.screenBased);
    details.push(values.interactive);

    const desc = [];
    desc.push({ label: "Activity: ", content: values.sedentaryActivity });
    desc.push({ label: "Distraction Used: ", content: values.distractionUsed });
    desc.push({ label: "Screen Based: ", content: values.screenBased });
    desc.push({ label: "Interactive: ", content: values.interactive });

    const newValues = _.cloneDeep(activityValues);
    if (rowIndex.current === -1) {
      newValues.push({
        type: "sedentary",
        durationMinutes: durationMinutes,
        startHHMM: startHHMM,
        startTime: startTime,
        startTimeISOstring: startTimeISOstring,
        endHHMM: endHHMM,
        endTime: endTime,
        endTimeISOstring: endTimeISOstring,
        desc: desc,
        details: details,
      });
    } else {
      newValues[rowIndex.current].durationMinutes = durationMinutes;
      newValues[rowIndex.current].startHHMM = startHHMM;
      newValues[rowIndex.current].startTime = startTime;
      newValues[rowIndex.current].startTimeISOstring = startTimeISOstring;
      newValues[rowIndex.current].endHHMM = endHHMM;
      newValues[rowIndex.current].endTime = endTime;
      newValues[rowIndex.current].endTimeISOstring = endTimeISOstring;
      newValues[rowIndex.current].desc = desc;
      newValues[rowIndex.current].details = details;
    }

    newValues.sort((a, b) => {
      return a.startTimeISOstring > b.startTimeISOstring ? 1 : -1;
    });
    setActivityValues(newValues);
    await submitNew(newValues, calendarDate, modeSubmit);
    closeInputPanel("sedentary");
  };

  const handleSubmitSleep = async (values, actions) => {
    const startTimeISOstring = values.starttime;
    const startTime = new Date(startTimeISOstring);
    const startHHMM =
      padTo2Digits(startTime.getHours()) +
      ":" +
      padTo2Digits(startTime.getMinutes());
    const endTimeISOstring = values.endtime;
    const endTime = new Date(endTimeISOstring);
    const endHHMM =
      padTo2Digits(endTime.getHours()) +
      ":" +
      padTo2Digits(endTime.getMinutes());

    const durationMinutes = Math.floor((endTime - startTime) / (60 * 1000));

    const details = [];
    details.push(values.location);
    details.push(values.otherLocation);
    details.push(values.selfCompanion);
    details.push(values.position);
    details.push(values.sleepingTool);
    details.push(values.otherSleepingTool);

    const desc = [];

    const locationSplit =
      values.location.length > 0 ? values.location.split(",") : [];
    if (locationSplit.length > 0) {
      desc.push({ label: "Location(s): ", content: locationSplit.join(", ") });
    }

    desc.push({ label: "Other Location(s): ", content: values.otherLocation });

    desc.push({ label: "Companion: ", content: values.selfCompanion });

    const positionSplit =
      values.position.length > 0 ? values.position.split(",") : [];
    if (positionSplit.length > 0) {
      desc.push({ label: "Position(s): ", content: positionSplit.join(", ") });
    }

    const sleepingToolSplit =
      values.sleepingTool.length > 0 ? values.sleepingTool.split(",") : [];
    if (sleepingToolSplit.length > 0) {
      desc.push({ label: "Tool(s): ", content: sleepingToolSplit.join(", ") });
    }

    desc.push({ label: "Other Tool(s): ", content: values.otherSleepingTool });

    const newActivityValues = _.cloneDeep(activityValues);
    if (rowIndex.current === -1) {
      newActivityValues.push({
        type: "sleep",
        durationMinutes: durationMinutes,
        startHHMM: startHHMM,
        startTime: startTime,
        startTimeISOstring: startTimeISOstring,
        endHHMM: endHHMM,
        endTime: endTime,
        endTimeISOstring: endTimeISOstring,
        desc: desc,
        details: details,
      });
    } else {
      newActivityValues[rowIndex.current].durationMinutes = durationMinutes;
      newActivityValues[rowIndex.current].startHHMM = startHHMM;
      newActivityValues[rowIndex.current].startTime = startTime;
      newActivityValues[rowIndex.current].startTimeISOstring =
        startTimeISOstring;
      newActivityValues[rowIndex.current].endHHMM = endHHMM;
      newActivityValues[rowIndex.current].endTime = endTime;
      newActivityValues[rowIndex.current].endTimeISOstring = endTimeISOstring;
      newActivityValues[rowIndex.current].desc = desc;
      newActivityValues[rowIndex.current].details = details;
    }

    newActivityValues.sort((a, b) => {
      return a.startTimeISOstring > b.startTimeISOstring ? 1 : -1;
    });
    setActivityValues(newActivityValues);
    await submitNew(newActivityValues, calendarDate, modeSubmit);
    closeInputPanel("sleep");
  };

  const handleSubmitMilk = async (values, actions) => {
    const startTimeISOstring = values.starttime;
    const startTime = new Date(startTimeISOstring);
    const startHHMM =
      padTo2Digits(startTime.getHours()) +
      ":" +
      padTo2Digits(startTime.getMinutes());
    const endTimeISOstring = values.endtime;
    const endTime = new Date(endTimeISOstring);
    const endHHMM =
      padTo2Digits(endTime.getHours()) +
      ":" +
      padTo2Digits(endTime.getMinutes());

    const durationMinutes = Math.floor((endTime - startTime) / (60 * 1000));

    const details = [];
    details.push(values.timePerFeedLeft);
    details.push(values.timePerFeedRight);
    details.push(values.typeofMilk);
    details.push(values.milkVolume);
    details.push(values.whoLed);

    const desc = [];
    const desc1 =
      values.timePerFeedLeft.length > 0 && values.timePerFeedRight.length > 0
        ? values.timePerFeedLeft + " (L), " + values.timePerFeedRight + " (R)"
        : values.timePerFeedLeft.length > 0
        ? values.timePerFeedLeft + " (L)"
        : values.timePerFeedRight.length > 0
        ? values.timePerFeedRight + " (R)"
        : null;
    !!desc1 && desc.push({ label: "Breast Feed: ", content: desc1 });

    const desc2 =
      values.typeofMilk.length > 0 && values.milkVolume > 0
        ? values.milkVolume.toString() + "ml (" + values.typeofMilk + ")"
        : null;
    !!desc2 && desc.push({ label: "Bottle Feed: ", content: desc2 });

    desc.push({ label: values.whoLed, content: "" });

    const newActivityValues = _.cloneDeep(activityValues);
    if (rowIndex.current === -1) {
      newActivityValues.push({
        type: "milk",
        durationMinutes: durationMinutes,
        startHHMM: startHHMM,
        startTime: startTime,
        startTimeISOstring: startTimeISOstring,
        endHHMM: endHHMM,
        endTime: endTime,
        endTimeISOstring: endTimeISOstring,
        desc: desc,
        details: details,
      });
    } else {
      newActivityValues[rowIndex.current].durationMinutes = durationMinutes;
      newActivityValues[rowIndex.current].startHHMM = startHHMM;
      newActivityValues[rowIndex.current].startTime = startTime;
      newActivityValues[rowIndex.current].startTimeISOstring =
        startTimeISOstring;
      newActivityValues[rowIndex.current].endHHMM = endHHMM;
      newActivityValues[rowIndex.current].endTime = endTime;
      newActivityValues[rowIndex.current].endTimeISOstring = endTimeISOstring;
      newActivityValues[rowIndex.current].desc = desc;
      newActivityValues[rowIndex.current].details = details;
    }

    newActivityValues.sort((a, b) => {
      return a.startTimeISOstring > b.startTimeISOstring ? 1 : -1;
    });
    setActivityValues(newActivityValues);
    await submitNew(newActivityValues, calendarDate, modeSubmit);
    closeInputPanel("milk");
  };

  const handleSubmitFood = async (values, actions) => {
    const startTimeISOstring = values.starttime;
    const startTime = new Date(startTimeISOstring);
    const startHHMM =
      padTo2Digits(startTime.getHours()) +
      ":" +
      padTo2Digits(startTime.getMinutes());
    const endTimeISOstring = values.endtime;
    const endTime = new Date(endTimeISOstring);
    const endHHMM =
      padTo2Digits(endTime.getHours()) +
      ":" +
      padTo2Digits(endTime.getMinutes());

    const durationMinutes = Math.floor((endTime - startTime) / (60 * 1000));

    const details = [];
    details.push(values.foodType);
    details.push(values.drinkType);
    details.push(values.otherDrinkType);
    details.push(values.whoLed);
    details.push(values.location);
    details.push(values.otherLocation);
    details.push(values.sittingOn);
    details.push(values.otherSittingOn);
    details.push(values.utensil);
    details.push(values.otherUtensil);

    const desc = [];
    const foodTypeSplit =
      values.foodType.length > 0 ? values.foodType.split(",") : [];
    if (foodTypeSplit.length > 0) {
      desc.push({ label: "Food(s): ", content: foodTypeSplit.join(", ") });
    }

    const drinkTypeSplit =
      values.drinkType.length > 0 ? values.drinkType.split(",") : [];
    if (drinkTypeSplit.length > 0) {
      desc.push({ label: "Drink(s): ", content: drinkTypeSplit.join(", ") });
    }

    desc.push({
      label: "Others (Drink):",
      content: values.otherDrinkType,
    });

    desc.push({ label: values.whoLed, content: "" });

    const locationSplit =
      values.location.length > 0 ? values.location.split(",") : [];
    if (locationSplit.length > 0) {
      desc.push({ label: "Location(s): ", content: locationSplit.join(", ") });
    }

    desc.push({
      label: "Others (Location):",
      content: values.otherLocation,
    });

    const sittingOnSplit =
      values.sittingOn.length > 0 ? values.sittingOn.split(",") : [];
    if (sittingOnSplit.length > 0) {
      desc.push({ label: "Sitting On: ", content: sittingOnSplit.join(", ") });
    }

    desc.push({
      label: "Others (Sitting On):",
      content: values.otherSittingOn,
    });

    const utensilSplit =
      values.utensil.length > 0 ? values.utensil.split(",") : [];
    if (utensilSplit.length > 0) {
      desc.push({ label: "Utensil(s): ", content: utensilSplit.join(", ") });
    }

    desc.push({
      label: "Others (Utensil):",
      content: values.otherUtensil,
    });

    const newActivityValues = _.cloneDeep(activityValues);
    if (rowIndex.current === -1) {
      newActivityValues.push({
        type: "food",
        durationMinutes: durationMinutes,
        startHHMM: startHHMM,
        startTime: startTime,
        startTimeISOstring: startTimeISOstring,
        endHHMM: endHHMM,
        endTime: endTime,
        endTimeISOstring: endTimeISOstring,
        desc: desc,
        details: details,
      });
    } else {
      newActivityValues[rowIndex.current].durationMinutes = durationMinutes;
      newActivityValues[rowIndex.current].startHHMM = startHHMM;
      newActivityValues[rowIndex.current].startTime = startTime;
      newActivityValues[rowIndex.current].startTimeISOstring =
        startTimeISOstring;
      newActivityValues[rowIndex.current].endHHMM = endHHMM;
      newActivityValues[rowIndex.current].endTime = endTime;
      newActivityValues[rowIndex.current].endTimeISOstring = endTimeISOstring;
      newActivityValues[rowIndex.current].desc = desc;
      newActivityValues[rowIndex.current].details = details;
    }

    newActivityValues.sort((a, b) => {
      return a.startTimeISOstring > b.startTimeISOstring ? 1 : -1;
    });
    setActivityValues(newActivityValues);
    await submitNew(newActivityValues, calendarDate, modeSubmit);
    closeInputPanel("food");
  };

  const handleDelete = async (idx) => {
    const newActivityValues = _.cloneDeep(activityValues);
    newActivityValues.splice(idx, 1);
    setActivityValues(newActivityValues);
    await submitNew(newActivityValues, calendarDate, modeSubmit);
    resetFlags();
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const leadingActions = (value, idx) => (
    <LeadingActions>
      <SwipeAction
        onClick={() => {
          modeEdit.current = true;
          modeSubmit.current = "Edit";
          rowIndex.current = idx;
          switch (value.type) {
            case "physical":
              setInitialValuesEditPhysical({
                environment: value.details[0],
                tummyTime: value.details[1],
                intensity: value.details[2],
                starttime: value.startTimeISOstring,
                endtime: value.endTimeISOstring,
              });
              setShowPhysicalInputPanel(true);
              break;
            case "sedentary":
              setInitialValuesEditSedentary({
                sedentaryActivity: value.details[0],
                distractionUsed: value.details[1],
                screenBased: value.details[2],
                interactive: value.details[3],
                starttime: value.startTimeISOstring,
                endtime: value.endTimeISOstring,
              });
              setShowSedentaryInputPanel(true);
              break;
            case "sleep":
              setInitialValuesEditSleep({
                location: value.details[0],
                otherLocation: value.details[1],
                selfCompanion: value.details[2],
                position: value.details[3],
                sleepingTool: value.details[4],
                otherSleepingTool: value.details[5],
                starttime: value.startTimeISOstring,
                endtime: value.endTimeISOstring,
              });
              setShowSleepInputPanel(true);
              break;
            case "milk":
              setInitialValuesEditMilk({
                timePerFeedLeft: value.details[0],
                timePerFeedRight: value.details[1],
                typeofMilk: value.details[2],
                milkVolume: value.details[3],
                whoLed: value.details[4],
                starttime: value.startTimeISOstring,
                endtime: value.endTimeISOstring,
              });
              setShowMilkInputPanel(true);
              break;
            case "food":
              setInitialValuesEditFood({
                foodType: value.details[0],
                drinkType: value.details[1],
                otherDrinkType: value.details[2],
                whoLed: value.details[3],
                location: value.details[4],
                otherLocation: value.details[5],
                sittingOn: value.details[6],
                otherSittingOn: value.details[7],
                utensil: value.details[8],
                otherUtensil: value.details[9],
                starttime: value.startTimeISOstring,
                endtime: value.endTimeISOstring,
              });
              setShowFoodInputPanel(true);
              break;
            default:
              break;
          }
        }}
      >
        <MDBox
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: "green",
          }}
          pl={1}
          pr={1}
        >
          <MDButton
            variant="text"
            size="large"
            color="white"
            iconOnly
            onClick={scrollToTop}
          >
            <EditIcon />
          </MDButton>
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="white"
            onClick={scrollToTop}
          >
            Edit
          </MDTypography>
        </MDBox>
      </SwipeAction>
    </LeadingActions>
  );

  const trailingActions = (idx) => (
    <TrailingActions>
      <SwipeAction
        destructive={true}
        onClick={() => {
          modeSubmit.current = "Delete";
          handleDelete(idx);
        }}
      >
        <MDBox
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: "red",
          }}
          pl={1}
          pr={1}
        >
          <MDButton variant="text" size="large" color="white" iconOnly>
            <DeleteIcon />
          </MDButton>
          <MDTypography variant="button" fontWeight="regular" color="white">
            Delete
          </MDTypography>
        </MDBox>
      </SwipeAction>
    </TrailingActions>
  );

  const getIcon = (type) => {
    const src =
      type === "sleep"
        ? sleep
        : type === "sedentary"
        ? meditation
        : type === "physical"
        ? triangle
        : type === "milk"
        ? feedingBottle
        : type === "food"
        ? spoon
        : null;
    return src;
  };

  const navigate = useNavigate();
  const navigateAfter = (path, millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        navigate(path);
      }, millisecond);
    });
  };

  useEffect(() => {
    try {
      setActivityValues(response.data.dailyRecords);
    } catch (err) {
      dispatchError(err);
    }
  }, [dispatchError, response.data, setIsUnmounted]);

  /*useEffect(() => {
    if (!!date) {
      // Date is selected from the "WeeklyLog" page
      const day = parseInt(date.substring(0, 2));
      const month = parseInt(date.substring(2, 4)) - 1;
      const year = parseInt(date.substring(4, 8));
      const newCalendarDate = new Date(year, month, day, 0, 0, 0);
      setCalendarDate(newCalendarDate);
    }
  }, [date, setCalendarDate, babyUid]);*/

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const checkAllFoodTypes = (listFood) => {
    if (
      listFood.includes("Carbohydrate") &&
      listFood.includes("Protein") &&
      listFood.includes("Vegetable") &&
      listFood.includes("Fat")
    )
      return true;
    else return false;
  };

  const checkSweetDrinks = (listDrinks) => {
    if (listDrinks.includes("Juice") || listDrinks.includes("Others"))
      return true;
    else return false;
  };

  const prepareAdvisoryNote = async () => {
    const daybeforeRecords = response.data.daybeforeRecords;

    const lifestyleNotes = [];
    const feedingNotes = [];
    if (daybeforeRecords.length > 0) {
      let listFoodTypesTaken = [];
      let listDrinksTaken = [];
      let mealDurationTooLong = false;
      let mealAfter7pm = false;
      let countCaregiverLed = 0;
      let countBabyLed = 0;
      let totalSleepingHours = 0;
      let totalPhysicalActivityMins = 0;
      let totalScreenTimeMins = 0;
      daybeforeRecords.forEach((record) => {
        record.type === "sleep" &&
          (totalSleepingHours += record.durationMinutes);
        record.type === "physical" &&
          (totalPhysicalActivityMins += record.durationMinutes);
        record.type === "sedentary" &&
          record.details[1] === "Yes" &&
          (totalScreenTimeMins += record.durationMinutes);

        if (record.type === "milk")
          record.details[4].toLowerCase() === "caregiver led"
            ? countCaregiverLed++
            : countBabyLed++;

        if (record.type === "food")
          record.details[2].toLowerCase() === "caregiver led"
            ? countCaregiverLed++
            : countBabyLed++;

        if (record.type === "food") {
          const foodsString = record.details[0];
          const foodsArray = foodsString.split(",");
          foodsArray.forEach((elm) => {
            listFoodTypesTaken.push(elm);
          });

          const drinksString = record.details[1];
          const drinksArray = drinksString.split(",");
          drinksArray.forEach((elm) => {
            listDrinksTaken.push(elm);
          });

          const startHHMM = record.startHHMM;
          const mealHour = parseInt(startHHMM.substring(0, 2));
          if (mealHour >= 19) mealAfter7pm = true;

          if (record.durationMinutes > 30) mealDurationTooLong = true;
        }
      });
      totalSleepingHours /= 60;
      /*console.log("Sleeping: " + JSON.stringify(totalSleepingHours));
      console.log("Physical: " + JSON.stringify(totalPhysicalActivityMins));
      console.log("Screen: " + JSON.stringify(totalScreenTimeMins));
      console.log("Foods: " + JSON.stringify(listFoodTypesTaken));
      console.log("All food types: " + checkAllFoodTypes(listFoodTypesTaken));
      console.log("Sweet drink: " + checkSweetDrinks(listDrinksTaken));
      console.log("MealAfter7pm: " + mealAfter7pm);
      console.log("countCaregiverLed: " + countCaregiverLed);
      console.log("countBabyLed: " + countBabyLed);*/

      let retrievedBaby = null;
      if (!!babyUid || babyUid === null) {
        const babyUidDb = await retrieveBabyUid();
        retrievedBaby = await retrieveDoc(collectionPathBabies, babyUidDb);
      } else {
        console.log(babyUid);
        retrievedBaby = await retrieveDoc(collectionPathBabies, babyUid);
      }

      const dob = new Date(
        retrievedBaby.data.dob.toDate().toLocaleDateString("en-US")
      );
      const date1 = new Date();
      const elapsedDays = (date1 - dob) / (1000 * 60 * 60 * 24);
      const days = parseInt(elapsedDays);
      const months = parseFloat(days / 30).toFixed(1); // Math.ceil(days / 30);

      if (months < 4 && (totalSleepingHours < 14 || totalSleepingHours > 17))
        lifestyleNotes.push(listLifestyleAdvisory[0]);
      else if (
        months < 12 &&
        (totalSleepingHours < 12 || totalSleepingHours > 15)
      )
        lifestyleNotes.push(listLifestyleAdvisory[1]);
      else if (
        months < 24 &&
        (totalSleepingHours < 11 || totalSleepingHours > 14)
      )
        lifestyleNotes.push(listLifestyleAdvisory[2]);

      if (months < 4 && totalPhysicalActivityMins < 30)
        lifestyleNotes.push(listLifestyleAdvisory[3]);
      else if (months < 12 && totalPhysicalActivityMins < 60)
        lifestyleNotes.push(listLifestyleAdvisory[4]);
      else if (months < 24 && totalPhysicalActivityMins < 3 * 60)
        lifestyleNotes.push(listLifestyleAdvisory[5]);

      if (months < 12 && totalScreenTimeMins > 0)
        lifestyleNotes.push(listLifestyleAdvisory[6]);
      else if (months < 24 && totalScreenTimeMins > 60)
        lifestyleNotes.push(listLifestyleAdvisory[7]);

      if (months < 4 && listFoodTypesTaken.length > 0)
        feedingNotes.push(listFeedingAdvisory[0]);
      else if (months > 6 && listFoodTypesTaken.length === 0)
        feedingNotes.push(listFeedingAdvisory[7]);
      else if (months > 6 && !checkAllFoodTypes(listFoodTypesTaken))
        feedingNotes.push(listFeedingAdvisory[1]);

      if (checkSweetDrinks(listDrinksTaken))
        feedingNotes.push(listFeedingAdvisory[2]);

      if (countCaregiverLed > countBabyLed)
        feedingNotes.push(listFeedingAdvisory[3]);

      if (mealAfter7pm) feedingNotes.push(listFeedingAdvisory[5]);

      if (mealDurationTooLong) feedingNotes.push(listFeedingAdvisory[6]);
    }
    setFeedingAdvisory(feedingNotes);
    setLifestyleAdvisory(lifestyleNotes);
    setOpen(true);
  };

  return (
    <DashboardLayout>
      <TopnavV3
        avatarURL={avatarURL}
        title={modeTitle}
        subtitle={"Feeding. Outdoor. Rest. Mealtime."}
      />
      {response.isPending ? (
        <SubmissionProgress />
      ) : (
        <>
          {/*response.error && (
            <>
              {response.error?.name === "OperationInvalidError" && (
                <InvalidOperation />
              )}
              {response.error?.name === "OperationIncompleteError" && (
                <IncompleteOperation />
              )}
              <ErrorSnackbar
                title={modeTitle}
                dateTime=""
                message={response.error?.message}
                autoDismiss
              />
            </>
          )*/}
          {response.success && (
            <SuccessSnackbar
              title={modeTitle}
              dateTime=""
              message={response.success}
              autoDismiss
            />
          )}
          <Grid container spacing={2}>
            {
              <Grid item xs={12}>
                <MDBox mb={2} />
                <Card id="menu-panel">
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    m={0}
                    mr={0}
                  >
                    {
                      /*response?.data?.daybeforeDate !== undefined*/ true && (
                        <MDButton
                          variant="text"
                          size="small"
                          color="info"
                          onClick={() => {
                            prepareAdvisoryNote();
                          }}
                        >
                          <StickyNote2Icon /> NOTES For{" "}
                          {response?.data?.daybeforeDate}
                        </MDButton>
                      )
                    }
                    <MDButton
                      variant="text"
                      size="small"
                      color="info"
                      onClick={async () => {
                        await navigateAfter("/tracking/weeklylog", 1000);
                      }}
                    >
                      <FormatListBulletedIcon /> Weekly
                    </MDButton>
                  </MDBox>
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    m={0}
                    mt={-1}
                  >
                    <MDButton
                      variant="text"
                      size="large"
                      color="dark"
                      iconOnly
                      onClick={() => {
                        const newCalendarDate = _.cloneDeep(calendarDate);
                        newCalendarDate.setDate(newCalendarDate.getDate() - 1);
                        setCalendarDate(newCalendarDate);
                      }}
                    >
                      <ArrowBackIosIcon />
                    </MDButton>
                    <MDTypography
                      variant="body2"
                      color="dark"
                      fontWeight="medium"
                      opacity={0.8}
                    >
                      {calendarDate.toDateString()}
                    </MDTypography>
                    <MDBox>
                      <MDButton
                        variant="text"
                        size="large"
                        color="dark"
                        iconOnly
                        onClick={() =>
                          openCalendar
                            ? setOpenCalendar(false)
                            : setOpenCalendar(true)
                        }
                      >
                        <CalendarMonthIcon />
                      </MDButton>
                    </MDBox>
                    <MDButton
                      variant="text"
                      size="large"
                      color="dark"
                      iconOnly
                      onClick={() => {
                        const newCalendarDate = _.cloneDeep(calendarDate);
                        newCalendarDate.setDate(newCalendarDate.getDate() + 1);
                        setCalendarDate(newCalendarDate);
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </MDButton>
                  </MDBox>
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {openCalendar && (
                      <DatePicker
                        placeholder="Select Date"
                        format="dd/MM/yyyy"
                        open={true}
                        onChange={(newDate) => {
                          setCalendarDate(newDate);
                          setOpenCalendar(false);
                        }}
                        renderCell={(date) => {
                          const day = date.getDate();
                          const month = date.getMonth();
                          const year = date.getFullYear();
                          const dateString =
                            padTo2Digits(day) +
                            "/" +
                            padTo2Digits(month + 1) +
                            "/" +
                            year.toString();

                          const findIdx = response.data.tocRecords.findIndex(
                            (elm) => elm.calendarDate === dateString
                          );

                          if (findIdx > -1) {
                            return (
                              <span>
                                {day}
                                <Icon color="info">edit</Icon>
                              </span>
                            );
                          }

                          return day;
                        }}
                      />
                    )}
                  </MDBox>
                  {showButtonPanel && (
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      m={2}
                      mt={0}
                    >
                      <MDButton
                        variant="text"
                        size="small"
                        //color="warning"
                        sx={styleButton}
                        onClick={() => {
                          setShowButtonPanel(false);
                          setShowMealTimeButtons(true);
                        }}
                      >
                        <MDBox
                          component="img"
                          src={lunchTime}
                          width={{ xs: "70%", xl: "70%" }}
                          opacity={0.9}
                          p={0.5}
                        />
                      </MDButton>
                      <MDBox mr={1} />
                      <MDButton
                        variant="text"
                        size="small"
                        //color="warning"
                        sx={styleButton}
                        onClick={() => {
                          setShowButtonPanel(false);
                          setShowPhysicalButtons(true);
                        }}
                      >
                        <MDBox
                          component="img"
                          src={physicalActivity}
                          width={{ xs: "70%", xl: "70%" }}
                          opacity={0.9}
                          p={0.5}
                        />
                      </MDButton>
                      <MDBox mr={1} />
                      <MDButton
                        variant="text"
                        size="small"
                        //color="warning"
                        sx={styleButton}
                        onClick={() => {
                          setShowSleepInputPanel(true);
                          setShowFoodInputPanel(false);
                          setShowMilkInputPanel(false);
                          setShowSedentaryInputPanel(false);
                          setShowPhysicalInputPanel(false);
                        }}
                      >
                        <MDBox
                          component="img"
                          src={sleep}
                          width={{ xs: "70%", xl: "70%" }}
                          opacity={0.9}
                          p={0.5}
                        />
                      </MDButton>
                    </MDBox>
                  )}
                  {showMealTimeButtons && (
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      m={2}
                      mt={0}
                    >
                      <MDBox></MDBox>
                      <MDBox display="flex" justifyContent="center">
                        <MDButton
                          variant="text"
                          size="small"
                          //color="warning"
                          sx={styleButton}
                        >
                          <MDBox
                            component="img"
                            src={feedingBottle}
                            width={{ xs: "70%", xl: "70%" }}
                            opacity={0.9}
                            p={0.5}
                            onClick={() => {
                              setShowMilkInputPanel(true);
                              setShowFoodInputPanel(false);
                              setShowPhysicalInputPanel(false);
                              setShowSedentaryInputPanel(false);
                              setShowSleepInputPanel(false);
                            }}
                          />
                        </MDButton>
                        <MDBox mr={1} />
                        <MDButton
                          variant="text"
                          size="small"
                          //color="warning"
                          sx={styleButton}
                        >
                          <MDBox
                            component="img"
                            src={spoon}
                            width={{ xs: "70%", xl: "70%" }}
                            opacity={0.9}
                            p={0.5}
                            onClick={() => {
                              setShowFoodInputPanel(true);
                              setShowMilkInputPanel(false);
                              setShowPhysicalInputPanel(false);
                              setShowSedentaryInputPanel(false);
                              setShowSleepInputPanel(false);
                            }}
                          />
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <MDButton
                          variant="text"
                          size="large"
                          color="info"
                          iconOnly
                          onClick={() => {
                            setShowButtonPanel(true);
                            setShowMealTimeButtons(false);
                          }}
                        >
                          <KeyboardReturnIcon />
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  )}
                  {showPhysicalButtons && (
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      m={2}
                      mt={0}
                    >
                      <MDBox></MDBox>
                      <MDBox display="flex" justifyContent="center">
                        <MDButton
                          variant="text"
                          size="small"
                          //color="warning"
                          sx={styleButton}
                        >
                          <MDBox
                            component="img"
                            src={triangle}
                            width={{ xs: "70%", xl: "70%" }}
                            opacity={0.9}
                            p={0.5}
                            onClick={() => {
                              setShowPhysicalInputPanel(true);
                              setShowFoodInputPanel(false);
                              setShowMilkInputPanel(false);
                              setShowSedentaryInputPanel(false);
                              setShowSleepInputPanel(false);
                            }}
                          />
                        </MDButton>
                        <MDBox mr={1} />
                        <MDButton
                          variant="text"
                          size="small"
                          //color="warning"
                          sx={styleButton}
                        >
                          <MDBox
                            component="img"
                            src={meditation}
                            width={{ xs: "70%", xl: "70%" }}
                            opacity={0.9}
                            p={0.5}
                            onClick={() => {
                              setShowSedentaryInputPanel(true);
                              setShowFoodInputPanel(false);
                              setShowMilkInputPanel(false);
                              setShowPhysicalInputPanel(false);
                              setShowSleepInputPanel(false);
                            }}
                          />
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <MDButton
                          variant="text"
                          size="large"
                          color="info"
                          iconOnly
                          onClick={() => {
                            setShowButtonPanel(true);
                            setShowPhysicalButtons(false);
                          }}
                        >
                          <KeyboardReturnIcon />
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  )}
                </Card>
              </Grid>
            }
            {showPhysicalInputPanel && (
              <Grid item xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={
                    modeEdit.current === false
                      ? initialValuesPhysical
                      : initialValuesEditPhysical
                  }
                  validationSchema={Yup.object().shape({
                    environment: Yup.string().required(
                      "Please indicate it is an indoor or outdoor activity."
                    ),
                    tummyTime: Yup.string().required(
                      "Please indicate if there is tummy time."
                    ),
                    intensity: Yup.string().required(
                      "Please indicate the level of intensity."
                    ),
                    starttime: Yup.string().required(
                      "Please indicate the start time."
                    ),
                    endtime: Yup.string().required(
                      "Please indicate the end time."
                    ),
                  })}
                  onSubmit={handleSubmitPhysical}
                >
                  {(formik) => (
                    <Form id={formIdPhysical} autoComplete="off">
                      <Card id="physical-details" sx={{ overflow: "visible" }}>
                        <PhysicalDetails
                          mainForm={formik}
                          mainFormField={formFieldPhysical}
                          closeInputPanel={closeInputPanel}
                          calendarDate={calendarDate}
                        />
                        <MDBox m={1} mt={0} mb={2}>
                          <MDBox
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDBox />
                            <MDButton
                              disabled={false}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {modeSubmit.current}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </Form>
                  )}
                </Formik>
              </Grid>
            )}
            {showSedentaryInputPanel && (
              <Grid item xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={
                    modeEdit.current === false
                      ? initialValuesSedentary
                      : initialValuesEditSedentary
                  }
                  validationSchema={Yup.object().shape({
                    sedentaryActivity: Yup.string().required(
                      "Please indicate it is a restrained or unrestrained activity."
                    ),
                    distractionUsed: Yup.string().required(
                      "Please indicate if distraction is applied."
                    ),
                    screenBased: Yup.string().required(
                      "Please indicate if screen is applied."
                    ),
                    interactive: Yup.string().required(
                      "Please indicate it is an interactive or non-interactive activity."
                    ),
                    starttime: Yup.string().required(
                      "Please indicate the start time."
                    ),
                    endtime: Yup.string().required(
                      "Please indicate the end time."
                    ),
                  })}
                  onSubmit={handleSubmitSedentary}
                >
                  {(formik) => (
                    <Form id={formIdSendetary} autoComplete="off">
                      <Card id="sedentary-details" sx={{ overflow: "visible" }}>
                        <SedentaryDetails
                          mainForm={formik}
                          mainFormField={formFieldSedentary}
                          closeInputPanel={closeInputPanel}
                          calendarDate={calendarDate}
                        />
                        <MDBox m={1} mt={0} mb={2}>
                          <MDBox
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDBox />
                            <MDButton
                              disabled={false}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {modeSubmit.current}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </Form>
                  )}
                </Formik>
              </Grid>
            )}
            {showSleepInputPanel && (
              <Grid id="inputPanel-sleep" item xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={
                    modeEdit.current === false
                      ? initialValuesSleep
                      : initialValuesEditSleep
                  } //{sleepValues}
                  validationSchema={Yup.object().shape({
                    location: Yup.string().required(
                      "Please indicate the location."
                    ),
                    otherLocation: Yup.string().required(
                      "Please provide the details for other location."
                    ),
                    selfCompanion: Yup.string().required(
                      "Please select self or companion."
                    ),
                    position: Yup.string().required(
                      "Please indicate the sleeping position."
                    ),
                    sleepingTool: Yup.string().required(
                      "Please indicate any sleeping tool used."
                    ),
                    otherSleepingTool: Yup.string().required(
                      "Please provide the details for other sleeping tool used."
                    ),
                    starttime: Yup.string().required(
                      "Please indicate the start time."
                    ),
                    endtime: Yup.string().required(
                      "Please indicate the end time."
                    ),
                  })}
                  onSubmit={handleSubmitSleep}
                >
                  {(formik) => (
                    <Form id={formId} autoComplete="off">
                      <Card id="sleep-details" sx={{ overflow: "visible" }}>
                        <SleepDetails
                          mainForm={formik}
                          mainFormField={formField}
                          closeInputPanel={closeInputPanel}
                          calendarDate={calendarDate}
                        />
                        <MDBox m={1} mt={0} mb={2}>
                          <MDBox
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDBox />
                            <MDButton
                              disabled={false}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {modeSubmit.current}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </Form>
                  )}
                </Formik>
              </Grid>
            )}
            {showMilkInputPanel && (
              <Grid item xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={
                    modeEdit.current === false
                      ? initialValuesMilk
                      : initialValuesEditMilk
                  }
                  validationSchema={Yup.object().shape({
                    typeofMilk: Yup.string().nullable(),
                    milkVolume: Yup.number().when("typeofMilk", {
                      is: (val) => val?.length > 0,
                      then: (schema) =>
                        schema.min(
                          1,
                          "Please indicate the milk volume consumed."
                        ),
                      otherwise: (schema) => schema.min(0),
                    }),
                    typeofFeed: Yup.string().required(
                      "Please fill in the details of breast feed or bottle feed."
                    ),
                    whoLed: Yup.string()
                      .required(
                        "Please indicate it is caregiver led or baby led."
                      )
                      .nullable(),
                    starttime: Yup.string().required(
                      "Please indicate the start time."
                    ),
                    endtime: Yup.string().required(
                      "Please indicate the end time."
                    ),
                  })}
                  onSubmit={handleSubmitMilk}
                >
                  {(formik) => (
                    <Form id={formIdMilk} autoComplete="off">
                      <Card id="milk-details" sx={{ overflow: "visible" }}>
                        <MilkDetails
                          mainForm={formik}
                          mainFormField={formFieldMilk}
                          closeInputPanel={closeInputPanel}
                          calendarDate={calendarDate}
                        />
                        <MDBox m={1} mt={0} mb={2}>
                          <MDBox
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDBox />
                            <MDButton
                              disabled={false}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {modeSubmit.current}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </Form>
                  )}
                </Formik>
              </Grid>
            )}
            {showFoodInputPanel && (
              <Grid item xs={12}>
                <Formik
                  enableReinitialize
                  initialValues={
                    modeEdit.current === false
                      ? initialValuesFood
                      : initialValuesEditFood
                  }
                  validationSchema={Yup.object().shape({
                    otherDrinkType: Yup.string().required(
                      "Please provide the details."
                    ),
                    varietyType: Yup.string().required(
                      "Please indicate type of food or drink."
                    ),
                    whoLed: Yup.string().required(
                      "Please select caregiver led or baby led."
                    ),
                    location: Yup.string().required(
                      "Please indicate the location."
                    ),
                    otherLocation: Yup.string().required(
                      "Please provide the detailss."
                    ),
                    sittingOn: Yup.string().required(
                      "Please provide the details."
                    ),
                    otherSittingOn: Yup.string().required(
                      "Please provide the details."
                    ),
                    utensil: Yup.string().required(
                      "Please indicate what utensil used."
                    ),
                    otherUtensil: Yup.string().required(
                      "Please provide the details."
                    ),
                    starttime: Yup.string().required(
                      "Please indicate the start time."
                    ),
                    endtime: Yup.string().required(
                      "Please indicate the end time."
                    ),
                  })}
                  onSubmit={handleSubmitFood}
                >
                  {(formik) => (
                    <Form id={formIdFood} autoComplete="off">
                      <Card id="food-details" sx={{ overflow: "visible" }}>
                        <FoodDetails
                          mainForm={formik}
                          mainFormField={formFieldFood}
                          closeInputPanel={closeInputPanel}
                          calendarDate={calendarDate}
                        />
                        <MDBox m={1} mt={0} mb={2}>
                          <MDBox
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <MDBox />
                            <MDButton
                              disabled={false}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {modeSubmit.current}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </Card>
                    </Form>
                  )}
                </Formik>
              </Grid>
            )}
            {Array.isArray(activityValues) && activityValues.length > 0 && (
              <Grid item xs={12}>
                <Card id="swipeable-list-tracking">
                  <SwipeableList type={ListType.IOS}>
                    {activityValues.map((value, idx) => (
                      <SwipeableListItem
                        fullSwipe={true}
                        leadingActions={leadingActions(value, idx)}
                        trailingActions={trailingActions(idx)}
                        key={idx}
                        //swipeStartThreshold={100}
                        //crollStartThreshold={100}
                      >
                        <Grid
                          container
                          rowSpacing={{ xs: 0 }}
                          columnSpacing={{ xs: 0 }}
                          alignItems="center"
                          sx={{ backgroundColor: "white" }}
                          p={1}
                        >
                          <Grid item xs={3}>
                            <MDBox
                              display="flex"
                              justifyContent="left"
                              alignItems="center"
                              ml={0}
                            >
                              <MDBox
                                component="img"
                                src={getIcon(value.type)}
                                width={{ xs: "50%" }}
                                opacity={0.7}
                                p={0}
                              />
                              <MDBox
                                m={1}
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <MDTypography
                                  variant="caption"
                                  fontWeight="bold"
                                  color="dark"
                                >
                                  {value.startHHMM}
                                </MDTypography>
                                <MDBox display="flex" justifyContent="center">
                                  <MDTypography
                                    variant="caption"
                                    fontWeight="regular"
                                    color="dark"
                                  >
                                    to
                                  </MDTypography>
                                </MDBox>
                                <MDTypography
                                  variant="caption"
                                  fontWeight="bold"
                                  color="dark"
                                >
                                  {value.endHHMM}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          </Grid>
                          <Grid item xs={9} pl={2}>
                            <MDBox
                              borderRadius="lg"
                              lineHeight={0}
                              sx={{
                                p: 1,
                                m: 1,
                                //border: "1px solid grey",
                                backgroundColor: "WhiteSmoke",
                              }}
                            >
                              <MDTypography
                                variant="caption"
                                fontWeight="bold"
                                color="dark"
                              >
                                {value.durationMinutes} mins
                              </MDTypography>
                              <MDBox mb={1} />
                              {value.desc.map((descValue, subidx) => {
                                return descValue.content !== "NIL" ? (
                                  <MDBox
                                    display="flex"
                                    justifyContent="left"
                                    alignItems="center"
                                    ml={1}
                                    mb={0.5}
                                    key={idx + subidx}
                                  >
                                    <MDTypography
                                      variant="caption"
                                      fontWeight="bold"
                                      color="dark"
                                      mr={1}
                                    >
                                      {descValue.label}
                                    </MDTypography>
                                    <MDTypography
                                      variant="caption"
                                      fontWeight="regular"
                                      color="dark"
                                    >
                                      {descValue.content}
                                    </MDTypography>
                                  </MDBox>
                                ) : null;
                              })}
                            </MDBox>
                          </Grid>
                        </Grid>
                      </SwipeableListItem>
                    ))}
                  </SwipeableList>
                </Card>
              </Grid>
            )}
          </Grid>
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogTitle>
              <MDButton variant="gradient" color="success" iconOnly>
                <NotificationsActiveIcon />
              </MDButton>
              &nbsp; Advisory Notes
            </DialogTitle>
            <DialogContent dividers>
              {feedingAdvisory.length > 0 && (
                <MDBox
                  display="flex"
                  borderRadius="lg"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ backgroundColor: "rgb(243,138,180)" }}
                  p={1}
                >
                  <MDTypography variant="body2" fontWeight="bold" color="white">
                    FEEDING
                  </MDTypography>
                </MDBox>
              )}
              {feedingAdvisory.map((note, index) => {
                return (
                  <MDBox
                    borderRadius="lg"
                    lineHeight={0}
                    sx={{
                      p: 1,
                      m: 1,
                      //border: "1px solid grey",
                      backgroundColor: "WhiteSmoke",
                    }}
                    key={"feeding" + index}
                  >
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="dark"
                    >
                      {note}
                    </MDTypography>
                  </MDBox>
                );
              })}
              {lifestyleAdvisory.length > 0 && (
                <MDBox
                  display="flex"
                  borderRadius="lg"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ backgroundColor: "rgb(79,145,205)" }}
                  p={1}
                >
                  <MDTypography variant="body2" fontWeight="bold" color="white">
                    LIFESTYLE
                  </MDTypography>
                </MDBox>
              )}
              {lifestyleAdvisory.map((note, index) => {
                return (
                  <MDBox
                    borderRadius="lg"
                    lineHeight={0}
                    sx={{
                      p: 1,
                      m: 1,
                      //border: "1px solid grey",
                      backgroundColor: "WhiteSmoke",
                    }}
                    key={"lifestyle" + index}
                  >
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="dark"
                    >
                      {note}
                    </MDTypography>
                  </MDBox>
                );
              })}
            </DialogContent>
            <DialogActions>
              <MDButton
                size="small"
                variant="gradient"
                color="info"
                onClick={handleClose}
              >
                OK
              </MDButton>
            </DialogActions>
          </Dialog>
        </>
      )}
    </DashboardLayout>
  );
}

export default Tracking;
