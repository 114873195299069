import { useCallback, useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

import initialValues from "pages/assessments/manage/schemas/initialValues";
import validations, {
  noValidation,
} from "pages/assessments/manage/schemas/validations";
import form from "pages/assessments/manage/schemas/form";

import { useAbac } from "react-abac";
import { Permission } from "models/abac";
import { useAppContext } from "context/AppContext";

//const collectionPath = "users";

const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "RETRIEVED_BABYPROFILE":
      return {
        isPending: false,
        data: initialValues,
        success: `Successfully retrieved baby profile.`,
        error: null,
        age: action.payload,
      };
    case "RETRIEVED_RECORD":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully retrieved record.`,
        error: null,
        //age: action.payload,
      };
    case "RETRIEVED_ALL_RECORDS":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully retrieved all records.`,
        error: null,
        //age: action.payload,
      };
    case "ERROR":
      return {
        isPending: false,
        data: payload,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const useQASurveyManager = (mode) => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);

  const { userHasPermissions } = useAbac();
  const { user } = useAuthContext();
  const { retrieveDoc, updateDoc, createDoc } = useDocument();
  const { pathname } = useLocation();
  const { formId, formField } = form;

  const { selectedBabyProfile } = useAppContext();
  const { babyUid, babyProfile } = selectedBabyProfile;
  //console.log(JSON.stringify(babyProfile));
  //console.log("babyUid: " + babyUid);

  const getAge = useCallback((babyProfile) => {
    if (babyProfile === undefined || babyProfile === null)
      return { days: 0, weeks: 0, months: 0 };
    else {
      const dob = new Date(
        babyProfile.dob.toDate().toLocaleDateString("en-US")
      );
      const today = new Date();
      const elapsedDays = (today - dob) / (1000 * 60 * 60 * 24);
      const days = parseInt(elapsedDays);
      const weeks = parseFloat(days / 7).toFixed(1); //Math.ceil(days / 7);
      const months = parseFloat(days / 30).toFixed(1); //Math.ceil(days / 30);
      return { days: days, weeks: weeks, months: months };
    }
  }, []);

  const updateInitValues = useCallback(() => {
    // Question Q_RR1
    initialValues.Q_RR1 =
      initialValues.age.months > formField.Q_RR1.minAge &&
      initialValues.age.months <= formField.Q_RR1.maxAge
        ? ""
        : "NA";

    // Question Q_RR2
    initialValues.Q_RR2 =
      initialValues.age.months > formField.Q_RR2.minAge &&
      initialValues.age.months <= formField.Q_RR2.maxAge
        ? ""
        : "NA";

    // Question Q_RR3
    initialValues.Q_RR3 =
      initialValues.age.months > formField.Q_RR3.minAge &&
      initialValues.age.months <= formField.Q_RR3.maxAge
        ? ""
        : "NA";

    // Question Q_RR4
    initialValues.Q_RR4 =
      initialValues.age.months > formField.Q_RR4.minAge &&
      initialValues.age.months <= formField.Q_RR4.maxAge
        ? ""
        : "NA";

    // Question Q_RF1a
    initialValues.Q_RF1a =
      initialValues.age.months > formField.Q_RF1a.minAge &&
      initialValues.age.months <= formField.Q_RF1a.maxAge
        ? ""
        : "NA";

    // Question Q_RF1b
    initialValues.Q_RF1b =
      initialValues.age.months > formField.Q_RF1b.minAge &&
      initialValues.age.months <= formField.Q_RF1b.maxAge
        ? ""
        : "NA";

    // Question Q_RF2
    initialValues.Q_RF2 =
      initialValues.age.months > formField.Q_RF2.minAge &&
      initialValues.age.months <= formField.Q_RF2.maxAge
        ? ""
        : "NA";

    // Question Q_WW1
    initialValues.Q_WW1 =
      initialValues.age.months > formField.Q_WW1.minAge &&
      initialValues.age.months <= formField.Q_WW1.maxAge
        ? ""
        : "NA";

    // Question Q_WBe1
    initialValues.Q_WBe1 =
      initialValues.age.months > formField.Q_WBe1.minAge &&
      initialValues.age.months <= formField.Q_WBe1.maxAge
        ? ""
        : "NA";

    // Question Q_WBa1
    initialValues.Q_WBa1 =
      initialValues.age.months > formField.Q_WBa1.minAge &&
      initialValues.age.months <= formField.Q_WBa1.maxAge
        ? ""
        : "NA";

    // Question Q_WBa2
    initialValues.Q_WBa2 =
      initialValues.age.months > formField.Q_WBa2.minAge &&
      initialValues.age.months <= formField.Q_WBa2.maxAge
        ? ""
        : "NA";

    // Question Q_WBa3
    initialValues.Q_WBa3 =
      initialValues.age.months > formField.Q_WBa3.minAge &&
      initialValues.age.months <= formField.Q_WBa3.maxAge
        ? ""
        : "NA";

    // Question Q_WBa4
    initialValues.Q_WBa4 =
      initialValues.age.months > formField.Q_WBa4.minAge &&
      initialValues.age.months <= formField.Q_WBa4.maxAge
        ? ""
        : "NA";

    // Question Q_HF1
    initialValues.Q_HF1 =
      initialValues.age.months > formField.Q_HF1.minAge &&
      initialValues.age.months <= formField.Q_HF1.maxAge
        ? ""
        : "NA";

    // Question Q_HT1
    initialValues.Q_HT1 =
      initialValues.age.months > formField.Q_HT1.minAge &&
      initialValues.age.months <= formField.Q_HT1.maxAge
        ? ""
        : "NA";

    // Question Q_HT2
    initialValues.Q_HT2 =
      initialValues.age.months > formField.Q_HT2.minAge &&
      initialValues.age.months <= formField.Q_HT2.maxAge
        ? ""
        : "NA";

    // Question Q_HT3
    initialValues.Q_HT3 =
      initialValues.age.months > formField.Q_HT3.minAge &&
      initialValues.age.months <= formField.Q_HT3.maxAge
        ? ""
        : "NA";

    // Question Q_HT4
    initialValues.Q_HT4 =
      initialValues.age.months > formField.Q_HT4.minAge &&
      initialValues.age.months <= formField.Q_HT4.maxAge
        ? ""
        : "NA";

    // Question Q_HSl1
    initialValues.Q_HSl1 =
      initialValues.age.months > formField.Q_HSl1.minAge &&
      initialValues.age.months <= formField.Q_HSl1.maxAge
        ? ""
        : "NA";

    // Question Q_HA1
    initialValues.Q_HA1 =
      initialValues.age.months > formField.Q_HA1.minAge &&
      initialValues.age.months <= formField.Q_HA1.maxAge
        ? ""
        : "NA";

    // Question Q_HA1_unit
    initialValues.Q_HA1_unit =
      initialValues.age.months <= formField.Q_HA1_unit.ageBreakPoint
        ? "minute(s)"
        : "hour(s)";

    // Question Q_HSc1
    initialValues.Q_HSc1 =
      initialValues.age.months > formField.Q_HSc1.minAge &&
      initialValues.age.months <= formField.Q_HSc1.maxAge
        ? ""
        : "NA";

    return initialValues;
  }, [formField]);

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        ![
          "PermissionDeniedError",
          "OperationInvalidError",
          "PromptAddBabyProfile",
        ].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      let promptAddBabyProfile = new Error(
        "Please add a baby profile to proceed."
      );
      promptAddBabyProfile.name = "PromptAddBabyProfile";

      switch (mode) {
        case "qasurvey":
          // validate operation
          if (!pathname.includes("/assessments/qasurvey")) {
            throw operationInvalidError;
          }

          if (babyProfile === undefined || babyProfile === null) {
            throw promptAddBabyProfile;
          }

          // Get baby age in (days, weeks and months)
          const age = getAge(babyProfile);
          initialValues.age = age;
          initialValues.avatarURL =
            babyProfile.avatarAttachments[0].attachmentURL;
          const values = updateInitValues();

          dispatchIfNotUnmounted({
            type: "RETRIEVED_BABYPROFILE",
            payload: values,
          });
          break;
        case "qaadvisory":
          // validate operation
          if (!pathname.includes("/assessments/qaadvisory")) {
            throw operationInvalidError;
          }

          if (babyProfile === undefined || babyProfile === null) {
            throw promptAddBabyProfile;
          }

          const retrievedDoc = await retrieveDoc("assessments", babyUid);
          const records_len = retrievedDoc.data.records.length;
          const record = retrievedDoc.data.records[records_len - 1];
          record.avatarURL = babyProfile.avatarAttachments[0].attachmentURL;
          dispatchIfNotUnmounted({
            type: "RETRIEVED_RECORD",
            payload: record,
          });
          break;
        case "qascore":
          // validate operation
          if (!pathname.includes("/assessments/qascore")) {
            throw operationInvalidError;
          }

          if (babyProfile === undefined || babyProfile === null) {
            throw promptAddBabyProfile;
          }

          const retrievedDoc2 = await retrieveDoc(
            "assessments",
            babyUid /*user.uid*/
          );
          //const records_len = retrievedDoc.data.records.length;
          dispatchIfNotUnmounted({
            type: "RETRIEVED_ALL_RECORDS",
            payload: {
              records: retrievedDoc2.data.records,
              avatarURL: babyProfile.avatarAttachments[0].attachmentURL,
            },
          });
          break;
        default:
          throw operationInvalidError;
      }
    } catch (err) {
      dispatchError(err);
    }
  }, [
    dispatchError,
    dispatchIfNotUnmounted,
    getAge,
    updateInitValues,
    pathname,
    mode,
    retrieveDoc,
    babyProfile,
    babyUid,
  ]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  //console.log(JSON.stringify(response));

  let modeTitle = "";
  let modeSubmit = "";
  let modeValidation = noValidation;
  let modeFieldDisabled = true;

  switch (mode) {
    case "qasurvey":
      modeTitle = "Assessment";
      modeSubmit = "Submit";
      modeValidation = validations;
      modeFieldDisabled = false;
      break;
    case "qaadvisory":
      modeTitle = "Advisory";
      modeSubmit = "Submit";
      modeValidation = noValidation;
      modeFieldDisabled = false;
      break;
    case "qascore":
      modeTitle = "Scores";
      modeSubmit = "Submit";
      modeValidation = noValidation;
      modeFieldDisabled = false;
      break;
    default:
      modeTitle = "Illegal Action";
  }

  const computeScores = useCallback(
    (values) => {
      const keys = [
        "Q_RR1",
        "Q_RR2",
        "Q_RR3",
        "Q_RR4",
        "Q_RF1a",
        "Q_RF2",
        "Q_WW1",
        "Q_WBe1",
        "Q_WBa1",
        "Q_WBa2",
        "Q_WBa3",
        "Q_WBa4",
        "Q_HF1",
        "Q_HT1",
        "Q_HT2",
        "Q_HT3",
        "Q_HT4",
        "Q_HSl1",
        "Q_HA1",
        "Q_HSc1",
      ];

      let totalQuestions = 0;
      let score = 0;
      keys.forEach((key) => {
        if (values[key] !== "NA") {
          totalQuestions++;
          if (key === "Q_WW1") {
            values.age.months <= 4 &&
              values[key] === formField[key].response_0m_4m &&
              score++;

            values.age.months > 4 &&
              values.age.months <= 9 &&
              values[key] === formField[key].response_4m_9m &&
              score++;
          } else if (key === "Q_HT1" || key === "Q_HT2") {
            parseInt(values[key]) >=
              parseInt(formField[key].responseLowerBand) &&
              parseInt(values[key]) <=
                parseInt(formField[key].responseUpperBand) &&
              score++;
          } else if (key === "Q_HSl1") {
            if (values.age.months <= 3) {
              parseInt(values[key]) >=
                parseInt(formField[key].responseLowerBand_0m_3m) &&
                parseInt(values[key]) <=
                  parseInt(formField[key].responseUpperBand_0m_3m) &&
                score++;
            } else if (values.age.months > 3 && values.age.months <= 11) {
              parseInt(values[key]) >=
                parseInt(formField[key].responseLowerBand_3m_11m) &&
                parseInt(values[key]) <=
                  parseInt(formField[key].responseUpperBand_3m_11m) &&
                score++;
            } else {
              parseInt(values[key]) >=
                parseInt(formField[key].responseLowerBand_11m_23m) &&
                parseInt(values[key]) <=
                  parseInt(formField[key].responseUpperBand_11m_23m) &&
                score++;
            }
          } else if (key === "Q_HA1") {
            // Get response in minutes
            const resMinutes =
              values["Q_HA1_unit"] === "minute(s)"
                ? parseFloat(values[key])
                : parseFloat(values[key]) * 60;

            if (values.age.months <= 3) {
              resMinutes >= parseFloat(formField[key].response_0m_3m) &&
                score++;
            } else if (values.age.months > 3 && values.age.months <= 11) {
              resMinutes >= parseFloat(formField[key].response_3m_11m) &&
                score++;
            } else {
              resMinutes >= parseFloat(formField[key].response_11m_23m) &&
                score++;
            }
          } else if (key === "Q_HSc1") {
            values.age.months <= 3 &&
              values[key] === formField[key].response_0m_3m &&
              score++;

            values.age.months > 3 &&
              values.age.months <= 11 &&
              values[key] === formField[key].response_3m_11m &&
              score++;

            values.age.months > 11 &&
              parseFloat(values[key]) <
                parseFloat(formField[key].response_11m_23m) &&
              score++;
          } else values[key] === formField[key].response && score++;
        }
      });
      const normalizedScore = (score / totalQuestions).toFixed(3);
      //console.log("totalQuestions: " + totalQuestions);
      //console.log("score: " + score);
      //console.log("normalizedScore: " + normalizedScore);

      return {
        totalQuestions: totalQuestions,
        score: score,
        normalizedScore: normalizedScore,
      };
    },
    [formField]
  );

  const submitNew = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (
        userHasPermissions(Permission.CREATE_ASSESSMENT_RECORD) &&
        userHasPermissions(Permission.UPDATE_ASSESSMENT_RECORD)
      ) {
        const retrievedDoc = await retrieveDoc(
          "assessments",
          babyUid /*user.uid*/
        );

        if (retrievedDoc.data === undefined) {
          await createDoc(
            "assessments",
            {
              records: [],
            },
            babyUid, //user.uid,
            babyUid //user.uid
          );
        }

        const m_date = new Date();
        const localeDate = m_date.toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
        const localeTime = m_date.toLocaleTimeString("en-SG");
        const localeDay = dayNames[m_date.getDay()];

        const { totalQuestions, score, normalizedScore } =
          computeScores(values);

        const rec = {
          createdAt: localeDate + " " + localeTime,
          createdBy: user.uid,
          modifiedAt: null,
          modifiedBy: null,
          deletedAt: null,
          deletedBy: null,
          localeDate: localeDate,
          localeTime: localeTime,
          localeDay: localeDay,
          Q_RR1: values.Q_RR1,
          Q_RR2: values.Q_RR2,
          Q_RR3: values.Q_RR3,
          Q_RR4: values.Q_RR4,
          Q_RF1a: values.Q_RF1a,
          Q_RF1b: values.Q_RF1b,
          Q_RF2: values.Q_RF2,
          Q_WW1: values.Q_WW1,
          Q_WBe1: values.Q_WBe1,
          Q_WBa1: values.Q_WBa1,
          Q_WBa2: values.Q_WBa2,
          Q_WBa3: values.Q_WBa3,
          Q_WBa4: values.Q_WBa4,
          Q_HF1: values.Q_HF1,
          Q_HT1: values.Q_HT1,
          Q_HT2: values.Q_HT2,
          Q_HT3: values.Q_HT3,
          Q_HT4: values.Q_HT4,
          Q_HSl1: values.Q_HSl1,
          Q_HA1_unit: values.Q_HA1_unit,
          Q_HA1: values.Q_HA1,
          Q_HSc1: values.Q_HSc1,
          age: values.age,
          totalQuestions: totalQuestions,
          score: score,
          normalizedScore: normalizedScore,
        };

        let recArray;
        retrievedDoc.data === undefined
          ? (recArray = [rec])
          : (recArray = [...retrievedDoc.data.records, rec]);

        await updateDoc("assessments", babyUid, {
          ...retrievedDoc.data,
          records: recArray,
        });

        /*const dispatchType = "SUBMITTED_CSESURVEY";
        dispatchIfNotUnmounted({
          type: dispatchType,
          payload: toPresentationValue(retrievedDoc.data),
        });*/
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  return {
    response,
    modeValidation,
    modeFieldDisabled,
    modeSubmit,
    modeTitle,
    formId,
    formField,
    submitNew,
  };
};
