//import { de } from "date-fns/locale";
import form from "pages/assessments/manage/schemas/form";

import {
  defaultAnswerQ_RR1,
  defaultAnswerQ_RR2,
  defaultAnswerQ_RR3,
  defaultAnswerQ_RR4,
  defaultAnswerQ_RF1a,
  defaultAnswerQ_RF1b,
  defaultAnswerQ_RF2,
  defaultAnswerQ_WW1,
  defaultAnswerQ_WBe1,
  defaultAnswerQ_WBa1,
  defaultAnswerQ_WBa2,
  defaultAnswerQ_WBa3,
  defaultAnswerQ_WBa4,
  defaultAnswerQ_HF1,
  defaultAnswerQ_HT1,
  defaultAnswerQ_HT2,
  defaultAnswerQ_HT3,
  defaultAnswerQ_HT4,
  defaultAnswerQ_HSl1,
  defaultAnswerQ_HA1,
  defaultAnswerQ_HA1_unit,
  defaultAnswerQ_HSc1,
} from "pages/assessments/manage/schemas/setups";

const {
  formField: {
    Q_RR1,
    Q_RR2,
    Q_RR3,
    Q_RR4,
    Q_RF1a,
    Q_RF1b,
    Q_RF2,
    Q_WW1,
    Q_WBe1,
    //Q_WBe2,
    //Q_WBe3,
    Q_WBa1,
    Q_WBa2,
    Q_WBa3,
    Q_WBa4,
    Q_HF1,
    Q_HT1,
    Q_HT2,
    Q_HT3,
    Q_HT4,
    Q_HSl1,
    Q_HA1,
    Q_HA1_unit,
    Q_HSc1,
  },
} = form;

const initialValues = {
  [Q_RR1.name]: defaultAnswerQ_RR1,
  [Q_RR2.name]: defaultAnswerQ_RR2,
  [Q_RR3.name]: defaultAnswerQ_RR3,
  [Q_RR4.name]: defaultAnswerQ_RR4,
  [Q_RF1a.name]: defaultAnswerQ_RF1a,
  [Q_RF1b.name]: defaultAnswerQ_RF1b,
  [Q_RF2.name]: defaultAnswerQ_RF2,
  [Q_WW1.name]: defaultAnswerQ_WW1,
  [Q_WBe1.name]: defaultAnswerQ_WBe1,
  //[Q_WBe2.name]: defaultAnswerQ_WBe2,
  //[Q_WBe3.name]: defaultAnswerQ_WBe3,
  [Q_WBa1.name]: defaultAnswerQ_WBa1,
  [Q_WBa2.name]: defaultAnswerQ_WBa2,
  [Q_WBa3.name]: defaultAnswerQ_WBa3,
  [Q_WBa4.name]: defaultAnswerQ_WBa4,
  [Q_HF1.name]: defaultAnswerQ_HF1,
  [Q_HT1.name]: defaultAnswerQ_HT1,
  [Q_HT2.name]: defaultAnswerQ_HT2,
  [Q_HT3.name]: defaultAnswerQ_HT3,
  [Q_HT4.name]: defaultAnswerQ_HT4,
  [Q_HSl1.name]: defaultAnswerQ_HSl1,
  [Q_HA1.name]: defaultAnswerQ_HA1,
  [Q_HA1_unit.name]: defaultAnswerQ_HA1_unit,
  [Q_HSc1.name]: defaultAnswerQ_HSc1,
};

export { initialValues as default };
