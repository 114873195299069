import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "components/templates/DashboardLayout";
import TopnavV3 from "components/organisms/TopnavV3";

//import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
//import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import PromptAddBabyProfile from "components/organisms/PromptAddBabyProfile";

import Grid from "@mui/material/Grid";
import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import SubmissionProgress from "components/organisms/SubmissionProgress";
//import Footer from "components/organisms/Footer";
import { Formik, Form } from "formik";
import Questionnaire from "pages/assessments/manage/components/Questionnaire";
import { useQASurveyManager } from "pages/assessments/hooks/useQASurveyManager";

function QASurvey() {
  //const { mode } = useParams();
  const [isProgressing, setIsProgressing] = useState(false);
  const mode = "qasurvey";
  const {
    response,
    modeValidation,
    modeFieldDisabled,
    modeSubmit,
    modeTitle,
    formId,
    formField,
    submitNew,
  } = useQASurveyManager(mode);

  //console.log(JSON.stringify(response.data));

  const navigate = useNavigate();
  const navigateAfter = (path, millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        navigate(path);
      }, millisecond);
    });
  };

  const handleSubmit = async (values, actions) => {
    try {
      setIsProgressing(true);
      await submitNew(values);
      await navigateAfter("/assessments/qaadvisory", 3000);
    } catch (err) {
      //dispatchError(err);
      //actions.setSubmitting(false);
    } finally {
      setIsProgressing(false);
      //actions.setSubmitting(false);
      //setEditProfile(false);
    }
  };

  const avatarURL =
    response.data?.avatarURL !== undefined ? response.data.avatarURL : null;

  return (
    <DashboardLayout>
      <TopnavV3 avatarURL={avatarURL} title={modeTitle} />
      {isProgressing && <SubmissionProgress />}
      {response.error && (
        <>
          {response.error?.name === "OperationInvalidError" && (
            <InvalidOperation />
          )}
          {response.error?.name === "OperationIncompleteError" && (
            <IncompleteOperation />
          )}
          {response.error?.name === "PromptAddBabyProfile" && (
            <PromptAddBabyProfile />
          )}
          {/*<ErrorSnackbar
            title={modeTitle}
            dateTime=""
            message={response.error?.message}
            autoDismiss
          />*/}
        </>
      )}
      {response.success && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox mb={3} />
            <Formik
              enableReinitialize
              initialValues={response.data}
              validationSchema={modeValidation}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form id={formId} autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Questionnaire
                        mainForm={formik}
                        mainFormField={formField}
                        modeDisabled={modeFieldDisabled}
                        //age={response.age}
                        //mode={mode}
                        //respondedCseSurveyNumber={response.data.csesurveyNumber}
                        //dispatchMainError={dispatchError}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <MDBox></MDBox>
                        <MDButton
                          disabled={formik.isSubmitting}
                          type="submit"
                          variant="gradient"
                          color="dark"
                        >
                          {modeSubmit}
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
}

export default QASurvey;
