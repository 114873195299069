import { useLocation, Navigate } from "react-router-dom";

import { useAuthContext } from "context/AuthContext";

import PropTypes from "prop-types";

import { defaultSignedInPath } from "models/navigate";

function RedirectAuth({ children }) {
  let { user } = useAuthContext();
  let location = useLocation();

  if (
    user &&
    user.emailVerified &&
    location.pathname !== "/authentication/signup"
  ) {
    // user is signed in
    // redirect user to / page
    return <Navigate to={defaultSignedInPath} replace />;
  } else if (
    user &&
    !user.emailVerified &&
    location.pathname !== "/authentication/verifyemail" &&
    location.pathname !== "/authentication/signup"
  ) {
    // user is signed in but email is not verified
    // redirect user to /authentication/verifyemail page
    return (
      <Navigate
        to="/authentication/verifyemail"
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

RedirectAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RedirectAuth;
