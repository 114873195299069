const listLifestyleAdvisory = [
  "Babies between 0-3 months generally sleep between 14 to 17 hours. In order for your baby to sleep well, provide a regular routine and consistent bedtime schedule.  A conducive sleep environment is one which is quiet, dark, and at a slightly cool temperature. Let your baby sleep on his/her back, on a flat surface, together in the same room as caregivers to increase bonding supervision.",
  "Babies between 4-11 months generally sleep between 12 to 15 hours. In order for your baby to sleep well, provide a regular routine and consistent bedtime schedule.  A conducive sleep environment is one which is quiet, dark, and at a slightly cool temperature. Let your baby sleep on his/her back, on a flat surface, together in the same room as caregivers to increase bonding supervision. Spend a short while to do something special and calming with your baby, such as soft talk or simple reading. Allow them to fall asleep on their own so that they can learn to self-soothe.",
  "Babies between 12-23 months generally sleep between 11 to 14 hours. In order for your baby to sleep well, provide a consistent bedtime schedule.  A conducive sleep environment is one which is quiet, dark, and at a slightly cool temperature. Develop a calming bedtime routine to help your child sleep well—this includes reading bedtime stories, listening to a lullaby, or even engaging in a soft talk, improving the bond between you and him/her. Allow them to fall asleep on their own so that they can learn to self-soothe. Avoid screen time.",
  "It is recommended for your baby to spend at least 30 minutes on their tummy spread through the day, under the supervision of an adult. This helps with your baby’s overall development.",
  "In combination, spend at least 1 hour on tummy/prone activities. Be physically active many times through the day, in a safe and supervised environment. Engage in non-screen based interactive floor play. Organizing a daily schedule will be useful in integrating physical activity into your baby’s routine. Refrain from restraining or leaving him/her unattended for more than an hour each time.",
  "Your child should have at least 3 hours of physical activity spread through the day. You could participate together with them, this improves you and your child’s engagement, interaction, and relationship. Include daily outdoor play, with light to moderately vigorous intensities.",
  "Any form of screen time, including background screen time, is not recommended. Exposure to screen time during early childhood is associated with myopia, poorer sleep, increased sedentary behaviour, and childhood obesity. Your baby should be engaged with interactive activities instead, such as singing, reading, storytelling and imaginative play. This will be able to provide your child with the appropriate amount of stimulation required for optimal neurocognitive development. Have a daily routine for activities, as this can reduce sedentary behaviour and temptation to resort to using screentime.",
  "Limit screen time to <1 hour if your baby is less than 2 years old. Exposure to screen time during early childhood is associated with myopia, poorer sleep, increased sedentary behaviour, and childhood obesity. Instead, opt for interactive, non-screen based activities such as singing, reading, storytelling and imaginative play to promote cognitive and social development. Have a daily routine to reduce sedentary behaviour and temptation to resort to using screentime to engage your child.",
];

const listFeedingAdvisory = [
  "The introduction of complementary foods should occur around six months, but not earlier than four months, as earlier introduction may increase the risk of food allergies and excessive weight gain. Between four to six months, complementary foods can be given when the infant demonstrates developmental readiness, e.g. the ability to hold their head up steadily, disappearance of tongue thrust reflex, showing interest in food and frequently putting things into their mouth.",
  "Ensure a diverse range of age-appropriate textures and flavours to provide an essential balance of nutrition, while emphasizing the importance of healthy food preparation. Between 6 and 12 months, introduce textures progressively into the infant’s diet. Start with iron-rich food, preferably green vegetables. Provide early and repeated exposures to a wide variety of vegetables, fruit, carbohydrates, and proteins, without added sugar and salt. From 12 months, provide foods that are similar to the rest of the family, focusing on nutrient-dense options.",
  "In the first two years of life, avoid giving fruit juices and sugar sweetened beverages.",
  "Be attentive and responsive to hunger and satiety cues from birth. Encourage autonomy in feeding to nurture positive mealtime experience. From birth, respond to infant’s hunger and fullness cues, allowing them to set the pace for feeding. From six months, respond to infant’s readiness to explore new textures and flavours. Encourage self-feeding and food play, allowing the infant to control the pace and determine the amount of food consumed. By 12 to 18 months, involve the child in family mealtimes, using age-appropriate utensils. Encourage liquids from a cup instead of the milk bottle. Between 18 and 24 months, build confidence for the child to eat and drink independently, using a fork, spoon, and cup.",
  "Establish regular, family-centred mealtimes in a calm and distraction-free environment. From birth, provide a comfortable and soothing environment to promote a secure and nurturing feeding experience. From six months, have baby safely seated at a designated dining area while providing solids. From 12 months, make mealtimes family-centred to promote social interaction and eating enjoyment with the family. Encourage parents and caregivers to interact with infant and child during feeding and eating while avoiding screens and toys.",
  "In the first six months, observe and respond to the infant’s evolving feeding pattern, recognising their natural shift towards longer intervals between feeds, especially at night. From six months, promote the establishment of feeding during the day, recognizing that some infants may sleep 6 hours through the night, and do not need night feeding. From 12 to 24 months, maintain a daily routine of 3 meals and 2 snacks, ensuring that meals and milk feeds are synchronised with their waking hours in the daytime.",
  "Caregivers are encouraged to establish a routine for infants and young children, ensuring they begin eating within 15 minutes after food is introduced, and to keep the total meal duration between 20-30 minutes. This approach has been shown to effectively minimize distractions and discourage disruptive behaviour during feeding.",
  "Between 6 and 12 months, introduce textures progressively into the infant’s diet. Start with iron-rich food, preferably green vegetables. Provide early and repeated exposures to a wide variety of vegetables, fruit, carbohydrates, and proteins, without added sugar and salt. From 12 months, provide foods that are similar to the rest of the family, focusing on nutrient-dense options. In the first two years of life, avoid giving fruit juices and sugar sweetened beverages.",
];

export { listLifestyleAdvisory, listFeedingAdvisory };
