import format from "date-fns/format";
import form, {
  rolesForm,
  signatureAttachmentsForm,
  photoAttachmentsForm,
  avatarAttachmentsForm,
} from "pages/account/manage/schemas/form";

const {
  formField: {
    babyName,
    dob,
    userName,
    userGroup,
    roles,
    emailSignOff,
    signatureAttachments,
    photoAttachments,
    avatarAttachments,
  },
} = form;

const defaultDOB = format(new Date(), "dd/MM/yyyy");
//const defaultDOB = new Date();

const initialValues = {
  [babyName.name]: "",
  [dob.name]: defaultDOB,
  [userName.name]: "",
  [userGroup.name]: "",
  [roles.name]: [],
  [emailSignOff]: "",
  [signatureAttachments.name]: [],
  [photoAttachments.name]: [],
  [avatarAttachments.name]: [],
};

const {
  formField: { roleName },
} = rolesForm;

const rolesInitialValues = {
  [roleName.name]: "",
};

const {
  formField: { signatureAttachmentFile },
} = signatureAttachmentsForm;

const signatureAttachmentsInitialValues = {
  [signatureAttachmentFile.name]: "",
};

const {
  formField: { photoAttachmentFile },
} = photoAttachmentsForm;

const photoAttachmentsInitialValues = {
  [photoAttachmentFile.name]: "",
};

const {
  formField: { avatarAttachmentFile },
} = avatarAttachmentsForm;

const avatarAttachmentsInitialValues = {
  [avatarAttachmentFile.name]: "",
};

export {
  initialValues as default,
  rolesInitialValues,
  signatureAttachmentsInitialValues,
  photoAttachmentsInitialValues,
  avatarAttachmentsInitialValues,
};
