import { createContext, useContext, useReducer, useEffect } from "react";
import { subscribeAuthState, getUserRoles } from "services/authentication";

import PropTypes from "prop-types";

const AuthContext = createContext();
AuthContext.displayName = "AuthContext";

const authReducer = (state, action) => {
  const userState = action?.payload?.user;
  const rolesState = action?.payload?.roles;
  const displayNameState = userState?.displayName;
  const displayNameKeyState = displayNameState
    ?.replaceAll(" ", "-")
    .toLowerCase();

  switch (action.type) {
    case "SIGNIN":
      return {
        ...state,
        user: userState,
        roles: rolesState,
        displayName: displayNameState,
        displayNameKey: displayNameKeyState,
      };
    case "SIGNOUT":
      return {
        ...state,
        user: null,
        roles: null,
        displayName: null,
        displayNameKey: null,
      };
    case "AUTH_IS_READY":
      return {
        user: userState,
        roles: rolesState,
        displayName: displayNameState,
        displayNameKey: displayNameKeyState,
        authIsReady: true,
      };
    default:
      return state;
  }
};

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    roles: null,
    displayName: null,
    displayNameKey: null,
    authIsReady: false,
  });

  useEffect(() => {
    const unsubscribe = subscribeAuthState(async (user) => {
      const roles = await getUserRoles();
      dispatch({
        type: "AUTH_IS_READY",
        payload: { user: user, roles: roles },
      });
    });
    // unsubscribe on unmount
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  //console.log(JSON.stringify(context));
  if (!context) {
    throw Error("useAuthContext must be used inside an AuthContextProvider");
  }

  return context;
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthContextProvider, useAuthContext };
