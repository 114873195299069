import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";

function Legend(props) {
  return (
    <>
      <MDBox display="flex" justifyContent="center" alignItems="center">
        <MDBox
          display="flex"
          borderRadius="md"
          sx={{ backgroundColor: props.backgroundcolor }}
          p={1}
        ></MDBox>
        <MDBox mr={1}></MDBox>
        <MDTypography
          variant="caption"
          color="dark"
          fontWeight="medium"
          opacity={0.8}
        >
          {props.description}
        </MDTypography>
      </MDBox>
    </>
  );
}

export default Legend;
