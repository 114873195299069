import * as Yup from "yup";
//import parse from "date-fns/parse";
import form from "pages/assessments/manage/schemas/form";

const {
  formField: {
    Q_RR1,
    Q_RR2,
    Q_RR3,
    Q_RR4,
    Q_RF1a,
    Q_RF1b,
    Q_RF2,
    Q_WW1,
    Q_WBe1,
    Q_WBa1,
    Q_WBa2,
    Q_WBa3,
    Q_WBa4,
    Q_HF1,
    Q_HT1,
    Q_HT2,
    Q_HT3,
    Q_HT4,
    Q_HSl1,
    Q_HA1,
    Q_HA1_unit,
    Q_HSc1,
  },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [Q_RR1.name]: Yup.string()
    .required(Q_RR1.errorMsg)
    .min(1, Q_RR1.invalidMsg)
    .max(200, Q_RR1.invalidMsg),
  [Q_RR2.name]: Yup.string()
    .required(Q_RR2.errorMsg)
    .min(1, Q_RR2.invalidMsg)
    .max(200, Q_RR2.invalidMsg),
  [Q_RR3.name]: Yup.string()
    .required(Q_RR3.errorMsg)
    .min(1, Q_RR3.invalidMsg)
    .max(200, Q_RR3.invalidMsg),
  [Q_RR4.name]: Yup.string()
    .required(Q_RR4.errorMsg)
    .min(1, Q_RR4.invalidMsg)
    .max(200, Q_RR4.invalidMsg),
  [Q_RF1a.name]: Yup.string()
    .required(Q_RF1a.errorMsg)
    .min(1, Q_RF1a.invalidMsg)
    .max(200, Q_RF1a.invalidMsg),
  [Q_RF1b.name]: Yup.string()
    .required(Q_RF1b.errorMsg)
    .min(1, Q_RF1b.invalidMsg)
    .max(200, Q_RF1b.invalidMsg),
  [Q_RF2.name]: Yup.string()
    .required(Q_RF2.errorMsg)
    .min(1, Q_RF2.invalidMsg)
    .max(200, Q_RF2.invalidMsg),
  [Q_WW1.name]: Yup.string()
    .required(Q_WW1.errorMsg)
    .min(1, Q_WW1.invalidMsg)
    .max(200, Q_WW1.invalidMsg),
  [Q_WBe1.name]: Yup.string()
    .required(Q_WBe1.errorMsg)
    .min(1, Q_WBe1.invalidMsg)
    .max(200, Q_WBe1.invalidMsg),
  [Q_WBa1.name]: Yup.string()
    .required(Q_WBa1.errorMsg)
    .min(1, Q_WBa1.invalidMsg)
    .max(200, Q_WBa1.invalidMsg),
  [Q_WBa2.name]: Yup.string()
    .required(Q_WBa2.errorMsg)
    .min(1, Q_WBa2.invalidMsg)
    .max(200, Q_WBa2.invalidMsg),
  [Q_WBa3.name]: Yup.string()
    .required(Q_WBa3.errorMsg)
    .min(1, Q_WBa3.invalidMsg)
    .max(200, Q_WBa3.invalidMsg),
  [Q_WBa4.name]: Yup.string()
    .required(Q_WBa4.errorMsg)
    .min(1, Q_WBa4.invalidMsg)
    .max(200, Q_WBa4.invalidMsg),
  [Q_HF1.name]: Yup.string()
    .required(Q_HF1.errorMsg)
    .min(1, Q_HF1.invalidMsg)
    .max(200, Q_HF1.invalidMsg),
  [Q_HT1.name]: Yup.string()
    .required(Q_HT1.errorMsg)
    .min(1, Q_HT1.invalidMsg)
    .max(200, Q_HT1.invalidMsg),
  [Q_HT2.name]: Yup.string()
    .required(Q_HT2.errorMsg)
    .min(1, Q_HT2.invalidMsg)
    .max(200, Q_HT2.invalidMsg),
  [Q_HT3.name]: Yup.string()
    .required(Q_HT3.errorMsg)
    .min(1, Q_HT3.invalidMsg)
    .max(200, Q_HT3.invalidMsg),
  [Q_HT4.name]: Yup.string()
    .required(Q_HT4.errorMsg)
    .min(1, Q_HT4.invalidMsg)
    .max(200, Q_HT4.invalidMsg),
  [Q_HSl1.name]: Yup.string()
    .required(Q_HSl1.errorMsg)
    .min(1, Q_HSl1.invalidMsg)
    .max(200, Q_HSl1.invalidMsg),
  [Q_HA1.name]: Yup.string()
    .required(Q_HA1.errorMsg)
    .min(1, Q_HA1.invalidMsg)
    .max(200, Q_HA1.invalidMsg),
  [Q_HA1_unit.name]: Yup.string()
    .required(Q_HA1_unit.errorMsg)
    .min(1, Q_HA1_unit.invalidMsg)
    .max(200, Q_HA1_unit.invalidMsg),
  [Q_HSc1.name]: Yup.string()
    .required(Q_HSc1.errorMsg)
    .min(1, Q_HSc1.invalidMsg)
    .max(200, Q_HSc1.invalidMsg),
});

export { validations as default, noValidation };
