import formSleep from "pages/tracking/manage/schemas/formSleep";

const {
  formField: {
    location,
    otherLocation,
    selfCompanion,
    position,
    sleepingTool,
    otherSleepingTool,
    duration,
    starttime,
    endtime,
  },
} = formSleep;

const initialValuesSleep = {
  [location.name]: "",
  [otherLocation.name]: "NIL",
  [selfCompanion.name]: "",
  [position.name]: "",
  [sleepingTool.name]: "",
  [otherSleepingTool.name]: "NIL",
  [duration.name]: "",
  [starttime.name]: "",
  [endtime.name]: "",
};

export { initialValuesSleep as default };
