import { useParams } from "react-router-dom";
import QAAdvisory from "pages/assessments/advisory/QAAdvisory";

// using Proxy Page to remount when route to same route location but different param
function QAAdvisoryProxy() {
  const { mode, qasurveyId } = useParams();
  return <QAAdvisory key={`${mode}/${qasurveyId}`} />;
}

export default QAAdvisoryProxy;
