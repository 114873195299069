import form from "pages/nudges/schemas/form";

const {
  formField: { week, contents },
} = form;

const initialValues = {
  [week.name]: "",
  [contents.name]: "",
};

export { initialValues as default };
