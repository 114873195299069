//import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "components/templates/DashboardLayout";
import TopnavV3 from "components/organisms/TopnavV3";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import PromptAddBabyProfile from "components/organisms/PromptAddBabyProfile";
import Grid from "@mui/material/Grid";
import MDBox from "components/atoms/MDBox";
//import Footer from "components/organisms/Footer";
//import { Formik, Form } from "formik";
import { useQASurveyManager } from "pages/assessments/hooks/useQASurveyManager";
//import LatestScore from "pages/assessments/score/components/LatestScore";
import ScoreLatestSubmission from "pages/assessments/score/components/ScoreLatestSubmission";
//import PastScores from "pages/assessments/score/components/PastScores";
import ScoreHistory from "pages/assessments/score/components/ScoreHistory";
import ScoreChart from "pages/assessments/score/components/ScoreChart";

function QAAdvisory() {
  const mode = "qascore";
  const { response, modeTitle } = useQASurveyManager(mode);
  const avatarURL =
    response.data?.avatarURL !== undefined ? response.data.avatarURL : null;

  return (
    <DashboardLayout>
      <TopnavV3 avatarURL={avatarURL} title={modeTitle} />
      {response.error && (
        <>
          {response.error?.name === "OperationInvalidError" && (
            <InvalidOperation />
          )}
          {response.error?.name === "OperationIncompleteError" && (
            <IncompleteOperation />
          )}
          {response.error?.name === "PromptAddBabyProfile" && (
            <PromptAddBabyProfile />
          )}
          {/*<ErrorSnackbar
            title={modeTitle}
            dateTime=""
            message={response.error?.message}
            autoDismiss
          />*/}
        </>
      )}
      {response.success && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox mb={3} />
            {response.data?.records !== undefined && (
              <ScoreLatestSubmission
                record={response.data.records[response.data.records.length - 1]}
              />
            )}
            <MDBox mb={3} />
            {/* Check "response.data?.records.length > 1" to make sure there are at least 2 records - past record(s) + latest record */}
            {response.data?.records !== undefined &&
              response.data?.records.length > 1 && (
                <ScoreHistory records={response.data.records} />
              )}
            <MDBox mb={3} />
            {response.data?.records !== undefined &&
              response.data?.records.length > 0 && (
                <ScoreChart records={response.data.records} />
              )}
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
}

export default QAAdvisory;
