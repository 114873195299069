const form = {
  formId: "nudges-form",
  formField: {
    week: {
      name: "week",
      label: "Week",
      type: "text",
      errorMsg: "Week is required.",
      invalidMsg: "Week is invalid.",
    },
    contents: {
      name: "contents",
      label: "Contents",
      type: "text",
      errorMsg: "Contents is required.",
      invalidMsg: "Contents is invalid.",
    },
  },
};

export { form as default };
