/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `auth` key is used for marking an item on the Sidenav that require authentication to access the link.
  9. The `permissions` key is used for marking an item on the Sidenav that require specified roles to access the link.
  10. The `route` key is used for the "path" parameter of <Route> component.
  11. The `link` key is used for the "to" parameter of <Link> component.
  12. The `href` key is used to store the external links location.
  13. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  14. The `component` key is used to store the component of its route.
*/

import { Navigate } from "react-router-dom";

import AccountProfile from "pages/account/manage/AccountProfile";

//import ManageCompany from "pages/settings/company/manage/ManageCompany";
import NewUser from "pages/settings/user/new/NewUser";
import ManageUsers from "pages/settings/users/manage/ManageUsers";
import UserStatistics from "pages/settings/userstatistics/manage/UserStatistics";
import ManageRoles from "pages/settings/roles/manage/ManageRoles";

import Landing from "pages/main/landing";

import QASurveyProxy from "pages/assessments/manage/QASurveyProxy";
import QAAdvisoryProxy from "pages/assessments/advisory/QAAdvisoryProxy";
import QAScoreProxy from "pages/assessments/score/QAScoreProxy";

import Tracking from "pages/tracking/manage/Tracking";
import TrackingWeekly from "pages/tracking/manageweekly/TrackingWeekly";

import SignIn from "pages/authentication/signin";
import SignUp from "pages/authentication/signup";
import VerifyEmail from "pages/authentication/verifyemail";
import ResetPassword from "pages/authentication/resetpassword";

import NudgeRecords from "pages/nudges/manage/NudgeRecords";
import NudgeSendingSchedule from "pages/nudges/manage/NudgeSendingSchedule";

import MDAvatar from "components/atoms/MDAvatar";

import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewListIcon from "@mui/icons-material/ViewList";

import { Permission } from "models/abac";

const defaultSignedInPath = "/account/profile";

const padTo2Digits = (num) => {
  return String(num).padStart(2, "0");
};
const now = new Date();

const navigate = (displayName, displayNameKey) => {
  return [
    {
      name: displayName ?? "Unknown",
      key: displayNameKey ?? "unknown",
      auth: true,
      permissions: Permission.VIEW_MEMBER_DASHBOARD,
      link: `/account/profile`,
      route: `/account/profile`,
      component: <AccountProfile />,
      icon: (
        <MDAvatar bgColor="info" size="sm">
          {displayName?.charAt(0).toUpperCase()}
        </MDAvatar>
      ),
    },
    {
      type: "divider",
      key: "divider-menu",
    },
    {
      type: "title",
      title: "Menu",
      key: "title-menu",
    },
    {
      type: "divider",
      key: "divider-manage",
      auth: true,
      permissions: Permission.VIEW_ADMIN_DASHBOARD,
    },
    {
      type: "title",
      title: "Manage",
      key: "title-manage",
      auth: true,
      permissions: Permission.VIEW_ADMIN_DASHBOARD,
    },
    {
      type: "collapse",
      name: "Assessments",
      key: "assessments",
      auth: true,
      permissions: Permission.VIEW_SETTINGS_ACTION,
      icon: <QuestionAnswerIcon />,
      collapse: [
        {
          name: "Questionnaire",
          key: "que-ans",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          route: "/assessments/qasurvey",
          link: "/assessments/qasurvey",
          component: <QASurveyProxy />,
        },
        {
          name: "Advisories",
          key: "advisories",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          route: "/assessments/qaadvisory",
          link: "/assessments/qaadvisory",
          component: <QAAdvisoryProxy />,
        },
        {
          name: "Scores",
          key: "scores",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          route: "/assessments/qascore",
          link: "/assessments/qascore",
          component: <QAScoreProxy />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Tracking",
      key: "tracking",
      auth: true,
      permissions: Permission.VIEW_SETTINGS_ACTION,
      icon: <QuestionAnswerIcon />,
      collapse: [
        {
          name: "Daily Log",
          key: "daily-log",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          link:
            "/tracking/dailylog/" +
            padTo2Digits(now.getDate()) +
            padTo2Digits(now.getMonth() + 1) +
            padTo2Digits(now.getFullYear()),
          component: <Tracking />,
        },
        {
          name: "Weekly Log",
          key: "weekly-log",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          route: "/tracking/weeklylog",
          link: "/tracking/weeklylog",
          component: <TrackingWeekly />,
        },
      ],
    },

    {
      type: "divider",
      key: "divider-settings",
      auth: true,
      permissions: Permission.VIEW_ADMIN_DASHBOARD,
    },
    {
      type: "title",
      title: "Admin",
      key: "title-admin",
      auth: true,
      permissions: Permission.VIEW_ADMIN_DASHBOARD,
    },
    {
      type: "collapse",
      name: "Settings",
      key: "settings",
      auth: true,
      permissions: Permission.VIEW_SETTINGS_ACTION,
      icon: <SettingsIcon />,
      collapse: [
        {
          name: "New User",
          key: "new-user",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          route: "/settings/user",
          link: "/settings/user",
          component: <NewUser />,
        },
        {
          name: "Users",
          key: "manage-users",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          route: "/settings/users",
          link: "/settings/users",
          component: <ManageUsers />,
        },
        {
          name: "User Statistics",
          key: "manage-user-statistics",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          route: "/settings/userStatistics",
          link: "/settings/userStatistics",
          component: <UserStatistics />,
        },
        {
          name: "Roles",
          key: "manage-roles",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          route: "/settings/roles",
          link: "/settings/roles",
          component: <ManageRoles />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Nudges",
      key: "nudges",
      auth: true,
      permissions: Permission.VIEW_SETTINGS_ACTION,
      icon: <ViewListIcon />,
      collapse: [
        {
          name: "Months 0 to 2",
          key: "months-0to2",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          link: "/nudges/Months0to2",
          component: <NudgeRecords />,
        },
        {
          name: "Months 2 to 4",
          key: "months-2to4",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          link: "/nudges/Months2to4",
          component: <NudgeRecords />,
        },
        {
          name: "Months 4 to 6",
          key: "months-4to6",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          link: "/nudges/Months4to6",
          component: <NudgeRecords />,
        },
        {
          name: "Months 6 to 9",
          key: "months-6to9",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          link: "/nudges/Months6to9",
          component: <NudgeRecords />,
        },
        {
          name: "Months 9 to 12",
          key: "months-9to12",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          link: "/nudges/Months9to12",
          component: <NudgeRecords />,
        },
        {
          name: "Months 12 to 15",
          key: "months-12to15",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          link: "/nudges/Months12to15",
          component: <NudgeRecords />,
        },
        {
          name: "Months 15 to 24",
          key: "months-15to24",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          link: "/nudges/Months15to24",
          component: <NudgeRecords />,
        },
        {
          name: "Settings",
          key: "nudge-send-setting",
          auth: true,
          permissions: Permission.VIEW_ADMIN_DASHBOARD,
          route: "/nudges/settings",
          link: "/nudges/settings",
          component: <NudgeSendingSchedule />,
        },
      ],
    },
    {
      name: "Landing",
      key: "landing",
      route: "/main/landing",
      component: <Landing />,
    },
    {
      name: "Sign In",
      key: "sign-in",
      route: "/authentication/signin",
      component: <SignIn />,
    },
    {
      name: "Sign Up",
      key: "sign-up",
      route: "/authentication/signup",
      component: <SignUp />,
    },
    {
      name: "Reset Password",
      key: "reset-password",
      route: "/authentication/resetpassword",
      component: <ResetPassword />,
    },
    {
      name: "Verify Email",
      key: "verify-email",
      auth: true,
      route: "/authentication/verifyemail",
      component: <VerifyEmail />,
    },
    {
      name: "All",
      key: "all",
      route: "*",
      /*component: <Navigate to={`/main/landing`} />,*/
      component: <Navigate to={`/account/profile`} />,
    },
    {
      name: "Manage Daily Log",
      key: "manage-daily-log",
      auth: true,
      route: "/tracking/dailylog/:date",
      component: <Tracking />,
    },
    {
      name: "Manage Nudge Records",
      key: "manage-nudge-records",
      auth: true,
      route: "/nudges/:period",
      component: <NudgeRecords />,
    },

    /*{
      name: "Manage QA Survey",
      key: "manage-qa-survey",
      auth: true,
      route: "/assessments/:mode",
      component: <QASurveyProxy />,
    },
    {
      name: "Manage QA Advisory",
      key: "manage-qa-advisory",
      auth: true,
      route: "/assessments/:mode",
      component: <QAAdvisoryProxy />,
    },*/
  ];
};

export { navigate as default, defaultSignedInPath };
