const form = {
  formId: "account-form",
  formField: {
    dob: {
      name: "dob",
      label: "Date of Birth",
      type: "text",
      errorMsg: "Date of Birth is required.",
      invalidMsg: "Date of Birth is invalid.",
    },
    babyName: {
      name: "babyName",
      label: "Baby Name",
      type: "text",
      errorMsg: "Baby Name is required.",
      invalidMsg: "Baby Name is invalid.",
    },
    userName: {
      name: "userName",
      label: "User Name",
      type: "text",
      errorMsg: "User Name is required.",
      invalidMsg: "User Name is invalid.",
    },
    userGroup: {
      name: "userGroup",
      label: "User Group",
      type: "text",
      errorMsg: "User Group is required.",
      invalidMsg: "User Group is invalid.",
    },
    email: {
      name: "email",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    roles: {
      name: "roles",
      label: "Roles",
      type: "box",
      min1Msg: "Must have at least 1 Role.",
      max50Msg: "Must have at most 50 Roles.",
    },
    emailSignOff: {
      name: "emailSignOff",
      label: "Email Sign Off",
      type: "text",
      errorMsg: "Email Sign Off is required.",
      invalidMsg: "Email Sign Off is invalid.",
    },
    signatureAttachments: {
      name: "signatureAttachments",
      label: "Signature Attachment",
      type: "box",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
    photoAttachments: {
      name: "photoAttachments",
      label: "Baby Picture",
      type: "box",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
    avatarAttachments: {
      name: "avatarAttachments",
      label: "Avatar Picture",
      type: "box",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
  },
};

const rolesForm = {
  formId: "roles-form",
  formField: {
    roleName: {
      name: "roleName",
      label: "Role",
      type: "text",
      errorMsg: "Role is required.",
      invalidMsg: "Role is invalid.",
    },
  },
};

const signatureAttachmentsForm = {
  formId: "signature-attachments-form",
  formField: {
    signatureAttachmentFile: {
      name: "signatureAttachmentsFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    signatureAttachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    signatureAttachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    signatureAttachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

const photoAttachmentsForm = {
  formId: "photo-attachments-form",
  formField: {
    photoAttachmentFile: {
      name: "photoAttachmentsFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    photoAttachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    photoAttachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    photoAttachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

const avatarAttachmentsForm = {
  formId: "avatar-attachments-form",
  formField: {
    avatarAttachmentFile: {
      name: "avatarAttachmentsFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    avatarAttachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    avatarAttachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    avatarAttachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

export {
  form as default,
  rolesForm,
  signatureAttachmentsForm,
  photoAttachmentsForm,
  avatarAttachmentsForm,
};
