import { useAuthContext } from "context/AuthContext";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";

import FormAttachments from "components/molecules/Formik/FormAttachments";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormDatePicker from "components/molecules/Formik/FormDatePicker";
import CloseIcon from "@mui/icons-material/Close";

//import { signatureAttachmentsForm } from "pages/account/manage/schemas/form";
//import { signatureAttachmentsInitialValues } from "pages/account/manage/schemas/initialValues";
//import { signatureAttachmentsValidation } from "pages/account/manage/schemas/validations";

import { photoAttachmentsForm } from "pages/account/manage/schemas/form";
import { photoAttachmentsInitialValues } from "pages/account/manage/schemas/initialValues";
import { photoAttachmentsValidation } from "pages/account/manage/schemas/validations";

import { avatarAttachmentsForm } from "pages/account/manage/schemas/form";
import { avatarAttachmentsInitialValues } from "pages/account/manage/schemas/initialValues";
import { avatarAttachmentsValidation } from "pages/account/manage/schemas/validations";

function BasicInformation({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
  setEditProfile,
}) {
  const {
    babyName,
    dob,
    //userName,
    //userGroup,
    //email,
    // emailSignOff,
    //signatureAttachments,
    photoAttachments,
    avatarAttachments,
    //que1,
  } = mainFormField;

  const { user } = useAuthContext();
  //console.log(JSON.stringify(mainForm));
  //console.log(JSON.stringify(dob));
  //console.log("dob:: " + JSON.stringify(mainForm.values.dob));

  // destructure from Attachments Form for current section
  /*const {
    formId: signatureAttachmentsFormId,
    formField: signatureAttachmentsFormField,
  } = signatureAttachmentsForm;*/
  /*const {
    signatureAttachmentFile,
    signatureAttachmentName,
    signatureAttachmentURL,
  } = signatureAttachmentsFormField;*/

  const {
    formId: photoAttachmentsFormId,
    formField: photoAttachmentsFormField,
  } = photoAttachmentsForm;
  const { photoAttachmentFile, photoAttachmentName, photoAttachmentURL } =
    photoAttachmentsFormField;

  const {
    formId: avatarAttachmentsFormId,
    formField: avatarAttachmentsFormField,
  } = avatarAttachmentsForm;
  const { avatarAttachmentFile, avatarAttachmentName, avatarAttachmentURL } =
    avatarAttachmentsFormField;

  return (
    <Card id="basic-information" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox
          mb={3}
          display="flex"
          alignItems="end"
          justifyContent="space-between"
        >
          <MDTypography variant="h5">Baby Profile</MDTypography>
          <MDButton
            variant="outlined"
            color="success"
            iconOnly
            size="small"
            onClick={() => setEditProfile(false)}
          >
            <CloseIcon />
          </MDButton>
        </MDBox>

        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {babyName.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={babyName}
                variant="standard"
                disabled={modeDisabled}
                hideLabel={true}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {dob.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormDatePicker
                form={mainForm}
                field={dob}
                inputFormat={"dd/MM/yyyy"}
                variant="standard"
                disabled={modeDisabled}
                hideLabel="true"
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {photoAttachments.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormAttachments
                showTitle={false}
                form={mainForm}
                field={photoAttachments}
                //{photoAttachments}
                max={1}
                disabled={modeDisabled}
                dispatchError={dispatchMainError}
                itemFormId={photoAttachmentsFormId}
                itemInitialValues={photoAttachmentsInitialValues}
                itemValidation={photoAttachmentsValidation}
                itemFileField={photoAttachmentFile}
                itemKeyField={photoAttachmentURL}
                itemDisplayFields={[photoAttachmentName]}
                bucketPath={user.uid}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {avatarAttachments.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormAttachments
                showTitle={false}
                form={mainForm}
                field={avatarAttachments}
                //{avatarAttachments}
                max={1}
                disabled={modeDisabled}
                dispatchError={dispatchMainError}
                itemFormId={avatarAttachmentsFormId}
                itemInitialValues={avatarAttachmentsInitialValues}
                itemValidation={avatarAttachmentsValidation}
                itemFileField={avatarAttachmentFile}
                itemKeyField={avatarAttachmentURL}
                itemDisplayFields={[avatarAttachmentName]}
                bucketPath={user.uid}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default BasicInformation;
