import { useCallback, useState, useEffect } from "react";
import { useCollectionSnapshot } from "hooks/useCollectionSnapshot";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";

//import MDButton from "components/atoms/MDButton";
//import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
//import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";
//import DashboardFooter from "components/organisms/Footer/DashboardFooter";
//import PermissionDenied from "components/organisms/PermissionDenied";
//import InvalidOperation from "components/organisms/InvalidOperation";
//import IncompleteOperation from "components/organisms/IncompleteOperation";
//import SubmissionProgress from "components/organisms/SubmissionProgress";
//import { AllowedTo } from "react-abac";
//import { useSettingsManager } from "pages/settings/users/hooks/useSettingsManager";

import ComplexStatisticsCard from "components/molecules/Cards/StatisticsCards/ComplexStatisticsCard";

const usersQueries = {
  whereQueries: [
    {
      field: "deletedAt",
      condition: "==",
      value: null,
    },
  ],
};

function UserStatistics() {
  const { collectionData: usersData } = useCollectionSnapshot(
    "users",
    usersQueries
  );
  const [totalParticipants, setTotalParticipants] = useState(0);

  const initializeFieldValue = useCallback(async () => {
    setTotalParticipants(usersData.length);
  }, [usersData]);

  useEffect(() => {
    try {
      initializeFieldValue();
    } catch (err) {}
  }, [initializeFieldValue]);

  return (
    <DashboardLayout>
      <Topnav title="User Statistics" /*{modeTitle}*/ />
      <MDBox pt={3} pb={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mt={1.5} mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Total Participants"
                count={totalParticipants}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Number of participants registered",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {/*<AllowedTo perform={modePermission} no={() => <PermissionDenied />}>
        {response.isPending ? (
          <SubmissionProgress />
        ) : (
          <>
            {response.error && (
              <>
                {response.error?.name === "OperationInvalidError" && (
                  <InvalidOperation />
                )}
                {response.error?.name === "OperationIncompleteError" && (
                  <IncompleteOperation />
                )}
                <ErrorSnackbar
                  title={modeTitle}
                  dateTime=""
                  message={response.error?.message}
                  autoDismiss
                />
              </>
            )}
            {response.success && (
              <SuccessSnackbar
                title={modeTitle}
                dateTime=""
                message={response.success}
                autoDismiss
              />
            )}
            <MDBox pt={3}>
              <MDBox>
                {response.error?.name !== "OperationInvalidError" && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={2}>
                      <Sidenav />
                    </Grid>

                    <Grid item xs={12} lg={10}>
                      <MDBox mb={3}>
                        <Formik
                          enableReinitialize
                          initialValues={response.data}
                          validationSchema={modeValidation}
                          onSubmit={handleSubmit}
                        >
                          {(formik) => (
                            <Form id={formId} autoComplete="off">
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <Accounts
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    dispatchMainError={dispatchError}
                                    selectedAccount={selectedAccount}
                                    setSelectedAccount={setSelectedAccount}
                                    authUserDisabled={authUserDisabled}
                                    setAuthUserDisabled={setAuthUserDisabled}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <BasicInformation
                                    mainForm={formik}
                                    mainFormField={formField}
                                    modeDisabled={modeFieldDisabled}
                                    dispatchMainError={dispatchError}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <MDBox
                                    width="100%"
                                    display="flex"
                                    justifyContent="space-between"
                                  >
                                    {authUserDisabled !== null ? (
                                      <>
                                        {authUserDisabled === true && (
                                          <MDButton
                                            disabled={
                                              formik.isSubmitting || isSaving
                                            }
                                            variant="gradient"
                                            color="success"
                                            onClick={() => handleEnable()}
                                          >
                                            Enable
                                          </MDButton>
                                        )}
                                        {authUserDisabled === false && (
                                          <MDButton
                                            disabled={
                                              formik.isSubmitting || isSaving
                                            }
                                            variant="gradient"
                                            color="error"
                                            onClick={() => handleDisable()}
                                          >
                                            Disable
                                          </MDButton>
                                        )}
                                      </>
                                    ) : (
                                      <MDBox></MDBox>
                                    )}
                                    <MDButton
                                      disabled={formik.isSubmitting || isSaving}
                                      type="submit"
                                      variant="gradient"
                                      color="dark"
                                    >
                                      {modeSubmit}
                                    </MDButton>
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </Form>
                          )}
                        </Formik>
                      </MDBox>
                    </Grid>
                  </Grid>
                )}
              </MDBox>
            </MDBox>
            <DashboardFooter />
          </>
        )}
      </AllowedTo>*/}
    </DashboardLayout>
  );
}

export default UserStatistics;
