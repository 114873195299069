//import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

function ScoreChart({ records }) {
  const [chartData, setChartData] = useState([]);
  const options = {
    chart: {
      type: "line",
    },
    title: {
      text: "",
    },
    xAxis: {
      title: {
        text: "Age (months)",
      },
    },
    yAxis: {
      title: {
        text: "FLAGs (%)",
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: false,
      },
    },
    series: [
      {
        name: "Reggane",
        data: chartData,
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };

  useEffect(() => {
    try {
      const data = records.map((record) => {
        return [
          parseFloat(record.age.months),
          Math.round(parseFloat(record.normalizedScore) * 100),
        ];
      });
      setChartData(data);
    } catch (err) {
      console.log(err);
    }
  }, [records]);

  return (
    <MDBox mb={3}>
      <Card id="qa-scorechart" sx={{ overflow: "visible" }}>
        <MDBox ml={2.5} mt={-1.5}>
          <MDButton
            size="small"
            variant="contained"
            color="success"
            disabled={true}
          >
            Chart
          </MDButton>
        </MDBox>
        <MDBox
          m={2}
          p={2}
          pb={1}
          pl={1}
          sx={{ border: "1px solid grey", borderRadius: "10px" }}
        >
          {chartData.length > 0 && (
            <HighchartsReact highcharts={Highcharts} options={options} />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

export default ScoreChart;
