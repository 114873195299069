const formTimeSetting = {
  formId: "time-setting-form",
  formField: {
    timeISOstring: {
      name: "timeISOstring",
      type: "text",
      label: "Time",
      errorMsg: "Time is required.",
      invalidMsg: "Time is invalid.",
    },
    day: {
      name: "day",
      type: "text",
      label: "Day",
      errorMsg: "Day is required.",
      invalidMsg: "Day is invalid.",
    },
  },
};

export { formTimeSetting as default };
