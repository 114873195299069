import { useState, useEffect } from "react";

// react-router components
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
// import MDBadge from "components/atoms/MDBadge";

import NotificationItem from "components/molecules/Items/NotificationItem";

// import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import RefreshIcon from "@mui/icons-material/Refresh";
import HomeIcon from "@mui/icons-material/Home";

// Custom styles for TopnavV3
import {
  navbar,
  navbarContainer,
  // navbarRow,
  // navbarIconButton,
  // navbarDesktopMenu,
  // navbarMobileMenu,
} from "components/organisms/TopnavV3/styles";

import {
  useMuiContext,
  // setTransparentNavbar,
  setMiniSidenav,
} from "context/MuiContext";

import { useSignout } from "pages/authentication/hooks/useSignout";

import { useCacheContext } from "context/CacheContext";
import MDAvatar from "components/atoms/MDAvatar";

function TopnavV3({ avatarURL, title, subtitle, absolute, light, isMini }) {
  let { isLatestVersion, refreshCacheAndReload } = useCacheContext();
  const [navbarType, setNavbarType] = useState();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode, dispatch } =
    useMuiContext();
  const [openMenu, setOpenMenu] = useState(false);

  const navigate = useNavigate();
  const { signout } = useSignout();
  const { roles } = useAuthContext();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    // function handleTransparentNavbar() {
    //   setTransparentNavbar(
    //     dispatch,
    //     (fixedNavbar && window.scrollY === 0) || !fixedNavbar
    //   );
    // }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    // commented because causing too many rerender when scrolling
    //window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    //handleTransparentNavbar();

    // Remove event listener on cleanup
    //return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        icon={<Icon>groups</Icon>}
        title="Notification Message 1"
      />
      <NotificationItem
        icon={<Icon>question_answer</Icon>}
        title="Notification Message 2"
      />
      <NotificationItem
        icon={<Icon>currency_exchange</Icon>}
        title="Notification Message 3"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox display="flex" alignItems="center">
          {roles?.includes("SUPERADMIN") || roles?.includes("ADMIN") ? (
            <IconButton onClick={handleMiniSidenav} size="small" disableRipple>
              <Icon fontSize="medium" sx={iconsStyle}>
                {miniSidenav ? "menu_open" : "menu"}
              </Icon>
            </IconButton>
          ) : (
            <MDBox></MDBox>
          )}

          {/*<IconButton onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>*/}
          {avatarURL !== undefined && (
            <MDAvatar src={avatarURL} alt="avatar profile" size="sm" />
          )}
        </MDBox>

        <MDBox
          color="inherit"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          // mb={{ xs: 1, md: 0 }}
          // sx={(theme) => navbarRow(theme, { isMini })}
        >
          <MDTypography
            fontWeight="bold"
            textTransform="capitalize"
            variant="body2"
            color={light ? "white" : "dark"}
            noWrap
          >
            {title}
          </MDTypography>
          {!!subtitle && (
            <MDTypography
              fontWeight="regular"
              //textTransform="capitalize"
              variant="caption"
              color={light ? "white" : "dark"}
              noWrap
            >
              {subtitle}
            </MDTypography>
          )}
        </MDBox>

        {!isLatestVersion && (
          <IconButton
            size="small"
            disableRipple
            color="inherit"
            // sx={navbarIconButton}
            onClick={refreshCacheAndReload}
          >
            <RefreshIcon sx={iconsStyle}></RefreshIcon>
          </IconButton>
        )}
        <MDBox>
          <IconButton
            size="small"
            disableRipple
            color="inherit"
            // sx={navbarIconButton}
            onClick={async () => {
              navigate("/account/profile");
            }}
          >
            <HomeIcon sx={iconsStyle}></HomeIcon>
          </IconButton>
          <IconButton
            size="small"
            disableRipple
            color="inherit"
            // sx={navbarIconButton}
            onClick={async () => {
              await signout();
              navigate("/authentication/signin");
            }}
          >
            <LogoutIcon sx={iconsStyle}></LogoutIcon>
          </IconButton>
        </MDBox>
        {renderMenu()}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of TopnavV3
TopnavV3.defaultProps = {
  title: "",
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the TopnavV3
TopnavV3.propTypes = {
  title: PropTypes.string,
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default TopnavV3;
