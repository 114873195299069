import formSedentary from "pages/tracking/manage/schemas/formSedentary";

/*const startTimeValue = new Date();
const endTimeValue = new Date(startTimeValue);
endTimeValue.setMinutes(startTimeValue.getMinutes() + 30);*/

const {
  formField: {
    sedentaryActivity,
    distractionUsed,
    screenBased,
    interactive,
    duration,
    starttime,
    endtime,
  },
} = formSedentary;

const initialValuesSedentary = {
  [sedentaryActivity.name]: "",
  [distractionUsed.name]: "",
  [screenBased.name]: "NIL",
  [interactive.name]: "",
  [duration.name]: "",
  [starttime.name]: "",
  [endtime.name]: "",
};

export { initialValuesSedentary as default };
