const form = {
  formId: "account-form",
  formField: {
    /*firstName: {
      name: "firstName",
      label: "First Name",
      type: "text",
      errorMsg: "First Name is required.",
      invalidMsg: "First Name is invalid.",
    },*/
    /*lastName: {
      name: "lastName",
      label: "Last Name",
      type: "text",
      errorMsg: "Last Name is required.",
      invalidMsg: "Last Name is invalid.",
    },*/
    userName: {
      name: "userName",
      label: "User Name",
      type: "text",
      errorMsg: "User Name is required.",
      invalidMsg: "User Name is invalid.",
    },
    userGroup: {
      name: "userGroup",
      label: "User Group",
      type: "text",
      errorMsg: "User Group is required.",
      invalidMsg: "User Group is invalid.",
    },
    email: {
      name: "email",
      label: "Email",
      type: "text",
      errorMsg: "Email is required.",
      invalidMsg: "Email is invalid.",
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg:
        "Must have:\n At least 8 characters\n At least one digit\n At least one lowercase character\n At least one uppercase character\n At least one special character",
    },
    mobile: {
      name: "mobile",
      label: "Mobile",
      type: "text",
      errorMsg: "Mobile is required.",
      invalidMsg: "Mobile is invalid.",
    },
    roles: {
      name: "roles",
      label: "Roles",
      type: "box",
      min1Msg: "Must have at least 1 Role.",
      max50Msg: "Must have at most 50 Roles.",
    },
    emailSignOff: {
      name: "emailSignOff",
      label: "Email Sign Off",
      type: "text",
      errorMsg: "Email Sign Off is required.",
      invalidMsg: "Email Sign Off is invalid.",
    },
    signatureAttachments: {
      name: "signatureAttachments",
      label: "Signature Attachment",
      type: "box",
      min1Msg: "Must have at least 1 Attachment.",
      max20Msg: "Must have at most 20 Attachments.",
    },
  },
};

const rolesForm = {
  formId: "roles-form",
  formField: {
    roleName: {
      name: "roleName",
      label: "Role",
      type: "text",
      errorMsg: "Role is required.",
      invalidMsg: "Role is invalid.",
    },
  },
};

const signatureAttachmentsForm = {
  formId: "signature-attachments-form",
  formField: {
    signatureAttachmentFile: {
      name: "signatureAttachmentsFile",
      label: "Attachment File",
      type: "file",
      errorMsg: "Attachment File is required.",
      invalidMsg: "File size must be less than 5 MB.",
    },
    attachmentPath: {
      name: "attachmentPath",
      label: "Attachment Path",
      type: "text",
    },
    attachmentName: {
      name: "attachmentName",
      label: "Attachment Path",
      type: "text",
    },
    attachmentURL: {
      name: "attachmentURL",
      label: "Attachment Path",
      type: "text",
    },
  },
};

export { form as default, rolesForm, signatureAttachmentsForm };
