import { useEffect, useState } from "react";
import format from "date-fns/format";
import Grid from "@mui/material/Grid";
import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import DashboardLayout from "components/templates/DashboardLayout";
import TopnavV2 from "components/organisms/TopnavV2";
import DashboardFooter from "components/organisms/Footer/DashboardFooter";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";
import SubmissionProgress from "components/organisms/SubmissionProgress";

import BasicInformation from "pages/account/manage/components/BasicInformation";
import Functions from "pages/account/manage/components/Functions";
import { Formik, Form } from "formik";
import form from "pages/account/manage/schemas/form";
import SwipeableViews from "react-swipeable-views";
import backgroundImage from "assets/images/backgroundBlue.jpg";
import MDTypography from "components/atoms/MDTypography";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ImageIcon from "@mui/icons-material/Image";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { AllowedTo } from "react-abac";
//import initialValues from "pages/account/manage/schemas/initialValues";
import { useAccountManager } from "pages/account/hooks/useAccountManager";

import { useAppContext } from "context/AppContext";
import { setSelectedBabyProfile } from "context/AppContext";

function AccountProfile() {
  const [editProfile, setEditProfile] = useState(false);
  const {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modeValidation,
    submitEdit,
    submitNew,
    response,
    modePermission,
    dispatchError,
    formikInitValues,
    setFormikInitValues,
    updateDoc,
    collectionPathUsers,
    user,
    serverTimestamp,
  } = useAccountManager();

  const { formId, formField } = form;
  const handleSubmit = async (values, actions) => {
    try {
      if (tabIndex === response.data.babies.length) {
        await submitNew(values, response.data);
      } else {
        await submitEdit(values, response.data, tabIndex);
      }
    } catch (err) {
      dispatchError(err);
    } finally {
      handleChangeIndex(tabIndex);
      //handleChange(null, response.data.babies.length - 1);
    }
  };

  const [tabIndex, setTabIndex] = useState(0);
  const [swipeIndex, setSwipeIndex] = useState(0);
  const handleChange = async (event, value) => {
    if (value < response.data.babiesProfile.length) {
      setFormikInitValues({
        babyName: response.data.babiesProfile[value].babyName,
        dob: response.data.babiesProfile[value].dob
          .toDate()
          .toLocaleDateString("en-SG"),
        photoAttachments: response.data.babiesProfile[value].photoAttachments,
        avatarAttachments: response.data.babiesProfile[value].avatarAttachments,
      });

      // Update "selectedBaby" variable on user.uid document
      await updateDoc(collectionPathUsers, user.uid, {
        selectedBaby: response.data.babies[value],
        modifiedAt: serverTimestamp(),
        modifiedBy: user.uid,
      });
    } else {
      const dobString = format(new Date(), "dd/MM/yyyy");
      setFormikInitValues({
        babyName: "",
        dob: dobString,
        photoAttachments: [],
        avatarAttachments: [],
      });

      // Update "selectedBaby" variable on user.uid document
      await updateDoc(collectionPathUsers, user.uid, {
        selectedBaby: "",
        modifiedAt: serverTimestamp(),
        modifiedBy: user.uid,
      });
    }
    setTabIndex(value);
    setSwipeIndex(value);
  };
  const handleChangeIndex = async (index) => {
    if (index < response.data.babiesProfile.length) {
      setFormikInitValues({
        babyName: response.data.babiesProfile[index].babyName,
        dob: response.data.babiesProfile[index].dob
          .toDate()
          .toLocaleDateString("en-SG"),
        photoAttachments: response.data.babiesProfile[index].photoAttachments,
        avatarAttachments: response.data.babiesProfile[index].avatarAttachments,
      });

      // Update "selectedBaby" variable on user.uid document
      await updateDoc(collectionPathUsers, user.uid, {
        selectedBaby: response.data.babies[index],
        modifiedAt: serverTimestamp(),
        modifiedBy: user.uid,
      });
    } else {
      const dobString = format(new Date(), "dd/MM/yyyy");
      setFormikInitValues({
        babyName: "",
        dob: dobString,
        photoAttachments: [],
        avatarAttachments: [],
      });
      // Update "selectedBaby" variable on user.uid document
      await updateDoc(collectionPathUsers, user.uid, {
        selectedBaby: "",
        modifiedAt: serverTimestamp(),
        modifiedBy: user.uid,
      });
    }
    setSwipeIndex(index);
    setTabIndex(index);
  };

  const { dispatch: dispatchAppContext } = useAppContext();
  useEffect(() => {
    try {
      if (response.data?.babiesProfile !== undefined) {
        setSelectedBabyProfile(dispatchAppContext, {
          babyUid: response.data?.babies[tabIndex],
          babyProfile: response.data?.babiesProfile[tabIndex],
        });
      } else {
        setSelectedBabyProfile(dispatchAppContext, {
          babyUid: null,
          babyProfile: null,
        });
      }
    } catch (err) {
      dispatchError(err);
    }
  }, [dispatchError, dispatchAppContext, tabIndex, response]);

  return (
    <DashboardLayout>
      <TopnavV2 title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />}>
        {response.isPending ? (
          <SubmissionProgress />
        ) : (
          <>
            {response.error && (
              <>
                {response.error?.name === "OperationInvalidError" && (
                  <InvalidOperation />
                )}
                {response.error?.name === "OperationIncompleteError" && (
                  <IncompleteOperation />
                )}
                <ErrorSnackbar
                  title={modeTitle}
                  dateTime=""
                  message={response.error?.message}
                  autoDismiss
                />
              </>
            )}
            {response.success && (
              <SuccessSnackbar
                title={modeTitle}
                dateTime=""
                message={response.success}
                autoDismiss
              />
            )}

            {/* Profile picture */}
            <MDBox pt={0} mt={-4}>
              <Grid item xs={12}>
                <SwipeableViews
                  enableMouseEvents
                  index={swipeIndex}
                  onChangeIndex={handleChangeIndex}
                  //key={swipeIndex}
                >
                  {response.data?.babiesProfile !== undefined &&
                    response.data.babiesProfile.map((babyProfile) => {
                      const url = `url(${babyProfile.photoAttachments[0].attachmentURL})`;
                      const dob = new Date(
                        babyProfile.dob.toDate().toLocaleDateString("en-US")
                      );
                      const date1 = new Date();
                      const elapsedDays = (date1 - dob) / (1000 * 60 * 60 * 24);
                      const days = parseInt(elapsedDays);
                      const weeks = parseFloat(days / 7).toFixed(1); //Math.ceil(days / 7);
                      const months = parseFloat(days / 30).toFixed(1); // Math.ceil(days / 30);

                      return (
                        <MDBox
                          borderRadius="xl"
                          shadow="md"
                          zIndex={0}
                          sx={{
                            backgroundImage: url,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            //width: "100vw",
                            //transform: "scale(1.0)",
                            //filter: "blur(1px)",
                            opacity: [1, 1, 1],
                          }}
                          key={babyProfile.babyName}
                        >
                          <MDBox pt={45} />
                          <MDBox
                            p={0.5}
                            pl={2}
                            pr={2}
                            bgColor="white"
                            width="100%"
                            borderRadius="lg"
                            lineHeight={1}
                            sx={{ opacity: [0.8, 0.8, 0.8] }}
                          >
                            <Grid
                              container
                              rowSpacing={{ xs: 0 }}
                              columnSpacing={{ xs: 1 }}
                              alignItems="center"
                            >
                              <Grid item xs={12}>
                                <MDTypography
                                  variant="success"
                                  color="dark"
                                  fontWeight="medium"
                                >
                                  {babyProfile.babyName}
                                </MDTypography>
                                <MDButton
                                  variant="text"
                                  //size="small"
                                  color="dark"
                                  iconOnly
                                  onClick={() => setEditProfile(true)}
                                >
                                  <BorderColorIcon />
                                </MDButton>
                              </Grid>
                              <Grid item xs={4}>
                                <MDTypography
                                  variant="h3"
                                  color="dark"
                                  fontWeight="bold"
                                >
                                  {months}
                                </MDTypography>
                                <MDBox display="flex" justifyContent="left">
                                  <MDTypography
                                    variant="button"
                                    color="dark"
                                    fontWeight="regular"
                                  >
                                    MONTHS
                                  </MDTypography>
                                </MDBox>
                              </Grid>
                              <Grid item xs={4}>
                                <MDTypography
                                  variant="h3"
                                  color="dark"
                                  fontWeight="bold"
                                  textAlign="center"
                                >
                                  {weeks}
                                </MDTypography>
                                <MDBox display="flex" justifyContent="center">
                                  <MDTypography
                                    variant="button"
                                    color="dark"
                                    fontWeight="regular"
                                    textAlign="center"
                                  >
                                    WEEKS
                                  </MDTypography>
                                </MDBox>
                              </Grid>
                              <Grid item xs={4}>
                                <MDTypography
                                  variant="h3"
                                  color="dark"
                                  fontWeight="bold"
                                  textAlign="right"
                                >
                                  {days}
                                </MDTypography>
                                <MDBox display="flex" justifyContent="right">
                                  <MDTypography
                                    variant="button"
                                    color="dark"
                                    fontWeight="regular"
                                  >
                                    DAYS
                                  </MDTypography>
                                </MDBox>
                              </Grid>
                            </Grid>
                          </MDBox>
                        </MDBox>
                      );
                    })}
                  <MDBox
                    borderRadius="xl"
                    shadow="md"
                    zIndex={0}
                    sx={{
                      backgroundImage: `url(${backgroundImage})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      //width: "100vw",
                      //transform: "scale(1.0)",
                      //filter: "blur(1px)",
                      opacity: [1, 1, 1],
                    }}
                    key={"Add Profile"}
                  >
                    <MDBox pt={-2} />
                    <MDBox pt={20} display="flex" justifyContent="center">
                      <MDButton
                        variant="outlined"
                        size="large"
                        color="info"
                        disabled
                        iconOnly
                        //onClick={() => setEditProfile(true)}
                      >
                        <ImageIcon />
                      </MDButton>
                    </MDBox>
                    <MDBox pt={1} display="flex" justifyContent="center">
                      <MDTypography
                        variant="caption"
                        color="dark"
                        fontWeight="bold"
                      >
                        {"Profile Picture"}
                      </MDTypography>
                    </MDBox>
                    <MDBox pt={15} />
                    <MDBox
                      p={1.5}
                      pl={2}
                      pr={2}
                      bgColor="white"
                      width="100%"
                      borderRadius="lg"
                      lineHeight={1}
                      sx={{ opacity: [0.8, 0.8, 0.8] }}
                    >
                      <Grid
                        container
                        rowSpacing={{ xs: 0 }}
                        columnSpacing={{ xs: 1 }}
                      >
                        <Grid item xs={12}>
                          <MDBox display="flex" justifyContent="right">
                            <MDButton
                              variant="outlined"
                              size="small"
                              color="info"
                              //iconOnly
                              onClick={() => setEditProfile(true)}
                            >
                              Add Baby Profile
                            </MDButton>
                          </MDBox>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography
                            variant="h3"
                            color="dark"
                            fontWeight="bold"
                          >
                            {"0"}
                          </MDTypography>
                          <MDBox display="flex" justifyContent="left">
                            <MDTypography
                              variant="button"
                              color="dark"
                              fontWeight="regular"
                            >
                              MONTHS
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography
                            variant="h3"
                            color="dark"
                            fontWeight="bold"
                            textAlign="center"
                          >
                            {"0"}
                          </MDTypography>
                          <MDBox display="flex" justifyContent="center">
                            <MDTypography
                              variant="button"
                              color="dark"
                              fontWeight="regular"
                              textAlign="center"
                            >
                              WEEKS
                            </MDTypography>
                          </MDBox>
                        </Grid>
                        <Grid item xs={4}>
                          <MDTypography
                            variant="h3"
                            color="dark"
                            fontWeight="bold"
                            textAlign="right"
                          >
                            {"0"}
                          </MDTypography>
                          <MDBox display="flex" justifyContent="right">
                            <MDTypography
                              variant="button"
                              color="dark"
                              fontWeight="regular"
                            >
                              DAYS
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                </SwipeableViews>
                <Tabs
                  value={tabIndex}
                  onChange={handleChange}
                  //style={styles.tabs}
                >
                  {response.data?.babiesProfile !== undefined &&
                    response.data.babiesProfile.map((babyProfile, index) => {
                      return (
                        <Tab
                          label={babyProfile.babyName}
                          key={"tab-babyIndex" + index}
                        />
                      );
                    })}
                  <Tab label="Add Profile" key={"tab-addProfile"} />
                </Tabs>
              </Grid>
              <Grid item xs={12}>
                <MDBox mb={3}>
                  <Formik
                    enableReinitialize
                    initialValues={formikInitValues} //{response.data}
                    validationSchema={modeValidation}
                    onSubmit={handleSubmit}
                  >
                    {(formik) => (
                      <Form id={formId} autoComplete="off">
                        <Grid container spacing={3}>
                          {editProfile && (
                            <Grid item xs={12}>
                              <BasicInformation
                                mainForm={formik}
                                mainFormField={formField}
                                modeDisabled={modeFieldDisabled}
                                dispatchMainError={dispatchError}
                                setEditProfile={setEditProfile}
                              />
                            </Grid>
                          )}
                          {editProfile && (
                            <Grid item xs={12}>
                              <MDBox
                                width="100%"
                                display="flex"
                                justifyContent="space-between"
                              >
                                <MDBox></MDBox>
                                <MDButton
                                  disabled={formik.isSubmitting}
                                  type="submit"
                                  variant="gradient"
                                  color="dark"
                                >
                                  {modeSubmit}
                                </MDButton>
                              </MDBox>
                            </Grid>
                          )}
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                </MDBox>
              </Grid>
            </MDBox>
            {tabIndex < response.data.babiesProfile.length && <Functions />}
            <DashboardFooter />
          </>
        )}
      </AllowedTo>
    </DashboardLayout>
  );
}

export default AccountProfile;
