import GenericTemplate from "components/templates/GenericTemplate";

//import BackgroundDesign from "../../designs/BackgroundDesign";
import ContentFragment from "../../fragments/ContentFragment";
import FooterFragment from "../../fragments/FooterFragment";

function PlainFullLayout2({ children }) {
  return (
    <GenericTemplate>
      <ContentFragment>{children}</ContentFragment>
      <FooterFragment color={"secondary"}></FooterFragment>
    </GenericTemplate>
  );
}

export default PlainFullLayout2;
