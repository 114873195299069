import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CloseIcon from "@mui/icons-material/Close";
import Icon from "@mui/material/Icon";
import { ErrorMessage } from "formik";

//import { DateRangePicker, DatePicker } from 'rsuite';
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import isBefore from "date-fns/isBefore";

import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const foodTypeOptions = [
  { label: "Carbohydrate" },
  { label: "Protein" },
  { label: "Vegetable" },
  { label: "Fat" },
];

const drinkTypeOptions = [
  { label: "Water" },
  { label: "Juice" },
  { label: "Others" },
];

const locationOptions = [
  { label: "Living Room" },
  { label: "Bedroom" },
  { label: "Dining Room" },
  { label: "Kitchen" },
  { label: "Others" },
];

const sittingOnOptions = [
  { label: "High Chair" },
  { label: "Chair" },
  { label: "Floor" },
  { label: "Cot" },
  { label: "Others" },
];

const utensilOptions = [
  { label: "Fork" },
  { label: "Spoon" },
  { label: "Bowl" },
  { label: "Plate" },
  { label: "Bottle" },
  { label: "Others" },
];

function FoodDetails({
  mainForm,
  mainFormField,
  closeInputPanel,
  calendarDate,
}) {
  const {
    varietyType,
    foodType,
    drinkType,
    otherDrinkType,
    whoLed,
    location,
    otherLocation,
    sittingOn,
    otherSittingOn,
    utensil,
    otherUtensil,
    starttime,
    endtime,
  } = mainFormField;

  const { initialValues, values, setFieldValue } = mainForm;
  /* Init Type of Food */
  const foodTypeSplit =
    initialValues.foodType.length > 0 ? initialValues.foodType.split(",") : [];
  const initFoodType =
    foodTypeSplit.length > 0
      ? foodTypeSplit.map((elm) => ({
          label: elm,
        }))
      : [];
  const [foodTypeValue, setFoodTypeValue] = useState(initFoodType);

  /* Init Type of Drink */
  const drinkTypeSplit =
    initialValues.drinkType.length > 0
      ? initialValues.drinkType.split(",")
      : [];
  const initDrinkType =
    drinkTypeSplit.length > 0
      ? drinkTypeSplit.map((elm) => ({
          label: elm,
        }))
      : [];
  const [drinkTypeValue, setDrinkTypeValue] = useState(initDrinkType);

  const [otherDrinkTypeValue, setOtherDrinkTypeValue] = useState(
    initialValues.otherDrinkType
  );
  const [showOtherDrinkType, setShowOtherDrinkType] = useState(false);

  /* Init Who led */
  const [whoLedValue, setWhoLedValue] = useState(initialValues.whoLed);

  /* Init Location */
  const locationSplit =
    initialValues.location.length > 0 ? initialValues.location.split(",") : [];
  const initLocation =
    locationSplit.length > 0
      ? locationSplit.map((elm) => ({
          label: elm,
        }))
      : [];
  const [locationValue, setLocationValue] = useState(initLocation);

  const [otherLocationValue, setOtherLocationValue] = useState(
    initialValues.otherLocation
  );
  const [showOtherLocation, setShowOtherLocation] = useState(false);

  /* Init Sitting On */
  const sittingOnSplit =
    initialValues.sittingOn.length > 0
      ? initialValues.sittingOn.split(",")
      : [];
  const initSittingOn =
    sittingOnSplit.length > 0
      ? sittingOnSplit.map((elm) => ({
          label: elm,
        }))
      : [];
  const [sittingOnValue, setSittingOnValue] = useState(initSittingOn);

  const [otherSittingOnValue, setOtherSittingOnValue] = useState(
    initialValues.otherSittingOn
  );
  const [showOtherSittingOn, setShowOtherSittingOn] = useState(false);

  /* Init Utensil */
  const utensilSplit =
    initialValues.utensil.length > 0 ? initialValues.utensil.split(",") : [];
  const initUtensil =
    utensilSplit.length > 0
      ? utensilSplit.map((elm) => ({
          label: elm,
        }))
      : [];
  const [utensilValue, setUtensilValue] = useState(initUtensil);

  const [otherUtensilValue, setOtherUtensilValue] = useState(
    initialValues.otherUtensil
  );
  const [showOtherUtensil, setShowOtherUtensil] = useState(false);

  const [startTimeValue, setStartTimeValue] = useState("");
  const [endTimeValue, setEndTimeValue] = useState("");
  const [disableBeforeValue, setDisableBeforeValue] = useState("");
  //const [disableBeforeHour, setDisableBeforeHour] = useState(0);

  useEffect(() => {
    try {
      const dateDisableBefour = new Date(
        calendarDate.getFullYear(),
        calendarDate.getMonth(),
        calendarDate.getDate(),
        0,
        0,
        0
      );
      setDisableBeforeValue(dateDisableBefour);

      const Now = new Date();
      if (initialValues.starttime.length > 0) {
        setStartTimeValue(new Date(initialValues.starttime));
        setFieldValue(starttime.name, initialValues.starttime);
      } else {
        const newDate = new Date(
          calendarDate.getFullYear(),
          calendarDate.getMonth(),
          calendarDate.getDate(),
          Now.getHours(),
          Now.getMinutes(),
          Now.getSeconds()
        );
        setStartTimeValue(newDate);
        setFieldValue(starttime.name, newDate.toISOString());
        //setDisableBeforeHour(newDate.getHours());
      }

      if (initialValues.endtime.length > 0) {
        setEndTimeValue(new Date(initialValues.endtime));
        setFieldValue(endtime.name, initialValues.endtime);
      } else {
        const newDate = new Date(
          calendarDate.getFullYear(),
          calendarDate.getMonth(),
          calendarDate.getDate(),
          Now.getHours(),
          Now.getMinutes(),
          Now.getSeconds()
        );
        newDate.setMinutes(newDate.getMinutes() + 15);
        setEndTimeValue(newDate);
        setFieldValue(endtime.name, newDate.toISOString());
      }

      if (initialValues.drinkType.length > 0) {
        initialValues.drinkType.includes("Others") &&
          setShowOtherDrinkType(true);
      } else {
        setShowOtherDrinkType(false);
      }

      if (initialValues.location.length > 0) {
        initialValues.location.includes("Others") && setShowOtherLocation(true);
      } else {
        setShowOtherLocation(false);
      }

      if (initialValues.sittingOn.length > 0) {
        initialValues.sittingOn.includes("Others") &&
          setShowOtherSittingOn(true);
      } else {
        setShowOtherSittingOn(false);
      }

      if (initialValues.utensil.length > 0) {
        initialValues.utensil.includes("Others") && setShowOtherUtensil(true);
      } else {
        setShowOtherUtensil(false);
      }
    } catch (err) {
      console.log(err);
    }
  }, [initialValues, starttime, endtime, setFieldValue, calendarDate]);

  useEffect(() => {
    values.foodType.length > 0 && values.drinkType.length
      ? setFieldValue(varietyType.name, "Food Type and Drink Type provided")
      : values.foodType.length > 0
      ? setFieldValue(varietyType.name, "Food Type provided")
      : values.drinkType.length > 0
      ? setFieldValue(varietyType.name, "Drink Type provided")
      : setFieldValue(varietyType.name, "");
  }, [values.foodType, values.drinkType, setFieldValue, varietyType]);

  return (
    <MDBox m={1} mt={2}>
      <MDBox
        display="flex"
        borderRadius="lg"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "rgb(201,21, 94)" }}
        p={1}
      >
        <MDBox />
        <MDTypography variant="body2" fontWeight="bold" color="white">
          NON-MILK FEEDS / FOOD
        </MDTypography>
        <MDButton
          variant="text"
          size="medium"
          color="white"
          iconOnly
          onClick={() => closeInputPanel("food")}
        >
          <CloseIcon />
        </MDButton>
      </MDBox>

      <MDBox m={1} mt={3} mb={0}>
        <Grid
          container
          rowSpacing={{ xs: 0 }}
          columnSpacing={{ xs: 1 }}
          alignItems="center"
        >
          {/* Variety */}
          <Grid item xs={5}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                Variety
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={7}></Grid>

          {/* Food */}
          <Grid item xs={5}>
            <MDBox display="flex" alignItems="center" ml={1}>
              <Icon>done</Icon> <MDBox mr={1}></MDBox>
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {foodType.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={7}>
            <>
              <Autocomplete
                multiple
                id="autocomplete-food-type"
                value={foodTypeValue}
                options={foodTypeOptions}
                getOptionLabel={(option) => (option?.label ? option.label : "")} //(option?.label ? option.label : "")}
                size="small"
                onChange={(e, newValue) => {
                  setFoodTypeValue(newValue);
                  if (newValue.length > 0) {
                    const labelArray = newValue.map((elm) => {
                      return elm.label;
                    });
                    setFieldValue(foodType.name, labelArray.toString());
                  } else {
                    setFieldValue(foodType.name, "");
                  }
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 2 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type of Food"
                    inputProps={{ ...params.inputProps, readOnly: true }}
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
              />
            </>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            <FormTextFieldFast
              form={mainForm}
              field={foodType}
              variant="standard"
              disabled={true}
              hideLabel={true}
              hideField={true}
            />
          </Grid>

          {/* Drink */}
          <Grid item xs={5}>
            <MDBox display="flex" alignItems="center" ml={1}>
              <Icon>done</Icon> <MDBox mr={1}></MDBox>
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {drinkType.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={7}>
            <>
              <Autocomplete
                multiple
                id="autocomplete-drink-type"
                value={drinkTypeValue}
                options={drinkTypeOptions}
                getOptionLabel={(option) => (option?.label ? option.label : "")}
                size="small"
                onChange={(e, newValue) => {
                  setDrinkTypeValue(newValue);
                  if (newValue.length > 0) {
                    const labelArray = newValue.map((elm) => {
                      return elm.label;
                    });
                    setFieldValue(drinkType.name, labelArray.toString());

                    const index = labelArray.findIndex(
                      (elm) => elm === "Others"
                    );

                    if (index >= 0) {
                      // "Others" option selected
                      setShowOtherDrinkType(true);
                      // Set otherLocation data field to empty for user input
                      setOtherDrinkTypeValue("");
                      setFieldValue(otherDrinkType.name, "");
                    } else {
                      // "Others" option not selected
                      setShowOtherDrinkType(false);
                      // Set otherLocation data field to "NIL"
                      setOtherDrinkTypeValue("NIL");
                      setFieldValue(otherDrinkType.name, "NIL");
                    }
                  } else {
                    setFieldValue(drinkType.name, "");
                    // "Others" option not selected
                    setShowOtherDrinkType(false);
                    // Set otherLocation data field to "NIL"
                    setOtherDrinkTypeValue("NIL");
                    setFieldValue(otherDrinkType.name, "NIL");
                  }
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 2 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type of Drink"
                    inputProps={{ ...params.inputProps, readOnly: true }}
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
              />
            </>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            <FormTextFieldFast
              form={mainForm}
              field={drinkType}
              variant="standard"
              disabled={true}
              hideLabel={true}
              hideField={true}
            />
          </Grid>
          {/* Other Drink Type */}
          {showOtherDrinkType && (
            <>
              <Grid item xs={5}>
                <MDBox display="flex">
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                  >
                    {otherDrinkType.label}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  id="otherDrinkType"
                  label=""
                  variant="standard"
                  fullWidth
                  value={otherDrinkTypeValue}
                  onChange={(e) => {
                    if (!!e.target.value) {
                      setOtherDrinkTypeValue(e.target.value);
                      setFieldValue(otherDrinkType.name, e.target.value);
                    } else {
                      setOtherDrinkTypeValue("");
                      setFieldValue(otherDrinkType.name, "");
                    }
                  }}
                ></TextField>
              </Grid>
              {/* Error message - Other Drink Type */}
              <Grid item xs={12}>
                <MDBox
                  mt={1}
                  ml={0}
                  mr={0}
                  mb={1}
                  display="flex"
                  justifyContent="right"
                >
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    <ErrorMessage name={otherDrinkType.name}></ErrorMessage>
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} mb={1}></Grid>
            </>
          )}
          {/* Error message - Type of Variety */}
          <Grid item xs={12}>
            <MDBox
              mt={-1}
              ml={3}
              mr={1}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={varietyType.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={2}></Grid>

          {/* Autonomy */}
          <Grid item xs={5}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                Autonomy
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={7}></Grid>

          <Grid item xs={12}>
            <MDBox display="flex" alignItems="center" ml={1}>
              <Icon>done</Icon> <MDBox mr={1}></MDBox>
              <ToggleButtonGroup
                color="info"
                size="small"
                variant="contained"
                value={whoLedValue}
                exclusive
                fullWidth={true}
                onChange={(event, value) => {
                  value !== null && setWhoLedValue(value);
                  value !== null && setFieldValue(whoLed.name, value);
                }}
                aria-label="Platform"
              >
                <ToggleButton value="Caregiver Led">Caregiver Led</ToggleButton>
                <ToggleButton value="Baby Led">Baby Led</ToggleButton>
              </ToggleButtonGroup>
            </MDBox>
          </Grid>
          {/* Error message - Who Led */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={3}
              mr={1}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={whoLed.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={2}></Grid>

          {/* Setting */}
          <Grid item xs={5}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                Setting
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={7}></Grid>

          {/* Location */}
          <Grid item xs={5}>
            <MDBox display="flex" alignItems="center" ml={1}>
              <Icon>done</Icon> <MDBox mr={1}></MDBox>
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {location.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={7}>
            <>
              <Autocomplete
                multiple
                id="autocomplete-location"
                value={locationValue}
                options={locationOptions}
                getOptionLabel={(option) => (option?.label ? option.label : "")}
                size="small"
                onChange={(e, newValue) => {
                  setLocationValue(newValue);
                  if (newValue.length > 0) {
                    const labelArray = newValue.map((elm) => {
                      return elm.label;
                    });
                    setFieldValue(location.name, labelArray.toString());

                    const index = labelArray.findIndex(
                      (elm) => elm === "Others"
                    );
                    if (index >= 0) {
                      // "Others" option selected
                      setShowOtherLocation(true);
                      // Set otherLocation data field to empty for user input
                      setOtherLocationValue("");
                      setFieldValue(otherLocation.name, "");
                    } else {
                      // "Others" option not selected
                      setShowOtherLocation(false);
                      // Set otherLocation data field to "NIL"
                      setOtherLocationValue("NIL");
                      setFieldValue(otherLocation.name, "NIL");
                    }
                  } else {
                    setFieldValue(location.name, "");
                    // "Others" option not selected
                    setShowOtherLocation(false);
                    // Set otherLocation data field to "NIL"
                    setOtherLocationValue("NIL");
                    setFieldValue(otherLocation.name, "NIL");
                  }
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 2 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Location"
                    inputProps={{ ...params.inputProps, readOnly: true }}
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
              />
            </>
          </Grid>
          {/* Error message - Location */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={3}
              mr={1}
              mb={2}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={location.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>

          {/* Other Location */}
          {showOtherLocation && (
            <>
              <Grid item xs={5}>
                <MDBox display="flex">
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                  >
                    {otherLocation.label}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  id="otherLocation"
                  label=""
                  variant="standard"
                  fullWidth
                  value={otherLocationValue}
                  onChange={(e) => {
                    if (!!e.target.value) {
                      setOtherLocationValue(e.target.value);
                      setFieldValue(otherLocation.name, e.target.value);
                    } else {
                      setOtherLocationValue("");
                      setFieldValue(otherLocation.name, "");
                    }
                  }}
                ></TextField>
              </Grid>
              {/* Error message - Other Location */}
              <Grid item xs={12}>
                <MDBox
                  mt={1}
                  ml={0}
                  mr={0}
                  mb={1}
                  display="flex"
                  justifyContent="right"
                >
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    <ErrorMessage name={otherLocation.name}></ErrorMessage>
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} mb={1}></Grid>
            </>
          )}

          {/* Sitting On */}
          <Grid item xs={5}>
            <MDBox display="flex" alignItems="center" ml={1}>
              <Icon>done</Icon> <MDBox mr={1}></MDBox>
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {sittingOn.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={7}>
            <>
              <Autocomplete
                multiple
                id="autocomplete-sitting-on"
                value={sittingOnValue}
                options={sittingOnOptions}
                getOptionLabel={(option) => (option?.label ? option.label : "")}
                size="small"
                onChange={(e, newValue) => {
                  setSittingOnValue(newValue);
                  if (newValue.length > 0) {
                    const labelArray = newValue.map((elm) => {
                      return elm.label;
                    });
                    setFieldValue(sittingOn.name, labelArray.toString());

                    const index = labelArray.findIndex(
                      (elm) => elm === "Others"
                    );
                    if (index >= 0) {
                      // "Others" option selected
                      setShowOtherSittingOn(true);
                      // Set otherLocation data field to empty for user input
                      setOtherSittingOnValue("");
                      setFieldValue(otherSittingOn.name, "");
                    } else {
                      // "Others" option not selected
                      setShowOtherSittingOn(false);
                      // Set otherLocation data field to "NIL"
                      setOtherSittingOnValue("NIL");
                      setFieldValue(otherSittingOn.name, "NIL");
                    }
                  } else {
                    setFieldValue(sittingOn.name, "");
                    // "Others" option not selected
                    setShowOtherSittingOn(false);
                    // Set otherLocation data field to "NIL"
                    setOtherSittingOnValue("NIL");
                    setFieldValue(otherSittingOn.name, "NIL");
                  }
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 2 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sitting On"
                    inputProps={{ ...params.inputProps, readOnly: true }}
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
              />
            </>
          </Grid>
          {/* Error message - Sitting On */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={3}
              mr={1}
              mb={2}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={sittingOn.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>

          {/* Other Sitting On */}
          {showOtherSittingOn && (
            <>
              <Grid item xs={5}>
                <MDBox display="flex">
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                  >
                    {otherSittingOn.label}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  id="otherSittingOn"
                  label=""
                  variant="standard"
                  fullWidth
                  value={otherSittingOnValue}
                  onChange={(e) => {
                    if (!!e.target.value) {
                      setOtherSittingOnValue(e.target.value);
                      setFieldValue(otherSittingOn.name, e.target.value);
                    } else {
                      setOtherSittingOnValue("");
                      setFieldValue(otherSittingOn.name, "");
                    }
                  }}
                ></TextField>
              </Grid>
              {/* Error message - Other Location */}
              <Grid item xs={12}>
                <MDBox
                  mt={1}
                  ml={0}
                  mr={0}
                  mb={1}
                  display="flex"
                  justifyContent="right"
                >
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    <ErrorMessage name={otherSittingOn.name}></ErrorMessage>
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} mb={1}></Grid>
            </>
          )}

          {/* Utensil */}
          <Grid item xs={5}>
            <MDBox display="flex" alignItems="center" ml={1}>
              <Icon>done</Icon> <MDBox mr={1}></MDBox>
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {utensil.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={7}>
            <>
              <Autocomplete
                multiple
                id="autocomplete-utensil"
                value={utensilValue}
                options={utensilOptions}
                getOptionLabel={(option) => (option?.label ? option.label : "")}
                size="small"
                onChange={(e, newValue) => {
                  setUtensilValue(newValue);
                  if (newValue.length > 0) {
                    const labelArray = newValue.map((elm) => {
                      return elm.label;
                    });
                    setFieldValue(utensil.name, labelArray.toString());

                    const index = labelArray.findIndex(
                      (elm) => elm === "Others"
                    );
                    if (index >= 0) {
                      // "Others" option selected
                      setShowOtherUtensil(true);
                      // Set otherUtensil data field to empty for user input
                      setOtherUtensilValue("");
                      setFieldValue(otherUtensil.name, "");
                    } else {
                      // "Others" option not selected
                      setShowOtherUtensil(false);
                      // Set otherUtensil data field to "NIL"
                      setOtherUtensilValue("NIL");
                      setFieldValue(otherUtensil.name, "NIL");
                    }
                  } else {
                    setFieldValue(utensil.name, "");
                    // "Others" option not selected
                    setShowOtherUtensil(false);
                    // Set otherUtensil data field to "NIL"
                    setOtherUtensilValue("NIL");
                    setFieldValue(otherUtensil.name, "NIL");
                  }
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 2 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Utensil"
                    inputProps={{ ...params.inputProps, readOnly: true }}
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
              />
            </>
          </Grid>
          {/* Error message - Utensil */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={3}
              mr={1}
              mb={2}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={utensil.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>

          {/* Other Utensil */}
          {showOtherUtensil && (
            <>
              <Grid item xs={5}>
                <MDBox display="flex">
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                  >
                    {otherUtensil.label}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  id="otherUtensil"
                  label=""
                  variant="standard"
                  fullWidth
                  value={otherUtensilValue}
                  onChange={(e) => {
                    if (!!e.target.value) {
                      setOtherUtensilValue(e.target.value);
                      setFieldValue(otherUtensil.name, e.target.value);
                    } else {
                      setOtherUtensilValue("");
                      setFieldValue(otherUtensil.name, "");
                    }
                  }}
                ></TextField>
              </Grid>
              {/* Error message - Other Location */}
              <Grid item xs={12}>
                <MDBox
                  mt={1}
                  ml={0}
                  mr={0}
                  mb={1}
                  display="flex"
                  justifyContent="right"
                >
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    <ErrorMessage name={otherUtensil.name}></ErrorMessage>
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} mb={1}></Grid>
            </>
          )}

          <Grid item xs={12} mb={2}></Grid>

          {/* Start time */}
          <Grid item xs={5}>
            <MDTypography variant="button" fontWeight="regular" color="dark">
              {starttime.label}
            </MDTypography>
          </Grid>
          <Grid item xs={7}>
            <DatePicker
              format="HH:mm"
              block
              editable={false}
              appearance="default"
              placement="topEnd"
              style={{ width: 300 }}
              size="sm"
              value={startTimeValue instanceof Date ? startTimeValue : null}
              onChange={(date) => {
                if (date !== null) {
                  const newDate = new Date(
                    calendarDate.getFullYear(),
                    calendarDate.getMonth(),
                    calendarDate.getDate(),
                    date.getHours(),
                    date.getMinutes(),
                    0
                  );
                  setFieldValue(starttime.name, newDate.toISOString());
                  setStartTimeValue(newDate);
                  //setDisableBeforeHour(newDate.getHours());
                }
              }}
            />
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            <FormTextFieldFast
              form={mainForm}
              field={starttime}
              variant="standard"
              disabled={true}
              hideLabel={true}
              hideField={true}
            />
          </Grid>

          {/* End time */}
          <Grid item xs={5}>
            <MDTypography variant="button" fontWeight="regular" color="dark">
              {endtime.label}
            </MDTypography>
          </Grid>
          <Grid item xs={7}>
            <DatePicker
              format="dd/MM/yyyy HH:mm"
              block
              editable={false}
              appearance="default"
              placement="topEnd"
              style={{ width: 300 }}
              size="sm"
              value={endTimeValue instanceof Date ? endTimeValue : null}
              onChange={(date) => {
                if (date !== null) {
                  setFieldValue(endtime.name, date.toISOString());
                  setEndTimeValue(date);
                }
              }}
              shouldDisableDate={(date) =>
                isBefore(date, new Date(disableBeforeValue))
              }
              //hideHours={(hour) => hour < disableBeforeHour}
            />
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            <FormTextFieldFast
              form={mainForm}
              field={endtime}
              variant="standard"
              disabled={true}
              hideLabel={true}
              hideField={true}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default FoodDetails;
