const formSedentary = {
  formId: "sedentary-form",
  formField: {
    sedentaryActivity: {
      name: "sedentaryActivity",
      type: "text",
      label: "Sedentary Activity",
      errorMsg: "Sedentary Activity is required.",
      invalidMsg: "Sedentary Activity is invalid.",
    },
    distractionUsed: {
      name: "distractionUsed",
      type: "text",
      label: "Distraction used",
      errorMsg: "Distraction used is required.",
      invalidMsg: "Distraction used is invalid.",
    },
    screenBased: {
      name: "screenBased",
      type: "text",
      label: "Screen-based",
      errorMsg: "Screen-based is required.",
      invalidMsg: "Screen-based is invalid.",
    },
    interactive: {
      name: "interactive",
      type: "text",
      label: "Interactive",
      errorMsg: "Interactive is required.",
      invalidMsg: "Interactive is invalid.",
    },
    duration: {
      name: "duration",
      type: "text",
      label: "Duration",
      errorMsg: "Duration is required.",
      invalidMsg: "Duration is invalid.",
    },
    starttime: {
      name: "starttime",
      type: "text",
      label: "Start Time",
      errorMsg: "Start Time is required.",
      invalidMsg: "Start Time is invalid.",
    },
    endtime: {
      name: "endtime",
      type: "text",
      label: "End Time",
      errorMsg: "End Time is required.",
      invalidMsg: "End Time is invalid.",
    },
  },
};

export { formSedentary as default };
