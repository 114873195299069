//import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import { Doughnut } from "react-chartjs-2";

function ScoreLatestSubmission({ record }) {
  //const [scoreData, setScoreData] = useState(null);
  const [scoreData, setScoreData] = useState(null);

  useEffect(() => {
    try {
      const scoreRounded = Math.round(record.normalizedScore * 100);
      const scoreData = {
        data: {
          labels: ["Score", "Balance"],
          datasets: [
            {
              label: "Score",
              data: [scoreRounded, 100 - scoreRounded],
              backgroundColor: [
                "rgba(79,145,205, 1)",
                "rgba(222, 226, 230, 1)",
              ],
              borderColor: ["rgba(222, 226, 230, 1)", "rgba(222, 226, 230, 1)"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
        plugins: [
          {
            beforeDraw: function (chart) {
              var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
              ctx.restore();
              var fontSize = (height / 200).toFixed(1);
              ctx.font = fontSize + "em sans-serif";
              // ctx.textBaseline = "top";

              var text = scoreRounded.toString() + "%";
              var textX =
                Math.round((width - ctx.measureText(text).width) / 2) + 5;
              var textY = height / 2 + 5;
              ctx.fillText(text, textX, textY);
              ctx.save();
            },
          },
        ],
      };
      setScoreData(scoreData);
    } catch (err) {
      console.log(err);
    }
  }, [record.normalizedScore]);

  return (
    <>
      <Card id="qa-latest-score" sx={{ overflow: "visible" }}>
        {record !== undefined && (
          <MDBox ml={2.5} mt={-1.5}>
            <MDButton
              size="small"
              variant="contained"
              color="success"
              disabled={true}
            >
              {record.localeDay}, {record.localeDate} {record.localeTime}
            </MDButton>
          </MDBox>
        )}

        {record !== undefined && (
          <MDBox m={2.0} mt={3.0} mb={3.0}>
            <Grid
              container
              rowSpacing={{ xs: 1 }}
              columnSpacing={{ xs: 1 }}
              alignItems="center"
            >
              <Grid item xs={6}>
                <MDBox display="flex" justifyContent="center" p={2}>
                  {scoreData && (
                    <Doughnut
                      width={200}
                      height={200}
                      data={scoreData?.data}
                      plugins={scoreData?.plugins}
                      options={scoreData?.options}
                    />
                  )}
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox display="flex">
                  <MDTypography
                    variant="h3"
                    fontWeight="bold"
                    color="success"
                    textAlign="left"
                  >
                    Keep up the good habits for your baby!
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )}
      </Card>
      <MDBox mb={3}></MDBox>
    </>
  );
}

export default ScoreLatestSubmission;
