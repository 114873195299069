const answerOptions = ["", "YES", "NO"];
const defaultAnswerQ_RR1 = answerOptions[0];
const defaultAnswerQ_RR2 = answerOptions[0];
const defaultAnswerQ_RR3 = answerOptions[0];
const defaultAnswerQ_RR4 = answerOptions[0];
const defaultAnswerQ_RF1a = answerOptions[0];
const defaultAnswerQ_RF1b = answerOptions[0];
const defaultAnswerQ_RF2 = answerOptions[0];
const defaultAnswerQ_WW1 = answerOptions[0];
const defaultAnswerQ_WBe1 = answerOptions[0];
const defaultAnswerQ_WBa1 = answerOptions[0];
const defaultAnswerQ_WBa2 = answerOptions[0];
const defaultAnswerQ_WBa3 = answerOptions[0];
const defaultAnswerQ_WBa4 = answerOptions[0];
const defaultAnswerQ_HF1 = answerOptions[0];
const defaultAnswerQ_HT1 = answerOptions[0];
const defaultAnswerQ_HT2 = answerOptions[0];
const defaultAnswerQ_HT3 = answerOptions[0];
const defaultAnswerQ_HT4 = answerOptions[0];
const defaultAnswerQ_HSl1 = answerOptions[0];
const defaultAnswerQ_HA1 = answerOptions[0];
const defaultAnswerQ_HA1_unit = answerOptions[0];
const defaultAnswerQ_HSc1 = answerOptions[0];

export {
  defaultAnswerQ_RR1,
  defaultAnswerQ_RR2,
  defaultAnswerQ_RR3,
  defaultAnswerQ_RR4,
  defaultAnswerQ_RF1a,
  defaultAnswerQ_RF1b,
  defaultAnswerQ_RF2,
  defaultAnswerQ_WW1,
  defaultAnswerQ_WBe1,
  defaultAnswerQ_WBa1,
  defaultAnswerQ_WBa2,
  defaultAnswerQ_WBa3,
  defaultAnswerQ_WBa4,
  defaultAnswerQ_HF1,
  defaultAnswerQ_HT1,
  defaultAnswerQ_HT2,
  defaultAnswerQ_HT3,
  defaultAnswerQ_HT4,
  defaultAnswerQ_HSl1,
  defaultAnswerQ_HA1,
  defaultAnswerQ_HA1_unit,
  defaultAnswerQ_HSc1,
};
