const formMilk = {
  formId: "food-form",
  formField: {
    varietyType: {
      name: "varietyType",
      type: "text",
      label: "Type of Variety",
      errorMsg: "Type of Variety is required.",
      invalidMsg: "Type of Variety is invalid.",
    },
    foodType: {
      name: "foodType",
      type: "text",
      label: "Type of Food",
      errorMsg: "Type of Food is required.",
      invalidMsg: "Type of Food is invalid.",
    },
    drinkType: {
      name: "drinkType",
      type: "text",
      label: "Type of Drink",
      errorMsg: "Type of Drink is required.",
      invalidMsg: "Type of Drink is invalid.",
    },
    otherDrinkType: {
      name: "otherDrinkType",
      type: "text",
      label: "Others (Type of Drink)",
      errorMsg: "Other Type of Drink is required.",
      invalidMsg: "Other Type of Drink is invalid.",
    },
    whoLed: {
      name: "whoLed",
      type: "text",
      label: "Who led",
      errorMsg: "Who led is required.",
      invalidMsg: "Who led is invalid.",
    },
    location: {
      name: "location",
      type: "text",
      label: "Location",
      errorMsg: "Location is required.",
      invalidMsg: "Location is invalid.",
    },
    otherLocation: {
      name: "otherLocation",
      type: "text",
      label: "Others (Location)",
      errorMsg: "Other Location is required.",
      invalidMsg: "Other Location is invalid.",
    },
    sittingOn: {
      name: "sittingOn",
      type: "text",
      label: "Sitting On",
      errorMsg: "Sitting On is required.",
      invalidMsg: "Sitting On is invalid.",
    },
    otherSittingOn: {
      name: "otherSittingOn",
      type: "text",
      label: "Others (Sitting On)",
      errorMsg: "Other Sitting On is required.",
      invalidMsg: "Other Sitting On is invalid.",
    },
    utensil: {
      name: "utensil",
      type: "text",
      label: "Utensil",
      errorMsg: "Utensil is required.",
      invalidMsg: "Utensil is invalid.",
    },
    otherUtensil: {
      name: "otherUtensil",
      type: "text",
      label: "Others (Utensil)",
      errorMsg: "Other Utensil is required.",
      invalidMsg: "Other Utensil is invalid.",
    },
    rightBreast: {
      name: "rightBreast",
      type: "text",
      label: "Right",
      errorMsg: "Right Breast is required.",
      invalidMsg: "Right Breast is invalid.",
    },
    timePerFeedRight: {
      name: "timePerFeedRight",
      type: "text",
      label: "Time per feed (Right)",
      errorMsg: "Time per feed (Right) is required.",
      invalidMsg: "Time per feed (Right) is invalid.",
    },
    typeofMilk: {
      name: "typeofMilk",
      type: "text",
      label: "Type of Milk",
      errorMsg: "Type of Milk is required.",
      invalidMsg: "Type of Milk is invalid.",
    },
    milkVolume: {
      name: "milkVolume",
      type: "text",
      label: "Volume",
      errorMsg: "Volume of Milk is required.",
      invalidMsg: "Volume of Milk is invalid.",
    },
    starttime: {
      name: "starttime",
      type: "text",
      label: "Start Time",
      errorMsg: "Start Time is required.",
      invalidMsg: "Start Time is invalid.",
    },
    endtime: {
      name: "endtime",
      type: "text",
      label: "End Time",
      errorMsg: "End Time is required.",
      invalidMsg: "End Time is invalid.",
    },
  },
};

export { formMilk as default };
