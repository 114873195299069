import { useParams } from "react-router-dom";
import QASurvey from "pages/assessments/manage/QASurvey";

// using Proxy Page to remount when route to same route location but different param
function QASurveyProxy() {
  const { mode, qasurveyId } = useParams();
  return <QASurvey key={`${mode}/${qasurveyId}`} />;
}

export default QASurveyProxy;
