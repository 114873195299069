import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
//import { TroubleshootOutlined } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MDButton from "components/atoms/MDButton";
//import { tr } from "date-fns/locale";

function Questionnaire({ mainForm, mainFormField, modeDisabled }) {
  const {
    Q_RR1,
    Q_RR2,
    Q_RR3,
    Q_RR4,
    Q_RF1a,
    Q_RF1b,
    Q_RF2,
    Q_WW1,
    Q_WBe1,
    Q_WBa1,
    Q_WBa2,
    Q_WBa3,
    Q_WBa4,
    Q_HF1,
    Q_HT1,
    Q_HT2,
    Q_HT3,
    Q_HT4,
    Q_HSl1,
    Q_HA1,
    Q_HSc1,
  } = mainFormField;

  const { values } = mainForm;

  const showNutritionalAdvisories = (values, Q, border) => {
    return (
      <MDBox mb={1.5}>
        <Grid item xs={12}>
          <MDBox border={border} borderRadius={"xl"} p={0.5} pl={1.5}>
            {values.age.months <= 2 && (
              <MDTypography
                variant="body3"
                fontWeight="regular"
                opacity={0.8}
                textAlign="justify"
                verticalAlign="top"
                lineHeight={1}
              >
                {Q.advisory_0m_2m}
              </MDTypography>
            )}
            {values.age.months > 2 && values.age.months <= 4 && (
              <MDTypography
                variant="body3"
                fontWeight="regular"
                opacity={0.8}
                textAlign="justify"
                verticalAlign="top"
                lineHeight={1}
              >
                {Q.advisory_2m_4m}
              </MDTypography>
            )}
            {values.age.months > 4 && values.age.months <= 6 && (
              <MDTypography
                variant="body3"
                fontWeight="regular"
                opacity={0.8}
                textAlign="justify"
                verticalAlign="top"
                lineHeight={1}
              >
                {Q.advisory_4m_6m}
              </MDTypography>
            )}
            {values.age.months > 6 && values.age.months <= 9 && (
              <MDTypography
                variant="body3"
                fontWeight="regular"
                opacity={0.8}
                textAlign="justify"
                verticalAlign="top"
              >
                {Q.advisory_6m_9m}
              </MDTypography>
            )}
            {values.age.months > 9 && values.age.months <= 12 && (
              <MDTypography
                variant="body3"
                fontWeight="regular"
                opacity={0.8}
                textAlign="justify"
                verticalAlign="top"
              >
                {Q.advisory_9m_12m}
              </MDTypography>
            )}
            {values.age.months > 12 && values.age.months <= 15 && (
              <MDTypography
                variant="body3"
                fontWeight="regular"
                opacity={0.8}
                textAlign="justify"
                verticalAlign="top"
              >
                {Q.advisory_12m_15m}
              </MDTypography>
            )}
            {values.age.months > 15 && (
              <MDTypography
                variant="body3"
                fontWeight="regular"
                opacity={0.8}
                textAlign="justify"
                verticalAlign="top"
              >
                {Q.advisory_15m_23m}
              </MDTypography>
            )}
          </MDBox>
        </Grid>
      </MDBox>
    );
  };

  const showSleepBehaviouralAdvisories = (values, Q, border) => {
    return (
      <MDBox mb={1.5}>
        <Grid item xs={12}>
          <MDBox border={border} borderRadius={"xl"} p={0.5} pl={1.5}>
            <MDTypography
              variant="body3"
              fontWeight="regular"
              opacity={0.8}
              textAlign="left"
              verticalAlign="top"
            >
              {values.age.months <= 3 && (
                <MDTypography
                  variant="body3"
                  fontWeight="regular"
                  opacity={0.8}
                  textAlign="left"
                  verticalAlign="top"
                >
                  {Q.advisory_0m_3m}
                </MDTypography>
              )}

              {values.age.months > 3 && values.age.months <= 11 && (
                <MDTypography
                  variant="body3"
                  fontWeight="regular"
                  opacity={0.8}
                  textAlign="left"
                  verticalAlign="top"
                >
                  {Q.advisory_3m_11m}
                </MDTypography>
              )}

              {values.age.months > 11 && (
                <MDTypography
                  variant="body3"
                  fontWeight="regular"
                  opacity={0.8}
                  textAlign="left"
                  verticalAlign="top"
                >
                  {Q.advisory_11m_23m}
                </MDTypography>
              )}
            </MDTypography>
          </MDBox>
        </Grid>
      </MDBox>
    );
  };

  const applyCondRulesHT = (valueQ_HT, Q_HT) => {
    return parseInt(valueQ_HT) >= parseInt(Q_HT.responseLowerBand) &&
      parseInt(valueQ_HT) <= parseInt(Q_HT.responseUpperBand)
      ? true
      : false;
  };

  const applyCondRulesHSl1 = (values, Q_HSl1) => {
    if (values.age.months <= 3) {
      return parseInt(values.Q_HSl1) >=
        parseInt(Q_HSl1.responseLowerBand_0m_3m) &&
        parseInt(values.Q_HSl1) <= parseInt(Q_HSl1.responseUpperBand_0m_3m)
        ? true
        : false;
    } else if (values.age.months > 3 && values.age.months <= 11) {
      return parseInt(values.Q_HSl1) >=
        parseInt(Q_HSl1.responseLowerBand_3m_11m) &&
        parseInt(values.Q_HSl1) <= parseInt(Q_HSl1.responseUpperBand_3m_11m)
        ? true
        : false;
    } else {
      return parseInt(values.Q_HSl1) >=
        parseInt(Q_HSl1.responseLowerBand_11m_23m) &&
        parseInt(values.Q_HSl1) <= parseInt(Q_HSl1.responseUpperBand_11m_23m)
        ? true
        : false;
    }
  };

  const applyCondRulesHA1 = (values, Q_HA1) => {
    const resMinutes =
      values.Q_HA1_unit === "minute(s)"
        ? parseFloat(values.Q_HA1)
        : parseFloat(values.Q_HA1) * 60;
    if (values.age.months <= 3) {
      return resMinutes >= parseFloat(Q_HA1.response_0m_3m) ? true : false;
    } else if (values.age.months > 3 && values.age.months <= 11) {
      return resMinutes >= parseFloat(Q_HA1.response_3m_11m) ? true : false;
    } else {
      return resMinutes >= parseFloat(Q_HA1.response_11m_23m) ? true : false;
    }
  };

  const applyCondRulesHSc1 = (values, Q_HSc1) => {
    if (values.age.months <= 3) {
      return values.Q_HSc1 === Q_HSc1.response_0m_3m ? true : false;
    } else if (values.age.months > 3 && values.age.months <= 11) {
      return values.Q_HSc1 === Q_HSc1.response_3m_11m ? true : false;
    } else {
      return parseFloat(values.Q_HSc1) < parseFloat(Q_HSc1.response_11m_23m)
        ? true
        : false;
    }
  };

  return (
    <>
      <Card id="qa-attention" sx={{ overflow: "visible" }}>
        <MDBox m={2}>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="lg"
            sx={{ backgroundColor: "#EF5350" }}
            p={1}
          >
            <MDTypography variant="body1" fontWeight="bold" color="white">
              AREAS TO IMPROVE
            </MDTypography>
            <MDButton variant="text" color="white" size="large" iconOnly>
              <ErrorOutlineIcon />
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox m={3} mt={0}>
          <MDBox mb={3}>
            <MDTypography variant="body2" fontWeight="bold" color="success">
              Regulation
            </MDTypography>
            {/* Question Q_RR1*/}
            {"age" in values === false ? null : values.age.months >=
                Q_RR1.minAge &&
              values.age.months <= Q_RR1.maxAge &&
              values.Q_RR1 !== Q_RR1.response ? (
              <>
                <MDBox mb={1.0}>
                  <Grid
                    container
                    rowSpacing={{ xs: 1 }}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={12}>
                      <MDTypography variant="body2" fontWeight="regular">
                        {values.age.months >= 0 &&
                          values.age.months <= 2 &&
                          Q_RR1.label_0m_2m}
                        {values.age.months > 2 &&
                          values.age.months <= 4 &&
                          Q_RR1.label_2m_4m}
                        {values.age.months > 4 &&
                          values.age.months <= 6 &&
                          Q_RR1.label_4m_6m}
                        {values.age.months > 6 &&
                          values.age.months <= 9 &&
                          Q_RR1.label_6m_9m}
                        {values.age.months > 9 &&
                          values.age.months <= 12 &&
                          Q_RR1.label_9m_12m}
                        {values.age.months > 12 &&
                          values.age.months <= 15 &&
                          Q_RR1.label_12m_15m}
                        {values.age.months > 15 && Q_RR1.label_15m_23m}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="left">
                        <ToggleButtonGroup
                          color="success"
                          size="small"
                          variant="contained"
                          value={values.Q_RR1}
                          fullWidth={true}
                          exclusive
                          aria-label="Platform"
                          disabled
                        >
                          <ToggleButton value="YES">YES</ToggleButton>
                          <ToggleButton value="NO">NO</ToggleButton>
                        </ToggleButtonGroup>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                {showNutritionalAdvisories(values, Q_RR1, "2px solid #94FA92")}
              </>
            ) : null}

            <MDBox mt={4} />
            {/* Question Q_RR2*/}
            {"age" in values === false ? null : values.age.months >=
                Q_RR2.minAge &&
              values.age.months <= Q_RR2.maxAge &&
              values.Q_RR2 !== Q_RR2.response ? (
              <>
                <MDBox mb={1.0}>
                  <Grid
                    container
                    rowSpacing={{ xs: 1 }}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={12}>
                      <MDTypography variant="body2" fontWeight="regular">
                        {values.age.months >= 0 &&
                          values.age.months <= 2 &&
                          Q_RR2.label_0m_2m}
                        {values.age.months > 2 &&
                          values.age.months <= 4 &&
                          Q_RR2.label_2m_4m}
                        {values.age.months > 4 &&
                          values.age.months <= 6 &&
                          Q_RR2.label_4m_6m}
                        {values.age.months > 6 &&
                          values.age.months <= 9 &&
                          Q_RR2.label_6m_9m}
                        {values.age.months > 9 &&
                          values.age.months <= 12 &&
                          Q_RR2.label_9m_12m}
                        {values.age.months > 12 &&
                          values.age.months <= 15 &&
                          Q_RR2.label_12m_15m}
                        {values.age.months > 15 && Q_RR2.label_15m_23m}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="left">
                        <ToggleButtonGroup
                          color="success"
                          size="small"
                          variant="contained"
                          value={values.Q_RR2}
                          fullWidth={true}
                          exclusive
                          aria-label="Platform"
                          disabled
                        >
                          <ToggleButton value="YES">YES</ToggleButton>
                          <ToggleButton value="NO">NO</ToggleButton>
                        </ToggleButtonGroup>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                {showNutritionalAdvisories(values, Q_RR2, "2px solid #94FA92")}
              </>
            ) : null}

            <MDBox mt={4} />
            {/* Question Q_RR3*/}
            {"age" in values === false ? null : values.age.months >=
                Q_RR3.minAge &&
              values.age.months <= Q_RR3.maxAge &&
              values.Q_RR3 !== Q_RR3.response ? (
              <>
                <MDBox mb={1.0}>
                  <Grid
                    container
                    rowSpacing={{ xs: 1 }}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={12}>
                      <MDTypography variant="body2" fontWeight="regular">
                        {values.age.months >= 0 &&
                          values.age.months <= 2 &&
                          Q_RR3.label_0m_2m}
                        {values.age.months > 2 &&
                          values.age.months <= 4 &&
                          Q_RR3.label_2m_4m}
                        {values.age.months > 4 &&
                          values.age.months <= 6 &&
                          Q_RR3.label_4m_6m}
                        {values.age.months > 6 &&
                          values.age.months <= 9 &&
                          Q_RR3.label_6m_9m}
                        {values.age.months > 9 &&
                          values.age.months <= 12 &&
                          Q_RR3.label_9m_12m}
                        {values.age.months > 12 &&
                          values.age.months <= 15 &&
                          Q_RR3.label_12m_15m}
                        {values.age.months > 15 && Q_RR3.label_15m_23m}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="left">
                        <ToggleButtonGroup
                          color="success"
                          size="small"
                          variant="contained"
                          value={values.Q_RR3}
                          fullWidth={true}
                          exclusive
                          aria-label="Platform"
                          disabled
                        >
                          <ToggleButton value="YES">YES</ToggleButton>
                          <ToggleButton value="NO">NO</ToggleButton>
                        </ToggleButtonGroup>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                {showNutritionalAdvisories(values, Q_RR3)}
              </>
            ) : null}

            <MDBox mt={4} />
            {/* Question Q_RR4*/}
            {"age" in values === false ? null : values.age.months >=
                Q_RR4.minAge &&
              values.age.months <= Q_RR4.maxAge &&
              values.Q_RR4 !== Q_RR4.response ? (
              <>
                <MDBox mb={1.0}>
                  <Grid
                    container
                    rowSpacing={{ xs: 1 }}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={12}>
                      <MDTypography variant="body2" fontWeight="regular">
                        {values.age.months >= 0 &&
                          values.age.months <= 2 &&
                          Q_RR4.label_0m_2m}
                        {values.age.months > 2 &&
                          values.age.months <= 4 &&
                          Q_RR4.label_2m_4m}
                        {values.age.months > 4 &&
                          values.age.months <= 6 &&
                          Q_RR4.label_4m_6m}
                        {values.age.months > 6 &&
                          values.age.months <= 9 &&
                          Q_RR4.label_6m_9m}
                        {values.age.months > 9 &&
                          values.age.months <= 12 &&
                          Q_RR4.label_9m_12m}
                        {values.age.months > 12 &&
                          values.age.months <= 15 &&
                          Q_RR4.label_12m_15m}
                        {values.age.months > 15 && Q_RR4.label_15m_23m}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="left">
                        <ToggleButtonGroup
                          color="success"
                          size="small"
                          variant="contained"
                          value={values.Q_RR4}
                          fullWidth={true}
                          exclusive
                          aria-label="Platform"
                          disabled
                        >
                          <ToggleButton value="YES">YES</ToggleButton>
                          <ToggleButton value="NO">NO</ToggleButton>
                        </ToggleButtonGroup>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                {showNutritionalAdvisories(values, Q_RR4, "2px solid #94FA92")}
              </>
            ) : null}

            <MDBox mt={4} />
            {/* Question Q_RF1a*/}
            {"age" in values === false ? null : values.age.months >=
                Q_RF1a.minAge &&
              values.age.months <= Q_RF1a.maxAge &&
              values.Q_RF1a !== Q_RF1a.response ? (
              <>
                <MDBox mb={0}>
                  <Grid
                    container
                    rowSpacing={{ xs: 1 }}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={12}>
                      <MDTypography variant="body2" fontWeight="regular">
                        {values.age.months >= 0 &&
                          values.age.months <= 2 &&
                          Q_RF1a.label_0m_2m}
                        {values.age.months > 2 &&
                          values.age.months <= 4 &&
                          Q_RF1a.label_2m_4m}
                        {values.age.months > 4 &&
                          values.age.months <= 6 &&
                          Q_RF1a.label_4m_6m}
                        {values.age.months > 6 &&
                          values.age.months <= 9 &&
                          Q_RF1a.label_6m_9m}
                        {values.age.months > 9 &&
                          values.age.months <= 12 &&
                          Q_RF1a.label_9m_12m}
                        {values.age.months > 12 &&
                          values.age.months <= 15 &&
                          Q_RF1a.label_12m_15m}
                        {values.age.months > 15 && Q_RF1a.label_15m_23m}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="left">
                        <ToggleButtonGroup
                          color="success"
                          size="small"
                          variant="contained"
                          value={values.Q_RF1a}
                          fullWidth={true}
                          exclusive
                          aria-label="Platform"
                          disabled
                        >
                          <ToggleButton value="YES">YES</ToggleButton>
                          <ToggleButton value="NO">NO</ToggleButton>
                        </ToggleButtonGroup>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </>
            ) : null}
            <MDBox mt={2} />
            {/* Question Q_RF1b*/}
            {"age" in values === false ? null : values.age.months >=
                Q_RF1b.minAge &&
              values.age.months <= Q_RF1b.maxAge &&
              values.Q_RF1a !== Q_RF1a.response ? (
              <>
                <MDBox mt={0}>
                  <Grid
                    container
                    rowSpacing={{ xs: 1 }}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={12}>
                      <MDTypography variant="body2" fontWeight="regular">
                        {values.age.months >= 0 &&
                          values.age.months <= 2 &&
                          Q_RF1b.label_0m_2m}
                        {values.age.months > 2 &&
                          values.age.months <= 4 &&
                          Q_RF1b.label_2m_4m}
                        {values.age.months > 4 &&
                          values.age.months <= 6 &&
                          Q_RF1b.label_4m_6m}
                        {values.age.months > 6 &&
                          values.age.months <= 9 &&
                          Q_RF1b.label_6m_9m}
                        {values.age.months > 9 &&
                          values.age.months <= 12 &&
                          Q_RF1b.label_9m_12m}
                        {values.age.months > 12 &&
                          values.age.months <= 15 &&
                          Q_RF1b.label_12m_15m}
                        {values.age.months > 15 && Q_RF1b.label_15m_23m}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDBox display="flex" justifyContent="left">
                        <FormTextFieldFast
                          form={mainForm}
                          field={Q_RF1b}
                          variant="standard"
                          disabled={true}
                          hideLabel={true}
                          //hideField={true}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6}></Grid>
                  </Grid>
                </MDBox>
                {showNutritionalAdvisories(values, Q_RF1a, "2px solid #94FA92")}
              </>
            ) : null}

            <MDBox mt={4} />
            {/* Question Q_RF2*/}
            {"age" in values === false ? null : values.age.months >=
                Q_RF2.minAge &&
              values.age.months <= Q_RF2.maxAge &&
              values.Q_RF2 !== Q_RF2.response ? (
              <>
                <MDBox mb={1.0}>
                  <Grid
                    container
                    rowSpacing={{ xs: 1 }}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={12}>
                      <MDTypography variant="body2" fontWeight="regular">
                        {values.age.months >= 0 &&
                          values.age.months <= 2 &&
                          Q_RF2.label_0m_2m}
                        {values.age.months > 2 &&
                          values.age.months <= 4 &&
                          Q_RF2.label_2m_4m}
                        {values.age.months > 4 &&
                          values.age.months <= 6 &&
                          Q_RF2.label_4m_6m}
                        {values.age.months > 6 &&
                          values.age.months <= 9 &&
                          Q_RF2.label_6m_9m}
                        {values.age.months > 9 &&
                          values.age.months <= 12 &&
                          Q_RF2.label_9m_12m}
                        {values.age.months > 12 &&
                          values.age.months <= 15 &&
                          Q_RF2.label_12m_15m}
                        {values.age.months > 15 && Q_RF2.label_15m_23m}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="left">
                        <ToggleButtonGroup
                          color="success"
                          size="small"
                          variant="contained"
                          value={values.Q_RF2}
                          fullWidth={true}
                          exclusive
                          aria-label="Platform"
                          disabled
                        >
                          <ToggleButton value="YES">YES</ToggleButton>
                          <ToggleButton value="NO">NO</ToggleButton>
                        </ToggleButtonGroup>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                {showNutritionalAdvisories(values, Q_RF2, "2px solid #94FA92")}
              </>
            ) : null}
          </MDBox>

          {/* Weaning */}
          <MDBox mb={3}>
            <MDTypography variant="body2" fontWeight="bold" color="warning">
              Weaning
            </MDTypography>
          </MDBox>
          {/* Question Q_WW1 - 0m_4m*/}
          {"age" in values === false ? null : values.age.months <= 4 &&
            values.Q_WW1 !== Q_WW1.response_0m_4m ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WW1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WW1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WW1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WW1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WW1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WW1.label_12m_15m}
                      {values.age.months > 15 && Q_WW1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={values.Q_WW1}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        {values.age.months <= 9 && (
                          <ToggleButton value="HAVEN'T STARTED">
                            Haven't started
                          </ToggleButton>
                        )}
                        {values.age.months <= 4 && (
                          <ToggleButton value="0-4 MONTHS">
                            0-4 MONTHS
                          </ToggleButton>
                        )}
                        {values.age.months > 4 && values.age.months <= 9 && (
                          <ToggleButton value="4-9 MONTHS">
                            4-9 MONTHS
                          </ToggleButton>
                        )}
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_WW1, "2px solid #FCAC48")}
            </>
          ) : null}

          {/* Question Q_WW1 - 4m_9m*/}
          {"age" in values === false ? null : values.age.months > 4 &&
            values.age.months <= 9 &&
            values.Q_WW1 !== Q_WW1.response_4m_9m ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WW1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WW1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WW1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WW1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WW1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WW1.label_12m_15m}
                      {values.age.months > 15 && Q_WW1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={values.Q_WW1}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        {values.age.months <= 9 && (
                          <ToggleButton value="HAVEN'T STARTED">
                            Haven't started
                          </ToggleButton>
                        )}
                        {values.age.months <= 4 && (
                          <ToggleButton value="0-4 MONTHS">
                            0-4 MONTHS
                          </ToggleButton>
                        )}
                        {values.age.months > 4 && values.age.months <= 9 && (
                          <ToggleButton value="4-9 MONTHS">
                            4-9 MONTHS
                          </ToggleButton>
                        )}
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_WW1, "2px solid #FCAC48")}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_WBe1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WBe1.minAge &&
            values.age.months <= Q_WBe1.maxAge &&
            values.Q_WBe1 !== Q_WBe1.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WBe1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WBe1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WBe1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WBe1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WBe1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WBe1.label_12m_15m}
                      {values.age.months > 15 && Q_WBe1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={values.Q_WBe1}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_WBe1, "2px solid #FCAC48")}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_WBa1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WBa1.minAge &&
            values.age.months <= Q_WBa1.maxAge &&
            values.Q_WBa1 !== Q_WBa1.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WBa1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WBa1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WBa1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WBa1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WBa1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WBa1.label_12m_15m}
                      {values.age.months > 15 && Q_WBa1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={values.Q_WBa1}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_WBa1, "2px solid #FCAC48")}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_WBa2*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WBa2.minAge &&
            values.age.months <= Q_WBa2.maxAge &&
            values.Q_WBa2 !== Q_WBa2.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WBa2.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WBa2.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WBa2.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WBa2.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WBa2.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WBa2.label_12m_15m}
                      {values.age.months > 15 && Q_WBa2.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={values.Q_WBa2}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_WBa2, "2px solid #FCAC48")}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_WBa3*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WBa3.minAge &&
            values.age.months <= Q_WBa3.maxAge &&
            values.Q_WBa3 !== Q_WBa3.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WBa3.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WBa3.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WBa3.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WBa3.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WBa3.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WBa3.label_12m_15m}
                      {values.age.months > 15 && Q_WBa3.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={values.Q_WBa3}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_WBa3, "2px solid #FCAC48")}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_WBa4*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WBa4.minAge &&
            values.age.months <= Q_WBa4.maxAge &&
            values.Q_WBa4 !== Q_WBa4.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WBa4.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WBa4.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WBa4.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WBa4.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WBa4.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WBa4.label_12m_15m}
                      {values.age.months > 15 && Q_WBa4.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={values.Q_WBa4}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_WBa4, "2px solid #FCAC48")}
            </>
          ) : null}

          {/* Habituation */}
          <MDBox mb={3}>
            <MDTypography variant="body2" fontWeight="bold" color="info">
              Habituation
            </MDTypography>
          </MDBox>

          <MDBox mt={4} />
          {/* Question Q_HF1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HF1.minAge &&
            values.age.months <= Q_HF1.maxAge &&
            values.Q_HF1 !== Q_HF1.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_HF1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_HF1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_HF1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_HF1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_HF1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_HF1.label_12m_15m}
                      {values.age.months > 15 && Q_HF1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="info"
                        size="small"
                        variant="contained"
                        value={values.Q_HF1}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_HF1, "2px solid #80B2F2")}
            </>
          ) : null}

          {/* Question Q_HT1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HT1.minAge &&
            values.age.months <= Q_HT1.maxAge &&
            !applyCondRulesHT(values.Q_HT1, Q_HT1) ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_HT1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_HT1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_HT1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_HT1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_HT1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_HT1.label_12m_15m}
                      {values.age.months > 15 && Q_HT1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <MDTypography
                        variant="body2"
                        fontWeight="light"
                        textAlign="justify"
                        sx={{
                          backgroundColor: "#80B2F2",
                          color: "#FFFFFF",
                          pr: 0.5,
                          pl: 0.5,
                        }}
                      >
                        {values.Q_HT1} minute(s)
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_HT1, "2px solid #80B2F2")}
            </>
          ) : null}

          {/* Question Q_HT2*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HT2.minAge &&
            values.age.months <= Q_HT2.maxAge &&
            !applyCondRulesHT(values.Q_HT2, Q_HT2) ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_HT2.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_HT2.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_HT2.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_HT2.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_HT2.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_HT2.label_12m_15m}
                      {values.age.months > 15 && Q_HT2.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <MDTypography
                        variant="body2"
                        fontWeight="light"
                        textAlign="justify"
                        sx={{
                          backgroundColor: "#80B2F2",
                          color: "#FFFFFF",
                          pr: 0.5,
                          pl: 0.5,
                        }}
                      >
                        {values.Q_HT2} time(s)
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_HT2, "2px solid #80B2F2")}
            </>
          ) : null}

          {/* Question Q_HT3*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HT3.minAge &&
            values.age.months <= Q_HT3.maxAge &&
            values.Q_HT3 !== Q_HT3.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_HT3.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_HT3.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_HT3.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_HT3.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_HT3.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_HT3.label_12m_15m}
                      {values.age.months > 15 && Q_HT3.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="info"
                        size="small"
                        variant="contained"
                        value={values.Q_HT3}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_HT3, "2px solid #80B2F2")}
            </>
          ) : null}

          {/* Question Q_HT4*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HT4.minAge &&
            values.age.months <= Q_HT4.maxAge &&
            values.Q_HT4 !== Q_HT4.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_HT4.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_HT4.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_HT4.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_HT4.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_HT4.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_HT4.label_12m_15m}
                      {values.age.months > 15 && Q_HT4.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="info"
                        size="small"
                        variant="contained"
                        value={values.Q_HT4}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_HT4, "2px solid #80B2F2")}
            </>
          ) : null}

          {/* Question Q_HSl1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HSl1.minAge &&
            values.age.months <= Q_HSl1.maxAge &&
            !applyCondRulesHSl1(values, Q_HSl1) ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 3 &&
                        Q_HSl1.label_0m_3m}
                      {values.age.months > 3 &&
                        values.age.months <= 11 &&
                        Q_HSl1.label_3m_11m}
                      {values.age.months > 11 && Q_HSl1.label_11m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <MDTypography
                        variant="body2"
                        fontWeight="light"
                        textAlign="justify"
                        sx={{
                          backgroundColor: "#80B2F2",
                          color: "#FFFFFF",
                          pr: 0.5,
                          pl: 0.5,
                        }}
                      >
                        {values.Q_HSl1} hour(s)
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showSleepBehaviouralAdvisories(
                values,
                Q_HSl1,
                "2px solid #80B2F2"
              )}
            </>
          ) : null}

          {/* Question Q_HA1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HA1.minAge &&
            values.age.months <= Q_HA1.maxAge &&
            !applyCondRulesHA1(values, Q_HA1) ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 3 &&
                        Q_HA1.label_0m_3m}
                      {values.age.months > 3 &&
                        values.age.months <= 11 &&
                        Q_HA1.label_3m_11m}
                      {values.age.months > 11 && Q_HA1.label_11m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <MDTypography
                        variant="body2"
                        fontWeight="light"
                        textAlign="justify"
                        sx={{
                          backgroundColor: "#80B2F2",
                          color: "#FFFFFF",
                          pr: 0.5,
                          pl: 0.5,
                        }}
                      >
                        {values.Q_HA1} {values.Q_HA1_unit}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showSleepBehaviouralAdvisories(
                values,
                Q_HA1,
                "2px solid #80B2F2"
              )}
            </>
          ) : null}

          {/* Question Q_HSc1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HSc1.minAge &&
            values.age.months <= Q_HSc1.maxAge &&
            !applyCondRulesHSc1(values, Q_HSc1) ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 3 &&
                        Q_HSc1.label_0m_3m}
                      {values.age.months > 3 &&
                        values.age.months <= 11 &&
                        Q_HSc1.label_3m_11m}
                      {values.age.months > 11 && Q_HSc1.label_11m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      {values.age.months <= 11 ? (
                        <ToggleButtonGroup
                          color="info"
                          size="small"
                          variant="contained"
                          value={values.Q_HSc1}
                          fullWidth={true}
                          exclusive
                          aria-label="Platform"
                          disabled
                        >
                          {values.age.months <= 11 && (
                            <ToggleButton value="YES">YES</ToggleButton>
                          )}
                          {values.age.months <= 11 && (
                            <ToggleButton value="NO">NO</ToggleButton>
                          )}
                        </ToggleButtonGroup>
                      ) : (
                        <MDTypography
                          variant="body2"
                          fontWeight="light"
                          textAlign="justify"
                          sx={{
                            backgroundColor: "#80B2F2",
                            color: "#FFFFFF",
                            pr: 0.5,
                            pl: 0.5,
                          }}
                        >
                          {values.Q_HSc1} hour(s)
                        </MDTypography>
                      )}
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showSleepBehaviouralAdvisories(
                values,
                Q_HSc1,
                "2px solid #80B2F2"
              )}
            </>
          ) : null}
        </MDBox>
      </Card>

      <MDBox mb={3}></MDBox>

      <Card id="qa-doingwell" sx={{ overflow: "visible" }}>
        <MDBox m={2}>
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="lg"
            sx={{ backgroundColor: "#66BB6A" }}
            p={1}
          >
            <MDTypography variant="body1" fontWeight="bold" color="white">
              KEEP UP THE GOOD WORK
            </MDTypography>
            <MDButton variant="text" color="white" size="large" iconOnly>
              <CheckCircleOutlineIcon />
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox m={3} mt={0}>
          <MDBox mb={3}>
            <MDTypography variant="body2" fontWeight="bold" color="success">
              Regulation
            </MDTypography>
            {/* Question Q_RR1*/}
            {"age" in values === false ? null : values.age.months >=
                Q_RR1.minAge &&
              values.age.months <= Q_RR1.maxAge &&
              values.Q_RR1 === Q_RR1.response ? (
              <>
                <MDBox mb={1.0}>
                  <Grid
                    container
                    rowSpacing={{ xs: 1 }}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={12}>
                      <MDTypography variant="body2" fontWeight="regular">
                        {values.age.months >= 0 &&
                          values.age.months <= 2 &&
                          Q_RR1.label_0m_2m}
                        {values.age.months > 2 &&
                          values.age.months <= 4 &&
                          Q_RR1.label_2m_4m}
                        {values.age.months > 4 &&
                          values.age.months <= 6 &&
                          Q_RR1.label_4m_6m}
                        {values.age.months > 6 &&
                          values.age.months <= 9 &&
                          Q_RR1.label_6m_9m}
                        {values.age.months > 9 &&
                          values.age.months <= 12 &&
                          Q_RR1.label_9m_12m}
                        {values.age.months > 12 &&
                          values.age.months <= 15 &&
                          Q_RR1.label_12m_15m}
                        {values.age.months > 15 && Q_RR1.label_15m_23m}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="left">
                        <ToggleButtonGroup
                          color="success"
                          size="small"
                          variant="contained"
                          value={values.Q_RR1}
                          fullWidth={true}
                          exclusive
                          aria-label="Platform"
                          disabled
                        >
                          <ToggleButton value="YES">YES</ToggleButton>
                          <ToggleButton value="NO">NO</ToggleButton>
                        </ToggleButtonGroup>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                {showNutritionalAdvisories(values, Q_RR1, "2px solid #94FA92")}
              </>
            ) : null}

            <MDBox mt={4} />
            {/* Question Q_RR2*/}
            {"age" in values === false ? null : values.age.months >=
                Q_RR2.minAge &&
              values.age.months <= Q_RR2.maxAge &&
              values.Q_RR2 === Q_RR2.response ? (
              <>
                <MDBox mb={1.0}>
                  <Grid
                    container
                    rowSpacing={{ xs: 1 }}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={12}>
                      <MDTypography variant="body2" fontWeight="regular">
                        {values.age.months >= 0 &&
                          values.age.months <= 2 &&
                          Q_RR2.label_0m_2m}
                        {values.age.months > 2 &&
                          values.age.months <= 4 &&
                          Q_RR2.label_2m_4m}
                        {values.age.months > 4 &&
                          values.age.months <= 6 &&
                          Q_RR2.label_4m_6m}
                        {values.age.months > 6 &&
                          values.age.months <= 9 &&
                          Q_RR2.label_6m_9m}
                        {values.age.months > 9 &&
                          values.age.months <= 12 &&
                          Q_RR2.label_9m_12m}
                        {values.age.months > 12 &&
                          values.age.months <= 15 &&
                          Q_RR2.label_12m_15m}
                        {values.age.months > 15 && Q_RR2.label_15m_23m}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="left">
                        <ToggleButtonGroup
                          color="success"
                          size="small"
                          variant="contained"
                          value={values.Q_RR2}
                          fullWidth={true}
                          exclusive
                          aria-label="Platform"
                          disabled
                        >
                          <ToggleButton value="YES">YES</ToggleButton>
                          <ToggleButton value="NO">NO</ToggleButton>
                        </ToggleButtonGroup>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                {showNutritionalAdvisories(values, Q_RR2, "2px solid #94FA92")}
              </>
            ) : null}

            <MDBox mt={4} />
            {/* Question Q_RR3*/}
            {"age" in values === false ? null : values.age.months >=
                Q_RR3.minAge &&
              values.age.months <= Q_RR3.maxAge &&
              values.Q_RR3 === Q_RR3.response ? (
              <>
                <MDBox mb={1.0}>
                  <Grid
                    container
                    rowSpacing={{ xs: 1 }}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={12}>
                      <MDTypography variant="body2" fontWeight="regular">
                        {values.age.months >= 0 &&
                          values.age.months <= 2 &&
                          Q_RR3.label_0m_2m}
                        {values.age.months > 2 &&
                          values.age.months <= 4 &&
                          Q_RR3.label_2m_4m}
                        {values.age.months > 4 &&
                          values.age.months <= 6 &&
                          Q_RR3.label_4m_6m}
                        {values.age.months > 6 &&
                          values.age.months <= 9 &&
                          Q_RR3.label_6m_9m}
                        {values.age.months > 9 &&
                          values.age.months <= 12 &&
                          Q_RR3.label_9m_12m}
                        {values.age.months > 12 &&
                          values.age.months <= 15 &&
                          Q_RR3.label_12m_15m}
                        {values.age.months > 15 && Q_RR3.label_15m_23m}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="left">
                        <ToggleButtonGroup
                          color="success"
                          size="small"
                          variant="contained"
                          value={values.Q_RR3}
                          fullWidth={true}
                          exclusive
                          aria-label="Platform"
                          disabled
                        >
                          <ToggleButton value="YES">YES</ToggleButton>
                          <ToggleButton value="NO">NO</ToggleButton>
                        </ToggleButtonGroup>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                {showNutritionalAdvisories(values, Q_RR3, "2px solid #94FA92")}
              </>
            ) : null}

            <MDBox mt={4} />
            {/* Question Q_RR4*/}
            {"age" in values === false ? null : values.age.months >=
                Q_RR4.minAge &&
              values.age.months <= Q_RR4.maxAge &&
              values.Q_RR4 === Q_RR4.response ? (
              <>
                <MDBox mb={1.0}>
                  <Grid
                    container
                    rowSpacing={{ xs: 1 }}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={12}>
                      <MDTypography variant="body2" fontWeight="regular">
                        {values.age.months >= 0 &&
                          values.age.months <= 2 &&
                          Q_RR4.label_0m_2m}
                        {values.age.months > 2 &&
                          values.age.months <= 4 &&
                          Q_RR4.label_2m_4m}
                        {values.age.months > 4 &&
                          values.age.months <= 6 &&
                          Q_RR4.label_4m_6m}
                        {values.age.months > 6 &&
                          values.age.months <= 9 &&
                          Q_RR4.label_6m_9m}
                        {values.age.months > 9 &&
                          values.age.months <= 12 &&
                          Q_RR4.label_9m_12m}
                        {values.age.months > 12 &&
                          values.age.months <= 15 &&
                          Q_RR4.label_12m_15m}
                        {values.age.months > 15 && Q_RR4.label_15m_23m}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="left">
                        <ToggleButtonGroup
                          color="success"
                          size="small"
                          variant="contained"
                          value={values.Q_RR4}
                          fullWidth={true}
                          exclusive
                          aria-label="Platform"
                          disabled
                        >
                          <ToggleButton value="YES">YES</ToggleButton>
                          <ToggleButton value="NO">NO</ToggleButton>
                        </ToggleButtonGroup>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                {showNutritionalAdvisories(values, Q_RR4, "2px solid #94FA92")}
              </>
            ) : null}

            <MDBox mt={4} />
            {/* Question Q_RF1a*/}
            {"age" in values === false ? null : values.age.months >=
                Q_RF1a.minAge &&
              values.age.months <= Q_RF1a.maxAge &&
              values.Q_RF1a === Q_RF1a.response ? (
              <>
                <MDBox mb={0}>
                  <Grid
                    container
                    rowSpacing={{ xs: 1 }}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={12}>
                      <MDTypography variant="body2" fontWeight="regular">
                        {values.age.months >= 0 &&
                          values.age.months <= 2 &&
                          Q_RF1a.label_0m_2m}
                        {values.age.months > 2 &&
                          values.age.months <= 4 &&
                          Q_RF1a.label_2m_4m}
                        {values.age.months > 4 &&
                          values.age.months <= 6 &&
                          Q_RF1a.label_4m_6m}
                        {values.age.months > 6 &&
                          values.age.months <= 9 &&
                          Q_RF1a.label_6m_9m}
                        {values.age.months > 9 &&
                          values.age.months <= 12 &&
                          Q_RF1a.label_9m_12m}
                        {values.age.months > 12 &&
                          values.age.months <= 15 &&
                          Q_RF1a.label_12m_15m}
                        {values.age.months > 15 && Q_RF1a.label_15m_23m}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="left">
                        <ToggleButtonGroup
                          color="success"
                          size="small"
                          variant="contained"
                          value={values.Q_RF1a}
                          fullWidth={true}
                          exclusive
                          aria-label="Platform"
                          disabled
                        >
                          <ToggleButton value="YES">YES</ToggleButton>
                          <ToggleButton value="NO">NO</ToggleButton>
                        </ToggleButtonGroup>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </>
            ) : null}
            <MDBox mt={2} />
            {/* Question Q_RF1b*/}
            {"age" in values === false ? null : values.age.months >=
                Q_RF1b.minAge &&
              values.age.months <= Q_RF1b.maxAge &&
              values.Q_RF1a === Q_RF1a.response ? (
              <>
                <MDBox mt={0}>
                  <Grid
                    container
                    rowSpacing={{ xs: 1 }}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={12}>
                      <MDTypography variant="body2" fontWeight="regular">
                        {values.age.months >= 0 &&
                          values.age.months <= 2 &&
                          Q_RF1b.label_0m_2m}
                        {values.age.months > 2 &&
                          values.age.months <= 4 &&
                          Q_RF1b.label_2m_4m}
                        {values.age.months > 4 &&
                          values.age.months <= 6 &&
                          Q_RF1b.label_4m_6m}
                        {values.age.months > 6 &&
                          values.age.months <= 9 &&
                          Q_RF1b.label_6m_9m}
                        {values.age.months > 9 &&
                          values.age.months <= 12 &&
                          Q_RF1b.label_9m_12m}
                        {values.age.months > 12 &&
                          values.age.months <= 15 &&
                          Q_RF1b.label_12m_15m}
                        {values.age.months > 15 && Q_RF1b.label_15m_23m}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDBox display="flex" justifyContent="left">
                        <FormTextFieldFast
                          form={mainForm}
                          field={Q_RF1b}
                          variant="standard"
                          disabled={true}
                          hideLabel={true}
                          //hideField={true}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6}></Grid>
                  </Grid>
                </MDBox>
                {showNutritionalAdvisories(values, Q_RF1a, "2px solid #94FA92")}
              </>
            ) : null}

            <MDBox mt={4} />
            {/* Question Q_RF2*/}
            {"age" in values === false ? null : values.age.months >=
                Q_RF2.minAge &&
              values.age.months <= Q_RF2.maxAge &&
              values.Q_RF2 === Q_RF2.response ? (
              <>
                <MDBox mb={1.0}>
                  <Grid
                    container
                    rowSpacing={{ xs: 1 }}
                    columnSpacing={{ xs: 1 }}
                  >
                    <Grid item xs={12}>
                      <MDTypography variant="body2" fontWeight="regular">
                        {values.age.months >= 0 &&
                          values.age.months <= 2 &&
                          Q_RF2.label_0m_2m}
                        {values.age.months > 2 &&
                          values.age.months <= 4 &&
                          Q_RF2.label_2m_4m}
                        {values.age.months > 4 &&
                          values.age.months <= 6 &&
                          Q_RF2.label_4m_6m}
                        {values.age.months > 6 &&
                          values.age.months <= 9 &&
                          Q_RF2.label_6m_9m}
                        {values.age.months > 9 &&
                          values.age.months <= 12 &&
                          Q_RF2.label_9m_12m}
                        {values.age.months > 12 &&
                          values.age.months <= 15 &&
                          Q_RF2.label_12m_15m}
                        {values.age.months > 15 && Q_RF2.label_15m_23m}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox display="flex" justifyContent="left">
                        <ToggleButtonGroup
                          color="success"
                          size="small"
                          variant="contained"
                          value={values.Q_RF2}
                          fullWidth={true}
                          exclusive
                          aria-label="Platform"
                          disabled
                        >
                          <ToggleButton value="YES">YES</ToggleButton>
                          <ToggleButton value="NO">NO</ToggleButton>
                        </ToggleButtonGroup>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
                {showNutritionalAdvisories(values, Q_RF2, "2px solid #94FA92")}
              </>
            ) : null}
          </MDBox>

          {/* Weaning */}
          <MDBox mb={3}>
            <MDTypography variant="body2" fontWeight="bold" color="warning">
              Weaning
            </MDTypography>
          </MDBox>
          {/* Question Q_WW1 - 0m_4m*/}
          {"age" in values === false ? null : values.age.months <= 4 &&
            values.Q_WW1 === Q_WW1.response_0m_4m ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WW1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WW1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WW1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WW1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WW1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WW1.label_12m_15m}
                      {values.age.months > 15 && Q_WW1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={values.Q_WW1}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        {values.age.months <= 9 && (
                          <ToggleButton value="HAVEN'T STARTED">
                            Haven't started
                          </ToggleButton>
                        )}
                        {values.age.months <= 4 && (
                          <ToggleButton value="0-4 MONTHS">
                            0-4 MONTHS
                          </ToggleButton>
                        )}
                        {values.age.months > 4 && values.age.months <= 9 && (
                          <ToggleButton value="4-9 MONTHS">
                            4-9 MONTHS
                          </ToggleButton>
                        )}
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_WW1, "2px solid #FCAC48")}
            </>
          ) : null}

          {/* Question Q_WW1 - 4m_9m*/}
          {"age" in values === false ? null : values.age.months > 4 &&
            values.age.months <= 9 &&
            values.Q_WW1 === Q_WW1.response_4m_9m ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WW1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WW1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WW1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WW1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WW1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WW1.label_12m_15m}
                      {values.age.months > 15 && Q_WW1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={values.Q_WW1}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        {values.age.months <= 9 && (
                          <ToggleButton value="HAVEN'T STARTED">
                            Haven't started
                          </ToggleButton>
                        )}
                        {values.age.months <= 4 && (
                          <ToggleButton value="0-4 MONTHS">
                            0-4 MONTHS
                          </ToggleButton>
                        )}
                        {values.age.months > 4 && values.age.months <= 9 && (
                          <ToggleButton value="4-9 MONTHS">
                            4-9 MONTHS
                          </ToggleButton>
                        )}
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_WW1, "2px solid #FCAC48")}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_WBe1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WBe1.minAge &&
            values.age.months <= Q_WBe1.maxAge &&
            values.Q_WBe1 === Q_WBe1.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WBe1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WBe1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WBe1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WBe1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WBe1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WBe1.label_12m_15m}
                      {values.age.months > 15 && Q_WBe1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={values.Q_WBe1}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_WBe1, "2px solid #FCAC48")}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_WBa1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WBa1.minAge &&
            values.age.months <= Q_WBa1.maxAge &&
            values.Q_WBa1 === Q_WBa1.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WBa1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WBa1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WBa1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WBa1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WBa1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WBa1.label_12m_15m}
                      {values.age.months > 15 && Q_WBa1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={values.Q_WBa1}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_WBa1, "2px solid #FCAC48")}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_WBa2*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WBa2.minAge &&
            values.age.months <= Q_WBa2.maxAge &&
            values.Q_WBa2 === Q_WBa2.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WBa2.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WBa2.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WBa2.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WBa2.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WBa2.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WBa2.label_12m_15m}
                      {values.age.months > 15 && Q_WBa2.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={values.Q_WBa2}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_WBa2, "2px solid #FCAC48")}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_WBa3*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WBa3.minAge &&
            values.age.months <= Q_WBa3.maxAge &&
            values.Q_WBa3 === Q_WBa3.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WBa3.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WBa3.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WBa3.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WBa3.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WBa3.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WBa3.label_12m_15m}
                      {values.age.months > 15 && Q_WBa3.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={values.Q_WBa3}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_WBa3, "2px solid #FCAC48")}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_WBa4*/}
          {"age" in values === false ? null : values.age.months >=
              Q_WBa4.minAge &&
            values.age.months <= Q_WBa4.maxAge &&
            values.Q_WBa4 === Q_WBa4.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_WBa4.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_WBa4.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_WBa4.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_WBa4.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_WBa4.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_WBa4.label_12m_15m}
                      {values.age.months > 15 && Q_WBa4.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="warning"
                        size="small"
                        variant="contained"
                        value={values.Q_WBa4}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_WBa4, "2px solid #FCAC48")}
            </>
          ) : null}

          {/* Habituation */}
          <MDBox mb={3}>
            <MDTypography variant="body2" fontWeight="bold" color="info">
              Habituation
            </MDTypography>
          </MDBox>

          <MDBox mt={4} />
          {/* Question Q_HF1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HF1.minAge &&
            values.age.months <= Q_HF1.maxAge &&
            values.Q_HF1 === Q_HF1.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_HF1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_HF1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_HF1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_HF1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_HF1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_HF1.label_12m_15m}
                      {values.age.months > 15 && Q_HF1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="info"
                        size="small"
                        variant="contained"
                        value={values.Q_HF1}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_HF1, "2px solid #80B2F2")}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_HT1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HT1.minAge &&
            values.age.months <= Q_HT1.maxAge &&
            applyCondRulesHT(values.Q_HT1, Q_HT1) ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_HT1.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_HT1.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_HT1.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_HT1.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_HT1.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_HT1.label_12m_15m}
                      {values.age.months > 15 && Q_HT1.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <MDTypography
                        variant="body2"
                        fontWeight="light"
                        textAlign="justify"
                        sx={{
                          backgroundColor: "#80B2F2",
                          color: "#FFFFFF",
                          pr: 0.5,
                          pl: 0.5,
                        }}
                      >
                        {values.Q_HT1} minute(s)
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_HT1, "2px solid #80B2F2")}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_HT2*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HT2.minAge &&
            values.age.months <= Q_HT2.maxAge &&
            applyCondRulesHT(values.Q_HT2, Q_HT2) ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_HT2.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_HT2.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_HT2.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_HT2.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_HT2.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_HT2.label_12m_15m}
                      {values.age.months > 15 && Q_HT2.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <MDTypography
                        variant="body2"
                        fontWeight="light"
                        textAlign="justify"
                        sx={{
                          backgroundColor: "#80B2F2",
                          color: "#FFFFFF",
                          pr: 0.5,
                          pl: 0.5,
                        }}
                      >
                        {values.Q_HT2} time(s)
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_HT2, "2px solid #80B2F2")}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_HT3*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HT3.minAge &&
            values.age.months <= Q_HT3.maxAge &&
            values.Q_HT3 === Q_HT3.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_HT3.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_HT3.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_HT3.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_HT3.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_HT3.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_HT3.label_12m_15m}
                      {values.age.months > 15 && Q_HT3.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="info"
                        size="small"
                        variant="contained"
                        value={values.Q_HT3}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_HT3, "2px solid #80B2F2")}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_HT4*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HT4.minAge &&
            values.age.months <= Q_HT4.maxAge &&
            values.Q_HT4 === Q_HT4.response ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 2 &&
                        Q_HT4.label_0m_2m}
                      {values.age.months > 2 &&
                        values.age.months <= 4 &&
                        Q_HT4.label_2m_4m}
                      {values.age.months > 4 &&
                        values.age.months <= 6 &&
                        Q_HT4.label_4m_6m}
                      {values.age.months > 6 &&
                        values.age.months <= 9 &&
                        Q_HT4.label_6m_9m}
                      {values.age.months > 9 &&
                        values.age.months <= 12 &&
                        Q_HT4.label_9m_12m}
                      {values.age.months > 12 &&
                        values.age.months <= 15 &&
                        Q_HT4.label_12m_15m}
                      {values.age.months > 15 && Q_HT4.label_15m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <ToggleButtonGroup
                        color="info"
                        size="small"
                        variant="contained"
                        value={values.Q_HT4}
                        fullWidth={true}
                        exclusive
                        aria-label="Platform"
                        disabled
                      >
                        <ToggleButton value="YES">YES</ToggleButton>
                        <ToggleButton value="NO">NO</ToggleButton>
                      </ToggleButtonGroup>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showNutritionalAdvisories(values, Q_HT4, "2px solid #80B2F2")}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_HSl1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HSl1.minAge &&
            values.age.months <= Q_HSl1.maxAge &&
            applyCondRulesHSl1(values, Q_HSl1) ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 3 &&
                        Q_HSl1.label_0m_3m}
                      {values.age.months > 3 &&
                        values.age.months <= 11 &&
                        Q_HSl1.label_3m_11m}
                      {values.age.months > 11 && Q_HSl1.label_11m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <MDTypography
                        variant="body2"
                        fontWeight="light"
                        textAlign="justify"
                        sx={{
                          backgroundColor: "#80B2F2",
                          color: "#FFFFFF",
                          pr: 0.5,
                          pl: 0.5,
                        }}
                      >
                        {values.Q_HSl1} hour(s)
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showSleepBehaviouralAdvisories(
                values,
                Q_HSl1,
                "2px solid #80B2F2"
              )}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_HA1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HA1.minAge &&
            values.age.months <= Q_HA1.maxAge &&
            applyCondRulesHA1(values, Q_HA1) ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 3 &&
                        Q_HA1.label_0m_3m}
                      {values.age.months > 3 &&
                        values.age.months <= 11 &&
                        Q_HA1.label_3m_11m}
                      {values.age.months > 11 && Q_HA1.label_11m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      <MDTypography
                        variant="body2"
                        fontWeight="light"
                        textAlign="justify"
                        sx={{
                          backgroundColor: "#80B2F2",
                          color: "#FFFFFF",
                          pr: 0.5,
                          pl: 0.5,
                        }}
                      >
                        {values.Q_HA1} {values.Q_HA1_unit}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showSleepBehaviouralAdvisories(
                values,
                Q_HA1,
                "2px solid #80B2F2"
              )}
            </>
          ) : null}

          <MDBox mt={4} />
          {/* Question Q_HSc1*/}
          {"age" in values === false ? null : values.age.months >=
              Q_HSc1.minAge &&
            values.age.months <= Q_HSc1.maxAge &&
            applyCondRulesHSc1(values, Q_HSc1) ? (
            <>
              <MDBox mb={1.0}>
                <Grid
                  container
                  rowSpacing={{ xs: 1 }}
                  columnSpacing={{ xs: 1 }}
                >
                  <Grid item xs={12}>
                    <MDTypography variant="body2" fontWeight="regular">
                      {values.age.months >= 0 &&
                        values.age.months <= 3 &&
                        Q_HSc1.label_0m_3m}
                      {values.age.months > 3 &&
                        values.age.months <= 11 &&
                        Q_HSc1.label_3m_11m}
                      {values.age.months > 11 && Q_HSc1.label_11m_23m}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox display="flex" justifyContent="left">
                      {values.age.months <= 11 ? (
                        <ToggleButtonGroup
                          color="info"
                          size="small"
                          variant="contained"
                          value={values.Q_HSc1}
                          fullWidth={true}
                          exclusive
                          aria-label="Platform"
                          disabled
                        >
                          <ToggleButton value="YES">YES</ToggleButton>
                          <ToggleButton value="NO">NO</ToggleButton>
                        </ToggleButtonGroup>
                      ) : (
                        <MDTypography
                          variant="body2"
                          fontWeight="light"
                          textAlign="justify"
                          sx={{
                            backgroundColor: "#80B2F2",
                            color: "#FFFFFF",
                            pr: 0.5,
                            pl: 0.5,
                          }}
                        >
                          {values.Q_HSc1} hour(s)
                        </MDTypography>
                      )}
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {showSleepBehaviouralAdvisories(
                values,
                Q_HSc1,
                "2px solid #80B2F2"
              )}
            </>
          ) : null}
        </MDBox>
      </Card>
    </>
  );
}

Questionnaire.propTypes = {
  mainForm: PropTypes.object.isRequired,
  mainFormField: PropTypes.object.isRequired,
  //modeDisabled: PropTypes.bool.isRequired,
  //mode: PropTypes.string.isRequired,
};

export default Questionnaire;
