import { useEffect, useCallback, useState, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { useAbac } from "react-abac";
import { Permission } from "models/abac";
import initialValues from "pages/account/manage/schemas/initialValues";
import validations from "pages/account/manage/schemas/validations";
import parse from "date-fns/parse";

const collectionPathUsers = "users";
const collectionPathBabies = "babies";
initialValues.babiesProfile = [];
initialValues.babies = [];

const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "INITIAL_USER":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "RETRIEVED_USER":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "UPDATED_USER":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully updated my profile.`,
        error: null,
      };
    case "CREATED_BABY_PROFILE":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully created baby profile.`,
        error: null,
      };
    case "UPDATED_BABY_PROFILE":
      return {
        isPending: false,
        data: action.payload,
        success: `Successfully updated baby profile.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useAccountManager = () => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);

  const { user } = useAuthContext();

  const { userHasPermissions } = useAbac();

  const {
    retrieveDoc,
    updateDoc,
    createDoc,
    serverTimestamp,
    convertToTimestamp,
  } = useDocument();

  const { pathname } = useLocation();

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  // TODO: Refactor to DAO Layer
  const toPersistenceValue = useCallback(
    (document) => {
      try {
        if (document.dob) {
          typeof document.dob === "string" &&
            (document.dob = convertToTimestamp(
              parse(document.dob, "dd/MM/yyyy", new Date())
            ));
          //console.log(JSON.stringify(document.dob));
        }

        if (document.photoAttachments) {
          document.photoAttachments = document.photoAttachments.map(
            (element) => {
              const { attachmentName, attachmentPath, attachmentURL } = element;
              return { attachmentName, attachmentPath, attachmentURL };
            }
          );
        }

        if (document.avatarAttachments) {
          document.avatarAttachments = document.avatarAttachments.map(
            (element) => {
              const { attachmentName, attachmentPath, attachmentURL } = element;
              return { attachmentName, attachmentPath, attachmentURL };
            }
          );
        }

        return document;
      } catch (err) {
        dispatchError(err);
      }
    },
    [dispatchError, convertToTimestamp]
  );

  // TODO: Refactor to DAO Layer
  /*const toPresentationValue = useCallback(
    (data) => {
      try {
        // nothing to convert at this moment
        return data;
      } catch (err) {
        dispatchError(err);
      }
    },
    [dispatchError]
  );*/

  const [formikInitValues, setFormikInitValues] = useState({
    babyName: initialValues.babyName,
    dob: initialValues.dob,
    photoAttachments: initialValues.photoAttachments,
    avatarAttachments: initialValues.avatarAttachments,
  });

  const retrieveBabyProfiles = useCallback(
    async (retrievedUser) => {
      const babiesProfile = await Promise.all(
        retrievedUser.data.babies.map(async (babyId) => {
          const retrievedBaby =
            user.uid && (await retrieveDoc(collectionPathBabies, babyId));
          const { babyName, dob, photoAttachments, avatarAttachments } =
            retrievedBaby.data;

          const retJson = {
            babyName: babyName,
            dob: dob,
            photoAttachments: photoAttachments,
            avatarAttachments: avatarAttachments,
          };

          return retJson;
        })
      );

      setFormikInitValues({
        babyName: babiesProfile[0].babyName,
        dob: babiesProfile[0].dob.toDate().toLocaleDateString("en-SG"),
        photoAttachments: babiesProfile[0].photoAttachments,
        avatarAttachments: babiesProfile[0].avatarAttachments,
      });

      return babiesProfile;
    },
    [setFormikInitValues, retrieveDoc, user.uid]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      if (!pathname.includes("/account/profile")) {
        throw operationInvalidError;
      }

      const retrievedUser =
        user.uid && (await retrieveDoc(collectionPathUsers, user.uid));

      const babies = retrievedUser.data.babies;
      const babiesProfile =
        babies.length > 0 ? await retrieveBabyProfiles(retrievedUser) : [];

      if (babies.length > 0) {
        // Init "selectedBaby" variable on user.uid document
        await updateDoc(collectionPathUsers, user.uid, {
          selectedBaby: retrievedUser.data.babies[0], // Select first baby
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });
      }

      const retrievedUserData = {
        babiesProfile: babiesProfile,
        babies: babies,
      };

      dispatchIfNotUnmounted({
        type: "RETRIEVED_USER",
        payload: retrievedUserData,
      });
    } catch (err) {
      dispatchError(err);
    }
  }, [
    dispatchIfNotUnmounted,
    user.uid,
    retrieveDoc,
    pathname,
    dispatchError,
    retrieveBabyProfiles,
    updateDoc,
    serverTimestamp,
  ]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  let modeTitle = "Baby Tracker";
  let modeSubmit = "Update";
  let modeFieldDisabled = false;
  let modePermission = Permission.VIEW_MEMBER_DASHBOARD;
  let modeValidation = validations;

  const submitNew = async (values, records) => {
    try {
      //dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.CREATE_BABY_PROFILE)) {
        const { babiesProfile, babies } = records;
        const babyProfile = toPersistenceValue(values);

        // Create new baby profile under "babies" collection
        const createdDoc = await createDoc(
          collectionPathBabies,
          {
            avatarAttachments: babyProfile.avatarAttachments,
            babyName: babyProfile.babyName,
            dob: babyProfile.dob,
            photoAttachments: babyProfile.photoAttachments,
            parents: [user.uid],
            assessments: [],
          },
          user.uid
        );

        // Add new record of babyProfile to "babiesProfile"
        babiesProfile.push(babyProfile);
        // Add new babyId to "babies"
        babies.push(createdDoc.id);

        const userData = {
          babiesProfile: babiesProfile,
          babies: babies,
        };

        await updateDoc(collectionPathUsers, user.uid, {
          babies: babies,
          //selectedBaby: createdDoc.id,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "CREATED_BABY_PROFILE",
          payload: userData,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to create baby profile."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitEdit = async (values, records, index) => {
    try {
      //dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.CREATE_BABY_PROFILE)) {
        const { babiesProfile, babies } = records;
        const baby = babies[index];
        const babyProfile = toPersistenceValue(values);
        babiesProfile[index] = babyProfile;

        await updateDoc(collectionPathBabies, baby, {
          ...babyProfile,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        const userData = {
          babiesProfile: babiesProfile,
          babies: babies,
        };

        dispatchIfNotUnmounted({
          type: "UPDATED_BABY_PROFILE",
          payload: userData,
        });
      }
      /*if (userHasPermissions(Permission.CREATE_BABY_PROFILE)) {
        values = toPersistenceValue(values);
        const updatedDoc = await updateDoc(collectionPathUsers, user.uid, {
          ...values,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        updateUserProfile(values.displayName, null);

        dispatchIfNotUnmounted({
          type: "UPDATED_USER",
          payload: toPresentationValue(updatedDoc.data),
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to update user profile."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }*/
    } catch (err) {
      dispatchError(err);
    }
  };

  return {
    modeTitle,
    modeSubmit,
    modeFieldDisabled,
    modePermission,
    modeValidation,
    submitEdit,
    submitNew,
    response,
    dispatchDismiss,
    dispatchError,
    formikInitValues,
    setFormikInitValues,
    updateDoc,
    collectionPathUsers,
    user,
    serverTimestamp,
  };
};
