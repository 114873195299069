import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorMessage } from "formik";

//import { DateRangePicker, DatePicker } from 'rsuite';
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import isBefore from "date-fns/isBefore";

function SedentaryDetails({
  mainForm,
  mainFormField,
  closeInputPanel,
  calendarDate,
}) {
  const {
    sedentaryActivity,
    distractionUsed,
    screenBased,
    interactive,
    starttime,
    endtime,
  } = mainFormField;
  const { setFieldValue, initialValues } = mainForm;
  const [sedentaryActivityValue, setSedentaryActivityValue] = useState("");
  const [distractionUsedValue, setDistractionUsedValue] = useState("");
  const [screenBasedValue, setScreenBasedValue] = useState("");
  const [interactiveValue, setInteractiveValue] = useState("");
  const [startTimeValue, setStartTimeValue] = useState("");
  const [endTimeValue, setEndTimeValue] = useState("");
  const [disableBeforeValue, setDisableBeforeValue] = useState("");
  //const [disableBeforeHour, setDisableBeforeHour] = useState(0);

  useEffect(() => {
    try {
      setSedentaryActivityValue(initialValues.sedentaryActivity);
      setDistractionUsedValue(initialValues.distractionUsed);
      setScreenBasedValue(initialValues.screenBased);
      setInteractiveValue(initialValues.interactive);
      const dateDisableBefour = new Date(
        calendarDate.getFullYear(),
        calendarDate.getMonth(),
        calendarDate.getDate(),
        0,
        0,
        0
      );
      setDisableBeforeValue(dateDisableBefour);

      const Now = new Date();
      if (initialValues.starttime.length > 0) {
        setStartTimeValue(new Date(initialValues.starttime));
        setFieldValue(starttime.name, initialValues.starttime);
      } else {
        const newDate = new Date(
          calendarDate.getFullYear(),
          calendarDate.getMonth(),
          calendarDate.getDate(),
          Now.getHours(),
          Now.getMinutes(),
          Now.getSeconds()
        );
        setStartTimeValue(newDate);
        setFieldValue(starttime.name, newDate.toISOString());
        //setDisableBeforeHour(newDate.getHours());
      }

      if (initialValues.endtime.length > 0) {
        setEndTimeValue(new Date(initialValues.endtime));
        setFieldValue(endtime.name, initialValues.endtime);
      } else {
        const newDate = new Date(
          calendarDate.getFullYear(),
          calendarDate.getMonth(),
          calendarDate.getDate(),
          Now.getHours(),
          Now.getMinutes(),
          Now.getSeconds()
        );
        newDate.setMinutes(newDate.getMinutes() + 15);
        setEndTimeValue(newDate);
        setFieldValue(endtime.name, newDate.toISOString());
      }
    } catch (err) {
      console.log(err);
    }
  }, [initialValues, starttime, endtime, setFieldValue, calendarDate]);

  return (
    <MDBox m={1} mt={2}>
      <MDBox
        display="flex"
        borderRadius="lg"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "rgb(43,99, 149)" }}
        p={1}
      >
        <MDBox />
        <MDTypography variant="body2" fontWeight="bold" color="white">
          SEDENTARY ACTIVITY
        </MDTypography>
        <MDButton
          variant="text"
          size="medium"
          color="white"
          iconOnly
          onClick={() => {
            closeInputPanel("sedentary");
          }}
        >
          <CloseIcon />
        </MDButton>
      </MDBox>
      <MDBox m={1} mt={3} mb={0}>
        <Grid
          container
          rowSpacing={{ xs: 0 }}
          columnSpacing={{ xs: 1 }}
          alignItems="center"
        >
          <Grid item xs={4}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {sedentaryActivity.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={8}>
            <ToggleButtonGroup
              color="info"
              size="small"
              variant="contained"
              value={sedentaryActivityValue}
              exclusive
              fullWidth={true}
              onChange={(event, value) => {
                value !== null && setSedentaryActivityValue(value);
                value !== null && setFieldValue(sedentaryActivity.name, value);
              }}
              aria-label="Platform"
            >
              <ToggleButton value="Restrained">Restrained</ToggleButton>
              <ToggleButton value="Unrestrained">Unrestrained</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {/* Error message - Sedentary Activity */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={0}
              mr={0}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={sedentaryActivity.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>

          <Grid item xs={4}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {distractionUsed.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={8}>
            <ToggleButtonGroup
              color="info"
              size="small"
              variant="contained"
              value={distractionUsedValue}
              exclusive
              fullWidth={true}
              onChange={(event, value) => {
                if (value !== null) {
                  setDistractionUsedValue(value);
                  setFieldValue(distractionUsed.name, value);
                  if (value === "Yes") {
                    setScreenBasedValue("");
                    setFieldValue(screenBased.name, "");
                  } else if (value === "No") {
                    setScreenBasedValue("NIL");
                    setFieldValue(screenBased.name, "NIL");
                  }
                } else {
                  setDistractionUsedValue("");
                  setFieldValue(distractionUsed.name, "");
                  setScreenBasedValue("NIL");
                  setFieldValue(screenBased.name, "NIL");
                }
              }}
              aria-label="Platform"
            >
              <ToggleButton value="Yes">Yes</ToggleButton>
              <ToggleButton value="No">No</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {/* Error message - Distraction-used */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={0}
              mr={0}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={distractionUsed.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>

          {distractionUsedValue === "Yes" && (
            <>
              <Grid item xs={4}>
                <MDBox display="flex">
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="dark"
                  >
                    {screenBased.label}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={8}>
                <ToggleButtonGroup
                  color="info"
                  size="small"
                  variant="contained"
                  value={screenBasedValue}
                  exclusive
                  fullWidth={true}
                  onChange={(event, value) => {
                    value !== null && setScreenBasedValue(value);
                    value !== null && setFieldValue(screenBased.name, value);
                  }}
                  aria-label="Platform"
                >
                  <ToggleButton value="Yes">Yes</ToggleButton>
                  <ToggleButton value="No">No</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              {/* Error message - Screen-based */}
              <Grid item xs={12}>
                <MDBox
                  mt={1}
                  ml={0}
                  mr={0}
                  mb={1}
                  display="flex"
                  justifyContent="right"
                >
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    <ErrorMessage name={screenBased.name}></ErrorMessage>
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12} mb={1}></Grid>
            </>
          )}

          <Grid item xs={4}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {interactive.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={8}>
            <ToggleButtonGroup
              color="info"
              size="small"
              variant="contained"
              value={interactiveValue}
              exclusive
              fullWidth={true}
              onChange={(event, value) => {
                value !== null && setInteractiveValue(value);
                value !== null && setFieldValue(interactive.name, value);
              }}
              aria-label="Platform"
            >
              <ToggleButton value="Yes">Yes</ToggleButton>
              <ToggleButton value="No">No</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {/* Error message - Interactivity */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={0}
              mr={0}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={interactive.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>
          <Grid item xs={4}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {starttime.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={8}>
            <DatePicker
              format="HH:mm"
              block
              editable={false}
              appearance="default"
              placement="topEnd"
              style={{ width: 300 }}
              size="sm"
              value={startTimeValue instanceof Date ? startTimeValue : null}
              onChange={(date) => {
                if (date !== null) {
                  const newDate = new Date(
                    calendarDate.getFullYear(),
                    calendarDate.getMonth(),
                    calendarDate.getDate(),
                    date.getHours(),
                    date.getMinutes(),
                    0
                  );
                  setFieldValue(starttime.name, newDate.toISOString());
                  setStartTimeValue(newDate);
                  //setDisableBeforeHour(newDate.getHours());
                }
              }}
            />
          </Grid>
          {/* Error message - Start Time */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={0}
              mr={0}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={starttime.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>
          <Grid item xs={4}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {endtime.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={8}>
            <DatePicker
              format="dd/MM/yyyy HH:mm"
              block
              editable={false}
              appearance="default"
              placement="topEnd"
              style={{ width: 300 }}
              size="sm"
              value={endTimeValue instanceof Date ? endTimeValue : null}
              onChange={(date) => {
                if (date !== null) {
                  setFieldValue(endtime.name, date.toISOString());
                  setEndTimeValue(date);
                }
              }}
              shouldDisableDate={(date) =>
                isBefore(date, new Date(disableBeforeValue))
              }
              //hideHours={(hour) => hour < disableBeforeHour}
            />
          </Grid>
          {/* Error message - End Time */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={0}
              mr={0}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={endtime.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default SedentaryDetails;
