import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CloseIcon from "@mui/icons-material/Close";
import { ErrorMessage } from "formik";

//import { DateRangePicker, DatePicker } from 'rsuite';
import { DatePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import isBefore from "date-fns/isBefore";

const intensityLevels = [
  { label: "Light" },
  { label: "Moderate" },
  { label: "Vigorous" },
];

function PhysicalDetails({
  mainForm,
  mainFormField,
  closeInputPanel,
  calendarDate,
}) {
  const { environment, tummyTime, intensity, starttime, endtime } =
    mainFormField;
  const { initialValues, setFieldValue } = mainForm;
  const [environmentValue, setEnvironmentValue] = useState("");
  const [tummyTimeValue, setTummyTimeValue] = useState("");
  const [intensityValue, setIntensityValue] = useState(null);
  const [startTimeValue, setStartTimeValue] = useState("");
  const [endTimeValue, setEndTimeValue] = useState("");
  const [disableBeforeValue, setDisableBeforeValue] = useState("");
  //const [disableBeforeHour, setDisableBeforeHour] = useState(0);

  useEffect(() => {
    try {
      setEnvironmentValue(initialValues.environment);
      setTummyTimeValue(initialValues.tummyTime);
      const dateDisableBefour = new Date(
        calendarDate.getFullYear(),
        calendarDate.getMonth(),
        calendarDate.getDate(),
        0,
        0,
        0
      );
      setDisableBeforeValue(dateDisableBefour);

      const Now = new Date();
      if (initialValues.starttime.length > 0) {
        setStartTimeValue(new Date(initialValues.starttime));
        setFieldValue(starttime.name, initialValues.starttime);
      } else {
        const newDate = new Date(
          calendarDate.getFullYear(),
          calendarDate.getMonth(),
          calendarDate.getDate(),
          Now.getHours(),
          Now.getMinutes(),
          Now.getSeconds()
        );
        setStartTimeValue(newDate);
        setFieldValue(starttime.name, newDate.toISOString());
        //setDisableBeforeHour(newDate.getHours());
      }

      if (initialValues.endtime.length > 0) {
        setEndTimeValue(new Date(initialValues.endtime));
        setFieldValue(endtime.name, initialValues.endtime);
      } else {
        const newDate = new Date(
          calendarDate.getFullYear(),
          calendarDate.getMonth(),
          calendarDate.getDate(),
          Now.getHours(),
          Now.getMinutes(),
          Now.getSeconds()
        );
        newDate.setMinutes(newDate.getMinutes() + 15);
        setEndTimeValue(newDate);
        setFieldValue(endtime.name, newDate.toISOString());
      }
    } catch (err) {
      console.log(err);
    }
  }, [initialValues, starttime, endtime, setFieldValue, calendarDate]);

  return (
    <MDBox m={1} mt={2}>
      <MDBox
        display="flex"
        borderRadius="lg"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "rgb(79,145,205)" }}
        p={1}
      >
        <MDBox />
        <MDTypography variant="body2" fontWeight="bold" color="white">
          PHYSICAL ACTIVITY
        </MDTypography>
        <MDButton
          variant="text"
          size="medium"
          color="white"
          iconOnly
          onClick={() => closeInputPanel("physical")}
        >
          <CloseIcon />
        </MDButton>
      </MDBox>
      <MDBox m={1} mt={3} mb={0}>
        <Grid
          container
          rowSpacing={{ xs: 0 }}
          columnSpacing={{ xs: 1 }}
          alignItems="center"
        >
          <Grid item xs={4}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {environment.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={8}>
            <ToggleButtonGroup
              color="info"
              size="small"
              variant="contained"
              value={environmentValue}
              exclusive
              fullWidth={true}
              onChange={(event, value) => {
                value !== null && setEnvironmentValue(value);
                value !== null && setFieldValue(environment.name, value);
              }}
              aria-label="Platform"
            >
              <ToggleButton value="Indoor">Indoor</ToggleButton>
              <ToggleButton value="Outdoor">Outdoor</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {/* Error message - Environment */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={3}
              mr={1}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={environment.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>

          <Grid item xs={4}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {tummyTime.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={8}>
            <ToggleButtonGroup
              color="info"
              size="small"
              variant="contained"
              value={tummyTimeValue}
              exclusive
              fullWidth={true}
              onChange={(event, value) => {
                value !== null && setTummyTimeValue(value);
                value !== null && setFieldValue(tummyTime.name, value);
              }}
              aria-label="Platform"
            >
              <ToggleButton value="Yes">Yes</ToggleButton>
              <ToggleButton value="No">No</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {/* Error message - Tummy Time */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={3}
              mr={1}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={tummyTime.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>

          <Grid item xs={4}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {intensity.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              disablePortal
              id="autocomplete-intensity-levels"
              value={intensityValue}
              options={intensityLevels}
              getOptionLabel={(option) => (option?.label ? option.label : "")}
              size="small"
              onChange={(e, newValue) => {
                setIntensityValue(newValue);
                if (newValue !== null) {
                  setFieldValue(intensity.name, newValue.label);
                } else {
                  setFieldValue(intensity.name, "");
                }
              }}
              renderInput={({ inputProps, ...rest }) => (
                <TextField
                  {...rest}
                  label="Intensity"
                  inputProps={{ ...inputProps, readOnly: true }}
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
            />
          </Grid>
          {/* Error message - Intensity */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={3}
              mr={1}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={intensity.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>

          <Grid item xs={4}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {starttime.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={8}>
            <DatePicker
              format="HH:mm"
              block
              editable={false}
              appearance="default"
              placement="topEnd"
              size="sm"
              style={{ width: 600 }}
              value={startTimeValue instanceof Date ? startTimeValue : null}
              onChange={(date) => {
                if (date !== null) {
                  const newDate = new Date(
                    calendarDate.getFullYear(),
                    calendarDate.getMonth(),
                    calendarDate.getDate(),
                    date.getHours(),
                    date.getMinutes(),
                    0
                  );
                  setFieldValue(starttime.name, newDate.toISOString());
                  setStartTimeValue(newDate);
                  //setDisableBeforeHour(newDate.getHours());
                }
              }}
            />
          </Grid>
          {/* Error message - Start Time */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={3}
              mr={1}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={starttime.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>

          <Grid item xs={4}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {endtime.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={8}>
            <DatePicker
              format="dd/MM/yyyy HH:mm"
              block
              editable={false}
              appearance="default"
              placement="topEnd"
              size="sm"
              style={{ width: 600 }}
              value={endTimeValue instanceof Date ? endTimeValue : null}
              onChange={(date) => {
                if (date !== null) {
                  setFieldValue(endtime.name, date.toISOString());
                  setEndTimeValue(date);
                }
              }}
              shouldDisableDate={(date) =>
                isBefore(date, new Date(disableBeforeValue))
              }
              //hideHours={(hour) => hour < disableBeforeHour}
            />
          </Grid>
          {/* Error message - End Time */}
          <Grid item xs={12}>
            <MDBox
              mt={1}
              ml={3}
              mr={1}
              mb={1}
              display="flex"
              justifyContent="right"
            >
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={endtime.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default PhysicalDetails;
