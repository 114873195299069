import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import SubmissionProgress from "components/organisms/SubmissionProgress";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import TimeDetails from "pages/nudges/manage/components/TimeDetails";
import formTimeSetting from "pages/nudges/schemas/formTimeSetting";

import { AllowedTo } from "react-abac";
import { useNudgeSettingManager } from "pages/nudges/hooks/useNudgeSettingManager";

function SendNudgeSetting() {
  const { formId: formIdTimeSetting, formField: formFieldTimeSetting } =
    formTimeSetting;

  const { modeTitle, modePermission, response, submitUpdate } =
    useNudgeSettingManager();

  const handleSubmitTimeSetting = async (values, actions) => {
    submitUpdate(values);
  };

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />}>
        {response.isPending ? (
          <SubmissionProgress />
        ) : (
          <>
            {response.error && (
              <>
                {response.error?.name === "OperationInvalidError" && (
                  <InvalidOperation />
                )}
                {response.error?.name === "OperationIncompleteError" && (
                  <IncompleteOperation />
                )}
                <ErrorSnackbar
                  title={modeTitle}
                  dateTime=""
                  message={response.error?.message}
                  autoDismiss
                />
              </>
            )}
            {response.success && (
              <SuccessSnackbar
                title={modeTitle}
                dateTime=""
                message={response.success}
                autoDismiss
              />
            )}
            <MDBox m={1} mt={3} mb={0}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {response?.data !== null && (
                    <Card
                      id="nudge-seeting-information"
                      sx={{ overflow: "visible" }}
                    >
                      <Formik
                        enableReinitialize
                        initialValues={response.data}
                        validationSchema={Yup.object().shape({
                          timeISOstring: Yup.string().required(
                            "Please select the time to send nudges to participants."
                          ),
                          day: Yup.string().required(
                            "Please select day of the week to send nudges to participants."
                          ),
                        })}
                        onSubmit={handleSubmitTimeSetting}
                      >
                        {(formik) => (
                          <Form id={formIdTimeSetting} autoComplete="off">
                            <TimeDetails
                              mainForm={formik}
                              mainFormField={formFieldTimeSetting}
                              //closeInputPanel={closeInputPanel}
                              //calendarDate={calendarDate}
                            />
                            <MDBox m={1} mt={0} mb={2}>
                              <MDBox
                                width="100%"
                                display="flex"
                                justifyContent="space-between"
                              >
                                <MDBox />
                                <MDButton
                                  disabled={false}
                                  type="submit"
                                  variant="gradient"
                                  color="dark"
                                >
                                  Submit
                                </MDButton>
                              </MDBox>
                            </MDBox>
                          </Form>
                        )}
                      </Formik>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </MDBox>
          </>
        )}
      </AllowedTo>
    </DashboardLayout>
  );
}

export default SendNudgeSetting;
