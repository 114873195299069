const regulations = [
  {
    domain: "Regulation",
    subdomain: "Responsive Feeding",
  },
  {
    domain: "Regulation",
    subdomain: "Feeding Mode",
  },
];

const weaning = [
  {
    domain: "Weaning/Habituation",
    subdomain: "Weaning Age",
  },
  {
    domain: "Weaning/Habituation",
    subdomain: "Beverage",
  },
  {
    domain: "Weaning/Habituation",
    subdomain: "Balance/Variety",
  },
];

const habituation = [
  {
    domain: "Habituation",
    subdomain: "Family Food",
  },
  {
    domain: "Habituation",
    subdomain: "Time",
  },
  {
    domain: "Habituation",
    subdomain: "Sleep",
  },
  {
    domain: "Habituation",
    subdomain: "Activities",
  },
  {
    domain: "Habituation",
    subdomain: "Screen",
  },
];

const form = {
  formId: "qasurvey-form",
  formField: {
    Q_RR1: {
      name: "Q_RR1",
      label_0m_2m: "Do you think your baby is consuming enough feeds?",
      label_2m_4m: "Do you think your baby is consuming enough feeds?",
      label_4m_6m: "Do you think your baby is consuming enough feeds/food?",
      label_6m_9m: "Do you think your baby is consuming enough feeds/food?",
      label_9m_12m: "Do you think your baby is consuming enough feeds/food?",
      label_12m_15m: "Do you think your child is consuming enough feeds/food?",
      label_15m_23m: "Do you think your child is consuming enough feeds/food?",
      type: "text",
      domain: regulations[0].domain,
      subdomain: regulations[0].subdomain,
      response: "YES",
      advisory: "Advisory notes",
      advisory_0m_2m:
        "Babies  in the first few days often take 30-60 ml (1-2 oz) of breast milk or formula, 8-12 times a day. Between 1-2 months of age, they often take about 60-120 ml (2-4 oz) of milk every 2-4 hours per feed. Breastfed babies typically feed every 1-3 hours throughout the day and night. Babies will generally take what they need at each feeding and stop feeding  when they are full. If your baby is gaining weight and having 6-8 wet diapers per day, he/she is likely taking enough milk. If your baby has a specially prescribed formula to follow, do consult with your doctor/dietitian on the correct amount to feed every day.",
      advisory_2m_4m:
        "Babies at this age take about 90-150 ml (3-5 oz) of breast milk or formula, every 3-4 hours. Babies will generally take what they need at each feeding and stop feeding when they are full. Breastfed babies typically feed every 2-4 hours throughout the day and night. If your baby is gaining weight and having 6-8 wet diapers per day, he/she is likely taking enough milk. If your baby has a specially prescribed formula to follow, do consult with your doctor/dietitian on the correct amount to feed every day.",
      advisory_4m_6m:
        "Babies at this age take about 120-180 ml (4-6 oz) of breast milk or formula at each feeding, depending on how often they feed. Breastfed babies typically feed every 3 hours, although they may feed less frequently at night or even skip a feed. Babies will generally take what they need at each feeding and stop feeding when they are full. If your baby is gaining weight and having 6-8 wet diapers per day, he/she is likely taking enough milk. If your baby has a specially prescribed formula to follow, do consult with your doctor/dietitian on the correct amount to feed every day.",
      advisory_6m_9m:
        "Babies at this age take about 150-210 ml (5-7 oz) of breast milk or formula, 4-5 times a day, or a total of 750-900ml of milk a day. This varies greatly when babies are given solid foods, which can decrease how much milk they drink. Do not force your baby to finish the bottle if he/she appears to be full. If your baby is gaining weight and having 6-8 wet diapers per day, he/she is likely taking enough milk and food. If your baby has a specially prescribed formula to follow, do consult with your doctor/dietitian on the correct amount to feed every day.",
      advisory_9m_12m:
        "Babies at this age take about 500-700 ml of milk per day. Breastfed babies will be fed for 5-20 minutes, 3-4 times a day. This is complemented by a variety of solid foods 3-4 times a day. Do not force your baby to finish the bottle or foods if he/she appears to be full. If your baby is gaining weight and having 5-7 wet diapers per day, he/she is likely taking enough milk. If your baby has a specially prescribed formula to follow, do consult with your doctor/dietitian on the correct amount to feed every day.",
      advisory_12m_15m:
        "Children at this age eat more solid foods and drink milk less frequently. Frequent and high milk intake can affect the appetite of the child for mealtime. Keep to about 500-700 ml of milk daily. You can continue breastfeeding or consider switching from formula to full cream cow's milk. You may want to start by replacing one milk feed with cow’s milk to help your child transition. Children eat 3 meals and 1-2 snacks per day; this varies depending on their feeding and sleep schedules. Do not force your child to finish the foods or bottle if he/she appears to be full. If your child is gaining weight and having 4-6 wet diapers per day, he/she is likely taking enough liquid and solid foods.",
      advisory_15m_23m:
        "Children at this age eat 3 meals and 1-2 snacks, and drink about 500-700 ml of milk per day. It is important not to exceed the range; too much milk may lead to insufficient solid foods to meet nutrition needs. Keep in mind that children often eat different amounts of food depending on how hungry they are at mealtimes. Do not force your child to finish the foods or bottle if he/she appears to be full. If your child is growing as he/she should, it means that he/she is eating enough.",
      minAge: 0,
      maxAge: 24,
      errorMsg: "Please answer the question above.", //"Question 1 is required,",
      invalidMsg: "Answer for Q_RR1 is required.",
    },
    Q_RR2: {
      name: "Q_RR2",
      label_0m_2m: "Does your baby let you know when he/she is hungry?",
      label_2m_4m: "Does your baby let you know when he/she is hungry?",
      label_4m_6m: "Does your baby let you know when he/she is hungry?",
      label_6m_9m: "Does your baby let you know when he/she is hungry?",
      label_9m_12m: "Does your baby let you know when he/she is hungry?",
      label_12m_15m: "Does your child let you know when he/she is hungry?",
      label_15m_23m: "Does your child let you know when he/she is hungry?",
      type: "text",
      domain: regulations[0].domain,
      subdomain: regulations[0].subdomain,
      response: "YES",
      advisory: "Advisory notes",
      advisory_0m_2m:
        "Babies show that they are hungry about every 1-3 hours during the first month, and 2-4 hours during the second month. Crying is often a late sign of hunger. Look for other signs of hunger, so you can put your child to the breast or bottle while he/she is still calm. Early signs of hunger include putting hands to mouth, turning head towards breast or bottle, puckering, smacking or licking lips, or clenching their fist. Some babies may be sleepy and not interested in feeding . Babies need to eat about every 2-4 hours to get enough nutrition to grow. You may need to wake your baby to feed if he/she keeps sleeping. You can try massaging or stimulating your baby, undressing them or changing his/her diaper to help wake him/her.",
      advisory_2m_4m:
        "Babies show hunger in many ways. They may suck on their hands or fingers, make sucking noises with tongues and lips, move their mouth around to look for a breast or bottle, become more alert and active, clench their fists, or open and close their mouths. Watch for and respond early for your baby’s hunger signs before crying. Crying may be a late hunger sign. Once baby is crying, they may be more distressed and can be harder to feed. Babies might feed every 3-4 hours at this age and may sleep for stretches of up to 5-6 hours at night.",
      advisory_4m_6m:
        "Babies show hunger in many ways. They may suck on their hands or fingers, make sucking noises with tongues and lips, move their mouth around to look for a breast or bottle, become more alert and active, clench their fists, or open and close their mouths. Crying may be a late hunger sign. Once baby is crying, they may be more distressed and can be harder to feed. Babies may feed every 3-4 hours in the day at this age. They may sleep for stretches of up to 7-8 hours at night.",
      advisory_6m_9m:
        "Babies show hunger in many ways. They may suck on their hands or fingers, move their mouth around to look for a breast or bottle, get more irritable and fuss more. They open their mouth for the breast, bottle or spoon.",
      advisory_9m_12m:
        "Babies can show hunger in many ways. They put their fingers in their mouth, reach for or point to foods, open their mouths when offered a spoon or food, get excited when seeing food, use hand motions or make sounds to let you know they are still hungry. Some may suck on something like toys.",
      advisory_12m_15m:
        "Children can show hunger in many ways. They may still fuss as they did when they were younger. But, they also use hand, eye and body movements. For example, they look at the fridge or reach out for a food they want. Some may be able to use words or sounds, like ‘mum mum’.",
      advisory_15m_23m:
        "Children can show hunger in many ways. They may still fuss as they did when they were younger. But, they also use hand, eye and body movements. For example, they take adults to the fridge and point to the food that they want. They also climb up high chair to reach the food. Some say words ‘eat’, ‘more’ or the name of the favourite food.",
      minAge: 0,
      maxAge: 24,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_RR2 is required.",
    },
    Q_RR3: {
      name: "Q_RR3",
      label_0m_2m: "Does your baby let you know when he/she is full?",
      label_2m_4m: "Does your baby let you know when he/she is full?",
      label_4m_6m: "Does your baby let you know when he/she is full?",
      label_6m_9m: "Does your baby let you know when he/she is full?",
      label_9m_12m: "Does your baby let you know when he/she is full?",
      label_12m_15m: "Does your child let you know when he/she is full?",
      label_15m_23m: "Does your child let you know when he/she is full?",
      type: "text",
      domain: regulations[0].domain,
      subdomain: regulations[0].subdomain,
      response: "YES",
      advisory: "Advisory notes",
      advisory_0m_2m:
        "Babies show they are full in many ways. They stop sucking, spit out the nipple or bottle, relax hand, rest happily in your arm or fall asleep. Your baby should show you at least one way when he/she is full. If your baby is showing signs of being full, avoid force-feeding as this can affect their natural hunger and satiety cues.",
      advisory_2m_4m:
        "Babies show they are full in many ways. They stop sucking, spit out the nipple or bottle, relax hands, rest happily in your arm or fall asleep. Your baby should show you at least one way when he/she is full. If your baby is showing signs of being full, avoid force-feeding as this can affect their natural hunger and satiety cues.",
      advisory_4m_6m:
        "Babies show they are full in many ways. They stop sucking, spit out the nipple or bottle, relax hands, rest happily in your arm or fall asleep. Your baby should show you at least one way when he/she is full. If your baby is showing signs of being full, avoid force-feeding as this can affect their natural hunger and satiety cues.",
      advisory_6m_9m:
        "Babies show they are full in many ways. They stop sucking, spit out the nipple or bottle, relax hands, smile or rest happily in your arm.  They may turn their head or push the spoon away, close their mouth when food is offered, use hand motions or makes sounds to let you know they are full. Some may push or spit the food out of their mouth. Many stop eating when they are full. Let your baby decide how much he/she wants. Your baby does not need to finish a bottle or all the food he/ she is offered.",
      advisory_9m_12m:
        "Babies show they are full in many ways. They slow down and are less excited about the food. They may close their mouth or turn away. When this happens, give your baby a break by offering a drink or another food. Then, offer the first food again to see if he/she is really done or simply needs a break. Let your baby decide how much he/she wants. Your baby does not need to finish a bottle or all food served/prepared.",
      advisory_12m_15m:
        "Children let you know when they have eaten enough. They slow down and are less excited about the food. They may look around or play with their food. Many will shake their head telling you ‘no’. When this happens, give your child a break by offering a drink or another food. Then, offer the first food again to see if he/she is really done or simply needs a break. Let your child decide how much he/she wants. Your child does not need to finish a bottle or all food in the jar or on the plate.",
      advisory_15m_23m:
        "Children let you know when they have eaten enough. Since many of them feed themselves, they may put down their spoon or play with their bowl. If they are in high chair, they may use gesture to indicate that they want to be taken out of it. Some may close their mouth or turn away when offered with food. Because children vary in how much food they eat, watch your child and listen to him/her. He/she will show you his/her meal is done after eating different amounts at different times. Let your child decide how much he/she wants. Your child does not need to finish a bottle or all food in the jar or on the plate.",
      minAge: 0,
      maxAge: 24,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_RR3 is required.",
    },
    Q_RR4: {
      name: "Q_RR4",
      label_0m_2m: "Does your baby enjoy feeding?",
      label_2m_4m: "Does your baby enjoy feeding?",
      label_4m_6m: "Does your baby enjoy feeding?",
      label_6m_9m: "Does your baby enjoy feeding?",
      label_9m_12m: "Does your baby enjoy feeding?",
      label_12m_15m: "Does your child enjoy mealtime?",
      label_15m_23m: "Does your child enjoy mealtime?",
      type: "text",
      domain: regulations[0].domain,
      subdomain: regulations[0].subdomain,
      response: "YES",
      advisory: "Advisory notes",
      advisory_0m_2m:
        "Babies should be calm and engaged during feeds, and feel full and satisfied at the end of a feed . If your baby is often upset during feeds, check your latch or baby position while feeding. If breastfeeding, ensure baby’s mouth is wide open and covering areola, and stays attached with a good latch throughout the feed. If bottle feeding, ensure that the milk temperature is not too hot, or the flow is not too fast or too slow. Hold your baby in your arms, where the baby is well supported with the body and head in line.",
      advisory_2m_4m:
        "Babies should be calm and engaged during feeds, and feel full and satisfied at the end of a meal. If your baby is often upset during feeds, check your latch or baby position while feeding. If breastfeeding, ensure baby’s mouth is wide open and covering areola, and stays attached with a good latch throughout the feed. If bottle feeding, ensure that the milk temperature is not too hot, or the flow is not too fast or too slow. Hold your baby in your arms, where the baby is well supported with the body and head in line.",
      advisory_4m_6m:
        "Babies may show excitement with body movement when they see the breast or bottle. They are able to stay calm and alert during feedings. They may look and smile at the person feeding them. They open their mouth to let you know they want the breast or bottle, and comfortably pace the meal. Avoid force-feeding when your baby is upset. If your baby is often upset during feeds, ensure that you are not force-feeding them. If bottle feeding, ensure that the milk temperature is not too hot, or the flow is not too fast or too slow. Ensure that they are well-supported during feeding . If feeding solids, ensure that they are upright with adequate back and side support.",
      advisory_6m_9m:
        "Babies may show excitement and smile when they see the breast, bottle or spoon. They engage well with the caregivers during meals. They open their mouth to let you know they want the breast, bottle or spoon, and comfortably pace the meal. Avoid force-feeding when your baby is upset. If giving the bottle, ensure that the milk or food temperature is not too hot, or the flow is not too fast or too slow. If feeding solids, ensure that the foods are of appropriate textures. Ensure that they are well-supported during feeding. If feeding solids, ensure that they are seated upright with appropriate support.",
      advisory_9m_12m:
        "Babies may show excitement and smile when they see the breast, bottle, spoon or food. They can also be interactive during the meals. They may look at the caregivers and the food presented during the meals",
      advisory_12m_15m:
        "Children at this age may enjoy their mealtimes in various ways. They readily go into the meal and may actively participate in the mealtime experience by setting the table, making choices of the food they want or self-feeding. They may reach for the cup, spoon or bowl, and try to put the food into their mouth. Many children love using spoon to bring food into their mouth.",
      advisory_15m_23m:
        "Children at this age may enjoy their mealtimes in various ways.  They readily go into the meal and may actively participate in the mealtime experience by setting the table, making choices of the food they want or self-feeding.",
      minAge: 0,
      maxAge: 24,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_RR4 is required.",
    },
    Q_RF1a: {
      name: "Q_RF1a",
      label_0m_2m: `Do you have to do anything special to help your baby feed, such as giving extra rest breaks, use of distractions or special utensils during feeding?`,
      label_2m_4m: `Do you have to do anything special to help your baby feed, such as giving extra rest breaks, use of distractions or special utensils during feeding?`,
      label_4m_6m: `Do you have to do anything special to help your baby feed/eat, such as giving extra rest breaks, use of distractions or special utensils during feeding?`,
      label_6m_9m: `Do you have to do anything special to help your baby feed/eat, such as giving extra rest breaks, use of distractions or special utensils during feeding?`,
      label_9m_12m: `Do you have to do anything special to help your baby feed/eat, such as giving extra rest breaks, use of distractions or special utensils during feeding?`,
      label_12m_15m: `Do you have to do anything special to help your child feed/eat, such as giving extra rest breaks, use of distractions or special utensils during feeding?`,
      label_15m_23m: `Do you have to do anything special to help your child feed/eat, such as giving extra rest breaks, use of distractions or special utensils during feeding?`,
      type: "text",
      domain: regulations[1].domain,
      subdomain: regulations[1].subdomain,
      response: "NO",
      advisory: "Advisory notes",
      advisory_0m_2m:
        "Sucking from a breast or bottle is reflexive for babies at this age. They do not typically need any special help to feed from a breast or bottle. It is important to follow the cues that your baby is showing during feeding. You can provide breaks during the feeding as required. Avoid jiggling the nipple in your baby’s mouth to speed up the feeding. If you find that your baby is having difficulty feeding with frequent coughing or choking, or is taking too long to feed (i.e. 1 hour), talk with your doctor.",
      advisory_2m_4m:
        "Babies have learnt to suck effectively from a breast or bottle at this age. They do not typically need any special help to feed from a breast or bottle. It is important to follow your baby’s cues during feeding and provide breaks during the feeding as required. Avoid jiggling the nipple in your baby’s mouth to speed up the feeding. If you find that your baby is having difficulty feeding with frequent coughing or choking, or is taking too long to feed (i.e. 1 hour), talk with your doctor.",
      advisory_4m_6m:
        "Babies have learnt to suck effectively and efficiently from a breast or bottle at this age. They do not typically need any special help to feed from a breast or bottle. It is important to follow their cues during feeding. If starting solids, it is typical and safe to offer them pureed or mashed foods from a spoon. If your baby is having difficulty with feeding, e.g. with frequent choking or coughing, talk with your doctor.",
      advisory_6m_9m:
        "Babies do not typically need any special help to eat. They can join you at the meal table and be engaged with feeding- related items (e.g. spoons, bowls) and/or allowed to self-feed. At this age, they may also be learning different feeding skills such as chewing. Babies usually do not need to be distracted or given toys or screens during mealtime. You can interact with your baby and demonstrate appropriate eating skills during mealtimes. If you have to often use distractions or work hard just to get your baby to eat, talk with your doctor.",
      advisory_9m_12m:
        "Babies do not typically need any special help to eat. They can join you at the meal table and be engaged with feeding -related items (e.g. spoons, bowls) and/or allowed to self-feed. Some need help while learning self-feeding and may need their food mashed or cut into small pieces. This is normal. Babies usually do not need to be distracted or given toys or screens during mealtime. The meal should be interesting enough as it is with the food and the interaction with parents. If you have to often use distractions or work hard just to get your baby to eat, talk with your doctor.",
      advisory_12m_15m:
        "Children usually do not need any extra special help to eat. Some need help while learning self-feeding or may require their food to be cut or mashed into small pieces. This is normal. Eating is a  social activity. Children can be adequately engaged at mealtimes for a positive experience. They can be allowed to self-feed and interact with others at the meal. They typically do not need to be distracted or given toys or screens during mealtime. If you have to often use distractions or work hard just to get your child to eat, talk with your doctor.",
      advisory_15m_23m:
        "Children usually do not need any extra special help to eat. Some need help while learning self-feeding or may require their food to be cut or mashed into small pieces. This is normal. Eating is a social activity. Children can be adequately engaged at mealtimes experience. They can be allowed to self-feed and interact with others at the meal. They typically do not need to be distracted or given toys or screens during mealtime. If you have to often use distractions or work hard just to get your child to eat, talk with your doctor.",
      minAge: 0,
      maxAge: 24,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_RF1a is required.",
    },
    Q_RF1b: {
      name: "Q_RF1b",
      label_0m_2m: `If yes, please specify: Screen/Special utensil/Toy/Others: `,
      label_2m_4m: `If yes, please specify: Screen/Special utensil/Toy/Others:`,
      label_4m_6m: `If yes, please specify: Screen/Special utensil/Toy/Others:`,
      label_6m_9m: `If yes, please specify: Screen/Special utensil/Toy/Others:`,
      label_9m_12m: `If yes, please specify: Screen/Special utensil/Toy/Others:`,
      label_12m_15m: `If yes, please specify: Screen/Special utensil/Toy/Others:`,
      label_15m_23m: `If yes, please specify: Screen/Special utensil/Toy/Others:`,
      type: "text",
      domain: regulations[1].domain,
      subdomain: regulations[1].subdomain,
      minAge: 0,
      maxAge: 24,
      errorMsg: "Please specify.",
      invalidMsg: "Answer for Q_RF1b is required.",
    },
    Q_RF2: {
      name: "Q_RF2",
      label_0m_2m: ``,
      label_2m_4m: ``,
      label_4m_6m: ``,
      label_6m_9m: ``,
      label_9m_12m: `Can your baby feed him/herself?`,
      label_12m_15m: `Can your child feed him/herself?`,
      label_15m_23m: `Can your child feed him/herself?`,
      type: "text",
      domain: regulations[1].domain,
      subdomain: regulations[1].subdomain,
      response: "YES",
      advisory: "Advisory notes",
      advisory_0m_2m: "",
      advisory_2m_4m: "",
      advisory_4m_6m: "",
      advisory_6m_9m: "",
      advisory_9m_12m:
        "Most babies at this age like to feed themselves with fingers and/or spoon. Majority can pick up small, cut-up pieces of food. Encouraging self-feeding at this stage is important for your baby to develop fine motor skills and learn about the different types of food. Ensure your baby is supported well when sitting at the meal table. This can reduce the risk of choking and help your child engage adequately with the meal",
      advisory_12m_15m:
        "Children at this age usually like to pick up small pieces of food and bring them to their mouth. They are also learning to use spoon for self-feeding. At this age, it’s time to start to wean off the milk bottle and switch to a cup. Some also often reach for an adult’s cup  and that’s a good way to introduce them an open cup. Finger feeding, drinking from a cup, and using a spoon are part of your child’s developmental milestones.",
      advisory_15m_23m:
        "Children at this age are good at eating with their fingers and enjoy doing it. They can pick up food pieces between their thumb and pointer finger and bring them to their mouth. They can also drink from an open cup or straw. Encouraging your child to eat or drink on his/her own is important to develop fine motor skills.",
      minAge: 9,
      maxAge: 24,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_RF2 is required.",
    },
    Q_WW1: {
      name: "Q_WW1",
      label_0m_2m: `When did you start feeding your baby food other than milk?`,
      label_2m_4m: `When did you start feeding your baby food other than milk?`,
      label_4m_6m: `When did you start feeding your baby food other than milk?`,
      label_6m_9m: `When did you start feeding your baby food other than milk?`,
      label_9m_12m: ``,
      label_12m_15m: ``,
      label_15m_23m: ``,
      type: "text",
      domain: weaning[0].domain,
      subdomain: weaning[0].subdomain,
      response: "4-6m",
      response_0m_4m: "HAVEN'T STARTED",
      response_4m_9m: "4-9 MONTHS",
      advisory: "Advisory notes",
      advisory_0m_2m:
        "Do not feed your baby with any solid food. Your baby is not ready yet to receive those solid items.",
      advisory_2m_4m:
        "Do not feed your baby with any solid food. Your baby is not ready yet to receive those solid items. There is also a risk of increased weight gain and obesity for babies  who are introduced solid food at this age.",
      advisory_4m_6m:
        "If your baby i) can sit up with support; ii) can control his/her head and neck steadily; iii) shows interest in food; iv) opens his/her mouth when offered a spoon, he/she may be ready for solids. When starting solids, you can offer purees once a day at a time when your baby is not too tired or hungry. Offer only one new food at a time, so that you can watch for any signs of allergy.",
      advisory_6m_9m:
        "It’s time to introduce your baby to solid foods. You can start with any purees such as iron-fortified cereal mixed with breast milk or formula, or pureed vegetables/ fruits/ meats/ eggs . Start with 1 time a day and offer at a time when the baby is not too tired or hungry. Offer only one new food at a time, so that you can watch for allergies. If your baby is keen for solids, gradually increase the quantity with the aim to gradually replace a milk feed.  Follow your baby’s cues - your baby will open his/ her mouth if he/she is keen for more, and close his/her mouth, turn his /her head away or cry if he/she had enough.",
      advisory_9m_12m: "",
      advisory_12m_15m: "",
      advisory_15m_23m: "",
      minAge: 0,
      maxAge: 9,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_WW1 is required.",
    },
    Q_WBe1: {
      name: "Q_WBe1",
      label_0m_2m: `Do you feed your baby with drinks other than breast milk or formula milk?`,
      label_2m_4m: `Do you feed your baby with drinks other than breast milk or formula milk?`,
      label_4m_6m: `Do you feed your baby with drinks other than breast milk or formula milk?`,
      label_6m_9m: `Do you feed your baby with drinks other than breast milk, formula milk or water?`,
      label_9m_12m: `Do you feed your baby with drinks other than breast milk, formula milk or water?`,
      label_12m_15m: `Do you feed your child with drinks other than breast milk, formula milk, cow’s milk (fresh/UHT), or water?`,
      label_15m_23m: `Do you feed your child with drinks other than breast milk, formula milk, cow’s milk (fresh/UHT), or water?`,
      type: "text",
      domain: weaning[1].domain,
      subdomain: weaning[1].subdomain,
      response: "NO",
      advisory: "Advisory notes",
      advisory_0m_2m:
        "Babies in the first 6 months after birth do not need water or other liquids in addition to breast milk or formula milk. Adding extra water to formula or giving drinks such as juices reduces the amount of nutrients to be received by a baby. Excessive water also disturbs electrolyte and mineral balances, which can lead to major health problems including seizures.",
      advisory_2m_4m:
        "Babies in the first 6 months after birth do not need water or other liquids in addition to breast milk or formula milk. Adding extra water to formula or giving drinks such as juices reduces the amount of nutrients to be received by a baby. Excessive water also disturbs electrolyte and mineral balances which can lead to major health problems including seizures.",
      advisory_4m_6m:
        "Babies in the first 6 months after birth do not need water or other liquids in addition to breast milk or formula milk. Giving other drinks such as juices reduces the amount of nutrients that baby receives from breast or formula milk. However, if your baby has started solids, small amounts of water (few teaspoons) with meals are acceptable.",
      advisory_6m_9m:
        "Provide only your baby with breast milk, formula, or water before 1 year of age. Avoid cow’s milk before 12 months old which may put your baby at risk for intestinal bleeding. It also has too many proteins and minerals for your baby’s kidneys to handle and does not have the right amount of nutrients your baby needs. Avoid sugar-sweetened drinks and juices.",
      advisory_9m_12m:
        "Provide your baby with only breast milk, formula or water. Avoid cow’s milk before 12 months old as the protein and minerals may be too much for your baby’s kidneys to handle. It also does not have the right nutrients that babies need. Avoid sugar-sweetened drinks and fruit juices (including baby-friendly juices) as they contain very few nutrients. Fluids can now be offered by different modes (such as straw cup or open cup).",
      advisory_12m_15m:
        "Children younger than 24 months old should avoid added sugars from sugar-sweetened drinks including fruit juices . Sugar-sweetened drinks (such as soft drinks, flavoured milks, sports drinks, flavoured water with sugar, and juice drinks) contain added sugars without much nutrients. Fruit juices are also discouraged as they can be a concentrated source of sugars, which can be easily consumed, and they do not provide the same nutrients that whole fruits contain. Provide only your baby with breast milk, formula, plain cow’s milk or water.",
      advisory_15m_23m:
        "Children younger than 24 months old should avoid added sugars from sugar-sweetened drinks including fruit juices . Sugar-sweetened drinks (such as soft drinks, flavoured milks, sports drinks, flavoured water with sugar, and juice drinks) contain added sugars without much nutrients. Fruit juices are also discouraged as they can be a concentrated source of sugars, which can be easily consumed, and they do not provide the same nutrients that whole fruits contain. Provide only your baby with breast milk, formula, plain cow’s milk or water.",
      minAge: 0,
      maxAge: 24,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_WBe1 is required.",
    },
    Q_WBa1: {
      name: "Q_WBa1",
      label_0m_2m: ``,
      label_2m_4m: ``,
      label_4m_6m: ``,
      label_6m_9m: ``,
      label_9m_12m: `Does your baby eat foods from all different food groups: grains, protein foods, fruits, vegetables? (Protein foods include meats, poultry, fish, eggs, tofu, legumes, beans)`,
      label_12m_15m: `Does your child eat foods from all different food groups: grains, protein foods, fruits, vegetables? (Protein foods include meats, poultry, fish, eggs, tofu, legumes, beans)`,
      label_15m_23m: `Does your child eat foods from all different food groups: grains, protein foods, fruits, vegetables? (Protein foods include meats, poultry, fish, eggs, tofu, legumes, beans)`,
      caption:
        "(Protein foods include meats, poultry, fish, eggs, tofu, legumes, beans)",
      type: "text",
      domain: weaning[2].domain,
      subdomain: weaning[2].subdomain,
      response: "YES",
      advisory: "Advisory notes",
      advisory_0m_2m: "",
      advisory_2m_4m: "",
      advisory_4m_6m: "",
      advisory_6m_9m: "",
      advisory_9m_12m:
        "Healthy eating is all about balance and variety, with intake of foods from all different food groups to provide the essential vitamins and minerals for growth. Giving your baby different food types helps him/her to learn to accept and like a variety of foods.",
      advisory_12m_15m:
        "Healthy eating is all about balance and variety, with intake of foods from all different food groups to provide the essential vitamins and minerals for growth. Giving your child different food types helps him/her to learn to accept and like a variety of foods. Small amounts of seasonings are allowed at this age.",
      advisory_15m_23m:
        "Healthy eating is all about balance and variety, with intake of foods from all different food groups to provide the essential vitamins and minerals for growth.  Giving your child different food types helps him/her to learn to accept and like a variety of foods. Children at this age are also encouraged to eat the same foods as the rest of the family.",
      minAge: 9,
      maxAge: 24,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_WBa1 is required.",
    },
    Q_WBa2: {
      name: "Q_WBa2",
      label_0m_2m: ``,
      label_2m_4m: ``,
      label_4m_6m: ``,
      label_6m_9m: ``,
      label_9m_12m: `Does your baby eat foods with different flavours?`,
      label_12m_15m: `Does your child eat foods with different flavours?`,
      label_15m_23m: `Does your child eat foods with different flavours?`,
      type: "text",
      domain: weaning[2].domain,
      subdomain: weaning[2].subdomain,
      response: "YES",
      advisory: "Advisory notes",
      advisory_0m_2m: "",
      advisory_2m_4m: "",
      advisory_4m_6m: "",
      advisory_6m_9m: "",
      advisory_9m_12m:
        "Some babies enjoy new flavour and new foods right away. Many breast-fed babies who taste different flavours through their mother’s milk accept new flavours faster than babies who drink formula. You can offer different flavours through the use of herbs and spices when cooking or varying the cooking methods (e.g. steam, pan fry, bake, grill). Smile and encourage your baby while giving new foods. Many babies may need several tastes of food before they begin to accept it. It can take up to 20 times for your child to accept it. Keep offering but it is ok if they don’t seem to like it.",
      advisory_12m_15m:
        "Children at this age can enjoy the same foods as the family and eat a variety of food with different flavours. Encourage foods  from all food groups – fruits, vegetables, cereals, breads and meats, cheeses or yogurts. They also can enjoy foods cooked in different ways (e.g steam, pan-fry, stir- fry, bake, grill) and have foods with light seasonings. Many often like fruits that are sweet more than vegetables that might be bitter, but it is still important to encourage intake of both. If your child often gags when he/she tries a new food, or if he/she will eat only very few foods, talk to a doctor or dietitian. Keep in mind that some children need to have a new food offered up to 20 times before they will eat it, as they have to get used to both the way the food tastes and the way it feels.",
      advisory_15m_23m:
        "Most children at this age eat a variety of food with different flavours. They take tastes from all food groups – fruits, vegetables, cereals, breads and meats, cheeses or yogurts. Many often like fruits that are sweet more than vegetables that might be bitter, but it is still important to encourage intake of both. If your child often gags when he/she tries a new food, or if he/she will eat only very few foods, talk to a doctor or dietitian. Keep in mind that some children need to have a new food offered up to 20 times before they will eat it, as they have to get used to both the way the food tastes and the way it feels.",
      minAge: 9,
      maxAge: 24,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_WBa2 is required.",
    },
    Q_WBa3: {
      name: "Q_WBa3",
      label_0m_2m: ``,
      label_2m_4m: ``,
      label_4m_6m: ``,
      label_6m_9m: `Does your baby eat foods with added sugar/salt/seasoning?`,
      label_9m_12m: `Does your baby eat foods with added sugar/salt/seasoning?`,
      label_12m_15m: ``,
      label_15m_23m: ``,
      type: "text",
      domain: weaning[2].domain,
      subdomain: weaning[2].subdomain,
      response: "NO",
      advisory: "Advisory notes",
      advisory_0m_2m: "",
      advisory_2m_4m: "",
      advisory_4m_6m: "",
      advisory_6m_9m:
        "There is no need to add sugar, salt or seasoning to your baby’s food.",
      advisory_9m_12m:
        "There is no need to add sugar, salt or seasoning to your baby’s food.",
      advisory_12m_15m: "",
      advisory_15m_23m: "",
      minAge: 6,
      maxAge: 12,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_WBa2 is required.",
    },
    Q_WBa4: {
      name: "Q_WBa4",
      label_0m_2m: ``,
      label_2m_4m: ``,
      label_4m_6m: ``,
      label_6m_9m: ``,
      label_9m_12m: `Does your baby eat a variety of food textures (pureed /mashed /minced /crunchy /soft)?`,
      label_12m_15m: `Does your child eat a variety of food textures (pureed /mashed /minced / crunchy /soft)?`,
      label_15m_23m: `Does your child eat a variety of food textures (pureed /mashed /minced / crunchy /soft)?`,
      type: "text",
      domain: weaning[2].domain,
      subdomain: weaning[2].subdomain,
      response: "YES",
      advisory: "Advisory notes",
      advisory_0m_2m: "",
      advisory_2m_4m: "",
      advisory_4m_6m: "",
      advisory_6m_9m: "",
      advisory_9m_12m:
        "Your baby should be able to accept mashed or minced textures at this age, and small finger foods such as soft cut fruit or steamed vegetable cubes. Start offering small finger foods/baby biscuits to your baby to expose them to more solid textures. You may also gradually introduce small soft lumps to the puree, e.g. by blending for a shorter time. If your child is unable to eat a variety of food textures, speak to your doctor.",
      advisory_12m_15m:
        "Most children at this age eat a variety of foods that have different textures, similar to adults. This includes mashed food (e.g. whipped potato),  soft foods (e.g. noodles, rice), crunchy foods (e.g. apple slices) and chewy foods (e.g. small pieces of meat or vegetables). If your child is unable to eat a variety of food textures, speak to your doctor.",
      advisory_15m_23m:
        "Most children at this age eat a variety of foods that have different textures, similar to adults. This includes mashed food (e.g. whipped potato),  soft foods (e.g. noodles, rice), crunchy foods (e.g. apple slices) and chewy foods (e.g. small pieces of meat or vegetables). If your child is unable to eat a variety of food textures, speak to your doctor.",
      minAge: 9,
      maxAge: 24,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_WBa4 is required.",
    },
    Q_HF1: {
      name: "Q_HF1",
      label_0m_2m: ``,
      label_2m_4m: ``,
      label_4m_6m: ``,
      label_6m_9m: ``,
      label_9m_12m: ``,
      label_12m_15m: `Do you give your child the same food as what the family eats?`,
      label_15m_23m: `Do you give your child the same food as what the family eats?`,
      type: "text",
      domain: habituation[0].domain,
      subdomain: habituation[0].subdomain,
      response: "YES",
      advisory: "Advisory notes",
      advisory_0m_2m: "",
      advisory_2m_4m: "",
      advisory_4m_6m: "",
      advisory_6m_9m: "",
      advisory_9m_12m: "",
      advisory_12m_15m:
        "Children at this age can enjoy the same foods as the family. At 1 year old, your child is learning to eat on her own. Many children at this age look at what their parents eat and often reach for their food. It is encouraged to give your child the same food as the rest of the family eats. You can cut up the food into small pieces to avoid choking hazards . Keep in mind that this is when a child’s taste preferences and food habits are formed, so healthy family eating habits are encouraged.",
      advisory_15m_23m:
        "Many children at this age look at what their parents eat and often reach for their food. It is encouraged to give your child the same food as the rest of the family eats.  Sitting down as a family for mealtime can teach your child how to eat and helps your child develop social skills.",
      minAge: 12,
      maxAge: 23,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_HF1 is required.",
    },
    Q_HT1: {
      name: "Q_HT1",
      label_0m_2m: `How long does your baby eat per feeding?`,
      label_2m_4m: `How long does your baby eat per feeding?`,
      label_4m_6m: `How long does your baby eat per feeding?`,
      label_6m_9m: `How long does your baby eat per feeding?`,
      label_9m_12m: `How long does your baby eat per feeding?`,
      label_12m_15m: `How long does your child eat per meal?`,
      label_15m_23m: `How long does your child eat per meal?`,
      type: "text",
      domain: habituation[1].domain,
      subdomain: habituation[1].subdomain,
      response: "5-30 MINUTES",
      responseLowerBand: "5",
      responseUpperBand: "30",
      advisory: "Advisory notes",
      advisory_0m_2m:
        "Babies usually take 15-30 minutes for each breastfeeding or bottlefeeding session.  Those who take less than 5 minutes may not be receiving enough milk. They may be too tired or lack the skills to take more. Babies who take more than 30 minutes may be experiencing difficulties sucking from the breast or bottle.",
      advisory_2m_4m:
        "Babies usually take 15-30 minutes for each feed. This happens whether they are breastfed or bottle-fed. Those who take less than 5 minutes may not be eating enough. They may be too tired or lack the skill to take more. Babies who take more than 30 minutes are most likely having hard time to suck.",
      advisory_4m_6m:
        "Babies usually take 15-30 minutes for each feed. This happens whether they are breastfed or bottle-fed. Those who take less than 5 minutes may not be eating enough. They may be too tired or lack the skill to take more. Babies who take more than 30 minutes are most likely having hard time to suck.",
      advisory_6m_9m:
        "Babies usually take 15-30 minutes for each milk feed. This happens whether they are breastfed or bottle-fed. Those who take less than 5 minutes may not be eating enough. They may be too tired or lack the skill to take more. Babies at this age may not be able to sit upright sustain their attention for more than 10-15 minutes. Each solid mealtime may vary between 5-20 minutes depending on how ready and engaged your baby is. Babies who take more than 30 minutes are most likely having hard time to suck, chew, swallow or being distracted. They may also dislike the food, feel full and do not want to eat.",
      advisory_9m_12m:
        "Babies usually take 15-30 minutes for a feed/meal. This happens whether they are breastfed or bottle-fed. Babies who take more than 30 minutes are most likely having hard time to suck, chew, swallow or being distracted. They may also dislike the food, feel full and do not want to eat.",
      advisory_12m_15m:
        "Mealtimes are often about 15-30 minutes. Those who take less than 5 minutes are most likely not eating enough. They may be too tired or lack the skill to take more. Children who take more than 30 minutes are most likely having hard time to chew or being distracted. They may also dislike the food, feel full and do not want to eat.  Aim to limit meal durations to 30 minutes by minimizing distractions (TV, toys).",
      advisory_15m_23m:
        "Mealtimes are often about 15-30 minutes. Those who take less than 5 minutes are most likely not eating enough. They may be too tired or lack the skill to take more. Children who take more than 30 minutes are most likely having hard time to chew or being distracted. They may also dislike the food, feel full and do not want to eat. Aim to limit meal durations to 30 minutes by minimizing distractions (TV, toys).",
      minAge: 0,
      maxAge: 23,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_HT1 is required.",
    },
    Q_HT2: {
      name: "Q_HT2",
      label_0m_2m: ``,
      label_2m_4m: ``,
      label_4m_6m: ``,
      label_6m_9m: ``,
      label_9m_12m: `How many times does your baby eat per day, excluding milk feeding?`,
      label_12m_15m: `How many times does your child eat per day, excluding milk feeding?`,
      label_15m_23m: `How many times does your child eat per day, excluding milk feeding?`,
      type: "text",
      domain: habituation[1].domain,
      subdomain: habituation[1].subdomain,
      response: "3-6 TIMES",
      responseLowerBand: "3",
      responseUpperBand: "6",
      advisory: "Advisory notes",
      advisory_0m_2m: "",
      advisory_2m_4m: "",
      advisory_4m_6m: "",
      advisory_6m_9m: "",
      advisory_9m_12m:
        "Babies at this age usually have 3-4 breast milk or formula feedings a day. They also have 3-4 solid meals/snacks a day. If your baby is eating less frequently and drinking milk too frequently, they may not be getting the appropriate nutrients and eating practice  they require. If your baby is hungry every 2 hours or more often, do think about these: i) your baby may be having difficulty taking adequate amounts at each meal; ii) they may not be hungry but simply enjoys eating for other reasons; iii) they may just eat more often with smaller feeds as it is more comfortable than having a larger amount at once.  Talk to a doctor or dietitian if you are worried about your baby’s feeding frequency.",
      advisory_12m_15m:
        "Children at this age usually have 2-4 breast milk or formula feedings a day. They also have 3-5 solid meals/snacks a day. If your child is hungry every 2 hours or more often, do think about these: i) your baby may be having difficulty taking adequate amounts at each meal; ii) they may not be hungry but simply enjoys eating for other reasons; iii) it may just eat more often with smaller feeds as it is more comfortable than having a larger amount at once. Breastfed children often need to be fed more often as breast milk is easy to digest. If your child eats more often than every 2 hours, he/she may be at risk of not eating enough or over-eating. Talk to a doctor or dietitian.",
      advisory_15m_23m:
        "Children at this age should eat only during the day. If your child asks for foods every 2 hours or more often, do think about these: i) your baby may be having difficulty taking adequate amounts at each meal; ii) they may not be hungry but simply enjoys eating for other reasons; iii) it may just eat more often with smaller feeds as it is more comfortable than having a larger amount at once. If your child eats more often than every 2 hours, he/she may be at risk of not eating enough or over-eating. Talk to a doctor or dietitian.",
      minAge: 9,
      maxAge: 23,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_HT2 is required.",
    },
    Q_HT3: {
      name: "Q_HT3",
      label_0m_2m: ``,
      label_2m_4m: ``,
      label_4m_6m: ``,
      label_6m_9m: ``,
      label_9m_12m: `Does your baby eat at a regular timing and interval?`,
      label_12m_15m: `Does your child eat at a regular timing and interval?`,
      label_15m_23m: `Does your child eat at a regular timing and interval?`,
      type: "text",
      domain: habituation[1].domain,
      subdomain: habituation[1].subdomain,
      response: "YES",
      advisory: "Advisory notes",
      advisory_0m_2m: "",
      advisory_2m_4m: "",
      advisory_4m_6m: "",
      advisory_6m_9m: "",
      advisory_9m_12m:
        "Offering meals and snacks at regular times encourages a better appetite regulation in your baby. This is crucial to develop healthy eating habits. Hunger can build up when the feeding time is too long, leading to overeating.",
      advisory_12m_15m:
        "Having a routine meal timing can help children know what to expect when it is time for meals or snacks. This is crucial to develop a better appetite regulation and healthy eating habits. Hunger can build up when the feeding time is too long, leading to food seeking behaviour and overeating.",
      advisory_15m_23m:
        "Having a routine meal timing can help children know what to expect when it is time for meals or snacks. This is crucial to develop a better appetite regulation and healthy eating habits. Hunger can build up when the feeding time is too long, leading to food seeking behaviour and overeating.",
      minAge: 9,
      maxAge: 23,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_HT3 is required.",
    },
    Q_HT4: {
      name: "Q_HT4",
      label_0m_2m: ``,
      label_2m_4m: ``,
      label_4m_6m: ``,
      label_6m_9m: ``,
      label_9m_12m: `Do you feed your baby in the middle of the night?`,
      label_12m_15m: `Do you feed your child in the middle of the night?`,
      label_15m_23m: `Do you feed your child in the middle of the night?`,
      type: "text",
      domain: habituation[1].domain,
      subdomain: habituation[1].subdomain,
      response: "NO",
      advisory: "Advisory notes",
      advisory_0m_2m: "",
      advisory_2m_4m: "",
      advisory_4m_6m: "",
      advisory_6m_9m: "",
      advisory_9m_12m:
        "Most babies at this age sleep through the night  and save eating for daytime. Babies who need feeding after midnight may not be eating enough during the day. In most cases, the baby may be comfort feeding instead of waking due to hunger.",
      advisory_12m_15m:
        "Children at this age have established circadian regulation. They sleep through the night and do not require to eat during the middle of the night. Children who need feeding during the post-midnight may not be eating enough during the day. In most cases, the child may be comfort feeding instead of waking due to hunger.",
      advisory_15m_23m:
        "Children at this age have established circadian regulation. They sleep through the night and do not require to eat during the middle of the night. Children who need feeding during the post-midnight may not be eating enough during the day. In most cases, the child may be comfort feeding instead of waking due to hunger.",
      minAge: 9,
      maxAge: 23,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_HT4 is required.",
    },
    Q_HSl1: {
      name: "Q_HSl1",
      label_0m_3m: `How long does your baby sleep per day, including naps?`,
      label_3m_11m: `How long does your baby sleep per day, including naps?`,
      label_11m_23m: `How long does your child sleep per day, including naps?`,
      type: "text",
      domain: habituation[2].domain,
      subdomain: habituation[2].subdomain,
      response: "14-17h",
      response_0m_3m: `14 HOURS - 17 HOURS`,
      response_3m_11m: `12 HOURS - 15 HOURS`,
      response_11m_23m: `11 HOURS - 14 HOURS`,
      responseLowerBand_0m_3m: "14",
      responseUpperBand_0m_3m: "17",
      responseLowerBand_3m_11m: "12",
      responseUpperBand_3m_11m: "15",
      responseLowerBand_11m_23m: "11",
      responseUpperBand_11m_23m: "14",
      advisory:
        "Babies at this age generally sleep between 14 to 17 hours. In order for your baby to sleep well, provide a regular routine and consistent bedtime schedule.  A conducive sleep environment is one which is quiet, dark, and at a slightly cool temperature. Let your baby sleep on his/her back, on a flat surface, together in the same room as caregivers to increase bonding supervision.",
      advisory_0m_3m:
        "Babies at this age generally sleep between 14 to 17 hours. In order for your baby to sleep well, provide a regular routine and consistent bedtime schedule.  A conducive sleep environment is one which is quiet, dark, and at a slightly cool temperature. Let your baby sleep on his/her back, on a flat surface, together in the same room as caregivers to increase bonding supervision.",
      advisory_3m_11m:
        "Babies at this age generally sleep between 12 to 15 hours. In order for your baby to sleep well, provide a regular routine and consistent bedtime schedule.  A conducive sleep environment is one which is quiet, dark, and at a slightly cool temperature. Let your baby sleep on his/her back, on a flat surface, together in the same room as caregivers to increase bonding supervision. Spend a short while to do something special and calming with your baby, such as soft talk or simple reading. Allow them to fall asleep on their own so that they can learn to self-soothe.",
      advisory_11m_23m:
        "Babies at this age generally sleep between 11 to 14 hours. In order for your baby to sleep well, provide a consistent bedtime schedule.  A conducive sleep environment is one which is quiet, dark, and at a slightly cool temperature. Develop a calming bedtime routine to help your child sleep well—this includes reading bedtime stories, listening to a lullaby, or even engaging in a soft talk, improving the bond between you and him/her. Allow them to fall asleep on their own so that they can learn to self-soothe. Avoid screen time.",
      minAge: 0,
      maxAge: 24,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_HSl1 is required.",
    },
    Q_HA1: {
      name: "Q_HA1",
      label_0m_3m: `How much time does your baby spend on his/her tummy per day?`,
      label_3m_11m: `How much time does your baby spend on his/her tummy/prone activities per day?`,
      label_11m_23m: `How much time does your child engage in physical activities per day, including energetic play?`,
      type: "text",
      domain: habituation[3].domain,
      subdomain: habituation[3].subdomain,
      response: ">30min",
      response_0m_3m: "30", //30 minutes `>=30 MINUTES`,
      response_3m_11m: "60", //60 minutes, `>=1 HOUR`,
      response_11m_23m: "180", //180 minutes, `>=3 HOURS`,
      advisory:
        "It is recommended for your baby to spend at least 30 minutes on their tummy spread through the day, under the supervision of an adult. This helps with your baby’s overall development.",
      advisory_0m_3m:
        "It is recommended for your baby to spend at least 30 minutes on their tummy spread through the day, under the supervision of an adult. This helps with your baby’s overall development.",
      advisory_3m_11m:
        "In combination, spend at least 1 hour on tummy/prone activities. Be physically active many times through the day, in a safe and supervised environment. Engage in non-screen based interactive floor play. Organizing a daily schedule will be useful in integrating physical activity into your baby’s routine. Refrain from restraining or leaving him/her unattended for more than an hour each time.",
      advisory_11m_23m:
        "Your child should have at least 3 hours of physical activity spread through the day. You could participate together with them, this improves you and your child’s engagement, interaction, and relationship. Include daily outdoor play, with light to moderately vigorous intensities.",
      minAge: 0,
      maxAge: 24,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_HA1 is required.",
    },
    Q_HA1_unit: {
      name: "Q_HA1_unit",
      label: `Q_HA1 unit`,
      type: "text",
      ageBreakPoint: 4,
      errorMsg: "Please select the time unit",
      invalidMsg: "Answer for Q_HA1_unit is required.",
    },
    Q_HSc1: {
      name: "Q_HSc1",
      label_0m_3m: `Do you expose your baby to electronic screen, including background screen?`,
      label_3m_11m: `Do you expose your baby to electronic screen, including background screen?`,
      label_11m_23m: `How much time does your child spend on electronic screen per day?`,
      type: "text",
      domain: habituation[4].domain,
      subdomain: habituation[4].subdomain,
      response: "NO",
      response_0m_3m: "NO",
      response_3m_11m: "NO",
      response_11m_23m: "1", //`<1 HOUR`,
      advisory:
        "Any form of screen time, including background screen time, is not recommended. Exposure to screen time during early childhood is associated with myopia, poorer sleep, increased sedentary behaviour, and childhood obesity. Your baby should be engaged with interactive activities instead, such as singing, reading, storytelling and imaginative play. This will be able to provide your child with the appropriate amount of stimulation required for optimal neurocognitive development. Have a daily routine for activities, as this can reduce sedentary behaviour and temptation to resort to using screentime.",
      advisory_0m_3m:
        "Any form of screen time, including background screen time, is not recommended. Exposure to screen time during early childhood is associated with myopia, poorer sleep, increased sedentary behaviour, and childhood obesity. Your baby should be engaged with interactive activities instead, such as singing, reading, storytelling and imaginative play. This will be able to provide your child with the appropriate amount of stimulation required for optimal neurocognitive development. Have a daily routine for activities, as this can reduce sedentary behaviour and temptation to resort to using screentime",
      advisory_3m_11m:
        "Any form of screen time, including background screen time, is not recommended. Exposure to screen time during early childhood is associated with myopia, poorer sleep, increased sedentary behaviour, and childhood obesity. Your baby should be engaged with interactive activities instead, such as singing, reading, storytelling and imaginative play. This will be able to provide your child with the appropriate amount of stimulation required for optimal neurocognitive development. Have a daily routine for activities, as this can reduce sedentary behaviour and temptation to resort to using screentime",
      advisory_11m_23m:
        "Limit screen time to <1 hour if your baby is less than 2 years old. Exposure to screen time during early childhood is associated with myopia, poorer sleep, increased sedentary behaviour, and childhood obesity. Instead, opt for interactive, non-screen based activities such as singing, reading, storytelling and imaginative play to promote cognitive and social development. Have a daily routine to reduce sedentary behaviour and temptation to resort to using screentime to engage your child.",
      minAge: 0,
      maxAge: 24,
      errorMsg: "Please answer the question above.",
      invalidMsg: "Answer for Q_HSc1 is required.",
    },
  },
};

export { form as default };
