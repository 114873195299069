import formFood from "pages/tracking/manage/schemas/formFood";

const {
  formField: {
    varietyType,
    foodType,
    drinkType,
    otherDrinkType,
    whoLed,
    location,
    otherLocation,
    sittingOn,
    otherSittingOn,
    utensil,
    otherUtensil,
    starttime,
    endtime,
  },
} = formFood;

const initialValuesFood = {
  [varietyType.name]: "",
  [foodType.name]: "",
  [drinkType.name]: "",
  [otherDrinkType.name]: "NIL",
  [whoLed.name]: "",
  [location.name]: "",
  [otherLocation.name]: "NIL",
  [sittingOn.name]: "",
  [otherSittingOn.name]: "NIL",
  [utensil.name]: "",
  [otherUtensil.name]: "NIL",
  [starttime.name]: "",
  [endtime.name]: "",
};

export { initialValuesFood as default };
