import { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import DashboardLayout from "components/templates/DashboardLayout";
import Topnav from "components/organisms/Topnav";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";
import PhoneInput from "react-phone-input-2";

import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";

import SubmissionProgress from "components/organisms/SubmissionProgress";
import PermissionDenied from "components/organisms/PermissionDenied";
import InvalidOperation from "components/organisms/InvalidOperation";
import IncompleteOperation from "components/organisms/IncompleteOperation";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";

import { Formik, Form } from "formik";
import form from "pages/nudges/schemas/form";
import initialValues from "pages/nudges/schemas/initialValues";
import NudgeDetails from "pages/nudges/manage/components/NudgeDetails";

import Dialog from "@mui/material/Dialog";
//import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { AllowedTo } from "react-abac";
import { useNudgeManager } from "pages/nudges/hooks/useNudgeManager";
import { List } from "rsuite";
import { sendWhatsAppNudges } from "services/callable";

function NudgeRecords() {
  const { period } = useParams();
  const [nudgeValues, setNudgeValues] = useState(initialValues);
  const [modeSubmission, setModeSubmission] = useState("");
  const { formId, formField } = form;
  const mobile = useRef("");
  const wsContents = useRef("");
  const [wsResponse, setWSReponse] = useState(null);

  const {
    modeTitle,
    modePermission,
    modeValidation,
    modeFieldDisabled,
    response,
    //dispatchDismiss,
    dispatchError,
    nudges,
    setNudges,
    submitMoveUp,
    submitMoveDown,
    submitEdit,
    submitCreate,
    submitDelete,
    submitUpdate,
    nudgeIndex,
    updateMode,
  } = useNudgeManager(period);

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newNudges = [...nudges];
      const moveData = newNudges.splice(oldIndex, 1);
      newNudges.splice(newIndex, 0, moveData[0]);
      newNudges.forEach((elm, index) => {
        newNudges[index].week = (index + 1).toString();
      });

      setNudges(newNudges);
      submitUpdate(newNudges);
    }
  };

  const handleSubmit = async (values, actions) => {
    if (updateMode.current === "edit") {
      submitEdit(values);
    } else if (updateMode.current === "insert") {
      submitCreate(values);
    }
    setOpenDialog(false);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [openSendWSDialog, setOpenSendWSDialog] = useState(false);
  const handleCloseSendWSDialog = () => {
    setOpenSendWSDialog(false);
  };

  return (
    <DashboardLayout>
      <Topnav title={modeTitle} />
      <AllowedTo perform={modePermission} no={() => <PermissionDenied />}>
        {response.isPending ? (
          <SubmissionProgress />
        ) : (
          <>
            {response.error && (
              <>
                {response.error?.name === "OperationInvalidError" && (
                  <InvalidOperation />
                )}
                {response.error?.name === "OperationIncompleteError" && (
                  <IncompleteOperation />
                )}
                <ErrorSnackbar
                  title={modeTitle}
                  dateTime=""
                  message={response.error?.message}
                  autoDismiss
                />
              </>
            )}
            {response.success && (
              <SuccessSnackbar
                title={modeTitle}
                dateTime=""
                message={response.success}
                autoDismiss
              />
            )}
            <MDBox mt={3} mb={3}>
              {nudges.length === 0 ? (
                <MDBox
                  display="flex"
                  justifyContent="right"
                  gap={1}
                  sx={{ p: 0, mr: 3, mb: 3 }}
                >
                  <MDButton
                    size="small"
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      nudgeIndex.current = 0;
                      updateMode.current = "insert";
                      setNudgeValues({
                        week: (nudgeIndex.current + 1).toString(),
                        contents: "",
                      });
                      setModeSubmission("CREATE");
                      setOpenDialog(true);
                    }}
                  >
                    INSERT A NEW NUDGE HERE
                  </MDButton>
                </MDBox>
              ) : (
                <List bordered hover sortable onSort={handleSortEnd}>
                  {!!nudges &&
                    nudges.map((nudge, index) => (
                      <List.Item key={index} index={index}>
                        <Card
                          raised={true}
                          sx={{
                            p: 4,
                            pt: 2,
                            m: 2,
                            border: "1px solid red",
                            backgroundColor: "warning",
                          }}
                        >
                          <MDBox display="flex" justifyContent="right" gap={1}>
                            <MDButton
                              size="small"
                              variant="gradient"
                              color="info"
                              iconOnly
                              onClick={() => {
                                const nudge = nudges[index];
                                nudgeIndex.current = index;
                                updateMode.current = "edit";
                                setNudgeValues({
                                  week: nudge.week,
                                  contents: nudge.contents,
                                });
                                setModeSubmission("UPDATE");
                                setOpenDialog(true);
                              }}
                            >
                              <ModeEditIcon />
                            </MDButton>
                            {index > 0 && (
                              <MDButton
                                size="small"
                                variant="gradient"
                                color="info"
                                iconOnly
                                onClick={() => {
                                  submitMoveUp(index);
                                }}
                              >
                                <ArrowUpwardIcon />
                              </MDButton>
                            )}
                            {index < nudges.length - 1 && (
                              <MDButton
                                size="small"
                                variant="gradient"
                                color="info"
                                iconOnly
                                onClick={() => {
                                  submitMoveDown(index);
                                }}
                              >
                                <ArrowDownwardIcon />
                              </MDButton>
                            )}
                            <MDButton
                              size="small"
                              variant="gradient"
                              color="info"
                              iconOnly
                              onClick={() => {
                                submitDelete(index);
                              }}
                            >
                              <RemoveCircleOutlineIcon />
                            </MDButton>
                          </MDBox>
                          <MDBox
                            borderRadius="lg"
                            sx={{
                              p: 0,
                              mt: 1,
                              mb: 1,
                              border: "1px solid grey",
                              opacity: 0.3,
                            }}
                          ></MDBox>
                          <MDBox display="flex" gap={2}>
                            <MDTypography variant="body2" fontWeight="medium">
                              Week {nudge.week}
                            </MDTypography>
                          </MDBox>
                          <MDBox display="flex" gap={2} textAlign="justify">
                            <MDTypography variant="body3" fontWeight="regular">
                              {nudge.contents}
                            </MDTypography>
                          </MDBox>
                          <MDBox
                            display="flex"
                            justifyContent="right"
                            gap={1}
                            sx={{ p: 0, mr: 0, mb: 0 }}
                          >
                            <MDButton
                              size="small"
                              variant="gradient"
                              color="success"
                              startIcon={<WhatsAppIcon />}
                              onClick={() => {
                                wsContents.current = nudge.contents;
                                mobile.current = ""; // Initial mobile number before opening the dialog box
                                setWSReponse(null); // Init variable "wsResponse" as null
                                setOpenSendWSDialog(true);
                              }}
                            >
                              Send Nudge
                            </MDButton>
                          </MDBox>
                        </Card>
                        <MDBox
                          display="flex"
                          justifyContent="right"
                          gap={1}
                          sx={{ p: 0, mr: 3, mb: 3 }}
                        >
                          <MDButton
                            size="small"
                            variant="gradient"
                            color="info"
                            onClick={() => {
                              nudgeIndex.current = index + 1; // Current nudge-index: index; New nudge to be inserted: index+1
                              updateMode.current = "insert";
                              setNudgeValues({
                                week: (nudgeIndex.current + 1).toString(),
                                contents: "",
                              });
                              setModeSubmission("CREATE");
                              setOpenDialog(true);
                            }}
                          >
                            INSERT A NEW NUDGE HERE
                          </MDButton>
                        </MDBox>
                      </List.Item>
                    ))}
                </List>
              )}
            </MDBox>
          </>
        )}
      </AllowedTo>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          <MDButton variant="gradient" color="success" iconOnly>
            <NotificationsActiveIcon />
          </MDButton>
          &nbsp; Insert New Nudge
        </DialogTitle>
        <DialogContent dividers>
          <MDBox mb={3}>
            <Formik
              enableReinitialize
              initialValues={nudgeValues}
              validationSchema={modeValidation}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form id={formId} autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <NudgeDetails
                        mainForm={formik}
                        mainFormField={formField}
                        modeDisabled={modeFieldDisabled}
                        dispatchMainError={dispatchError}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <MDBox></MDBox>
                        <MDButton
                          disabled={formik.isSubmitting}
                          type="submit"
                          variant="gradient"
                          color="info"
                        >
                          {modeSubmission}
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </MDBox>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSendWSDialog}
        onClose={handleCloseSendWSDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          <MDButton variant="gradient" color="success" iconOnly>
            <NotificationsActiveIcon />
          </MDButton>
          &nbsp; Enter Mobile Number
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PhoneInput
                country={"sg"}
                onChange={(value) => {
                  mobile.current = value;
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {wsResponse !== null && (
                <MDTypography variant="body2" fontWeight="medium">
                  {wsResponse}
                </MDTypography>
              )}
            </Grid>
            <Grid item xs={12}>
              <MDBox width="100%" display="flex" justifyContent="space-between">
                <MDBox></MDBox>
                <MDBox>
                  <MDButton
                    disabled={false}
                    variant="gradient"
                    color="success"
                    startIcon={<SendIcon />}
                    onClick={async () => {
                      if (mobile.current.length === 10) {
                        const response = await sendWhatsAppNudges({
                          type: "template",
                          name: "flags_nudge_template",
                          mobile: mobile.current,
                          contents: wsContents.current,
                        });

                        parseInt(response.data.statusCode) === 200 &&
                          setWSReponse("Nudge sent!");

                        parseInt(response.data.statusCode) >= 400 &&
                          setWSReponse("Fail to send nudge!");
                      } else {
                        setWSReponse("Mobile number is invalid!");
                      }
                    }}
                  >
                    send
                  </MDButton>{" "}
                  <MDButton
                    disabled={false}
                    variant="gradient"
                    color="info"
                    startIcon={<CloseIcon />}
                    onClick={() => {
                      handleCloseSendWSDialog();
                    }}
                  >
                    close
                  </MDButton>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
}

export default NudgeRecords;
