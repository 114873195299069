import { useEffect, useCallback, useState, useReducer, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { Permission } from "models/abac";
import { useAbac } from "react-abac";

import validations from "pages/nudges/schemas/validations";
import initialValues from "pages/nudges/schemas/initialValues";

const collectionPathNudges = "nudges";
const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "INITIAL":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "UPDATED_NUDGES":
      return {
        isPending: false,
        data: action.payload,
        success: `Nudge records updated.`,
        error: null,
      };
    case "CREATED_NEW_NUDGE":
      return {
        isPending: false,
        data: action.payload,
        success: `New nudge added.`,
        error: null,
      };
    case "DELETED_NUDGE":
      return {
        isPending: false,
        data: action.payload,
        success: `Nudge deleted.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useNudgeManager = (period) => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const { pathname } = useLocation();
  const { retrieveDoc, createDoc, updateDoc, serverTimestamp } = useDocument();
  const [nudges, setNudges] = useState([]);
  const { userHasPermissions } = useAbac();
  const { user } = useAuthContext();
  const nudgeIndex = useRef(null);
  const updateMode = useRef(null);

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      const checkPathname = "/nudges/" + period;
      if (!pathname.includes(checkPathname)) {
        throw operationInvalidError;
      }

      const retrievedNudges = await retrieveDoc(collectionPathNudges, period);
      !!retrievedNudges.data?.nudges
        ? setNudges(retrievedNudges.data?.nudges)
        : setNudges([]);

      dispatchIfNotUnmounted({
        type: "INITIAL",
        payload: "Works in progress ...",
      });
    } catch (err) {
      dispatchError(err);
    }
  }, [dispatchIfNotUnmounted, pathname, dispatchError, retrieveDoc, period]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  const submitMoveUp = async (idx) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.UPDATE_NUDGE)) {
        const newNudges = [...nudges];
        const nudgeRemoved = newNudges.splice(idx, 1);
        newNudges.splice(idx - 1, 0, nudgeRemoved[0]);
        newNudges.forEach((elm, index) => {
          newNudges[index].week = (index + 1).toString();
        });
        setNudges(newNudges);

        const updatedDoc = await updateDoc(collectionPathNudges, period, {
          nudges: newNudges,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "UPDATED_NUDGES",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to update the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to update the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitMoveDown = async (idx) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.UPDATE_NUDGE)) {
        const newNudges = [...nudges];
        const nudgeRemoved = newNudges.splice(idx, 1);
        newNudges.splice(idx + 1, 0, nudgeRemoved[0]);
        newNudges.forEach((elm, index) => {
          newNudges[index].week = (index + 1).toString();
        });
        setNudges(newNudges);

        const updatedDoc = await updateDoc(collectionPathNudges, period, {
          nudges: newNudges,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "UPDATED_NUDGES",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to update the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to update the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitDelete = async (idx) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.DELETE_NUDGE)) {
        const newNudges = [...nudges];
        newNudges.splice(idx, 1);
        newNudges.forEach((elm, index) => {
          newNudges[index].week = (index + 1).toString();
        });

        setNudges(newNudges);

        const updatedDoc = await updateDoc(collectionPathNudges, period, {
          nudges: newNudges,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "DELETED_NUDGE",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to update the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to update the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitUpdate = async (nudges) => {
    dispatchIfNotUnmounted({ type: "IS_PENDING" });
    try {
      if (userHasPermissions(Permission.UPDATE_NUDGE)) {
        const updatedDoc = await updateDoc(collectionPathNudges, period, {
          nudges: nudges,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "UPDATED_NUDGES",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to edit the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to edit the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitEdit = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.UPDATE_NUDGE)) {
        nudges[nudgeIndex.current].contents = values.contents;
        setNudges(nudges);

        const updatedDoc = await updateDoc(collectionPathNudges, period, {
          nudges: nudges,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "UPDATED_NUDGES",
          payload: updatedDoc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to edit the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to edit the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const submitCreate = async (values) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.CREATE_NUDGE)) {
        let doc = "";
        if (nudgeIndex.current > 0) {
          nudges.splice(nudgeIndex.current, 0, values);
          nudges.forEach((elm, index) => {
            nudges[index].week = (index + 1).toString();
          });

          setNudges(nudges);
          doc = await updateDoc(collectionPathNudges, period, {
            nudges: nudges,
            modifiedAt: serverTimestamp(),
            modifiedBy: user.uid,
          });
        } else {
          doc = await createDoc(
            collectionPathNudges,
            {
              nudges: [values],
              modifiedAt: serverTimestamp(),
              modifiedBy: user.uid,
            },
            user.uid, //createdBy
            period //documentId
          );
          setNudges([values]);
        }

        dispatchIfNotUnmounted({
          type: "CREATED_NEW_NUDGE",
          payload: doc.data,
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to edit the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to edit the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  const modeValidation = validations;
  const modeFieldDisabled = false;
  let modePermission = Permission.READ_ALL_NUDGES;
  const modeTitle =
    period === "Months0to2"
      ? "Nudges (0-2 Months)"
      : period === "Months2to4"
      ? "Nudges (2-4 Months)"
      : period === "Months4to6"
      ? "Nudges (4-6 Months)"
      : period === "Months6to9"
      ? "Nudges (6-9 Months)"
      : period === "Months9to12"
      ? "Nudges (9-12 Months)"
      : period === "Months12to15"
      ? "Nudges (12-15 Months)"
      : period === "Months15to24"
      ? "Nudges (15-24 Months)"
      : "";

  return {
    modeTitle,
    modePermission,
    modeValidation,
    modeFieldDisabled,
    response,
    dispatchDismiss,
    dispatchError,
    nudges,
    setNudges,
    submitMoveUp,
    submitMoveDown,
    submitEdit,
    submitCreate,
    submitDelete,
    submitUpdate,
    nudgeIndex,
    updateMode,
  };
};
