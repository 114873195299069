import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CloseIcon from "@mui/icons-material/Close";
import Icon from "@mui/material/Icon";
import { ErrorMessage } from "formik";

import { DatePicker, Slider } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import isBefore from "date-fns/isBefore";

const arrayRange = (start, stop, step) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );

const durationsArray = arrayRange(5, 60, 5);

const durations = durationsArray.map((elm) =>
  elm === 0 ? { label: elm + " min" } : { label: elm + " mins" }
);

function MilkDetails({
  mainForm,
  mainFormField,
  closeInputPanel,
  calendarDate,
}) {
  const {
    leftBreast,
    timePerFeedLeft,
    rightBreast,
    timePerFeedRight,
    typeofMilk,
    milkVolume,
    typeofFeed,
    whoLed,
    starttime,
    endtime,
  } = mainFormField;

  const { initialValues, values, setFieldValue } = mainForm;
  const initTimePerFeedLeftValue =
    initialValues.timePerFeedLeft.length > 0
      ? {
          label: initialValues.timePerFeedLeft,
        }
      : null;
  const [timePerFeedLeftValue, setTimePerFeedLeftValue] = useState(
    initTimePerFeedLeftValue
  );
  const initTimePerFeedRightValue =
    initialValues.timePerFeedRight.length > 0
      ? {
          label: initialValues.timePerFeedRight,
        }
      : null;
  const [timePerFeedRightValue, setTimePerFeedRightValue] = useState(
    initTimePerFeedRightValue
  );
  const [typeofMilkValue, setTypeofMilkValue] = useState(
    initialValues.typeofMilk
  );
  const [milkVolumeValue, setMilkVolumeValue] = useState(
    initialValues.milkVolume
  ); //useState(0);
  const [whoLedValue, setWhoLedValue] = useState(initialValues.whoLed);

  const [startTimeValue, setStartTimeValue] = useState("");
  const [endTimeValue, setEndTimeValue] = useState("");
  const [disableBeforeValue, setDisableBeforeValue] = useState("");
  //const [disableBeforeHour, setDisableBeforeHour] = useState(0);

  useEffect(() => {
    try {
      const dateDisableBefour = new Date(
        calendarDate.getFullYear(),
        calendarDate.getMonth(),
        calendarDate.getDate(),
        0,
        0,
        0
      );
      setDisableBeforeValue(dateDisableBefour);

      const Now = new Date();
      if (initialValues.starttime.length > 0) {
        setStartTimeValue(new Date(initialValues.starttime));
        setFieldValue(starttime.name, initialValues.starttime);
      } else {
        const newDate = new Date(
          calendarDate.getFullYear(),
          calendarDate.getMonth(),
          calendarDate.getDate(),
          Now.getHours(),
          Now.getMinutes(),
          Now.getSeconds()
        );
        setStartTimeValue(newDate);
        setFieldValue(starttime.name, newDate.toISOString());
        //setDisableBeforeHour(newDate.getHours());
      }
      if (initialValues.endtime.length > 0) {
        setEndTimeValue(new Date(initialValues.endtime));
        setFieldValue(endtime.name, initialValues.endtime);
      } else {
        const newDate = new Date(
          calendarDate.getFullYear(),
          calendarDate.getMonth(),
          calendarDate.getDate(),
          Now.getHours(),
          Now.getMinutes(),
          Now.getSeconds()
        );
        newDate.setMinutes(newDate.getMinutes() + 15);
        setEndTimeValue(newDate);
        setFieldValue(endtime.name, newDate.toISOString());
      }
    } catch (err) {
      console.log(err);
    }
  }, [initialValues, starttime, endtime, setFieldValue, calendarDate]);

  useEffect(() => {
    const breastFeedInput =
      values.timePerFeedLeft.length > 0 || values.timePerFeedRight.length > 0
        ? true
        : false;
    const bottleFeedInput = values.typeofMilk?.length > 0 ? true : false;
    breastFeedInput && bottleFeedInput
      ? setFieldValue(typeofFeed.name, "Breast Feed and Bottle Feed")
      : breastFeedInput
      ? setFieldValue(typeofFeed.name, "Breast Feed")
      : bottleFeedInput
      ? setFieldValue(typeofFeed.name, "Bottle Feed")
      : setFieldValue(typeofFeed.name, "");
  }, [
    values.timePerFeedLeft,
    values.timePerFeedRight,
    values.typeofMilk,
    setFieldValue,
    typeofFeed,
  ]);

  return (
    <MDBox m={1} mt={2}>
      <MDBox
        display="flex"
        borderRadius="lg"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: "rgb(243,138,180)" }}
        p={1}
      >
        <MDBox />
        <MDTypography variant="body2" fontWeight="bold" color="white">
          MILK FEEDS
        </MDTypography>
        <MDButton
          variant="text"
          size="medium"
          color="white"
          iconOnly
          onClick={() => closeInputPanel("milk")}
        >
          <CloseIcon />
        </MDButton>
      </MDBox>

      <MDBox m={1} mt={3} mb={0}>
        <Grid
          container
          rowSpacing={{ xs: 0 }}
          columnSpacing={{ xs: 1 }}
          alignItems="center"
        >
          <Grid item xs={5}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                Breast Feed
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={7}></Grid>

          {/* Left breast feeding */}
          <Grid item xs={5}>
            <MDBox display="flex" alignItems="center" ml={1}>
              <Icon>done</Icon> <MDBox mr={1}></MDBox>
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {leftBreast.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={7}>
            <>
              <Autocomplete
                disablePortal
                id="autocomplete-timePerFeedLeftValue"
                value={timePerFeedLeftValue}
                options={durations}
                getOptionLabel={(option) => (option?.label ? option.label : "")}
                size="small"
                onChange={(event, newValue) => {
                  setTimePerFeedLeftValue(newValue);
                  if (newValue !== null) {
                    setFieldValue(timePerFeedLeft.name, newValue.label);
                  } else {
                    setFieldValue(timePerFeedLeft.name, "");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Time per feed"
                    inputProps={{ ...params.inputProps, readOnly: true }}
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
              />
            </>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            <FormTextFieldFast
              form={mainForm}
              field={timePerFeedLeft}
              variant="standard"
              disabled={true}
              hideLabel={true}
              hideField={true}
            />
          </Grid>

          {/* Right breast feeding */}
          <Grid item xs={5}>
            <MDBox display="flex" alignItems="center" ml={1}>
              <Icon>done</Icon> <MDBox mr={1}></MDBox>
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {rightBreast.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={7}>
            <>
              <Autocomplete
                disablePortal
                id="autocomplete-timePerFeedRightValue"
                value={timePerFeedRightValue}
                options={durations}
                getOptionLabel={(option) => (option?.label ? option.label : "")}
                size="small"
                onChange={(event, newValue) => {
                  setTimePerFeedRightValue(newValue);
                  if (newValue !== null) {
                    setFieldValue(timePerFeedRight.name, newValue.label);
                  } else {
                    setFieldValue(timePerFeedRight.name, "");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Time per feed"
                    inputProps={{ ...params.inputProps, readOnly: true }}
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
              />
            </>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            <FormTextFieldFast
              form={mainForm}
              field={timePerFeedRight}
              variant="standard"
              disabled={true}
              hideLabel={true}
              hideField={true}
            />
          </Grid>
          <Grid item xs={12} mb={2}></Grid>

          {/* Bottle feeding */}
          <Grid item xs={5}>
            <MDBox display="flex">
              <MDTypography variant="button" fontWeight="regular" color="dark">
                Bottle Feed
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={7}></Grid>
          {/* Type of Milk */}
          <Grid item xs={5}>
            <MDBox display="flex" alignItems="center" ml={1}>
              <Icon>done</Icon> <MDBox mr={1}></MDBox>
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {typeofMilk.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={7}>
            <ToggleButtonGroup
              color="info"
              size="small"
              variant="contained"
              value={typeofMilkValue}
              exclusive
              fullWidth={true}
              onChange={(event, value) => {
                setTypeofMilkValue(value);
                setFieldValue(typeofMilk.name, value);
                if (value === null) {
                  setMilkVolumeValue(0);
                }
              }}
              aria-label="Platform"
            >
              <ToggleButton value="Formula">Formula</ToggleButton>
              <ToggleButton value="Breast Milk">Breast Milk</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            <FormTextFieldFast
              form={mainForm}
              field={typeofMilk}
              variant="standard"
              disabled={true}
              hideLabel={true}
              hideField={true}
            />
          </Grid>

          {/* Milk volume */}
          <Grid item xs={5}>
            <MDBox display="flex" alignItems="center" ml={1}>
              <Icon>done</Icon> <MDBox mr={1}></MDBox>
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {milkVolume.label}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={7}>
            <TextField
              id="Volume"
              label="Volume"
              variant="outlined"
              type={"number"}
              value={milkVolumeValue.toString()}
              onChange={(e) => {
                if (!!e.target.value) {
                  setMilkVolumeValue(parseInt(e.target.value));
                  setFieldValue(milkVolume.name, e.target.value);
                } else {
                  setMilkVolumeValue(0);
                  setFieldValue(milkVolume.name, 0);
                }
              }}
            ></TextField>
          </Grid>
          <Grid item xs={12} mb={1}></Grid>
          <Grid item xs={12} ml={5} mr={2} mb={3}>
            <Slider
              //defaultValue={50}
              value={parseInt(milkVolumeValue)}
              step={10}
              graduated
              progress
              min={0}
              max={200}
              renderMark={(mark) => {
                if ([0, 100, 200, 300].includes(mark)) {
                  return (
                    <span>
                      <MDTypography
                        variant="caption"
                        fontWeight="regular"
                        color="dark"
                      >
                        {mark} ml
                      </MDTypography>
                    </span>
                  );
                }
                return null;
              }}
              onChange={(value) => {
                setMilkVolumeValue(value);
                setFieldValue(milkVolume.name, value);
              }}
            />
          </Grid>
          {/* Error message - Type of Feed */}
          <Grid item xs={12}>
            <MDBox mt={-2} ml={3} mr={1} mb={1}>
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={typeofFeed.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          {/* Error message - Milk volume */}
          <Grid item xs={12}>
            <MDBox mt={-2} ml={3} mr={1} mb={1}>
              <MDTypography
                component="div"
                variant="caption"
                color="error"
                fontWeight="regular"
                sx={{ whiteSpace: "pre-wrap" }}
              >
                <ErrorMessage name={milkVolume.name}></ErrorMessage>
              </MDTypography>
            </MDBox>
          </Grid>
          {/* Caregiver led or baby led */}
          {/*<Grid item xs={5}>
            <MDBox display="flex" alignItems="center" ml={1}>
              <Icon>done</Icon> <MDBox mr={1}></MDBox>
              <MDTypography variant="button" fontWeight="regular" color="dark">
                {whoLed.label}
              </MDTypography>
            </MDBox>
            </Grid>*/}
          <Grid item xs={12}>
            <MDBox display="flex" alignItems="center" ml={1}>
              <Icon>done</Icon> <MDBox mr={1}></MDBox>
              <ToggleButtonGroup
                color="info"
                size="small"
                variant="contained"
                value={whoLedValue}
                exclusive
                fullWidth={true}
                onChange={(event, value) => {
                  setWhoLedValue(value);
                  setFieldValue(whoLed.name, value);
                }}
                aria-label="Platform"
              >
                <ToggleButton value="Caregiver Led">Caregiver Led</ToggleButton>
                <ToggleButton value="Baby Led">Baby Led</ToggleButton>
              </ToggleButtonGroup>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox ml={3} mr={1}>
              <FormTextFieldFast
                form={mainForm}
                field={whoLed}
                variant="standard"
                disabled={true}
                hideLabel={true}
                hideField={true}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} mb={2}></Grid>
          {/* Start time */}
          <Grid item xs={5}>
            <MDTypography variant="button" fontWeight="regular" color="dark">
              {starttime.label}
            </MDTypography>
          </Grid>
          <Grid item xs={7}>
            <DatePicker
              format="HH:mm"
              block
              editable={false}
              appearance="default"
              placement="topEnd"
              style={{ width: 300 }}
              size="sm"
              value={startTimeValue instanceof Date ? startTimeValue : null}
              onChange={(date) => {
                if (date !== null) {
                  const newDate = new Date(
                    calendarDate.getFullYear(),
                    calendarDate.getMonth(),
                    calendarDate.getDate(),
                    date.getHours(),
                    date.getMinutes(),
                    0
                  );
                  setFieldValue(starttime.name, newDate.toISOString());
                  setStartTimeValue(newDate);
                  //setDisableBeforeHour(newDate.getHours());
                }
              }}
            />
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            <FormTextFieldFast
              form={mainForm}
              field={starttime}
              variant="standard"
              disabled={true}
              hideLabel={true}
              hideField={true}
            />
          </Grid>
          {/* End time */}
          <Grid item xs={5}>
            <MDTypography variant="button" fontWeight="regular" color="dark">
              {endtime.label}
            </MDTypography>
          </Grid>
          {endTimeValue instanceof Date && (
            <Grid item xs={7}>
              <DatePicker
                format="dd/MM/yyyy HH:mm"
                block
                editable={false}
                appearance="default"
                placement="topEnd"
                style={{ width: 300 }}
                size="sm"
                value={endTimeValue instanceof Date ? endTimeValue : null}
                onChange={(date) => {
                  if (date !== null) {
                    setFieldValue(endtime.name, date.toISOString());
                    setEndTimeValue(date);
                  }
                }}
                shouldDisableDate={(date) =>
                  isBefore(date, new Date(disableBeforeValue))
                }
                //hideHours={(hour) => hour < disableBeforeHour}
              />
            </Grid>
          )}
          <Grid item xs={5}></Grid>
          <Grid item xs={7}>
            <FormTextFieldFast
              form={mainForm}
              field={endtime}
              variant="standard"
              disabled={true}
              hideLabel={true}
              hideField={true}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default MilkDetails;
