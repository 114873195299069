import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "components/templates/DashboardLayout";
import TopnavV3 from "components/organisms/TopnavV3";
//import moment from "moment";
//import ErrorSnackbar from "components/molecules/Notifications/ErrorSnackbar";
//import SuccessSnackbar from "components/molecules/Notifications/SuccessSnackbar";
//import InvalidOperation from "components/organisms/InvalidOperation";
//import IncompleteOperation from "components/organisms/IncompleteOperation";
//import PromptAddBabyProfile from "components/organisms/PromptAddBabyProfile";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/atoms/MDBox";
import MDButton from "components/atoms/MDButton";
import MDTypography from "components/atoms/MDTypography";
import SubmissionProgress from "components/organisms/SubmissionProgress";
//import Divider from "@mui/material/Divider";
//import Footer from "components/organisms/Footer";
import { useTrackingWeeklyManager } from "pages/tracking/hooks/useTrackingWeeklyManager";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  Type as ListType,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";
import DailyChart from "pages/tracking/manageweekly/components/DailyChart";
import Legend from "pages/tracking/manageweekly/components/Legend";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import Highcharts from "highcharts/highcharts";
import xrange from "highcharts/modules/xrange";
xrange(Highcharts);

const _ = require("lodash");

function TrackingWeekly() {
  //const { mode } = useParams();
  const mode = "weeklylog";
  const {
    response,
    //modeValidation,
    //modeFieldDisabled,
    //modeSubmit,
    //modeTitle,
    //formId,
    //formField,
    //submitNew,
    //calendarDate,
    //setCalendarDate,
    dispatchError,
    babyProfile,
    //setIsUnmounted,
  } = useTrackingWeeklyManager(mode);
  const recordList = response.data.recordList;
  //const avatarURL =
  //  response.data?.avatarURL !== undefined ? response.data.avatarURL : null;

  const avatarURL =
    babyProfile?.avatarAttachments !== undefined
      ? babyProfile.avatarAttachments[0].attachmentURL
      : null;

  const [openCalendar, setOpenCalendar] = useState(false);
  const [weeklyDates, setWeeklyDates] = useState(null);
  const [weeklyDocRecords, setWeeklyDocRecords] = useState([]);

  const getNewDate = useCallback((date, dayOffset) => {
    const d = _.cloneDeep(date);
    return new Date(d.setDate(d.getDate() + dayOffset));
  }, []);

  const getStartEndWeek = useCallback(() => {
    const d = new Date();
    const day = d.getDay();
    return {
      zeroDay: getNewDate(d, -1 - day),
      firstDay: getNewDate(d, 0 - day), //new Date(d.setDate(diffFirstDay)),
      secondDay: getNewDate(d, 1 - day),
      thirdDay: getNewDate(d, 2 - day),
      fourthDay: getNewDate(d, 3 - day),
      fifthDay: getNewDate(d, 4 - day),
      sixthDay: getNewDate(d, 5 - day),
      lastDay: getNewDate(d, 6 - day),
    };
  }, [getNewDate]);

  const plusMinusOneWeek = (weeklyDates, mode) => {
    const diffFirstDay = mode === "plus" ? 7 : -7;

    return {
      zeroDay: getNewDate(weeklyDates.firstDay, diffFirstDay - 1),
      firstDay: getNewDate(weeklyDates.firstDay, diffFirstDay + 0),
      secondDay: getNewDate(weeklyDates.firstDay, diffFirstDay + 1),
      thirdDay: getNewDate(weeklyDates.firstDay, diffFirstDay + 2),
      fourthDay: getNewDate(weeklyDates.firstDay, diffFirstDay + 3),
      fifthDay: getNewDate(weeklyDates.firstDay, diffFirstDay + 4),
      sixthDay: getNewDate(weeklyDates.firstDay, diffFirstDay + 5),
      lastDay: getNewDate(weeklyDates.firstDay, diffFirstDay + 6),
    };
  };

  const getDateString = (date) => {
    const dateString = date.toDateString();
    return dateString.slice(0, dateString.length - 4);
  };

  useEffect(() => {
    if (!!weeklyDates && !!recordList) {
      const option = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      };
      const calendarDates = [];
      calendarDates.push(
        weeklyDates.zeroDay.toLocaleDateString("en-SG", option)
      );
      calendarDates.push(
        weeklyDates.firstDay.toLocaleDateString("en-SG", option)
      );
      calendarDates.push(
        weeklyDates.secondDay.toLocaleDateString("en-SG", option)
      );
      calendarDates.push(
        weeklyDates.thirdDay.toLocaleDateString("en-SG", option)
      );
      calendarDates.push(
        weeklyDates.fourthDay.toLocaleDateString("en-SG", option)
      );
      calendarDates.push(
        weeklyDates.fifthDay.toLocaleDateString("en-SG", option)
      );
      calendarDates.push(
        weeklyDates.sixthDay.toLocaleDateString("en-SG", option)
      );
      calendarDates.push(
        weeklyDates.lastDay.toLocaleDateString("en-SG", option)
      );

      const newWeeklyRecords = [];
      for (let ii = 0; ii < calendarDates.length - 1; ii++) {
        const calendarDateDaybefore = calendarDates[ii];
        const findIdxDaybefore = recordList.findIndex(
          (elm) => elm.calendarDate === calendarDateDaybefore
        );
        const documentIdDaybefore =
          findIdxDaybefore > -1 ? recordList[findIdxDaybefore].documentId : "";

        const calendarDateToday = calendarDates[ii + 1];
        const findIdxToday = recordList.findIndex(
          (elm) => elm.calendarDate === calendarDateToday
        );
        const documentIdToday =
          findIdxToday > -1 ? recordList[findIdxToday].documentId : "";

        newWeeklyRecords.push({
          documentIdDaybefore: documentIdDaybefore,
          calendarDateDaybefore: calendarDateDaybefore,
          documentIdToday: documentIdToday,
          calendarDateToday: calendarDateToday,
          documentId: documentIdDaybefore,
        });
      }
      setWeeklyDocRecords(newWeeklyRecords);
    }
  }, [weeklyDates, recordList]);

  useEffect(() => {
    try {
      const week = getStartEndWeek();
      setWeeklyDates(week);
    } catch (err) {
      dispatchError(err);
    }
  }, [dispatchError, response.data, getStartEndWeek]);

  const navigate = useNavigate();
  const navigateAfter = (path, millisecond) => {
    return new Promise(() => {
      setTimeout(() => {
        navigate(path);
      }, millisecond);
    });
  };

  const leadingActions = (value, idx) => (
    <LeadingActions>
      <SwipeAction
        onClick={async () => {
          const path =
            "/tracking/dailylog/" +
            value.calendarDateToday.substring(0, 2) +
            value.calendarDateToday.substring(3, 5) +
            value.calendarDateToday.substring(6, 10);
          await navigateAfter(path, 1000);
        }}
      >
        <MDBox
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: "green",
          }}
          pl={1}
          pr={1}
        >
          <MDButton variant="text" size="large" color="white" iconOnly>
            <AddCircleOutlineIcon />
          </MDButton>
          <MDTypography variant="button" fontWeight="regular" color="white">
            Daily
          </MDTypography>
        </MDBox>
      </SwipeAction>
    </LeadingActions>
  );

  return (
    <DashboardLayout>
      <TopnavV3
        avatarURL={avatarURL}
        title={"F.O.R.M. Tracker"}
        subtitle={"Feeding. Outdoor. Rest. Mealtime."}
      />
      {
        /*response.isPending*/ false ? (
          <SubmissionProgress />
        ) : (
          <>
            {/*response.error && (
            <>
              {response.error?.name === "OperationInvalidError" && (
                <InvalidOperation />
              )}
              {response.error?.name === "OperationIncompleteError" && (
                <IncompleteOperation />
              )}
              <ErrorSnackbar
                title={modeTitle}
                dateTime=""
                message={response.error?.message}
                autoDismiss
              />
            </>
              )*/}
            {/*response.success && (
            <SuccessSnackbar
              title={modeTitle}
              dateTime=""
              message={response.success}
              autoDismiss
            />
          )*/}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDBox mb={2} />
                <Card id="menu-panel">
                  {!!weeklyDates && (
                    <MDBox
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      m={1}
                      mb={0}
                    >
                      <MDButton
                        variant="text"
                        size="large"
                        color="dark"
                        iconOnly
                        onClick={() => {
                          setWeeklyDates(
                            plusMinusOneWeek(weeklyDates, "minus")
                          );
                        }}
                      >
                        <ArrowBackIosIcon />
                      </MDButton>
                      <MDTypography
                        variant="button"
                        color="dark"
                        fontWeight="medium"
                        opacity={0.8}
                      >
                        {getDateString(weeklyDates.firstDay)}
                      </MDTypography>
                      <MDBox mr={1} ml={1}>
                        -
                      </MDBox>
                      <MDTypography
                        variant="button"
                        color="dark"
                        fontWeight="medium"
                        opacity={0.8}
                      >
                        {getDateString(weeklyDates.lastDay)}
                      </MDTypography>
                      <MDBox>
                        <MDButton
                          variant="text"
                          size="large"
                          color="dark"
                          iconOnly
                          onClick={() =>
                            openCalendar
                              ? setOpenCalendar(false)
                              : setOpenCalendar(true)
                          }
                        >
                          <CalendarMonthIcon />
                        </MDButton>
                      </MDBox>
                      <MDButton
                        variant="text"
                        size="large"
                        color="dark"
                        iconOnly
                        onClick={() => {
                          const newWeeklyDates = plusMinusOneWeek(
                            weeklyDates,
                            "plus"
                          );
                          setWeeklyDates(newWeeklyDates);
                        }}
                      >
                        <ArrowForwardIosIcon />
                      </MDButton>
                    </MDBox>
                  )}
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    m={1}
                    mt={0}
                  >
                    <Grid
                      container
                      rowSpacing={{ xs: 1 }}
                      columnSpacing={{ xs: 1 }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item xs={4} md={2}>
                        <Legend
                          backgroundcolor={"rgb(243,138,180)"}
                          description={"MILK"}
                        />
                      </Grid>
                      <Grid item xs={4} md={2}>
                        <Legend
                          backgroundcolor={"rgb(201,21, 94)"}
                          description={"NON-MILK"}
                        />
                      </Grid>
                      <Grid item xs={4} md={2}>
                        <Legend
                          backgroundcolor={"rgb(79,145,205)"}
                          description={"PHYSICAL"}
                        />
                      </Grid>
                      <Grid item xs={4} md={2}>
                        <Legend
                          backgroundcolor={"rgb(43,99, 149)"}
                          description={"SEDENTARY"}
                        />
                      </Grid>
                      <Grid item xs={4} md={2}>
                        <Legend
                          backgroundcolor={"rgb(146, 208, 80)"}
                          description={"SLEEP / REST"}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>

                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {openCalendar && (
                      <DateRangePicker
                        oneTap
                        showOneCalendar
                        hoverRange="week"
                        ranges={[]}
                        showHeader={false}
                        editable={false}
                        placeholder="Select Week"
                        onChange={(newDates) => {
                          setOpenCalendar(false);
                          const sunday = new Date(newDates[0]);
                          setWeeklyDates({
                            zeroDay: getNewDate(sunday, -1),
                            firstDay: getNewDate(sunday, 0),
                            secondDay: getNewDate(sunday, 1),
                            thirdDay: getNewDate(sunday, 2),
                            fourthDay: getNewDate(sunday, 3),
                            fifthDay: getNewDate(sunday, 4),
                            sixthDay: getNewDate(sunday, 5),
                            lastDay: getNewDate(sunday, 6),
                          });
                        }}
                      />
                    )}
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card id="swipeable-list-weeklylog">
                  {weeklyDocRecords.length > 0 && (
                    <SwipeableList type={ListType.IOS}>
                      {weeklyDocRecords.map((value, idx) => (
                        <SwipeableListItem
                          fullSwipe={true}
                          leadingActions={leadingActions(value, idx)}
                          key={idx}
                        >
                          <Grid
                            container
                            rowSpacing={{ xs: 0 }}
                            columnSpacing={{ xs: 0 }}
                            alignItems="center"
                            sx={{ backgroundColor: "white" }}
                            p={0}
                          >
                            <Grid item xs={12}>
                              <DailyChart data={value} />
                            </Grid>
                          </Grid>
                        </SwipeableListItem>
                      ))}
                    </SwipeableList>
                  )}
                </Card>
              </Grid>
            </Grid>
          </>
        )
      }
    </DashboardLayout>
  );
}

export default TrackingWeekly;
