const Role = Object.freeze({
  //SUPERADMIN: "SUPERADMIN",
  ADMIN: "ADMIN",
  PARTICIPANT: "PARTICIPANT",
  //STUDENT: "STUDENT",
  //MEMBER: "MEMBER",
});

const Permission = Object.freeze({
  // permission of dashboard (as shown on side nav bar)
  VIEW_SUPERADMIN_DASHBOARD: "VIEW_SUPERADMIN_DASHBOARD",
  VIEW_ADMIN_DASHBOARD: "VIEW_ADMIN_DASHBOARD",
  VIEW_MEMBER_DASHBOARD: "VIEW_MEMBER_DASHBOARD",

  // permission of action (as shown on side nav bar)
  VIEW_SETTINGS_ACTION: "VIEW_SETTINGS_ACTION",

  // permission for data (also use for firestore rules)
  PERFORM_SUPERADMIN_ACTION: "PERFORM_SUPERADMIN_ACTION",
  PERFORM_ADMIN_ACTION: "PERFORM_ADMIN_ACTION",

  READ_ALL_ROLES: "READ_ALL_ROLES",
  CREATE_ROLE: "CREATE_ROLE",
  UPDATE_ROLE: "UPDATE_ROLE",
  READ_ROLE: "READ_ROLE",
  DELETE_ROLE: "DELETE_ROLE",

  READ_ALL_USERS: "READ_ALL_USERS",
  CREATE_USER: "CREATE_USER",
  UPDATE_USER: "UPDATE_USER",
  READ_USER: "READ_USER",
  DELETE_USER: "DELETE_USER",

  // PURGE_ROLE: "PURGE_ROLE",
  //READ_ALL_COMPANIES: "READ_ALL_COMPANIES",
  //CREATE_COMPANY: "CREATE_COMPANY",
  //UPDATE_COMPANY: "UPDATE_COMPANY",
  //READ_COMPANY: "READ_COMPANY",
  //DELETE_COMPANY: "DELETE_COMPANY",
  // PURGE_COMPANY: "PURGE_COMPANY",
  // PURGE_USER: "PURGE_USER",
  READ_BABY_PROFILE: "READ_BABY_PROFILE",
  READ_BABIES_PROFILE: "READ_BABIES_PROFILE",
  CREATE_BABY_PROFILE: "CREATE_BABY_PROFILE",
  UPDATE_BABY_PROFILE: "UPDATE_BABY_PROFILE",

  READ_ASSESSMENT_RECORD: "READ_ASSESSMENT_RECORD",
  CREATE_ASSESSMENT_RECORD: "CREATE_ASSESSMENT_RECORD",
  UPDATE_ASSESSMENT_RECORD: "UPDATE_ASSESSMENT_RECORD",
  DELETE_ASSESSMENT_RECORD: "DELETE_ASSESSMENT_RECORD",

  READ_ACTIVITY_RECORD: "READ_ACTIVITY_RECORD",
  CREATE_ACTIVITY_RECORD: "CREATE_ACTIVITY_RECORD",
  UPDATE_ACTIVITY_RECORD: "UPDATE_ACTIVITY_RECORD",
  DELETE_ACTIVITY_RECORD: "DELETE_ACTIVITY_RECORD",

  READ_NUDGE: "READ_NUDGE",
  READ_ALL_NUDGES: "READ_ALL_NUDGES",
  CREATE_NUDGE: "CREATE_NUDGE",
  UPDATE_NUDGE: "UPDATE_NUDGE",
  DELETE_NUDGE: "DELETE_NUDGE",
  SET_NUDGE_SENDING_SCHEDULE: "SET_NUDGE_SENDING_SCHEDULE",
});

export { Role, Permission };
