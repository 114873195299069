const formMilk = {
  formId: "milk-form",
  formField: {
    leftBreast: {
      name: "leftBreast",
      type: "text",
      label: "Left",
      errorMsg: "Left Breast is required.",
      invalidMsg: "Left Breast is invalid.",
    },
    timePerFeedLeft: {
      name: "timePerFeedLeft",
      type: "text",
      label: "Time per feed (Left)",
      errorMsg: "Time per feed (Left) is required.",
      invalidMsg: "Time per feed (Left) is invalid.",
    },
    rightBreast: {
      name: "rightBreast",
      type: "text",
      label: "Right",
      errorMsg: "Right Breast is required.",
      invalidMsg: "Right Breast is invalid.",
    },
    timePerFeedRight: {
      name: "timePerFeedRight",
      type: "text",
      label: "Time per feed (Right)",
      errorMsg: "Time per feed (Right) is required.",
      invalidMsg: "Time per feed (Right) is invalid.",
    },
    typeofMilk: {
      name: "typeofMilk",
      type: "text",
      label: "Type of Milk",
      errorMsg: "Type of Milk is required.",
      invalidMsg: "Type of Milk is invalid.",
    },
    milkVolume: {
      name: "milkVolume",
      type: "text",
      label: "Volume",
      errorMsg: "Volume of Milk is required.",
      invalidMsg: "Volume of Milk is invalid.",
    },
    typeofFeed: {
      name: "typeofFeed",
      type: "text",
      label: "Type of Feed",
      errorMsg: "Type of Feed of Milk is required.",
      invalidMsg: "Type of Feed of Milk is invalid.",
    },
    whoLed: {
      name: "whoLed",
      type: "text",
      label: "Who led",
      errorMsg: "Who led is required.",
      invalidMsg: "Who led is invalid.",
    },
    starttime: {
      name: "starttime",
      type: "text",
      label: "Start Time",
      errorMsg: "Start Time is required.",
      invalidMsg: "Start Time is invalid.",
    },
    endtime: {
      name: "endtime",
      type: "text",
      label: "End Time",
      errorMsg: "End Time is required.",
      invalidMsg: "End Time is invalid.",
    },
  },
};

export { formMilk as default };
