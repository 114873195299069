// Don't handle exception in this React Hook to allow exception bubbling
// Handle exception at upwards level:
//   send error log back to server
//   show generic error message on snackbar to inform user to report to admin

import { useCallback } from "react";
import { addFile, purgeFile, getURL } from "services/storage";

export const useFile = () => {
  const getFileRef = useCallback(async (fullPath) => {
    const fileURL = await getURL(fullPath);
    const fileRef = {
      path: fullPath,
      name: fullPath.substring(fullPath.lastIndexOf("/") + 1),
      url: fileURL,
    };

    return fileRef;
  }, []);

  const uploadFile = useCallback(async (fullPath, file) => {
    const resultRef = await addFile(fullPath, file);
    const downloadURL = await getURL(resultRef.fullPath);
    const fileRef = {
      path: resultRef.fullPath,
      name: resultRef.name,
      url: downloadURL,
    };

    return fileRef;
  }, []);

  const deleteFile = useCallback(async (fullPath) => {
    await purgeFile(fullPath);
  }, []);

  // const downloadFile = () => {};

  // const listFile = () => {};

  return {
    getFileRef,
    uploadFile,
    // downloadFile,
    deleteFile,
    // listFile,
  };
};
