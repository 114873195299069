const formSleep = {
  formId: "sleep-form",
  formField: {
    location: {
      name: "location",
      type: "text",
      label: "Location",
      errorMsg: "Location is required.",
      invalidMsg: "Location is invalid.",
    },
    otherLocation: {
      name: "otherLocation",
      type: "text",
      label: "Others (Location)",
      errorMsg: "Other Location is required.",
      invalidMsg: "Other Location is invalid.",
    },
    selfCompanion: {
      name: "selfCompanion",
      type: "text",
      label: "Companion",
      errorMsg: "Companion is required.",
      invalidMsg: "Companion is invalid.",
    },
    position: {
      name: "position",
      type: "text",
      label: "Position",
      errorMsg: "Position is required.",
      invalidMsg: "Position is invalid.",
    },
    sleepingTool: {
      name: "sleepingTool",
      type: "text",
      label: "Sleeping Tool",
      errorMsg: "Sleeping Tool is required.",
      invalidMsg: "Sleeping Tool is invalid.",
    },
    otherSleepingTool: {
      name: "otherSleepingTool",
      type: "text",
      label: "Others (Sleeping Tool)",
      errorMsg: "Other Sleeping Tool is required.",
      invalidMsg: "Other Sleeping Tool is invalid.",
    },
    duration: {
      name: "duration",
      type: "text",
      label: "Duration",
      errorMsg: "Duration is required.",
      invalidMsg: "Duration is invalid.",
    },
    starttime: {
      name: "starttime",
      type: "text",
      label: "Start Time",
      errorMsg: "Start Time is required.",
      invalidMsg: "Start Time is invalid.",
    },
    endtime: {
      name: "endtime",
      type: "text",
      label: "End Time",
      errorMsg: "End Time is required.",
      invalidMsg: "End Time is invalid.",
    },
  },
};

export { formSleep as default };
