import * as Yup from "yup";
import form from "pages/nudges/schemas/form";

const {
  formField: { week, contents },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [week.name]: Yup.string().required(week.errorMsg).max(200, week.invalidMsg),
  [contents.name]: Yup.string()
    .required(contents.errorMsg)
    .max(5000, contents.invalidMsg),
});

export { validations as default, noValidation };
