import * as Yup from "yup";
import form, {
  rolesForm,
  signatureAttachmentsForm,
  photoAttachmentsForm,
  avatarAttachmentsForm,
} from "pages/account/manage/schemas/form";

const {
  formField: {
    babyName,
    userName,
    userGroup,
    email,
    roles,
    emailSignOff,
    signatureAttachments,
    photoAttachments,
    avatarAttachments,
  },
} = form;

const noValidation = Yup.object().shape({});

const validations = Yup.object().shape({
  [babyName.name]: Yup.string()
    .required(babyName.errorMsg)
    .max(200, babyName.invalidMsg),
  [userName.name]: Yup.string()
    .nullable()
    //.required(userName.errorMsg)
    .max(200, userName.invalidMsg),
  [userGroup.name]: Yup.string()
    .nullable()
    //.required(userGroup.errorMsg)
    .max(200, userGroup.invalidMsg),
  [email.name]: Yup.string()
    .nullable()
    //.required(email.errorMsg)
    .max(200, email.invalidMsg)
    .email(email.invalidMsg),
  [roles.name]: Yup.array()
    .nullable()
    // .min(1, serviceTeams.min1Msg)
    .max(20, roles.max20Msg),
  [emailSignOff.name]: Yup.string()
    .nullable()
    //.required(emailSignOff.errorMsg)
    .max(5000, emailSignOff.invalidMsg),
  [signatureAttachments.name]: Yup.array()
    .nullable()
    .max(20, signatureAttachments.max20Msg),
  [photoAttachments.name]: Yup.array()
    .min(1, photoAttachments.min1Msg)
    .max(20, photoAttachments.max20Msg),
  [avatarAttachments.name]: Yup.array()
    .min(1, avatarAttachments.min1Msg)
    .max(20, avatarAttachments.max20Msg),
});

const {
  formField: { roleName },
} = rolesForm;

const rolesValidation = Yup.object().shape({
  [roleName.name]: Yup.string()
    .nullable()
    .required(roleName.errorMsg)
    .max(200, roleName.invalidMsg),
});

const {
  formField: { signatureAttachmentFile },
} = signatureAttachmentsForm;

const signatureAttachmentsValidation = Yup.object().shape({
  [signatureAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(signatureAttachmentFile.errorMsg)
    .test(
      "fileSize",
      signatureAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

const {
  formField: { photoAttachmentFile },
} = photoAttachmentsForm;

const photoAttachmentsValidation = Yup.object().shape({
  [photoAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(photoAttachmentFile.errorMsg)
    .test(
      "fileSize",
      photoAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

const {
  formField: { avatarAttachmentFile },
} = avatarAttachmentsForm;

const avatarAttachmentsValidation = Yup.object().shape({
  [avatarAttachmentFile.name]: Yup.mixed()
    .nullable()
    .required(avatarAttachmentFile.errorMsg)
    .test(
      "fileSize",
      avatarAttachmentFile.invalidMsg,
      (value) => !value || (value && value.size <= 5 * 1024 * 1024)
    ),
});

export {
  validations as default,
  noValidation,
  rolesValidation,
  signatureAttachmentsValidation,
  photoAttachmentsValidation,
  avatarAttachmentsValidation,
};
