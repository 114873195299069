import PropTypes from "prop-types";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import MDInput from "components/atoms/MDInput";

function DatePickerWrapper({
  type,
  label,
  placeholder,
  size,
  variant,
  fullWidth,
  disabled,
  error,
  success,
  ...rest
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        {...rest}
        renderInput={(params) => (
          <MDInput
            {...params}
            fullWidth={!!fullWidth}
            type={type}
            label={label}
            placeholder={placeholder}
            size={size}
            variant={variant}
            disabled={disabled}
            error={error}
            success={success}
          />
        )}
      />
    </LocalizationProvider>
  );
}

// Setting default values for the props of DatePickerWrapperRadioGroupWrapper
DatePickerWrapper.defaultProps = {
  showTitle: true,
};

// typechecking props for DatePickerWrapper
DatePickerWrapper.propTypes = {
  name: PropTypes.string,
  value: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
};

export default DatePickerWrapper;
