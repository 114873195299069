import formPhysical from "pages/tracking/manage/schemas/formPhysical";

const {
  formField: {
    environment,
    tummyTime,
    intensity,
    duration,
    starttime,
    endtime,
  },
} = formPhysical;

const initialValuesPhysical = {
  [environment.name]: "",
  [tummyTime.name]: "",
  [intensity.name]: "",
  [duration.name]: "",
  [starttime.name]: "",
  [endtime.name]: "",
};

export { initialValuesPhysical as default };
