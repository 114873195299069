import { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/atoms/MDBox";
import MDTypography from "components/atoms/MDTypography";
import MDButton from "components/atoms/MDButton";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import FormTextFieldFast from "components/molecules/Formik/FormTextFieldFast";
import FormCheckboxGroup from "components/molecules/Formik/FormCheckboxGroup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { sendWhatsAppNudges } from "services/callable";
import { roleOptions } from "pages/settings/users/manage/schemas/setups";

function BasicInformation({
  mainForm,
  mainFormField,
  modeDisabled,
  dispatchMainError,
}) {
  const { setFieldValue } = mainForm;
  const { userName, userGroup, email, mobile, roles } = mainFormField;
  const [welcomeMsgSent, setWelcomeMsgSent] = useState(null);
  const [open, setOpen] = useState(false);
  const showStartDialog = () => {
    setOpen(true);
  };
  const closeStartDialog = () => {
    setOpen(false);
  };

  return (
    <Card id="basic-information" sx={{ overflow: "visible" }}>
      <MDBox m={3}>
        <MDBox mb={3}>
          <MDTypography variant="h5">Basic Information</MDTypography>
        </MDBox>

        <MDBox>
          <Grid
            container
            rowSpacing={{ xs: 1, sm: 2 }}
            columnSpacing={{ xs: 1, sm: 3 }}
          >
            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {userName.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={userName}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {userGroup.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={userGroup}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {email.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextFieldFast
                form={mainForm}
                field={email}
                variant="standard"
                disabled={modeDisabled}
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {mobile.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneInput
                country={"sg"}
                value={mainForm.values.mobile}
                onChange={(value) => {
                  mainForm.values.mobile = value;
                  setFieldValue(mobile.name, value);
                }}
              />
              <Grid item xs={12} sm={6}>
                <FormTextFieldFast
                  form={mainForm}
                  field={mobile}
                  variant="standard"
                  disabled={true}
                  hideLabel={true}
                  hideField={true}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDButton
                variant="gradient"
                color="success"
                onClick={async () => {
                  if (mainForm.values.mobile.length === 10) {
                    const response = await sendWhatsAppNudges({
                      type: "template",
                      name: "flags_welcome",
                      mobile: mainForm.values.mobile,
                    });
                    console.log(JSON.stringify(response));
                    console.log(JSON.stringify(response.data.statusCode));
                    console.log(
                      JSON.stringify(typeof response.data.statusCode)
                    );
                    parseInt(response.data.statusCode) === 200
                      ? setWelcomeMsgSent("WhatsApp Welcome Message Sent")
                      : parseInt(response.data.statusCode) >= 400
                      ? setWelcomeMsgSent("Failed to send WhatsApp Welcome!")
                      : setWelcomeMsgSent("");
                    /*const response = await sendWhatsAppNudges({
                    //type: "template",
                    name: "flags_nudge_template",
                    mobile: "6596526088",
                    contents: " ... weekly nudge for FLAGs ...",
                    });*/
                  } else {
                    showStartDialog();
                  }
                }}
              >
                WhatsApp Invite
              </MDButton>
            </Grid>

            <Grid item xs={12} sm={12}>
              {welcomeMsgSent !== null && (
                <MDTypography variant="body3" fontWeight="bold" color="success">
                  {welcomeMsgSent}
                </MDTypography>
              )}
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDTypography variant="body3" fontWeight="bold">
                {roles.label}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormCheckboxGroup
                showTitle={false}
                form={mainForm}
                field={roles}
                options={roleOptions}
                disabled={modeDisabled}
                hiddenOptions={["MEMBER"]}
                row
              />
            </Grid>
            <Grid item xs={12} sm={4}></Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={closeStartDialog}>
        <DialogTitle textAlign="center">ALERT!</DialogTitle>
        <DialogContent dividers>
          <MDBox
            borderRadius="lg"
            sx={{ p: 1, mb: 2, border: "1px solid red" }}
          >
            <MDBox>
              <MDTypography variant="body2" fontWeight="medium">
                Invalid Mobile Number
              </MDTypography>
            </MDBox>
            <MDBox>Please enter a valid mobile number to proceed.</MDBox>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton
            size="small"
            variant="gradient"
            color="success"
            onClick={closeStartDialog}
          >
            OKAY
          </MDButton>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default BasicInformation;
