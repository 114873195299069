import { useEffect, useCallback, useState, useReducer, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";
import { Permission } from "models/abac";
import { useAbac } from "react-abac";

//import validations from "pages/nudges/schemas/validations";
//import initialValues from "pages/nudges/schemas/initialValuesTimeSetting";
const initialValues = { day: "", timeISOstring: "" };

const collectionPath = "nudges";
const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "INITIAL":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "SCHEDULE_NUDGES":
      return {
        isPending: false,
        data: action.payload,
        success: `Sending schedule updated.`,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useNudgeSettingManager = () => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);
  const { pathname } = useLocation();
  const { retrieveDoc, updateDoc, serverTimestamp } = useDocument();
  const [nudges, setNudges] = useState([]);
  const { userHasPermissions } = useAbac();
  const { user } = useAuthContext();
  const nudgeIndex = useRef(null);
  const updateMode = useRef(null);

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchDismiss = useCallback(
    () => dispatchIfNotUnmounted({ type: "DISMISS" }),
    [dispatchIfNotUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        !["PermissionDeniedError", "OperationInvalidError"].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      if (!pathname.includes("/nudges/settings")) {
        throw operationInvalidError;
      }

      const retrievedDoc = await retrieveDoc(collectionPath, "settings");

      dispatchIfNotUnmounted({
        type: "INITIAL",
        payload: {
          day: retrievedDoc.data.day,
          timeISOstring: retrievedDoc.data.timeISOstring,
        },
      });
    } catch (err) {
      dispatchError(err);
    }
  }, [dispatchIfNotUnmounted, pathname, dispatchError, retrieveDoc]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    return () => {
      setIsUnmounted(true);
    };
  }, [dispatchError, validateOperation]);

  const submitUpdate = async (values) => {
    dispatchIfNotUnmounted({ type: "IS_PENDING" });
    try {
      if (userHasPermissions(Permission.UPDATE_NUDGE)) {
        console.log(JSON.stringify(values));
        const timeObject = new Date(values.timeISOstring);
        const updatedDoc = await updateDoc(collectionPath, "settings", {
          timeISOstring: values.timeISOstring,
          time: timeObject,
          day: values.day,
          modifiedAt: serverTimestamp(),
          modifiedBy: user.uid,
        });

        dispatchIfNotUnmounted({
          type: "SCHEDULE_NUDGES",
          payload: {
            day: updatedDoc.data.day,
            timeISOstring: updatedDoc.data.timeISOstring,
          },
        });
      } else {
        dispatchIfNotUnmounted({
          type: "ERROR",
          error: "Permission Denied. You are not allowed to edit the nudges.",
        });

        let error = new Error(
          "Permission Denied. You are not allowed to edit the nudges."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  let modePermission = Permission.SET_NUDGE_SENDING_SCHEDULE;
  const modeTitle = "Nudge Sending Schedule";

  return {
    modeTitle,
    modePermission,
    response,
    dispatchDismiss,
    dispatchError,
    nudges,
    setNudges,
    submitUpdate,
    nudgeIndex,
    updateMode,
  };
};
