import { useCallback, useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "context/AuthContext";
import { useDocument } from "hooks/useDocument";

//import initialValues from "pages/assessments/manage/schemas/initialValues";
import validations, {
  noValidation,
} from "pages/assessments/manage/schemas/validations";
import form from "pages/assessments/manage/schemas/form";

import { useAbac } from "react-abac";
import { Permission } from "models/abac";
import { useAppContext } from "context/AppContext";

const collectionPathTrackingDailyRecords = "trackingDailyRecords";
const collectionPathTracking = "tracking";
const initialValues = [];
const initialState = {
  data: initialValues,
  isPending: false,
  error: null,
  success: null,
};

const reducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    case "DISMISS":
      return {
        isPending: false,
        data: initialValues,
        success: null,
        error: null,
      };
    case "IS_PENDING":
      return {
        isPending: true,
        data: initialValues,
        success: null,
        error: null,
      };
    case "INITIAL_DAILYRECORDS":
      return {
        isPending: false,
        data: action.payload,
        success: null,
        error: null,
      };
    case "UPDATED_DAILYRECORDS":
      return {
        isPending: false,
        data: action.payload,
        success: `New record added.`,
        error: null,
      };
    case "RETRIEVED_BABYPROFILE":
      return {
        isPending: false,
        data: initialValues,
        success: `Successfully retrieved baby profile.`,
        error: null,
        age: action.payload,
      };
    case "ERROR":
      return {
        isPending: false,
        data: payload,
        success: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useTrackingWeeklyManager = (mode) => {
  const [response, dispatch] = useReducer(reducer, initialState);
  const [isUnmounted, setIsUnmounted] = useState(false);

  const { userHasPermissions } = useAbac();
  const { user } = useAuthContext();
  const { retrieveDoc, updateDoc, createDoc, serverTimestamp } = useDocument();
  const { pathname } = useLocation();
  const { formId, formField } = form;

  const { selectedBabyProfile } = useAppContext();
  const { babyUid, babyProfile } = selectedBabyProfile;
  //console.log(JSON.stringify(babyProfile));

  const [calendarDate, setCalendarDate] = useState(new Date());

  const dispatchIfNotUnmounted = useCallback(
    (action) => {
      if (!isUnmounted) {
        dispatch(action);
      }
    },
    [isUnmounted]
  );

  const dispatchError = useCallback(
    (err) => {
      console.error(err);
      if (
        ![
          "PermissionDeniedError",
          "OperationInvalidError",
          "PromptAddBabyProfile",
        ].includes(err.name)
      ) {
        err.message = "The operation couldn't be completed";
        err.name = "OperationIncompleteError";
        // TODO: send error stack to server
      }
      dispatchIfNotUnmounted({
        type: "ERROR",
        error: err,
      });
    },
    [dispatchIfNotUnmounted]
  );

  const validateOperation = useCallback(async () => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      let operationInvalidError = new Error(
        "Invalid Operation. You are not allowed to carry out this activity."
      );
      operationInvalidError.name = "OperationInvalidError";

      let promptAddBabyProfile = new Error(
        "Please add a baby profile to proceed."
      );
      promptAddBabyProfile.name = "PromptAddBabyProfile";

      switch (mode) {
        case "weeklylog":
          if (!pathname.includes("/tracking/weeklylog")) {
            throw operationInvalidError;
          }

          const retrievedTrackingDoc = await retrieveDoc(
            collectionPathTracking,
            babyUid
          );

          const data = {
            recordList: retrievedTrackingDoc.data.records,
          };

          dispatchIfNotUnmounted({
            type: "INITIAL_DAILYRECORDS",
            payload: data,
          });
          break;
        default:
          throw operationInvalidError;
      }
    } catch (err) {
      dispatchError(err);
    }
  }, [
    dispatchError,
    dispatchIfNotUnmounted,
    pathname,
    mode,
    babyUid,
    retrieveDoc,
  ]);

  useEffect(() => {
    try {
      validateOperation();
    } catch (err) {
      dispatchError(err);
    }
    /*return () => {
      setIsUnmounted(true);
    };*/
  }, [dispatchError, validateOperation]);

  let modeTitle = "";
  let modeSubmit = "";
  let modeValidation = noValidation;
  let modeFieldDisabled = true;

  switch (mode) {
    case "dailylog":
      modeTitle = "F.O.R.M. Tracker";
      modeSubmit = "Submit";
      modeValidation = validations;
      modeFieldDisabled = false;
      break;
    case "qasurvey":
      modeTitle = "Assessment";
      modeSubmit = "Submit";
      modeValidation = validations;
      modeFieldDisabled = false;
      break;
    case "qaadvisory":
      modeTitle = "Advisory";
      modeSubmit = "Submit";
      modeValidation = noValidation;
      modeFieldDisabled = false;
      break;
    case "qascore":
      modeTitle = "Scores";
      modeSubmit = "Submit";
      modeValidation = noValidation;
      modeFieldDisabled = false;
      break;
    default:
      modeTitle = "Illegal Action";
  }

  const submitNew = async (values, calendarDate) => {
    try {
      dispatchIfNotUnmounted({ type: "IS_PENDING" });
      if (userHasPermissions(Permission.SUBMIT_ASSESSMENT)) {
        let retrievedDoc = await retrieveDoc(
          collectionPathTracking,
          babyUid /*user.uid*/
        );

        if (retrievedDoc.data === undefined) {
          retrievedDoc = await createDoc(
            collectionPathTracking,
            {
              records: [],
            },
            user.uid, //createdBy,
            babyUid //documentId
          );
        }

        const m_date = new Date();
        const localeDate = m_date.toLocaleDateString("en-SG", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        });

        let dailyRecords = [];
        let findIdx = -1;
        if (retrievedDoc.data.records.length > 0)
          findIdx = retrievedDoc.data.records.findIndex(
            (elm) => elm.calendarDate === localeDate
          );

        if (findIdx < 0) {
          // Create new Daily Record for Tracking function
          const createdDoc = await createDoc(
            collectionPathTrackingDailyRecords,
            {
              records: values,
              babyUid: babyUid,
              calendarDate: localeDate,
            },
            user.uid // createdBy
          );
          dailyRecords = createdDoc.data.records;

          // Update "records" variable on "tracking" collection
          const rec = {
            calendarDate: localeDate,
            documentId: createdDoc.id,
          };
          let recArray;
          retrievedDoc.data === undefined
            ? (recArray = [rec])
            : (recArray = [...retrievedDoc.data.records, rec]);
          await updateDoc(collectionPathTracking, babyUid, {
            ...retrievedDoc.data,
            records: recArray,
          });
        } else {
          // Update document "trackingDailyRecords"
          const record = retrievedDoc.data.records[findIdx];
          const updatedDoc = await updateDoc(
            collectionPathTrackingDailyRecords,
            record.documentId,
            {
              records: values,
              babyUid: babyUid,
              calendarDate: localeDate,
              modifiedAt: serverTimestamp(),
              modifiedBy: user.uid,
            }
          );
          dailyRecords = updatedDoc.data.records;
        }

        console.log(JSON.stringify("UPDATED_DAILYRECORDS"));
        const dispatchType = "UPDATED_DAILYRECORDS";
        dispatchIfNotUnmounted({
          type: dispatchType,
          payload: dailyRecords,
        });
      } else {
        let error = new Error(
          "Permission Denied. You are not allowed to make submission."
        );
        error.name = "PermissionDeniedError";
        throw error;
      }
    } catch (err) {
      dispatchError(err);
    }
  };

  return {
    response,
    modeValidation,
    modeFieldDisabled,
    modeSubmit,
    modeTitle,
    formId,
    formField,
    submitNew,
    calendarDate,
    setCalendarDate,
    dispatchError,
    setIsUnmounted,
    babyUid,
    babyProfile,
  };
};
